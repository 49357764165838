import ContactUsPage from "@/components/pages/ContactUsPage.vue";
import NotFoundPage from "@/components/pages/NotFoundPage.vue";

export default [
    // contact & 404 Not Found Page
    {
        name: 'contact-us',
        path: '/contact-us',
        component: ContactUsPage
    },
    {
        path: '/contact',
        redirect: '/contact-us'
    },

    {
        name: 'not-found-404',
        path: '/:notFound(.*)',
        component: NotFoundPage
    },
]