import LearnAndExplorePage from "@/components/pages/LearnAndExplorePage.vue";
import LearningResource from "@/components/learn-and-explore/LearningResource.vue";

export default [
    // '/learn-and-explore/' - Stand alone route
    // '/learn-and-explore/:resourceSlug' - THIS IS A NESTED ROUTE - IT LOADS ON THE SAME PAGE, NOT A STAND-ALONE PAGE
    // And we use the resourceSlug to display the learning resource in questions.
    { name: 'learn-and-explore', path: '/learn-and-explore', component: LearnAndExplorePage,
        children: [
            {
                name: 'learn-and-explore-resource',
                path: ':resourceSlug',        // /learn-and-explore/make-the-most-of-your-earnings => LearningResource component will load in the <router-view> of the parent LearnAndExplore component
                component: LearningResource,
                props: true
            },
        ]
    },
]