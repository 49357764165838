import store from "@/store"

export default function useRetirementTaxBenefitCalculator(employeeContributionNew) {

    const taxBrackets = store.getters.currentYearFinancialTables.brackets
    const takeHomePayCurrent = store.getters.nettPayApi.api
    // const takeHomePayCurrent = 31769

    const employeeContributionCurrent = 0
    const monthlyIncome = store.getters.monthlyIncome
    // const monthlyIncome = 40000

    const findTaxBracket = (income, taxBrackets) => {
        for (let bracket in taxBrackets) {
            let lowerLimit = taxBrackets[bracket].lower
            let upperLimit = taxBrackets[bracket].upper

            if (income >= lowerLimit && (income <= upperLimit || upperLimit === null)) {
                return taxBrackets[bracket]
            }
        }

        return null // returns null if income doesn't fall into any tax bracket
    }

    const calculateTaxBenefit = (income, contribution, taxBrackets) => {

        let bracket = findTaxBracket(income, taxBrackets)
        if (bracket) {
            return bracket.taxRate * contribution
        } else {
            return 0 // return 0 if no tax bracket fits the income
        }
    }


    const taxBenefitCurrent = calculateTaxBenefit(monthlyIncome * 12, employeeContributionCurrent, taxBrackets)
    const taxBenefitNew = calculateTaxBenefit(monthlyIncome * 12, employeeContributionNew, taxBrackets)

    const takeHomePayNew = takeHomePayCurrent - employeeContributionNew + taxBenefitNew

    return {
        taxBenefitCurrent,
        taxBenefitNew,
        takeHomePayNew
    }
}