import {createRouter, createWebHistory} from 'vue-router'

import routes from "@/router/routes/routes"
import {scrollBehavior} from "@/router/router-scroll-behaviour"
import {globalAfterEachGuard} from "@/router/global-nav-guards/after-each";
import {globalBeforeEach} from "@/router/global-nav-guards/before-each";

// create router instance passing it config object
const router = createRouter({
    history: createWebHistory(),
    routes: routes,
    scrollBehavior,
});

// global navigation guards (local guards are passed in component or routes config obj)
router.afterEach(globalAfterEachGuard)
router.beforeEach(globalBeforeEach)

// export router instance to main.js to be used by bentoApp
export default router