<template>
    <card-general>
        <section-header>{{ title }}</section-header>
        
        <div id="loading-changes-outer-cont">
            <p class="mt-n2">A company wide leave report including leave balances and future leave requests for all employees.</p>
            
            <button-bento class="mt-2 w-100" size="lg" color="primary" @click="downloadLeaveReportHandler" v-if="!isDownloadingReport">
                Download Leave Report
            </button-bento>
            
            <bento-alert class="mt-4" static color="danger" v-if="apiDownloadError" dismiss :autohide="false">
                {{ apiDownloadError }}
            </bento-alert>
            
            <div id="loading-changes-cont" v-if="isDownloadingReport">
                <loading-small v-model="isDownloadingReport" parentSelector="#loading-changes-cont" loadingText="Downloading ..."/>
            </div>
        </div>
    </card-general>
</template>

<script setup>
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
    import {ref} from "vue"
    import LoadingSmall from "@/components/UI/v2/loading/LoadingSmall"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert"
    import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue";
    import {isDevelopment, isProd} from "@/hooks/api/app-environment-origin";
    import {useStore} from "vuex";
    const store = useStore()

    
    defineProps({
        title: {
            type: String,
            default: "Download Leave Report"
        }
    })

    const employerId = store.getters.employer_id
    
    const isDownloadingReport = ref(false)
    const apiDownloadError = ref(false)
    
    const downloadLeaveReportHandler = async () => {
        isDownloadingReport.value = true
        let reportUrl = `https://staging.mybento.net/reports/leave/employer_leave_report_xlsx/${employerId}/`
        if (isProd()) {
            reportUrl = `https://app.mybento.net/reports/leave/employer_leave_report_xlsx/${employerId}/`
        }
        if (isDevelopment()) {
            reportUrl = `https://development.mybento.net/reports/leave/employer_leave_report_xlsx/${employerId}/`
        }
        window.open(reportUrl, '_blank')
        isDownloadingReport.value = false
    }
</script>

<style lang="scss" scoped>
    #loading-changes-outer-cont {
        min-height: 50px;
    }
    
    #loading-changes-cont {
        min-height: 50px;
    }
</style>