// employee store module

import mutations from './mutations'
import actions from './actions'
import getters from './getters'

import PayslipFinancialsModule from './payslip-financials/index'
import MedicalAidEmployeeModule from "./medical-aid/medical-aid-employee/index"
import ReimbursementsModule from "./reimbursements/index"
import LeaveModule from './leave/index'
import MedicalAddonsModule from "./medical-aid/medical-addons/index"
import MedicalContractModule from "./medical-aid/medical-contract-actions/index"
import RetirementEmployeeModule from "@/store/modules/employee/retirement/retirement-employee/index"
import RetirementContractActionsModule from "@/store/modules/employee/retirement/retirement-contract-actions/index"
import PayrollChangesModule from "./payroll-changes/index"
import ProfileModule from './profile-and-directory/index'
import InsuranceModule from './insurance/index'

// local data objects
import data from '@/data/data.js'

export default {
    modules: {
        PayslipFinancialsModule,
        MedicalAidEmployeeModule,
        ReimbursementsModule,
        LeaveModule,
        MedicalAddonsModule,
        MedicalContractModule,
        RetirementEmployeeModule,
        RetirementContractActionsModule,
        PayrollChangesModule,
        ProfileModule,
        InsuranceModule
    },

    state() {
        return {
            // TODO:
            //  remove this once the API is fully integrated
            // # importing local data here (these should in theory all be removed with full API integration)
            essentialBenefits: data.essentialBenefits,

            // The employee object - '/employees/{{userApiId}}
            employee: null,
            isTermsAccepted: false,
            lastEmployeeFetch: null,
        }
    },

    mutations,
    actions,
    getters,
}