// employee/payslip-financials/deductions getters
// All deductions

import {createPayslipGetter} from '@/hooks/general/getters-helpers';
import {isValidArray} from "@/hooks/general/type-and-value-checks";

export default {

    deductionsList: (_, getters) => getters.latestPayslip?.employee_deductions || null,


    // employee medical aid deduction located in medical-aid-employee/getters.js


    // Pension Fund - Employee
    // We do this custom and don't use the createPayslipGetter because we need to be able to update 'planned'
    // Calculate the current value using the Pension Fund Contract object as a cross reference (or whatever it may be called).
    employeePensionFund(_, getters) {
        let apiAmount = 0;

        if (isValidArray(getters.deductionsList)) {
            let deduction = getters.deductionsList.find(item => item.internal_name === 'employee_pension_fund');
            if (deduction) {
                apiAmount = Number(deduction.amount);
            }
        }

        // For now we just make current and planned = apiAmount
        // But we should get it from the pensionFundContractObj like we do above for medical aid.
        let current = apiAmount;
        let planned = apiAmount;

        return {
            id: 'employee-pension-fund',
            label: 'Pension Fund - Employee',
            api: apiAmount,
            current,
            planned,
        }
    },

    retirementDeduction(_, getters) {
        let apiAmount = Number(getters.latestPayslip?.retirement_deduction) || 0
        // Planned I actually need to work out off the "retirement_fund_contract"
        const planned = apiAmount
        return {
            id: 'retirement-deduction',
            label: 'Retirement Deduction',
            api: apiAmount,
            current: false,
            planned: planned,
        }
    },

    // consumed here but don't need to use explicitly anywhere
    employeeLoanRepayment: (_, getters) => createPayslipGetter(getters.deductionsList, 'employee_loan_repayment'),


    // Employee PAYE - 'employee_paye'


    // api value only
    taxPayeApi(_, getters) {
        if (!getters.latestPayslip) {
            return null;
        }
        const apiAmount = Number(getters.deductionsList.find(obj => obj.internal_name === 'employee_paye').amount);
        return {
            id: 'tax-paye-api',
            label: 'Tax (PAYE) - API',
            api: apiAmount,
        }
    },


    employeeRepaymentOfAdvance(_, getters) {
        return createPayslipGetter(getters.deductionsList, 'employee_repayment_of_advance');
    },

    employeeUif(_, getters) {

        let apiAmount = 0;
        let current = 0;
        let planned = 0;

        if (isValidArray(getters.deductionsList)) {
            let deduction = getters.deductionsList.find(item => item.internal_name === 'employee_uif');
            if (deduction) {
                apiAmount = Number(deduction.amount);
            }

            // do a current and planned calc cos this amount can change
            const uifCalc = (gross) => (gross * .01 > 177.12) ? 177.12 : gross * .01;
            current = uifCalc(getters.grossRemuneration.current);
            planned = uifCalc(getters.grossRemuneration.planned);
        }


        return {
            id: 'uif-employee',
            label: 'UIF - Employee',
            api: apiAmount,
            current: current,
            planned: planned,
        }
    },



    // ### Custom SimplePay Items ###
    // ------------------------------
    // None currently


    // ### Totals - Summed and Derived
    // --------------------------------

    // # Total employee deductions (Manually summed / No API)
    totalEmployeeDeductionsApi(_, getters) {
        let apiAmount = 0
        if (isValidArray(getters.deductionsList)) {
            for (const deduction of getters.deductionsList) {
                // don't count employee pension fund twice
                if (deduction.internal_name === 'employee_pension_fund_benefit') {
                    continue;
                }
                apiAmount += Number(deduction.amount);
            }
        }

        return {
            id: 'total-employee-deductions-api',
            label: 'Total Employee Deductions',
            api: apiAmount,
        }
    },

    totalEmployeeDeductionsCalc(_, getters) {
        let current = getters.employeeMedicalAid?.api + getters.employeePensionFund?.api + getters.taxPayeApi?.api + getters.employeeRepaymentOfAdvance?.api + getters.employeeUif?.api
        let planned = getters.employeeMedicalAid?.planned + getters.employeePensionFund?.planned + getters.taxPayeAnnualisedCalc?.planned + getters.employeeRepaymentOfAdvance?.planned + getters.employeeUif?.planned

        return {
            id: 'total-employee-deductions-calc',
            label: 'Total Employee Deductions',
            api: getters.totalEmployeeDeductionsApi.api,
            current,
            planned,
        }
    },


    totalEmployeeDeductionsAlternative(_, getters) {
        let apiAmount = 0;
        if (getters.latestPayslip) {
            apiAmount = Number(getters.latestPayslip.total_employee_deductions)
        }
        return {
            id: 'total-employee-deductions',
            label: 'Total Employee Deductions',
            api: apiAmount,
            current: getters.grossRemuneration.current - getters.nettPayCalc.current - getters.totalEmployerContributionsExSdlUif.current + getters.expenseClaimsNonTaxable.api,
            planned: getters.grossRemuneration.planned - getters.nettPayCalc.planned - getters.totalEmployerContributionsExSdlUif.planned + getters.expenseClaimsNonTaxable.api,
        }
    },


    // Manual way of creating a deductions list
    // Not in use anymore. I have looped through the deductionsList rather
    payslipDeductions(_, getters) {
        return [
            getters.employeePensionFund,
            getters.employeeMedicalAid,
            getters.taxPaye,
            getters.employeeUif,
            getters.employeeRepaymentOfAdvance,
        ]
    },

}