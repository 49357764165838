<template>
    <div>
        <slot></slot>
    </div>
</template>

<style scoped>
    div {
        width: 600px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
</style>