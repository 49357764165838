// employee reimbursement actions
import {apiResponseHandler} from "@/hooks/api/api-helper-function"
import theApiCall from '@/hooks/api/the-api-call'

export default {

    async loadReimbursementClaimList(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateReimbursementClaimList) return
        if (!context.getters.isEnabledReimbursements) return

        const userApiId = (localStorage.userId) ? localStorage.userId : context.getters.userApiId
        const response = await theApiCall(`employees/${userApiId}/reimbursement_claims/`)
        const responseData = await apiResponseHandler(response, 'Failed to fetch Reimbursement Claim List.', 'Reimbursement claims list resource not found. [404]')

        context.commit('setReimbursementClaimList', responseData)
        context.commit('setLastReimbursementClaimListFetch', {timestamp: new Date().getTime()})
    },


    async loadReimbursementAllowanceList(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateReimbursementAllowanceList) {
            return
        }
        if (!context.getters.isEnabledReimbursements) {
            return
        }

        const userApiId = (localStorage.userId) ? localStorage.userId : context.getters.userApiId
        const response = await theApiCall(`employees/${userApiId}/reimbursement_allowances/`)
        const responseData = await apiResponseHandler(response, 'Failed to fetch Reimbursement Allowance List.', 'Reimbursement allowance list not found. [404]')

        context.commit('setReimbursementAllowanceList', responseData)
        context.commit('setLastReimbursementAllowanceListFetch', {timestamp: new Date().getTime()})
    },


    // posts a reimbursement claim -> options for state: submitted, approved, rejected, cancelled, error
    async postReimbursementClaimApi(context, expenseClaimObj) {
        let formData = new FormData();

        // Append non-file fields to FormData
        formData.append('allowance', expenseClaimObj.allowance)
        formData.append('date', expenseClaimObj.date)
        formData.append('invoice_date', expenseClaimObj.invoice_date)
        formData.append('notes', expenseClaimObj.notes)
        formData.append('amount', expenseClaimObj.amount)
        formData.append('state', expenseClaimObj.state)

        // Append files to FormData
        if (expenseClaimObj.files && expenseClaimObj.files.length) {
            expenseClaimObj.files.forEach(file => {
                formData.append('files', file)
            })
        }

        // Display the key/value pairs for debugging
        // for (let pair of formData.entries()) {
        //     console.log(`${pair[0]}, ${pair[1]}`)
        // }

        const userApiId = (localStorage.userId) ? localStorage.userId : context.getters.userApiId;
        const response = await theApiCall(`employees/${userApiId}/reimbursement_claims/`, 'POST', formData)
        const responseData = await apiResponseHandler(response, 'Error submitting reimbursement expense claim. Please contact support or try again.')

        // fetch updated data from api then commit
        await Promise.all([
            context.dispatch('loadReimbursementClaimList', {forceRefresh: true}),
            context.dispatch('loadReimbursementAllowanceList', {forceRefresh: true}),
            // fetch this so the approvee list is updated for the manager
            context.dispatch('loadReimbursementApproveeList', {forceRefresh: true})
        ])
        return responseData
    },

    // TODO:
    //  500 server error when a user trying to cancel their own claim (only allowed in submitted state)
    //  I think an employee should be able to cancel their own claim if it hasn't been approved or rejected
    async cancelReimbursementClaim(context, payload) {
        const requestID = payload.requestID
        const userApiId = (localStorage.userId) ? localStorage.userId : context.getters.userApiId
        const body = JSON.stringify({state: 'cancelled'})

        const response = await theApiCall(`employees/${userApiId}/reimbursement_claims/${requestID}/`, 'PATCH', body, 'application/json')
        const responseData = await apiResponseHandler(response, 'Failed to cancel reimbursement claim', 'Reimbursement claim resource not found. [404]')

        return (response.ok) ? {response, responseData} : null
    }

}