<template>
    <div>
        <loading-full-screen v-model="isLoading" loadingText="Loading employee list..."/>

        <width-container v-if="!isLoading">
            <card-general>
                <section-header>All Employee Data</section-header>
                <div class="mb-4 mt-n3 d-flex justify-content-between disclaimer-text fw-500">
                    <div>
                        Requires full employee list load<br>
                        /api/1.0/employees/
                    </div>
                    <div>
                        <span class="me-2">Include leave approvees: <MDBSwitch v-model="includeLeaveApprovees"/></span>
                    </div>
                </div>

                <div class="d-flex justify-content-between mt-2 mb-2 align-items-center flex-wrap">
                    <div class="flex-grow-1 d-flex align-items-center flex-wrap">
                        <admin-table-filter
                            filter-name="Employer filter" filter-identifier="employer-filter" placeholder-text="Select employer ..."
                            :filter-options="filterEmployerOptions" @option-selected="optionSelectedHandler" class="ms-0 mx-1"
                            :selected-options-string="filteredEmployerListString"
                            options-selected-label="employers selected"
                        />
                        <admin-table-filter
                            filter-name="Currently Employed" filter-identifier="currently-employed-filter" placeholder-text="Select option ..."
                            :filter-options="currentlyEmployedOptions" @option-selected="optionSelectedHandler" class="ms-0 mx-1"
                            :selected-options-string="filteredCurrentlyEmployedListString" :filter-search="false"
                            options-selected-label="options selected"
                        />
                        <admin-table-filter
                            filter-name="Is Active" filter-identifier="is-active-filter" placeholder-text="Select option ..."
                            :filter-options="isActiveOptions" @option-selected="optionSelectedHandler" class="ms-0 mx-1"
                            :selected-options-string="filteredIsActiveListString" :filter-search="false"
                            options-selected-label="options selected"
                        />
                        <admin-table-filter
                            filter-name="Is Leave Approver" filter-identifier="is-leave-approver-filter" placeholder-text="Select option ..."
                            :filter-options="isLeaveApproverOptions" @option-selected="optionSelectedHandler" class="ms-0 mx-1"
                            :selected-options-string="filteredIsLeaveApproverListString" :filter-search="false"
                            options-selected-label="options selected"
                        />
                    </div>

                    <button-bento color="primary" @click="clearFilters" :disabled="!areSelectionsMade" class="filter-button mb-3">
                        <span v-if="areSelectionsMade">Clear Filters</span><span v-else>No filters applied</span>
                    </button-bento>
                </div>

                <div>
                    <MDBInput class="mb-4" v-model="search3" label="Employee search" size="lg"/>
                    <MDBDatatable
                        :dataset="tableDataset"
                        :search="search3"
                        hover
                        fixedHeader
                        striped
                        :entries="50"
                        :entriesOptions="[20, 50, 100, 200]"
                        id="all-employees-table" class="my-3"
                        sm
                        noFoundMessage="No matching employees ..."
                    />
                </div>
            </card-general>
        </width-container>
    </div>
</template>

<script setup>
import {formatDate} from '@/hooks/general/date-helpers'
import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer.vue"
import {MDBDatatable, MDBInput, MDBSwitch} from "mdb-vue-ui-kit"
import {computed, ref, watch} from "vue"
import {useStore} from "vuex"
import {loadAllApiData, loadBentoAdminData} from "@/hooks/api/api-dispatch-calls"
import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen.vue"
import ButtonBento from "@/components/UI/v2/buttons/ButtonBento.vue"
import AdminTableFilter from "@/components/admin-restricted/pages/components/AdminTableFilter.vue"
import {isValidArray} from "@/hooks/general/type-and-value-checks"
import {isEmployeeLeaveApprover, getApprovers} from "@/hooks/general/approvers"

const store = useStore()
const search3 = ref("")

const includeLeaveApprovees = ref(false)
const employeeList = computed(() => store.getters.employeeList)
const employeeListWithLeaveApprovees = computed(() => store.getters.employeeListWithLeaveApprovees)
const employeeListToUse = computed(() => includeLeaveApprovees.value ? employeeListWithLeaveApprovees.value : employeeList.value)


// options for filter dropdowns ( 1. employer, 2. currently employed, 3. is leave approver)
// 1. employer filter
const employerList = computed(() => store.getters.employerList)

const filterEmployerOptions = computed(() => {
    const arr = [];
    for (const employer of employerList.value) {
        arr.push({
            text: employer.name,
            value: Number(employer.id)
        });
    }
    return arr
})
// 2. currently employed filter
const currentlyEmployedOptions = computed(() => {
    return [
        {text: 'Employed', value: true},
        {text: 'Terminated', value: false}
    ]
})
// 3. is active
const isActiveOptions = computed(() => {
    return [
        {text: 'Active', value: true},
        {text: 'Inactive', value: false}
    ]
})
// 4. is leave approver
const isLeaveApproverOptions = computed(() => {
    return [
        {text: 'Is Approver', value: true},
        {text: 'NOT approver', value: false}
    ]
})

const employerFromEmployee = employee => {
    const employer = employerList.value.find(employer => employer.id === employee.employer_id)
    return employer ? employer.name : null
}


const rows = computed(() => {

    let datasetRows = []

    for (const employee of employeeListToUse.value) {
        datasetRows.push({
            employeeObj: employee,
            id: employee.id.toString(),
            employee_name: `${employee.user.first_name} ${employee.user.last_name}`,
            is_active: employee.is_active ? '<span class="text-success fw-500">active</span>' : '<span class="text-danger fw-500">inactive</span>',
            is_currently_employed: employee.is_currently_employed ? '<span class="text-success fw-500">yes</span>' : '<span class="text-danger fw-500">terminated</span>',
            is_leave_approver: isEmployeeLeaveApprover(employeeListToUse.value, employee.id) ? 'approver' : 'no',
            leave_approvers: getApprovers(employee.leave_approvers) ? getApprovers(employee.leave_approvers) : '<span class="text-danger fw-500">No Leave Approver</span>',
            reimbursement_approvers: getApprovers(employee.reimbursement_approvers) ? getApprovers(employee.reimbursement_approvers) : '<span class="text-danger fw-500">No Reimbursement Approver</span>',
            employer_id: employee.employer_id.toString(),
            employer_name: employerFromEmployee(employee),
            email: employee.user.email,
            tc_date_of_acceptance: employee.tc_date_of_acceptance ? formatDate(employee.tc_date_of_acceptance) : '<span class="text-danger fw-500">not accepted</span>',
            date_of_termination: employee.date_of_termination ? `<span class="fw-500 font-size-11">${formatDate(employee.date_of_termination)}</span>` : '<span class="fw-500">-</span>',
            last_name: employee.user.last_name,
            leave_approvees: includeLeaveApprovees.value
                ? (employee.leave_approvees
                    ? employee.leave_approvees.map(approvee => approvee.name).join(', ')
                    : '<span class="text-danger fw-500">No Leave Approvees</span>')
                : '<span class="text-primary fw-500">Use the toggle option to include leave approvees</span>',
        })
    }


    // 1. employer filter
    if (isValidArray(filteredEmployerList.value)) {
        datasetRows = datasetRows.filter(employee => filteredEmployerList.value.includes(employee.employer_id))
    }
    // 2. currently employed filter
    if (isValidArray(filteredCurrentlyEmployedList.value)) {
        datasetRows = datasetRows.filter(employee => filteredCurrentlyEmployedList.value.includes(employee.employeeObj.is_currently_employed.toString()))
    }
    // 3. is active filter
    if (isValidArray(filteredIsActiveList.value)) {
        datasetRows = datasetRows.filter(employee => filteredIsActiveList.value.includes(employee.employeeObj.is_active.toString()))
    }
    // 4. is leave approver filter
    if (isValidArray(filteredIsLeaveApproverList.value)) {
        datasetRows = datasetRows.filter(employee => filteredIsLeaveApproverList.value.includes(isEmployeeLeaveApprover(employeeListToUse.value, employee.id).toString()))
    }

    return datasetRows
})

const tableDataset = computed(() => {
    return {
        columns: [
            {label: "UID", field: "id", width: '62'},
            {label: "Employee", field: "employee_name", width: '140'},
            {label: "Is Active", field: "is_active", width: '90'},
            {label: "Employed", field: "is_currently_employed", width: '100'},
            {label: "Is Leave Approver", field: "is_leave_approver", width: '144'},
            {label: "Leave Approver(s)", field: "leave_approvers", width: '200'},
            {label: "Reimbursement Approver(s)", field: "reimbursement_approvers", width: '210'},
            {label: "EID", field: "employer_id", width: '60'},
            {label: "Employer", field: "employer_name", width: '120'},
            {label: "Email", field: "email", width: '140'},
            {label: "TC Accepted", field: "tc_date_of_acceptance", width: '160'},
            {label: "Date of Termination", field: "date_of_termination", width: '160'},
            {label: "Last Name", field: "last_name", width: '180'},
            {label: "Leave Approvees", field: "leave_approvees", width: '320'},
        ],
        rows: rows.value
    }
})

// filtered from select selections
const filteredEmployerList = ref(null)
const filteredEmployerListString = computed(() => isValidArray(filteredEmployerList.value) ? filteredEmployerList.value.join(',') : null)

const filteredCurrentlyEmployedList = ref(null)
const filteredCurrentlyEmployedListString = computed(() => isValidArray(filteredEmployerList.value) ? filteredEmployerList.value.join(',') : null)

const filteredIsActiveList = ref(null)
const filteredIsActiveListString = computed(() => isValidArray(filteredEmployerList.value) ? filteredEmployerList.value.join(',') : null)

const filteredIsLeaveApproverList = ref(null)
const filteredIsLeaveApproverListString = computed(() => isValidArray(filteredEmployerList.value) ? filteredEmployerList.value.join(',') : null)


const areSelectionsMade = computed(() => !!filteredEmployerList.value)

const clearFilters = () => filteredEmployerList.value = null

const optionSelectedHandler = (selectedOptionsList, filterIdentifier) => {
    if (filterIdentifier === 'employer-filter') {
        filteredEmployerList.value = selectedOptionsList.value
    }
    if (filterIdentifier === 'currently-employed-filter') {
        filteredCurrentlyEmployedList.value = selectedOptionsList.value
    }
    if (filterIdentifier === 'is-active-filter') {
        filteredIsActiveList.value = selectedOptionsList.value
    }
    if (filterIdentifier === 'is-leave-approver-filter') {
        filteredIsLeaveApproverList.value = selectedOptionsList.value
    }
}

const loadEmployeeList = async() => {
    isLoading.value = true
    try {
        await store.dispatch('loadEmployeeList', {forceRefresh: false})
    } catch (error) {
        store.commit('setShowApiErrorModal', true)
        store.commit('setApiErrorMessage', error.message)
    }
    isLoading.value = false
}

const loadEmployeeListWithLeaveApproveesHandler = async () => {
    isLoading.value = true
    try {
        await store.dispatch('loadEmployeeListWithLeaveApprovees', {forceRefresh: false})
    } catch (error) {
        store.commit('setShowApiErrorModal', true)
        store.commit('setApiErrorMessage', error.message)
    }
    isLoading.value = false
}


// load admin data
const isLoading = ref(false)
const onLoad = async () => {
    isLoading.value = true
    await loadAllApiData()
    await loadBentoAdminData()
    await loadEmployeeList()
    // need to get employeeList before we can get employeeListWithLeaveApprovees
    if (includeLeaveApprovees.value) {
        await loadEmployeeListWithLeaveApproveesHandler()
    }
    isLoading.value = false
}
onLoad()

watch(includeLeaveApprovees, async (newValue) => {
    if (newValue === true) {
        await loadEmployeeListWithLeaveApproveesHandler()
    }
}, { immediate: false })

</script>

<style scoped lang="scss">

:deep(.datatable-pagination) {
    margin-top: 20px;
}

:deep(.table) {
    font-size: 12px !important;
    font-weight: 400;
}

</style>