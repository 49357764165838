// auth module actions
// ====================================================================================
// --> auth() -> authorisation login action
// --> tryLogin() -> uses localStorage to repopulate vuex store and keep user logged in
// --> logout() -> clear localStorage and vuex store of email, password, and userId. As well as clear accept terms in case someone else is using the same computer.
// ====================================================================================
import {isProd, isDevelopment} from "@/hooks/api/app-environment-origin"
import theApiCall from "@/hooks/api/the-api-call"
import {apiResponseHandler} from "@/hooks/api/api-helper-function"

export default {

    async sendHeartBeat(context) {
        if (!context.getters.isAuthenticated) { return }

        const response = await theApiCall('static_content/')
        const responseData = await apiResponseHandler(response, 'Token refresh failed', 'Token refresh static content resource not found. [404]')

        return responseData
    },
    
    async getAuthToken(context, payload) {
        let url = ''
        if (process.env.VUE_APP_API_HOST) {
            // if there exists and VUE_APP_API_HOST environmental variable skip straight to that
            url = `${process.env.VUE_APP_API_HOST}/api-token-auth/`
        } else if (isProd()) {
            url = 'https://app.mybento.net/api-token-auth/'
        } else if (isDevelopment()) {
            url = 'https://development.mybento.net/api-token-auth/'
        } else {
            url = 'https://staging.mybento.net/api-token-auth/'
        }

        const response = await fetch(url, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({username: payload.email, password: payload.password}),
            }
        )

        // custom response handling
        let responseData, errorMessage
        if (response.ok) {
            const contentType = response.headers.get('Content-Type')
            if (contentType && !contentType.includes('application/json')) {
                // Handle non-JSON response here (e.g., if it's HTML)
                errorMessage = 'Your session has expired. Please refresh your browser and try logging in again.'
                throw new Error(errorMessage || `Looks like something went wrong with logging in. Please clear your browser cookies and try again or <a href="mailto:hello@mybento.net">contact us for assistance.<a/>`)
            }
            responseData = await response.json() // converts network response to json
            // invalid credentials has a valid 200 response from server
            if (responseData.non_field_errors) {
                errorMessage = responseData.non_field_errors[0]
                throw new Error(errorMessage || `Looks like something went wrong with logging in. Please clear your browser cookies and try again or <a href="mailto:hello@mybento.net">contact us for assistance.<a/>`)
            }
        } else {
            if (response.status == 404) {
                errorMessage = 'Token Auth Resource not found [404].'
            }
            else if (response.status === 500) {
                errorMessage = response.statusText
            }
            else if (response.status === 501) {
                console.error('501 server error. Please contact us on hello@mybento.net')
                errorMessage = 'There has been an error. Please contact us on hello@mybento.net'
            }
            else if (response.status === 502) {
                console.error('502 server error. Please contact us on hello@mybento.net')
                errorMessage = 'There has been an error. Please contact us on hello@mybento.net'
            }
            else if (response.status === 504) {
                errorMessage = 'Your request has timed out. [504]. Please try again or contact us on hello@mybento.net'
            }
            else {
                responseData = await response.json()
                errorMessage = responseData.state || responseData.detail || responseData.message
            }
            throw new Error(errorMessage || `Looks like something went wrong with logging in. Please clear your browser cookies and try again or <a href="mailto:hello@mybento.net">contact us for assistance.<a/>`)
        }

        const isUserBento = payload.email.includes('@mybento.net')

        localStorage.setItem('authToken', responseData.token)
        localStorage.setItem('isUserBento', isUserBento)
        localStorage.setItem('userId', responseData.employee_id)
        localStorage.setItem('actualUserId', responseData.employee_id)
        context.commit('setUser', {
            authToken: responseData.token,
            userId: responseData.employee_id,
            actualUserId: responseData.id
        });
    },

    tryLogin(context) {
        const userId = localStorage.getItem('userId')
        const authToken = localStorage.getItem('authToken')
        if (userId && authToken) {
            context.commit('setUser', {userId, authToken})
        }
    },

    // log user out
    logout(context) {
        window.localStorage.clear()
        context.commit('clearUser')
        context.dispatch('clearState')
        location.reload()
    },

    clearState(context) {
        // # employee
        context.commit('setEmployee', null)
        context.commit('setLastEmployeeFetchTimestamp', null)
        context.commit('setPayslipsList', null)

        // # employer
        context.commit('setEmployer', null)
        context.commit('setLastEmployerFetchTimestamp', null)

        // # terms
        context.dispatch('setIsTermsAccepted', false)

        // have tested and the rest of the store clears automatically based on clearing the user in store and local storage
    },

    loadUserFromBentoAdmin(context, userData) {
        context.commit('setUserFromBentoAdmin', userData)
    }

}