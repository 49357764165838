<template>
    <div class="mt-n3">
        
        <div v-if="!arePayrollDraftChanges">
            <bento-alert color="info" static v-model="noPayrollChangesNotice">
                No payroll changes drafted.
            </bento-alert>
        </div>
        
        <!--medical aid draft changes-->
        <div v-if="areMedicalAidDraftChanges">
            <card-general-inner class="green no-shadow gradient-green-text-dark mb-4">
                <div class="font-size-16 fw-600 mb-1">Medical Aid Changes</div>
                <ul class="mb-0 mt-2 list-unstyled font-weight-500 gradient-green-text-light">
                    <li v-for="change in textSummaryOfChangesMedical" :key="change" v-html="change"></li>
                </ul>
                <div class="mt-3">
                    <button-bento color="primary" class="mb-2 me-2" @click="proceedWithMedicalAidChangesHandler">Proceed with changes</button-bento>
                    <button-bento color="secondary" class="mb-2" @click="deleteMedicalAidChangesHandler">Delete draft changes</button-bento>
                </div>
            </card-general-inner>
        </div>
        <div>
            <div v-if="showDeleteMedicalAidDraftNotice">
                <bento-alert :color="noticeColor" static v-model="showDeleteMedicalAidDraftNotice" class="mb-0 mt-4">
                    {{ deleteDraftNoticeText }}
                </bento-alert>
            </div>
            <div id="deleting-medical-aid-changes" v-if="isDeletingMedicalAidDraftChanges">
                <loading-small v-model="isDeletingMedicalAidDraftChanges" parentSelector="#deleting-medical-aid-changes" loadingText="Deleting draft changes ..."/>
            </div>
        </div>
        
        <!--retirement draft changes-->
        <div v-if="areRetirementDraftChangesDifferentToCurrent">
            <card-general-inner class="green no-shadow gradient-green-text-dark mb-4">
                <div class="fw-600 font-size-16 mb-1">Retirement Fund Changes</div>
                <ul class="mb-0 mt-2 list-unstyled font-weight-500 gradient-green-text-light">
                    <li v-for="change in textSummaryOfChangesRetirement" :key="change" v-html="change"></li>
                </ul>
                <div class="mt-3 d-flex flex-wrap">
                    <div v-if="!isBeneficiary1Complete && hasABeneficiary" class="alert-before-submitting">
                        <bento-alert color="warning" static v-model="addBeneficiaryNotice">
                            All fields for your primary beneficiary need to be completed. <span @click="updateBeneficiaryHandler" class="fw-600 cursor-pointer hover-underline">Update here.</span>
                        </bento-alert>
                    </div>
                    <div v-else>
                        <button-bento color="primary" class="mb-2 me-2" @click="proceedWithRetirementFundChangesHandler">Proceed with changes</button-bento>
                    </div>
                    <div>
                        <button-bento color="secondary" class="mb-2" @click="deleteRetirementFundChangesHandler">Delete draft changes</button-bento>
                    </div>
                </div>
            </card-general-inner>
        </div>
        <div>
            <div v-if="showDeleteRetirementFundDraftNotice">
                <bento-alert :color="noticeColor" static v-model="showDeleteRetirementFundDraftNotice" v-if="showDeleteRetirementFundDraftNotice" class="mb-0 mt-4">
                    {{ deleteDraftNoticeText }}
                </bento-alert>
            </div>
            <div id="deleting-retirement-fund-changes" v-if="isDeletingRetirementFundDraftChanges">
                <loading-small v-model="isDeletingRetirementFundDraftChanges" parentSelector="#deleting-retirement-fund-changes" loadingText="Deleting draft changes ..."/>
            </div>
        </div>
        
        <bento-alert color="secondary" static v-model="theAlert" style="line-height:1.9!important" class="mb-0 mt-4" v-if="arePayrollDraftChanges">
            <template #title>Please be advised</template>
            <template #default>Be confident that you’ve received the right input from an advisor, or feel free to ask Bento for further guidance to have the peace of mind of making the right decisions.</template>
        </bento-alert>
    </div>
</template>

<script setup>
    import {computed, ref} from "vue"
    import {useStore} from "vuex"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert"
    import CardGeneralInner from "@/components/UI/v2/containers-cards-headers/CardGeneralInner.vue"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento.vue"
    import LoadingSmall from "@/components/UI/v2/loading/LoadingSmall.vue"
    import {useRouter} from "vue-router"
    const router = useRouter()
    
    const store = useStore()
    
    const theAlert = ref(true)
    
    const arePayrollDraftChanges = computed(() => store.getters.arePayrollDraftChanges)
    const noPayrollChangesNotice = ref(true)
    const areMedicalAidDraftChanges = computed(() => store.getters.areMedicalAidDraftChanges)
    const textSummaryOfChangesMedical = computed(() => store.getters.textSummaryOfChangesMedical)
    
    const areRetirementDraftChangesDifferentToCurrent = computed(() => store.getters.areRetirementDraftChangesDifferentToCurrent)
    const textSummaryOfChangesRetirement = computed(() => store.getters.textSummaryOfChangesRetirement)
    
    const proceedWithMedicalAidChangesHandler = () => {
        store.commit('toggleShowRemDraftModalCombined', false)
        store.commit('toggleShowConfirmMedicalAidChangesModal', true)
    }
    
    const proceedWithRetirementFundChangesHandler = () => {
        store.commit('toggleShowRemDraftModalCombined', false)
        store.commit('toggleShowConfirmRetirementFundChangesModal', true)
    }
    
    // notices and checks before submitting
    const addBeneficiaryNotice = ref(true)
    const hasABeneficiary = computed(() => store.getters.hasABeneficiary)
    const isBeneficiary1Complete = computed(() => store.getters.isBeneficiary1Complete)
    const updateBeneficiaryHandler = () => {
        store.commit('toggleShowRemDraftModalCombined', false)
        store.commit('setCurrentRetirementWizardStep', 2)
        router.push({name: 'retirement'})
    }
    
    
    // Deleting Draft Changes
    
    const noticeColor = ref('primary') // used for both
    const deleteDraftNoticeText = ref(null) // used for both
    const isDeletingMedicalAidDraftChanges = ref(false)
    const showDeleteMedicalAidDraftNotice = ref(false)
    // const delay = ms => new Promise(res => setTimeout(res, ms));
    const isDeletingRetirementFundDraftChanges = ref(false)
    const showDeleteRetirementFundDraftNotice = ref(false)
    
    
    const deleteMedicalAidChangesHandler = async () => {
        isDeletingMedicalAidDraftChanges.value = true
        showDeleteMedicalAidDraftNotice.value = false
        deleteDraftNoticeText.value = null
        
        try {
            await store.dispatch('deleteMedicalAidDraftContractObjPlanned')
        } catch (error) {
            deleteDraftNoticeText.value = error
            showDeleteMedicalAidDraftNotice.value = true
            noticeColor.value = 'secondary'
            isDeletingMedicalAidDraftChanges.value = false
            setTimeout(() => showDeleteMedicalAidDraftNotice.value = false, 4000)
            return
        }
        
        isDeletingMedicalAidDraftChanges.value = false
        showDeleteMedicalAidDraftNotice.value = true
        deleteDraftNoticeText.value = "Draft medical aid changes deleted successfully"
        
        setTimeout(() => showDeleteMedicalAidDraftNotice.value = false, 4000)
    }
    
    
    const deleteRetirementFundChangesHandler = async () => {
        isDeletingRetirementFundDraftChanges.value = true
        showDeleteRetirementFundDraftNotice.value = false
        deleteDraftNoticeText.value = null
        
        try {
            await store.dispatch('deleteRetirementFundDraftContractObjPlanned')
        } catch (error) {
            deleteDraftNoticeText.value = error
            showDeleteRetirementFundDraftNotice.value = true
            noticeColor.value = 'secondary'
            isDeletingRetirementFundDraftChanges.value = false
            setTimeout(() => showDeleteRetirementFundDraftNotice.value = false, 4000)
            return
        }
        
        isDeletingRetirementFundDraftChanges.value = false
        showDeleteRetirementFundDraftNotice.value = true
        deleteDraftNoticeText.value = "Draft retirement fund changes deleted successfully"
        
        setTimeout(() => showDeleteRetirementFundDraftNotice.value = false, 4000)
    }

</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    ul {
        line-height: 1.9;
        :deep(li span) {
            font-weight: 600;
            color: $gradient-green-text-dark;
        }
    }
    
    h5 {
        font-size: 1.3rem;
    }
    
    #deleting-medical-aid-changes,
    #deleting-retirement-fund-changes {
        min-height: 50px;
        margin-top: 25px;
        margin-bottom: 25px;
    }
    
    .alert-before-submitting {
        flex: 0 0 100%;
    }
</style>