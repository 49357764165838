<template>
    <div :class="[{'col-md-6': !fullWidth, 'custom-invalid-entry': customErrorMessage},'col-12 card-col']">
        <MDBInput
            v-model="name" @input="$emit('update:modelValue', $event.target.value)"
            size="lg" :class="['rounded']" :label="labelRequired"
            :required="isRequired"
            :invalidFeedback="invalidFeedback"
            :validFeedback="validFeedback"
            :maxlength="maxLengthValue"
        />
        <div v-if="extraNote" class="extra-note">{{ extraNote }}</div>
        <div class="invalid-feedback-custom" v-if="customErrorMessage">{{ customErrorMessage }}</div>
    </div>
</template>

<script setup>
    import {ref, computed} from "vue";
    import {MDBInput} from 'mdb-vue-ui-kit'
    
    const props = defineProps({
        label: {
            type: String,
            required: true
        },
        inputValue: {
            type: [String, null],
            required: true,
            default() {
                return ''
            }
        },
        fullWidth: {
            type: Boolean,
            required: false,
            default() {
                return false
            }
        },
        isRequired: {
            type: Boolean,
            required: false,
            default() {
                return true
            }
        },
        invalidFeedback: {
            type: String,
            required: false,
        },
        validFeedback: {
            type: String,
            required: false,
        },
        customErrorMessage: {
            required: false,
            default() {
                return null
            }
        },
        maxLengthValue: {
            type: Number,
            required: false,
            default() {
                return 60
            }
        },
        extraNote: {
            type: String,
            required: false,
        }
    })
    
    const labelRequired = computed(() => {
        if (props.isRequired) {
            return props.label + '*'
        }
        return props.label
    })
    const name = ref(props.inputValue)
</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    :deep(.form-outline) {
        
        .form-control {
            min-height: 52px;
            color: $gradient-green-text-dark;
        }
        
        label.form-label {
            font-size: 13px;
        }
        
        input.invalid-entry {
            color: $danger !important;
            border-color: $danger !important;
        }
    }
    
    .custom-invalid-entry {
        :deep(.form-outline) {
            input.form-control {
                color: $danger !important;
            }
            label.form-label {
                color: $danger !important;
            }
            .form-notch {
                > div {

                }
            }
        }
    }
    
    .extra-note {
        width: 100%;
        font-size: 11px;
        color: $gray-main;
    }
    
    .invalid-feedback-custom {
        margin-top: -10.5px;
        font-size: 0.875rem;
        color: $danger-500;
        line-height: 1.3;
    }
</style>