<template>
    <div>
        <loading-full-screen v-model="isLoading" loadingText="Loading employee list..."/>
        
        <width-container v-if="!isLoading">
            <card-general class="admin-employee-selection" id="employee-select">
                <h3 class="mb-2">Bento Admin > Employee Selector</h3>
                
                <div class="loading-changes-outer-cont">
                    <div class="loading-changes-cont" v-if="isSettingNewEmployeeData">
                        <loading-small v-model="isSettingNewEmployeeData" parentSelector=".loading-changes-cont" loadingText="Setting new employee ..."/>
                    </div>
                    
                    <form v-else @submit.prevent="loadSelectedEmployeeHandler">
                        <div class="my-2">
                            <small><span class="me-3 d-inline-block">Selected employer ID: {{ selectedEmployer }}</span>
                                <span class="d-inline-block">Selected employee ID: {{ selectedEmployee }}</span></small>
                        </div>
                        <MDBSelect
                            v-model:options="optionsEmployer" v-model:selected="selectedEmployer" filter
                            label="Employer" size="lg" placeholder="Select employer ..." :preselect="false" class="my-4"
                        />
                        <MDBSelect
                            v-model:options="optionsEmployee" v-model:selected="selectedEmployee" filter
                            label="Employee" size="lg" placeholder="Select employee ..." :preselect="false" class="mb-4"
                            @change="validationNotice = false"
                        />
                        <div v-if="validationNotice" class="mt-n3 mb-3 text-danger font-weight-500 font-size-13">
                            Select an employee ...
                        </div>
                        <div>
                            <button-bento type="submit" class="mt-1 me-2">
                                Load selected employee
                            </button-bento>
                            <button-bento button-color="secondary-light" class="ms-2" @click="forceBackToMyUserHandler" v-if="!isSelectedUserMe">
                                Back to my user
                            </button-bento>
                        </div>
                    </form>
                </div>
            </card-general>
            
            <!--testing-->
            <card-general v-if="false">
                <section-header>Test getters are updating properly here</section-header>
                
            </card-general>
        </width-container>
    </div>
</template>

<script setup>
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento";
    import {ref, computed} from "vue";
    import {useStore} from "vuex";
    import {MDBSelect} from "mdb-vue-ui-kit";
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral";
    import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen";
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer";
    import LoadingSmall from "@/components/UI/v2/loading/LoadingSmall";
    import {loadAllApiData, loadBentoAdminData, loadDataInBackground} from "@/hooks/api/api-dispatch-calls";
    import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
    
    const store = useStore()
    
    const isSettingNewEmployeeData = ref(false)
    
    const validationNotice = ref(false)
    
    const employeeGetter = computed(() => store.getters.employee)
    const selectedEmployer = ref("")
    const employerList = computed(() => store.getters.employerList)
    const optionsEmployer = computed(() => {
        const arr = [];
        for (const employer of employerList.value) {
            const isSelected = (employer.id === employeeGetter.value.employer_id)
            arr.push({
                selected: isSelected,
                text: employer.name,
                value: Number(employer.id)
            });
        }
        return arr
    })
    
    const selectedEmployee = ref("")
    const minimalEmployeeList = computed(() => store.getters.minimalEmployeeList)
    const optionsEmployee = computed(() => {
        const arr = [];
        for (const employee of minimalEmployeeList.value) {
            const isSelected = (employee.id === employeeGetter.value.id)
            arr.push({
                selected: isSelected,
                employerId: employee.employer_id,
                text: `${employee.user.first_name} ${employee.user.last_name}`,
                value: Number(employee.id)
            });
        }
        return arr.filter(employee => employee.employerId === selectedEmployer.value)
    })
    
    async function loadSelectedEmployeeHandler() {
        const actualUserId = store.getters.actualUserId
        
        if (!selectedEmployee.value) {
            validationNotice.value = true
            return
        }
        
        isSettingNewEmployeeData.value = true
        localStorage.setItem('userId', selectedEmployee.value)
        
        await store.dispatch('loadUserFromBentoAdmin', {
            userId: selectedEmployee.value,
            actualUserId: actualUserId,
        });
        
        // clear the medical aid contract change request list because empty lists from api call aren't loaded
        await store.dispatch('loadEmployeeMedicalAidsList', {forceRefresh: true})
        await store.dispatch('loadMedicalAidContractChangeRequestList', {forceRefresh: true})
        await store.dispatch('loadTempSelectedMedicalAidContractObj', true)
        
        // problem is this is not being updated: medicalAidContractObj
        // to do that I need to update employeeMedicalAidsList
        
        // clear retirement contract change request list
        await store.dispatch('loadMedicalAidPlanList', {forceRefresh: true})
        await store.dispatch('loadRetirementFundContractChangeRequestList', {forceRefresh: true})
        await store.dispatch('loadTempSelectedRetirementFundContractObj', true)
        
        // clear bank account changes list
        await store.dispatch('loadBankAccountChangeRequestList', {forceRefresh: true})
        
        // clear 1. reimbursement allowance list and 2. reimbursement claim list and 3. reimbursement approvee list,
        // because if reimbursements not enabled for selected employee, api call won't run
        store.commit('setReimbursementClaimList', null)
        store.commit('setReimbursementAllowanceList', null)
        store.commit('setReimbursementApproveeList', null)
        store.commit('setReimbursementAllowanceListAllApprovees', null)

        await loadAllApiData(true)
        isSettingNewEmployeeData.value = false
        await loadDataInBackground(true)
    }
    
    const isSelectedUserMe = computed(() => store.getters.isSelectedUserMe);
    const forceBackToMyUserHandler = () => {
        localStorage.setItem('userId', localStorage.getItem('actualUserId'));
        location.reload();
    }

    const isLoading = ref(false)
    const onLoad = async () => {
        isLoading.value = true
        await loadAllApiData()
        await loadBentoAdminData()
        isLoading.value = false
    }
    onLoad()
</script>

<style lang="scss" scoped>
    .loading-changes-outer-cont {
        min-height: 205px;
    }
    
    .loading-changes-cont {
        min-height: 205px;
    }
</style>