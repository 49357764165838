<template>
    <div class="container">
        <div class="row">
            <div class="col d-flex align-items-center justify-content-center login-col">
                <div class="login-wrapper flex-grow-1">
                    <div class="login-content me-auto ms-auto card bento-card shadow-0 px-4 py-5 p-sm-5">
                        <div class="row justify-content-center justify-content-lg-start">
                            <div class="col-lg-7 login-form-col">
                                <div class="text-gray-500 font-size-15 mb-2">
                                    Login
                                </div>
                                <h1 class="login-headline text-primary mb-5" id="login-title">
                                    Let's make the most<br> of your earnings.
                                </h1>
                                <login-page-form/>
                            </div>
                            <div class="col-lg-5 d-flex justify-content-center justify-content-lg-end ">
                                <img src="@/assets/v2/illustrations/login-image.svg" class="login-image" alt="Bento Box">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    import LoginPageForm from "@/components/pages/login-auth/LoginPageForm"
    import {useStore} from "vuex"
    const store = useStore()
    store.commit('clearUser')
    store.dispatch('clearState')
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";

    .bento-card {
        border: 1px solid $card-border-color;
    }
    
    .login-col {
        margin-top: -50px; // negate the app 50px padding
        min-height: calc(100vh - 68px);
    }
    
    .login-wrapper {
        @include media-breakpoint-up(lg) {
            margin-bottom: 60px;
        }
    }
    
    .login-content {
        margin: 60px 0;
        @include media-breakpoint-up(lg) {
            width: 800px;
            max-width: 100%;
        }
    }
    
    .login-form-col {
        max-width: 390px;
        margin-bottom: 80px;
        @include media-breakpoint-up(lg) {
            margin-bottom: 0;
        }
    }
    
    h1.login-headline#login-title {
        line-height: 1.25;
        @include media-breakpoint-up(xl) {
            font-size: 38px!important;
        }
    }
    
    .login-image {
        display: block;
        width: 200px;
    }
</style>