// ### employee actions
import {apiResponseHandler} from "@/hooks/api/api-helper-function"
import theApiCall from '@/hooks/api/the-api-call'
import {stagingOrDevelopmentOrAppApiUrl} from "@/hooks/api/app-environment-origin";

export default {

    async loadPayslipsList(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdatePayslipsList) { return }
        if (!context.getters.isAuthenticated) { return }

        const userApiId = (localStorage.userId) ? localStorage.userId : context.getters.userApiId
        const response = await theApiCall(`employees/${userApiId}/payslips/`)
        const responseData = await apiResponseHandler(response, 'Could not load employee past payslips.')

        await context.commit('setPayslipsList', responseData)
        await context.commit('setLastPayslipsListFetchTimeStamp', { timestamp: new Date().getTime() });
    },


    async downloadPayslip(context, payload) {
        const userApiId = (localStorage.userId) ? localStorage.userId : context.getters.userApiId
        const urlEndpoint = `employees/${userApiId}/payslips/${payload.payslip.id}/pdf/`
        const pdfApiUrl = stagingOrDevelopmentOrAppApiUrl(urlEndpoint)

        // can't use theApiCall func as this doesn't use the standard api url
        let headers = new Headers();
        headers.set('Authorization', 'Token  ' + localStorage.getItem('authToken'))
        const response = await fetch(pdfApiUrl, {
                method: 'GET',
                headers: headers,
            }
        )

        await apiResponseHandler(response, 'Could not download payslip.', 'Payslip not found. [404]')

        // https://itnext.io/how-to-download-files-with-javascript-d5a69b749896
        const payslipPdf = await response.blob()
        const payslipPdfUrl = URL.createObjectURL(payslipPdf)

        const anchor = document.createElement("a")
        anchor.href = payslipPdfUrl
        anchor.download = `Payslip_${payload.payslip.date}`

        document.body.appendChild(anchor)
        anchor.click()
        document.body.removeChild(anchor)

        window.URL.revokeObjectURL(payslipPdfUrl)
    },
}