<template>
    <modal-summary id="remDraftModal" labelledby="remDraftModalLabel" v-model="showRemDraftModalRetirement" size="lg">
        <template #title>
            <span class="ms-3" v-if="areRetirementChangesSubmitted">Submitted Retirement Fund</span> <span class="ms-3" v-if="areRetirementDraftChangesDifferentToCurrent">Submit Retirement Fund Application</span>
        </template>
        <template #body>
            <rem-draft-modal-retirement-content v-if="areRetirementChangesSubmitted || areRetirementDraftChangesDifferentToCurrent"/>
            <div v-else class="font-size-15 font-weight-500">No retirement contract changes.</div>
            <div id="loading-retirement-fund-changes" class="mt-3" v-if="isSubmittingChanges">
                <loading-small v-model="isSubmittingChanges" parentSelector="#loading-retirement-fund-changes" loadingText="Saving as draft ..."/>
            </div>
            <bento-alert :color="noticeColor" static v-model="showSaveDraftNotice" v-if="showSaveDraftNotice && !isSubmittingChanges" class="mb-0 mt-4">
                {{ saveDraftNoticeText }}
            </bento-alert>
        </template>
        <template #button1 v-if="areRetirementDraftChangesDifferentToCurrent">
            <button-bento @click="proceedChangesHandler" button-color="dark" class="mx-2 flex-grow-1 shadow-hover" size="lg">Submit Application</button-bento>
        </template>
        <template #button2 v-if="areRetirementDraftChangesDifferentToCurrent">
            <button-bento @click="keepAsDraftHandler" button-color="white-bg-green" class="mx-2 flex-grow-1 shadow-hover" size="lg">Keep as draft</button-bento>
        </template>
    </modal-summary>
</template>

<script setup>
    import {ref, computed} from "vue"
    import {useStore} from "vuex"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
    import ModalSummary from "@/components/summary-and-payslip/modal/base-modals/ModalSummary"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert"
    import LoadingSmall from "@/components/UI/v2/loading/LoadingSmall"
    import RemDraftModalRetirementContent from "@/components/summary-and-payslip/modal/draft-and-submitted/retirement/RemDraftModalRetirementContent.vue"
    
    const store = useStore()
    
    const areRetirementDraftChangesDifferentToCurrent = computed(() => store.getters.areRetirementDraftChangesDifferentToCurrent)
    const areRetirementChangesSubmitted = computed(() => store.getters.areRetirementChangesSubmitted)
    
    const showRemDraftModalRetirement = computed({
        get: () => store.getters.showRemDraftModalRetirement,
        set: showValue => store.commit('toggleShowRemDraftModalRetirement', showValue)
    })
    
    const proceedChangesHandler = () => {
        submittingChangesComplete.value = false
        store.commit('toggleShowRemDraftModalRetirement', false)
        store.commit('toggleShowConfirmRetirementFundChangesModal', true)
    }
    
    const isSubmittingChanges = ref(false)
    const noticeColor = ref('primary')
    const showSaveDraftNotice = ref(false)
    const saveDraftNoticeText = ref(null)
    const submittingChangesComplete = ref(false)
    
    const keepAsDraftHandler = async () => {
        store.commit('toggleShowRemDraftModalRetirement', false)
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .reset-icon {
        width: 18px;
        position: relative;
        top: -2px;
    }
    
    p a {
        color: darken($primary, 4%);
        &:hover {
            color: darken($primary, 10%);
        }
    }
    
    #submitting-changes-loader {
        min-height: 50px;
    }
    
    #loading-retirement-fund-changes {
        min-height: 50px;
    }
</style>