// employee/payslip-financials/cash-portions getters

import {createPayslipGetter, sumOfApiList} from '@/hooks/general/getters-helpers';
import {isValidArray} from "@/hooks/general/type-and-value-checks"

export default {

    // return basic salary of 0 if there is nothing so can still display on the payslip like SimplePay payroll does
    cashPortionsList: (_, getters) => {
        if (isValidArray(getters.latestPayslip?.cash_portions)) {
            return getters.latestPayslip.cash_portions
        } else {
            return [
                {
                    "internal_name": "basic_salary",
                    "type_id": "1",
                    "name": "Basic Salary",
                    "amount": "0"
                }
            ]
        }
    },

    // ### Standard SimplePay Items (basicPayCashPortion - Payspace!?)
    // ----------------------------

    // are these the same with different labels
    basicSalaryCashPortion: (_, getters) => createPayslipGetter(getters.cashPortionsList, 'basic_salary'), // comes from SimplePay
    basicPayCashPortion: (_, getters) => createPayslipGetter(getters.cashPortionsList, 'basic_pay'), // comes from Payspace
    // Sometimes there is basic_salary (all SP clients to start) and sometimes there is basic_pay (Payspace. E.g. hear.com)
    basicIncomeCashPortion: (_, getters) => getters.basicSalaryCashPortion || getters.basicPayCashPortion || 0,

    monthlyIncome: (_, getters) => getters.basicIncomeCashPortion?.api || 0,

    annualBonusCashPortion: (_, getters) => createPayslipGetter(getters.cashPortionsList, 'annual_bonus', false),

    annualLeavePayExtra: (_, getters) => createPayslipGetter(getters.cashPortionsList, 'annual_leave_pay_extra', false),

    annualPaymentCashPortion: (_, getters) => createPayslipGetter(getters.cashPortionsList, 'annual_payment', false),

    commissionCashPortion: (_, getters) => createPayslipGetter(getters.cashPortionsList, 'commission'), // leaving as constant

    extraPayCashPortion: (_, getters) => createPayslipGetter(getters.cashPortionsList, 'extra_pay', false),

    holidayNormalPayCashPortion: (_, getters) => createPayslipGetter(getters.cashPortionsList, 'holiday_normal_pay', false),

    leaveEncashmentPayCashPortion: (_, getters) => createPayslipGetter(getters.cashPortionsList, 'leave_encashment', false),

    normalPay: (_, getters) => createPayslipGetter(getters.cashPortionsList, 'normal_pay'),

    onceOffCommission: (_, getters) => createPayslipGetter(getters.cashPortionsList, 'once_off_commission', false),

    unpaidLeave: (_, getters) => createPayslipGetter(getters.cashPortionsList, 'unpaid_leave', false),


    // ### Custom SimplePay Items ###
    // ------------------------------

    /*general*/
    /*none yet*/

    /*legalese*/
    kpiPayoutCashPortion(_, getters) {
        return createPayslipGetter(getters.cashPortionsList, 'kpi_payout', false)
    },

    aboveThresholdCommissionCashPortion(_, getters) {
        return createPayslipGetter(getters.cashPortionsList, 'above_threshold_commission')
    },

    newBusinessCommission(_, getters) {
        return createPayslipGetter(getters.cashPortionsList, 'new_business_commission', false)
    },

    profitShareScheme(_, getters) {
        return createPayslipGetter(getters.cashPortionsList, 'profit_share_scheme', false)
    },


    // ## salary_before_deductions (bento API)
    // Sum of all cash portions (summing is done in api backend code)
    // --------------------------------------------------------------
    salaryBeforeDeductions(_, getters) {
        let apiAmount = 0;
        if (getters.latestPayslip) {
            apiAmount = Number(getters.latestPayslip.salary_before_deductions);
        }
        // current & planned should always be the same as API. Planned cash portions does not change (for now)
        let current = sumOfApiList(getters.cashPortionsList);
        let planned = apiAmount;

        return {
            id: 'salary-before-deductions-api',
            label: 'Salary Before Deductions',
            api: apiAmount,
            current,
            planned,
        }
    },

    salaryBeforeDeductionsExclIrregularIncome(_, getters) {
        let current = Number(getters.salaryBeforeDeductions.api);
        for (const cashPortion of getters.cashPortionsList) {
            if (
                cashPortion.internal_name === 'annual_bonus' ||
                cashPortion.internal_name === 'annual_payment'
            ) {
                current -= Number(cashPortion.amount);
            }
        }

        return {
            id: 'salary-before-deductions-excluding-irregular-income',
            label: 'Salary Before Deductions (excluding irregular income)',
            api: false,
            current,
            planned: false,
        }

    },

}