// retirement contract getters
import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"
import {isValidArray} from "@/hooks/general/type-and-value-checks"
import useGetRetirementFundFromId from "@/hooks/retirement/get-retirement-fund-from-id"
import {isEqual} from "lodash"

export default {

    // temp selected retirement fund contract object
    tempSelectedRetirementFundContractObj: state => state.tempSelectedRetirementFundContractObj,

    // retirement fund list
    retirementFundList: state => state.retirementFundList,
    lastRetirementFundListFetch: state => state.lastRetirementFundListFetch,
    shouldUpdateRetirementFundList: (_, getters) => {
        if (!getters.lastRetirementFundListFetch) { return true }
        const currentTimeStamp = new Date().getTime()
        return ((currentTimeStamp - getters.lastRetirementFundListFetch) / 1000) > 900
    },


    // retirement contract change request list
    retirementFundContractChangeRequestList: (state) =>  state.retirementFundContractChangeRequestList || null,
    lastRetirementFundContractChangeRequestListFetch: state => state.lastRetirementFundContractChangeRequestListFetch,
    shouldUpdateRetirementFundContractChangeRequestList: (_, getters) => {
        if (!getters.lastRetirementFundContractChangeRequestListFetch) { return true }
        const currentTimeStamp = new Date().getTime()
        return ((currentTimeStamp - getters.lastRetirementFundContractChangeRequestListFetch) / 1000) > 900
    },

    // this can be 'draft' / 'submitted' / otherwise it will return null
    retirementFundContractObjPlanned: (state, getters) => getters.retirementFundContractObjDraft || getters.retirementFundContractObjSubmitted || null,
    retirementFundContractObjDraft: (state, getters) => {
        if (!isValidArray(getters.retirementFundContractChangeRequestList)) return null
        return getters.retirementFundContractChangeRequestList.find( obj => obj.status === 'draft')
    },
    retirementFundContractObjSubmitted: (state, getters) => {
        if (!isValidArray(getters.retirementFundContractChangeRequestList)) return null
        return getters.retirementFundContractChangeRequestList.find( obj => obj.status === 'submitted')
    },

    hasABeneficiary: (_, getters) => {
        if (!getters.tempSelectedRetirementFundContractObj) return false
        if (!getters.tempSelectedRetirementFundContractObj.extraData) return false
        if (!getters.tempSelectedRetirementFundContractObj.extraData.beneficiaries) return false
        if (!isValidArray(getters.tempSelectedRetirementFundContractObj.extraData.beneficiaries)) return false

        if (getters.tempSelectedRetirementFundContractObj.extraData.beneficiaries[0]) return true
        return false
    },
    isBeneficiary1Complete: (_, getters) => {
        if (!getters.hasABeneficiary) return false
        const beneficiary = getters.tempSelectedRetirementFundContractObj.extraData.beneficiaries[0]
        if (!beneficiary) return false
        const areAllValuesComplete = Object.values(beneficiary).every(value => {
            return value.trim() !== ""
        })
        return areAllValuesComplete
    },
    isBeneficiary2Complete: (_, getters) => {
        if (!getters.hasABeneficiary) return false
        const beneficiary = getters.tempSelectedRetirementFundContractObj.extraData.beneficiaries[1]
        if (!beneficiary) return false
        const areAllValuesComplete = Object.values(beneficiary).every(value => value.trim() !== "")
        return areAllValuesComplete
    },
    doesBeneficiary2HaveAnyValues: (_, getters) => {
        if (!getters.hasABeneficiary) return false
        const beneficiary = getters.tempSelectedRetirementFundContractObj.extraData.beneficiaries[1]
        if (!beneficiary) return false
        const hasAnyValue = Object.values(beneficiary).some(value => value.trim() !== "")
        return hasAnyValue
    },
    isBeneficiary3Complete: (_, getters) => {
        if (!getters.hasABeneficiary) return false
        const beneficiary = getters.tempSelectedRetirementFundContractObj.extraData.beneficiaries[2]
        if (!beneficiary) return false
        const areAllValuesComplete = Object.values(beneficiary).every(value => value.trim() !== "")
        return areAllValuesComplete
    },
    doesBeneficiary3HaveAnyValues: (_, getters) => {
        if (!getters.hasABeneficiary) return false
        const beneficiary = getters.tempSelectedRetirementFundContractObj.extraData.beneficiaries[2]
        if (!beneficiary) return false
        const hasAnyValue = Object.values(beneficiary).some(value => value.trim() !== "")
        return hasAnyValue
    },
    isBeneficiary4Complete: (_, getters) => {
        if (!getters.hasABeneficiary) return false
        const beneficiary = getters.tempSelectedRetirementFundContractObj.extraData.beneficiaries[3]
        if (!beneficiary) return false
        const areAllValuesComplete = Object.values(beneficiary).every(value => value.trim() !== "")
        return areAllValuesComplete
    },
    doesBeneficiary4HaveAnyValues: (_, getters) => {
        if (!getters.hasABeneficiary) return false
        const beneficiary = getters.tempSelectedRetirementFundContractObj.extraData.beneficiaries[3]
        if (!beneficiary) return false
        const hasAnyValue = Object.values(beneficiary).some(value => value.trim() !== "")
        return hasAnyValue
    },
    areAllBeneficiariesComplete: (_, getters) => {
        return (
            (getters.isBeneficiary1Complete) &&
            (getters.isBeneficiary2Complete || !getters.doesBeneficiary2HaveAnyValues) &&
            (getters.isBeneficiary3Complete || !getters.doesBeneficiary3HaveAnyValues) &&
            (getters.isBeneficiary4Complete || !getters.doesBeneficiary4HaveAnyValues)
        )
    },
    areAll4BeneficiariesComplete: (_, getters) => getters.isBeneficiary1Complete && getters.isBeneficiary2Complete && getters.isBeneficiary3Complete && getters.isBeneficiary4Completes,


    beneficiaryAllocationsTotal: (_, getters) => {
        const draftObj = getters.retirementFundContractObjDraft
        if (!draftObj || !draftObj.extra_data || !Array.isArray(draftObj.extra_data.beneficiaries)) {
            return 0
        }

        const beneficiaries = draftObj.extra_data.beneficiaries
        const totalAllocation = beneficiaries.reduce((sum, beneficiary) => {
            return sum + (Number(beneficiary.allocation) || 0)
        }, 0)

        return totalAllocation
    },
    areBeneficiaryAllocations100: (_, getters) => getters.beneficiaryAllocationsTotal === 100,

    
    // Retirement Fund Figures
    hasRetirementFundContract: (_, getters) => !!getters.retirementFundContractObj,
    hasRetirementFundContractPlanned: (_, getters) => !!getters.retirementFundContractObjPlanned,

    // text summaries of current and planned retirement contracts
    textSummaryOfCurrentPlanRetirement: (_, getters) => {
        let changeDescriptionArray = []

        if (!getters.hasRetirementFundContract) {
            changeDescriptionArray.push('You do not have a retirement contract.')
            return changeDescriptionArray
        }
        changeDescriptionArray.push(`Fund name: <span>${getters.retirementFundContractObj.retirement_fund.name}</span>`)
        changeDescriptionArray.push(`Your contribution: <span>${bentoCurrencyFormat(getters.retirementFundContractObj.employee_contribution)}</span>`)
        changeDescriptionArray.push(`Employer contribution: <span>${bentoCurrencyFormat(getters.retirementFundContractObj.employer_contribution)}</span>`)
        changeDescriptionArray.push(`Total contribution: <span>${bentoCurrencyFormat(+getters.retirementFundContractObj.employee_contribution + +getters.retirementFundContractObj.employer_contribution)}</span>`)
        return changeDescriptionArray
    },


    textSummaryOfChangesRetirement(_, getters) {
        const currentPlan = getters.retirementFundContractObj
        let newPlan = getters.retirementFundContractObjPlanned
        let changeDescriptionArray = []

        if (!isValidArray(getters.retirementFundList)) {
            changeDescriptionArray.push("Your employer hasn't made any retirement funds available")
            return changeDescriptionArray
        }

        if (!currentPlan && !newPlan) {
            changeDescriptionArray.push('You do not currently have a retirement fund plan or a selected retirement fund plan')
            return changeDescriptionArray
        }

        if (getters.hasRetirementFundContract && getters.retirementFundChangeIndicators) {
            if (getters.retirementFundChangeIndicators.retirement_fund) {
                changeDescriptionArray.push(`Your plan will change from <span>${currentPlan.retirement_fund.name}</span> to <span>${useGetRetirementFundFromId(newPlan.retirement_fund).name}</span>`)
            } else {
                changeDescriptionArray.push(`Your plan will be <span>${currentPlan.retirement_fund.name}</span>`)
            }
            if (getters.retirementFundChangeIndicators.employee_contribution) {
                changeDescriptionArray.push(`Your own contribution will change from <span>${bentoCurrencyFormat(currentPlan.employee_contribution)}</span> to <span>${bentoCurrencyFormat(newPlan.monthly_contribution)} pm</span>`)
            } else {
                changeDescriptionArray.push(`Your own contribution is <span>${bentoCurrencyFormat(newPlan.monthly_contribution)} pm</span>`)
            }
            if (getters.employerRetirementFund.api != getters.employerRetirementFund.planned) {
                changeDescriptionArray.push(`Your employer contribution will change from <span>${bentoCurrencyFormat(getters.employerRetirementFund.api)}</span> to <span>${bentoCurrencyFormat(getters.employerRetirementFund.planned)} pm</span>`)
            } else {
                changeDescriptionArray.push(`Your employer contribution will be <span>${bentoCurrencyFormat(getters.employerRetirementFund.planned)} pm</span>`)
            }
            // assume that if the employee contribution changes, so does the total contribution
            if (getters.retirementFundChangeIndicators.employee_contribution) {
                changeDescriptionArray.push(`Your employee contribution will change from <span>${bentoCurrencyFormat(currentPlan.employee_contribution)}</span> to <span>${bentoCurrencyFormat(newPlan.monthly_contribution)} pm</span>`)
                changeDescriptionArray.push(`Your total retirement fund contribution will change from <span>${bentoCurrencyFormat(Number(getters.employerRetirementFund.api) + Number(currentPlan.monthly_contribution))}</span> to <span>${bentoCurrencyFormat(Number(getters.employerRetirementFund.planned) + Number(newPlan.monthly_contribution))}</span>`)
            } else {
                changeDescriptionArray.push(`Your total retirement fund contribution will be <span>${bentoCurrencyFormat(Number(getters.employerRetirementFund.planned) + Number(newPlan.monthly_contribution))}</span>`)
            }


        } else if (getters.hasRetirementFundContractPlanned) {
            changeDescriptionArray = [
                `Your new plan will be <span>${useGetRetirementFundFromId(newPlan.retirement_fund).name}.</span>`,
                `You will contribute <span>${bentoCurrencyFormat(newPlan.monthly_contribution)} pm</span> towards the plan`,
                `Your employer will contribute <span>${bentoCurrencyFormat(getters.employerRetirementFund.planned)} pm</span> towards your plan`,
                `Your total retirement fund contribution will be <span>${bentoCurrencyFormat(Number(getters.employerRetirementFund.planned) + Number(newPlan.monthly_contribution))} pm</span>`,
            ]
        } else {
            changeDescriptionArray.push('You do not currently have any saved retirement fund plan changes.')
        }
        return changeDescriptionArray
    },

    // *** retirement fund plan change object ***
    // {
    //     "retirement_fund": true / false,
    //     "employee_contribution": true / false,
    //     "extra_data": true / false,
    // }
    // this is only used to help in textSummaryOfChangesRetirement()
    retirementFundChangeIndicators: (_, getters) => {
        let planChangeObject = {}
        const currentPlan = getters.retirementFundContractObj
        const newPlan = getters.retirementFundContractObjPlanned

        if (!currentPlan || !newPlan) return false
        planChangeObject.retirement_fund = currentPlan.retirement_fund.id !== newPlan.retirement_fund
        planChangeObject.employee_contribution = currentPlan.employee_contribution !== newPlan.monthly_contribution
        planChangeObject.extra_data = !isEqual(currentPlan.extra_data, newPlan.extra_data)
        return planChangeObject
    },



}