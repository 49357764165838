export const bentoCurrencyFormat = (number, decimalDigits = 2, noSpace = false) => {
    
    let newNumber = Math.round((Number(number) + Number.EPSILON) * 100) / 100

    if (Number.isInteger(newNumber)) {
        decimalDigits = 0
    }

    const formatter = new Intl.NumberFormat('en-ZA', {
        style: 'currency',
        currency: 'ZAR',
        maximumFractionDigits: decimalDigits,
    })

    let formatted = formatter.format(newNumber)

    formatted = formatted.replace(/,/g, ' ')

    if (noSpace) {
        formatted = formatted.replace(/\s+/g, '')
    }

    return formatted
}


export const roundToOneDecimal = num => Math.round(num * 10) / 10

export const roundToTwoDecimal = num => Math.round((Number(num) + Number.EPSILON) * 100) / 100

export const roundToThreeDecimal = num => Math.round((Number(num) + Number.EPSILON) * 1000) / 1000

export const convertToPercentage = num => roundToTwoDecimal(num * 100)