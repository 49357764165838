import {createStore} from "vuex";

import mutations from './mutations'
import actions from './actions'
import getters from './getters'

import authModule from './modules/auth/index'
import userTermsModule from './modules/user-terms/index'
import contentModule from './modules/content/index'
import employeeModule from './modules/employee/index'
import managerModule from './modules/manager/index'
import simpleBenefitsModule from './modules/simple-benefits/index'
import employerModule from './modules/employer/index'
import staticContentModule from './modules/static-content/index'
import adminSuperUserModule from './modules/admin-super-user/index'
import uiStateModule from './modules/ui-state/index'


const store = createStore({

    modules: {
        authModule,
        userTermsModule,
        contentModule,
        employeeModule,
        managerModule,
        simpleBenefitsModule,
        employerModule,
        staticContentModule,
        adminSuperUserModule,
        uiStateModule
    },

    state() {
        return {
            imageDir: 'https://content.mybento.net/media/images/',
        }

    },
    mutations,
    actions,
    getters,
});

// export the store, imported in main.js for our app to use there
export default store;