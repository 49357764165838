<template>
    <menu-item :class="classList" :is-current="isCurrent">
        <template #title>
            Leave Management
        </template>
        <template #subMenuItems>
            <menu-item-second route-path="/my-team/leave-management" section-id="manager-leave-calendar" v-if="isLeaveApprover">Combined Leave Calendar</menu-item-second>
            <menu-item-second route-path="/my-team/leave-management" section-id="manager-leave-requests" v-if="isLeaveApprover">Leave Requests</menu-item-second>
            <menu-item-second route-path="/my-team/leave-management" section-id="download-leave-report" v-if="isHrAdmin || permissionsCanDownloadLeaveReports">Download Leave Report</menu-item-second>
            <menu-item-second route-path="/my-team/leave-balance-lookup" v-if="(isHrAdmin || isLeaveApprover) && futureLeaveBalanceEnabled">Leave Balance Lookup</menu-item-second>
        </template>
    </menu-item>
</template>

<script setup>
    import MenuItem from "@/components/layouts/side-nav/side-nav-components/MenuItem.vue"
    import MenuItemSecond from "@/components/layouts/side-nav/side-nav-components/MenuItemSecond.vue"
    import {computed, ref} from "vue"
    import {useStore} from "vuex"
    const store = useStore()

    const isLeaveApprover = computed(() => store.getters.isLeaveApprover)
    const permissionsCanDownloadLeaveReports = computed(() => store.getters.permissionsCanDownloadLeaveReports)
    const isHrAdmin = computed(() => store.getters.isHrAdmin)
    
    import {useRoute} from 'vue-router'
    const route = useRoute()
    const isCurrent = computed(() => route.meta.menuParent === 'leave-management')
    const classList = computed(() => {
        return {'current-parent': isCurrent.value }
    })

    const futureLeaveBalanceEnabled = ref(false)
    const testGetFutureLeaveBalanceHandler = async() => {
        try {
            futureLeaveBalanceEnabled.value = await store.dispatch('getFutureLeaveBalance', {date: '2050-06-01'})
            futureLeaveBalanceEnabled.value = true
        } catch (error) {
            console.warn("Future leave balance functionality is not supported."); // Custom error message
        }
    }
    testGetFutureLeaveBalanceHandler()
</script>