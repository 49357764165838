<template>
    <card-general id="learn" class="green">
        <section-header class="green">Learn More & Explore</section-header>
        
        <div>
            <learn-grid>
                <base-learn-card
                    v-for="resource in learningResourcesHomeApi"
                    :key="resource.id"
                    :resource="resource"
                ></base-learn-card>
            </learn-grid>
        </div>
    </card-general>
</template>

<script setup>
    import SectionHeader from "../UI/v2/containers-cards-headers/SectionHeader";
    import LearnGrid from "@/components/learn-and-explore/LearnGrid";
    import BaseLearnCard from "@/components/learn-and-explore/BaseLearnCard";
    import {computed} from "vue";
    import {useStore} from "vuex";
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral";
    const store = useStore();
    
    const learningResourcesHomeApi = computed( () => store.getters.learningResourcesHomeApi );
</script>