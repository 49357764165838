<template>
    <div class="mt-3">
        <div v-if="!isValidArray(retirementFundList)">
            <div class="font-size-17 font-weight-600 mb-2">Your employer has not made any retirement funds available.</div>
        </div>
        <div v-else>
            <div class="font-size-17 font-weight-600 mb-2">Investment options available through Bento:</div>
            <Carousel
                :settings="settings"
                :breakpoints="breakpoints"
                ref="retirementCarousel"
            >
                <Slide v-for="(slide, index) in retirementFundList" :key="slide">
                    <div
                        @click="selectFundHandler(slide.id)"
                        class="carousel__item d-flex flex-column align-items-center justify-content-center"
                        :class="[{'gradient-blue-darkest': index % 4 === 0, 'gradient-orange': index % 4 === 1, 'gradient-primary': index % 4 === 2, 'gradient-purple': index % 4 === 3, 'selected': isSelected(slide.id)}]"
                    >
                        <div class="cont px-3 py-3 text-white d-flex flex-column justify-content-between cursor-pointer flex-grow-1">
                            <div>
                                <div class="mb-2 font-weight-600 font-size-18 title">{{ slide.name }}</div>
                                <div class="mb-2 font-weight-500 font-size-16 fst-italic sub-title" v-html="slide.blurb"></div>
                                <div class="mb-5 mb-sm-4 pt-1 font-size-14 font-weight-400 lh-base">{{ slide.description }}</div>
                            </div>
                            <div>
                                <div class="mb-3 fw-500 font-size-14" v-if="label(slide.id)">
                                    {{ label(slide.id) }}
                                </div>
                                <div class="font-weight-500 font-size-15" v-if="slide.fact_sheet_file">
                                    <a :href="slide.fact_sheet_file" target="_blank"><img src="@/assets/v2/icons/pdf-icon.svg" class="d-inline-block me-3"/>
                                        <span class="bento-underline-white-hover">Download fact sheet</span></a>
                                </div>
                            </div>
                        </div>

                    </div>
                </Slide>

                <template #addons>
                    <Navigation/>
                    <Pagination/>
                </template>
            </Carousel>
        </div>
    </div>
</template>

<script setup>
import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel'
import 'vue3-carousel/dist/carousel.css'
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {isValidArray} from "@/hooks/general/type-and-value-checks";

const store = useStore()

const retirementFundList = computed(() => store.getters.retirementFundList)

const hasRetirementFundContract = computed(() => store.getters.hasRetirementFundContract)
const retirementFundContractObj = computed(() => store.getters.retirementFundContractObj)

const retirementFundContractObjSubmitted = computed(() => store.getters.retirementFundContractObjSubmitted)
const retirementFundContractObjDraft = computed(() => store.getters.retirementFundContractObjDraft)

const label = (fundId) => {
    if (hasRetirementFundContract.value && fundId == retirementFundContractObj.value.retirement_fund.id) {
        return '-> Current Plan'
    }
    if (retirementFundContractObjSubmitted.value && fundId == retirementFundContractObjSubmitted.value.retirement_fund) {
        return '-> Submitted Plan'
    }
    if (retirementFundContractObjDraft.value && fundId == retirementFundContractObjDraft.value.retirement_fund) {
        return '-> Saved Draft Plan'
    }
    return null
}

// carousel settings
const settings = {
    itemsToShow: 1,
    snapAlign: 'center',
    wrapAround: true,
}

// breakpoints are mobile first
// any settings not specified will fallback to the carousel settings
const breakpoints = {
    // 700px and up
    700: {
        itemsToShow: 3,
    },
    // 1024 and up
    1024: {
        itemsToShow: 3,
    },
}


const tempSelectedRetirementFundContractObj = computed(() => store.getters.tempSelectedRetirementFundContractObj)
const selectedFund = computed(() => {
    if (!tempSelectedRetirementFundContractObj.value) return null
    return retirementFundList.value.find(fund => fund.id == tempSelectedRetirementFundContractObj.value.fundId)
})

const isSelected = (fundId) => selectedFund.value && fundId === selectedFund.value.id


const showSaveDraftNotice = ref(false)
const saveDraftNoticeText = ref(null)

const selectFundHandler = (fundId) => {
    saveDraftNoticeText.value = null
    showSaveDraftNotice.value = false
    store.commit('setTempSelectedRetirementFundContractObj', {
        ...tempSelectedRetirementFundContractObj.value,
        fundId: fundId
    })
}

</script>

<style scoped lang="scss">
@import '@/styles/global-scss/variables-and-mixins.scss';


.carousel__item {
    min-height: 356px;
    width: 100%;
    border-radius: 13px;
    //@include media-breakpoint-up(md) { min-height: 360px; }
    //@include media-breakpoint-up(lg) { min-height: 300px; }
    //@include media-breakpoint-up(xl) { min-height: 337px; }

    &:hover, &.selected {
        transform: scale(1.028);
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.96);
    }
}



.sub-title {
    line-height: 1.25;
}
</style>
