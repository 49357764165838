<template>
    <div class="intro-container">
        <div v-if="isEmployerCcfoCt">
            <bento-alert color="warning" static dismiss v-model="noContributionExplanation" class="pe-5 mb-4" icon-type="info-warning">
                CCFO does not currently contribute to your medical aid. However, benefit support is being reviewed as a priority.
            </bento-alert>
        </div>
        
        <bento-alert color="info" static dismiss v-model="playAroundExplanation" class="pe-5 mb-4" icon-type="info" v-if="!areMedicalAidChangesSubmitted">
            You can play around with your options below to see the impact it will have. Changes will not take effect until you submit them.
        </bento-alert>
        
        <bento-alert color="info" static dismiss v-model="playAroundExplanation" class="pe-5 mb-4" v-if="areMedicalAidChangesSubmitted">
            You have submitted a medical aid contract change which is currently being processed.
        </bento-alert>
    </div>
</template>

<script setup>
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
    import {computed, ref} from "vue"
    import {useStore} from "vuex"
    
    const store = useStore()
    const playAroundExplanation = ref(true)
    const noContributionExplanation = ref(true)
    const areMedicalAidChangesSubmitted = computed(() => store.getters.areMedicalAidChangesSubmitted)
    
    const isEmployerCcfoCt = store.getters.isEmployerCcfoCt
</script>