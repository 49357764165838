<template>
    <card-general-inner class="green no-shadow gradient-green-text-dark">
        <div class="d-flex align-items-center justify-content-start">
            <div class="profile-picture-cont ratio ratio-1x1 me-3" :style="{ backgroundImage: `url(${profilePicture(employee.profile_picture)})` }"></div>
            <div>
                <div class="fw-400">
                    <span class="fw-500">{{ nameAndPreferredName(employee) }}</span>
                </div>
                <div class="fw-400 text-gray-main font-size-13 lh-1">
                    {{ employee.job_title }}
                </div>
                <div class="fw-400 text-gray-main font-size-13">
                    <a v-if="isBentoDemoCompany" href="mailto:example@email.com">example@email.com</a>
                    <a v-else :href="`mailto:${employee.user.email}`">{{ employee.user.email }}</a>
                </div>
            </div>
            <i v-if="showRemoveButton" class="fas fa-times-circle remove-approver-icon font-size-16 text-secondary-800 cursor-pointer p-1" @click="$emit('removeApprover', employeeID)"></i>
        </div>
    </card-general-inner>
</template>

<script setup>
    import {computed} from "vue"
    import {useStore} from "vuex"
    import CardGeneralInner from "@/components/UI/v2/containers-cards-headers/CardGeneralInner.vue"
    import {nameAndPreferredName} from "@/hooks/general/employee-info-utilities"
    import {isProd} from "@/hooks/api/app-environment-origin";

    const store = useStore()
    const employer = computed(() => store.getters.employer)
    const isBentoDemoCompany = computed(() => employer.value.name === 'Bento Demo' || (employer.value.id === 6 && isProd()) )
    

    const props = defineProps({
        employeeID: {
            type: [Number, String],
            required: true
        },
        showRemoveButton: {
            type: Boolean,
            required: false,
            default: true,
        }
    })
    
    const employee = computed(() => store.getters.employeeList.find(employee => employee.id === props.employeeID))
    
    const profilePicture = (picture) => picture ? picture.replace('http://', 'https://') : require('@/assets/v2/placeholders/user-placeholder.png')
</script>

<style scoped lang="scss">
    .profile-picture-cont {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        flex: 0 0 40px;
        border-radius: 50%;
    }
    
    .remove-approver-icon {
        position: absolute;
        top: 7px;
        right: 5px;
    }
</style>