<template>
    <div class="col-12 col-md-6 col-xl-4 card-col">
        <div
            class="card-container d-flex align-items-center font-weight-500 bg-white rounded cursor-pointer"
            @click="selectResource(resource)"
        >
            {{ resource.title }}
        </div>
    </div>
</template>

<script setup>
    import {useRouter, useRoute} from 'vue-router'
    
    const route = useRoute()
    
    const router = useRouter()
    defineProps({
        resource: {
            type: Object,
            required: true,
        },
    })
    
    const selectResource = (resource) => {
        let path = '/'
        
        // if on simple benefit page
        if (route.meta.simpleBenefitPage) {
            const simpleBenefitCategoryId = route.params.simpleBenefitCategoryId
            const simpleBenefitCategory = route.params.simpleBenefitCategory
            const simpleBenefitId = route.params.simpleBenefitId
            const resourceSlug = route.params.simpleBenefitSlug
            path = `/perk/${simpleBenefitCategoryId}/${simpleBenefitCategory}/${simpleBenefitId}/${resourceSlug}/${resource.slug}`
        } else {
            // else if on other page (home, medical, retirement, risk, bonuses & commissions, leave, reimbursements etc ...)
            switch (resource.tag) {
                case 'home-link':
                    path = resource.slug         // '/employee-survey' && '/contact-us' pages.
                    break
                case 'medical':
                    path = '/benefits/medical-aid/' + resource.slug    // '/benefits/medical-aid/why-does-medical-aid-matter-anyway'
                    break
                case 'retirement':
                    path = '/benefits/retirement/' + resource.slug     // '/benefits/retirement/why-does-medical-aid-matter-anyway'
                    break
                case 'risk':
                    path = '/benefits/insurance/' + resource.slug
                    break
                case 'bonuses_commissions':
                    path = '/benefits/bonuses-and-commissions/' + resource.slug
                    break
                case 'leave':
                    path = '/leave/' + resource.slug
                    break
                case 'reimbursements':
                    path = '/reimbursements/' + resource.slug
                    break
                case 'ad-hoc-reimbursements':
                    path = '/ad-hoc-reimbursements/' + resource.slug
                    break
                default: //
                    path = '/learn-and-explore/' + resource.slug          // e.g. '/learn-and-explore/make-the-most-of-your-earnings'
            }
        }
        
        router.push({ path: path })
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    .card-col.active-card {
        .card-container {
            background: $gradient-primary;
            color: white;
        }
    }
    
    .card-container {
        padding: 10px 14px;
        transition: all .2s ease-in-out;
        min-height: 64px;
        font-size: 14px;
        line-height: 1.3;
        border: 1px solid $gradient-primary-1;
        color: $gradient-primary-1;
        @include media-breakpoint-up(sm) {
            font-size: 15px;
        }
        @include media-breakpoint-up(lg) {
            font-size: 16px;
        }
        &:hover {
            background: $gradient-primary;
            color: white;
        }
    }
</style>