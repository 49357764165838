// employee profile mutations
export default {

    // Bank Account Change Request List
    setBankAccountChangeRequestList: (state, bankAccountChangeRequestList) => state.bankAccountChangeRequestList = bankAccountChangeRequestList,
    setLastBankAccountChangeRequestListFetch: (state, payload) => state.lastBankAccountChangeRequestListFetch = (payload) ? (payload.timestamp) : null,

    // Employee Directory List
    setEmployeeDirectoryList: (state, employeeDirectoryList) => state.employeeDirectoryList = employeeDirectoryList,
    setLastEmployeeDirectoryListFetch: (state, payload) => state.lastEmployeeDirectoryListFetch = (payload) ? (payload.timestamp) : null,
}