// admin-super-user module

import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    state() {
        return {
            employeeList: null,
            lastEmployeeListFetch: null,

            minimalEmployeeList: null,
            lastMinimalEmployeeListFetch: null,

            employerList: null,
            lastEmployerListFetch: null,

            employeeListWithLeaveApprovees: null,
            lastEmployeeListWithLeaveApproveesFetch: null,
        }
    },

    mutations,
    actions,
    getters,
}