// Employee profile store module
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {

    state() {
        return {

            bankAccountChangeRequestList: null,
            lastBankAccountChangeRequestListFetch: null,

            employeeDirectoryList: null,
            lastEmployeeDirectoryListFetch: null,
        }
    },

    mutations,
    actions,
    getters,


}