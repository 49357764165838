<template>
    <modal-basic v-model="modelValueComputed">
        <template #title>Overlapping leave request</template>
        <template #body>
            <div>This leave request has overlapping dates with the following request:</div>
            <div class="my-2">
                <div>
                    <span>Leave type: </span><span class="text-secondary-700">{{ overlappingRequest.leave_type_name }}</span>
                </div>
                <div>
                    <span>Request dates: </span><span class="text-secondary-700">{{ dateRangeFormatter(overlappingRequest.start_date, overlappingRequest.end_date) }} <span v-if="overlappingRequest.hours == 4">(Half Day)</span></span>
                </div>
                <div>
                    <span>Request status: </span><span class="text-secondary-700">{{ toTitleCase(overlappingRequest.state) }}</span>
                </div>
            </div>
        </template>
        <template #button1>
            <button-bento @click="closeModal" size="lg" button-color="secondary-light" class="mx-2 flex-grow-1 px-1">Change request dates</button-bento>
        </template>
        <template #button2>
            <button-bento @click="submitLeaveRequestHandler" class="mx-2 flex-grow-1 px-1" size="lg">Submit anyway</button-bento>
        </template>
    </modal-basic>
</template>

<script setup>
    import {dateRangeFormatter} from "@/hooks/general/date-helpers"
    import {toTitleCase} from "@/hooks/general/text-formatters"
    import ModalBasic from "@/components/UI/v2/modals/ModalBasic.vue"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento.vue"
    import {computed} from "vue";
    
    const props = defineProps({
        modelValue: {
            required: true,
            type: Boolean
        },
        overlappingRequest: {
            required: true,
            type: [Object, null]
        },
    })
    
    const emit = defineEmits(['update:modelValue', 'submitLeaveRequest'])
    
    const modelValueComputed = computed({
        get: () => props.modelValue,
        set: (showModal) => emit('update:modelValue', showModal)
    })
    
    const closeModal = () => {
        emit('update:modelValue', false)
    }
    
    const submitLeaveRequestHandler = () => {
        emit('submitLeaveRequest')
    }

</script>

<style scoped lang="scss">

</style>