// employee profile
import EmployeeProfilePage from "@/components/employee-profile-directory/employee-profile/EmployeeProfilePage.vue";
import PersonalInfoPage from "@/components/employee-profile-directory/employee-profile/PersonalInfoPage.vue";
import FinancialDetailsPage from "@/components/employee-profile-directory/employee-profile/FinancialDetailsPage.vue";
import CalendarLinksPage from "@/components/employee-profile-directory/employee-profile/CalendarLinksPage.vue";
import PayslipPage from "@/components/summary-and-payslip/payslip/PayslipPage.vue";

// employee directory
import EmployeeDirectory from "@/components/employee-profile-directory/employee-directory/EmployeeDirectory.vue";
import EmployeeDirectoryEmployeeProfile
    from "@/components/employee-profile-directory/employee-directory/EmployeeDirectoryEmployeeProfile.vue";

export default [

    // employee profile
    { name: 'employee-profile', path: '/profile', component: EmployeeProfilePage, redirect: {name: 'employee-personal-info'} },
    { name: 'employee-personal-info', path: '/profile-and-directory/personal-info', component: PersonalInfoPage, meta: { menuParent: 'profile' } },
    { name: 'employee-financial-details', path: '/profile-and-directory/financial-details', component: FinancialDetailsPage, meta: { menuParent: 'profile' } },
    { name: 'employee-calendar-links', path: '/profile-and-directory/calendar-links', component: CalendarLinksPage, meta: { menuParent: 'profile' } },
    { name: 'employee-payslip', path: '/my-payslip', component: PayslipPage, meta: { menuParent: 'salary' } },

    // employee directory
    { name: 'employee-directory', path: '/employee-directory', component: EmployeeDirectory, meta: { menuParent: 'profile' } },
    {
        name: 'employee-directory-employee-profile',
        path: '/employee-directory/:employeeId',
        component: EmployeeDirectoryEmployeeProfile,
        meta: { menuParent: 'profile' }
    },
]
