// auth module mutations
export default {

    setUser: (state, userData) => {
        if (userData.authToken) {
            state.authToken = userData.authToken
        }
        if (userData.userId) {
            state.userId = userData.userId
            state.actualUserId = userData.userId
        }
    },

    clearUser: (state) => {
        state.authToken = null
        state.userId = null
        state.actualUserId = null
    },

    setUserFromBentoAdmin: (state, userData) => {
        if (userData.userId) {
            state.userId = userData.userId
        }
        if (userData.actualUserId) {
            state.actualUserId = userData.actualUserId
        }
    },

}