// admin-super-user module mutations

export default {
    setEmployeeList: (state, payload) => state.employeeList = payload,
    setLastEmployeeListFetchTimeStamp: (state, payload) => state.lastEmployeeListFetch = payload.timestamp,

    setMinimalEmployeeList: (state, payload) => state.minimalEmployeeList = payload,
    setLastMinimalEmployeeListFetchTimeStamp: (state, payload) => state.lastMinimalEmployeeListFetch = payload.timestamp,

    setEmployerList: (state, payload) => state.employerList = payload,
    setlastEmployerListFetchTimestamp: (state, payload) => state.lastEmployerListFetch = payload.timestamp,

    setEmployeeListWithLeaveApprovees: (state, payload) => state.employeeListWithLeaveApprovees = payload,
    setlastEmployeeListWithLeaveApproveesFetch: (state, payload) => state.lastEmployeeListWithLeaveApproveesFetch = payload.timestamp,
}