
export function isRequiredValidate(value) {
    if (value && value.trim()) {
        return true
    }
    return false
    // return "This is required";
}

export function isLongerThan(value, longerThan) {
    if (value && value.trim().length > longerThan) {
        return true
    }
    return false
    // return `This needs to be longer than ${longerThan} characters`;
}

export function isValidEmail (value) {
    if (value && /[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/i.test(value)) {
        return true;
    }
    return "This field must be a valid email";
}

export function isPhoneNumber(value) {
    if (value && value.length === 9) {
        return true;
    }
    return "Phone number should have exact 9 number";
}