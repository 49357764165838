// Employee store module [not namespaced]

import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {

    state() {
        return {
            contentCards: null,
            lastContentFetch: null,

        }
    },

    mutations,
    actions,
    getters,


}