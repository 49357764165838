<template>
    <div class="mb-4 mt-2 d-flex flex-wrap align-items-center justify-content-between">

        <div class="fw-500 lh-1">
            <div class="approved-amount font-size-22">
                {{ bentoCurrencyFormat(approvedAmount) }}
            </div>
            <div class="mt-1 pt-1 font-size-11 text-gradient-blue-text-dark">
                approved of <span class="fw-600">{{ bentoCurrencyFormat(reimbursementAllowance.amount) }}</span>
            </div>
        </div>

        <div class="col-3-remaining text-end fw-500 lh-1">
            <div class="approved-amount font-size-22">
                {{ bentoCurrencyFormat(remainingBalance) }}
            </div>
            <div class="mt-1 pt-1 font-size-11 text-gradient-blue-text-dark">
                left of <span class="fw-600">{{ bentoCurrencyFormat(reimbursementAllowance.amount) }}</span>
            </div>
        </div>

        <div class="bar-cont mt-3">
            <div class="the-bar" id="the-bar" ref="theBar">
                <div class="submitted-claimed-bar progress-bar" :style="{ width: submittedClaimedWidth + '%'}"></div>
                <div class="approved-bar progress-bar" :style="{ width: approvedWidth + '%'}"></div>
            </div>
        </div>

        <div>
            <div v-if="false" class="mt-1 pt-1 font-size-11 text-gradient-blue-text-dark">Submitted {{ bentoCurrencyFormat(submittedClaimedAmount) }}</div>
            <div
                v-if="awaitingApproval > 0"
                class="mt-1 pt-1 font-size-12 fw-500 text-gradient-blue-text-dark"
            >
                Awaiting approval: {{ bentoCurrencyFormat(awaitingApproval) }}
            </div>
        </div>
    </div>
</template>

<script setup>
import {computed} from 'vue'
import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"

const props = defineProps({
    reimbursementAllowance: {
        type: Object,
        required: true,
    }
})

const remainingBalance = computed(() => Number(props.reimbursementAllowance.amount) - Number(props.reimbursementAllowance.claimed_total_for_current_period))
const totalWidth = computed(() => Number(props.reimbursementAllowance.amount))
const approvedAmount = computed(() => Number(props.reimbursementAllowance.approved_total_for_current_period))
const submittedClaimedAmount = computed(() => Number(props.reimbursementAllowance.claimed_total_for_current_period))
const awaitingApproval = computed(() => Number(props.reimbursementAllowance.claimed_total_for_current_period) - Number(props.reimbursementAllowance.approved_total_for_current_period))
const approvedWidth = computed(() => ((approvedAmount.value / totalWidth.value) * 100))
const submittedClaimedWidth = computed(() => ((submittedClaimedAmount.value / totalWidth.value) * 100))
</script>


<style lang="scss" scoped>
@import "@/styles/global-scss/variables-and-mixins.scss";

.bar-cont {
    border: 1px solid #bdbdbd;
    padding: 3px;
    border-radius: 17px;
    flex: 0 0 100%;
}

.the-bar {
    height: 26px;
    background-color: #DCDCDC;
    position: relative;
    border-radius: 14px;
}

.progress-bar {
    font-weight: 400;
    border-radius: 14px;
    height: 26px;
    position: absolute;
    text-align: right;
    left: 0;
    top: 0;
}

.approved-amount {
    color: lighten($gradient-blue-text-dark, 5%);
}

.approved-bar {
    background: linear-gradient(to right, $gradient-blue-text-dark, lighten($gradient-blue-text-dark, 20%));
}

.submitted-claimed-bar {
    background-color: #979797;
}
</style>