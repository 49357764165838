<template>
    <div class="custom-width-salary-figures">
        
        <button-bento>
            <router-link :to="{name: 'bento-admin'}">Back to Bento Admin</router-link>
        </button-bento>
        
        <div class="employee-api">
            <h3>{{ currentYearFinancialTables.financialYear }} Financial Table</h3>
            <p>Current fincancial tax year based on employee current payslip displayed in app.</p>
            <div>
                <pre>
                    {{ currentYearFinancialTables }}
                </pre>
            </div>
        </div>
        
        <div class="employee-api">
            <h3>{{ nextYearFinancialTables.financialYear }} Financial Table</h3>
            <p>
                Next financial tax year based on employee current payslip displayed in app.<br> <strong>
                <mark>*If this is the same as current, it means next year financial tables haven't come out yet. Or they haven't been loaded in /admin/ backend*</mark>
            </strong>
            </p>
            <div>
                <pre>
                    {{ currentYearFinancialTables }}
                </pre>
            </div>
        </div>
    
    </div>
</template>

<script setup>
    import {computed} from "vue";
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
    import {useStore} from "vuex"
    
    const store = useStore()
    
    const currentYearFinancialTables = computed(() => store.getters.currentYearFinancialTables)
    const nextYearFinancialTables = computed(() => store.getters.nextYearFinancialTables)
</script>

<style lang="scss" scoped>
    
    .custom-width-salary-figures {
        padding: 0 20px 45px;
        max-width: 1200px;
        margin: 0 auto;
    }
    
    .employee-api {
        margin-top: 45px;
        margin-bottom: 45px;
        border: 1px solid black;
        padding: 15px 15px 50px;
    }
    
    h3 {
        margin-top: 6px;
        margin-bottom: 6px;
    }
    
    p {
        margin-top: 6px;
        margin-bottom: 24px;
        font-size: 14px;
        font-weight: 600;
        color: #555;
        line-height: 1.5;
    }
    
    pre {
        font-size: 14px;
    }

</style>