import {isValidArray} from "@/hooks/general/type-and-value-checks"

export const shouldUpdateCheck = (lastFetchTime, currentTime) => {
    if (!lastFetchTime) return true
    return (currentTime - lastFetchTime) / 1000 > 900
}


// ### create getter from internal name and list of type (e.g. cashPortionsList, deductionsList, allowancesList, employerContributionsList)
export function createPayslipGetter(itemList, internalName, isConstant = true) {
    if (isValidArray(itemList)) {
        let item = itemList.find(item => item.internal_name === internalName)
        if (item) {
            let apiAmount = Number(item.amount)
            let itemLabel = item.name

            // this getter creator function is used for getters that are constant and not constant
            // default is constant, which makes planned = apiAmount
            // and we leave current as false, because it's not a calc value
            const planned = (isConstant) ? apiAmount : false

            return {
                id: internalName,
                label: itemLabel,
                api: apiAmount,
                current: false,
                planned: planned,
            }
        }
    }

    // Return null if the internal name is not found in the itemList
    return null
}

// ### create getter for year to date amount. Used for ytdAnnualBonus & ytdAnnualPayment
export function createYtdGetter(payslipsList, payslipProperty, internalName) {
    let amount = 0;
    let label = '';
    // *** need to look for annual_bonus & annual_payment (cash portions) in all payslips for the current year
    // *** need to lookg for broad_based_employee_share_plan (allowances) in all payslips for the current year
    if (isValidArray(payslipsList)) {
        for (const payslip of payslipsList) {
            const payslipSectionList = payslip[payslipProperty];
            for (const item of payslipSectionList) {
                if (item.internal_name === internalName) {
                    amount += Number(item.amount);
                    label = item.name;
                    break;
                }
            }
        }
    }

    // planned is going to be the same as current. We don't know if an irregular income is going to be included in next months payslip
    return {
        label: label,
        current: Number(amount),
        planned: Number(amount),
    }
}


export function sumOfApiList(apiList) {
    if (!isValidArray(apiList)) {
        return false;
    }
    let total = 0;
    for (const item of apiList) {
      total += Number(item.amount);
    }
    return total;
}