// static contents module

import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {

    state() {
        return {
            // from API
            staticContent: null,
            lastStaticContentFetch: null,

        }
    },

    mutations,
    actions,
    getters,

}