// manager store module
import TeamAdminModule from './team-admin/index'
import ManagerLeaveModule from './leave/index'
import ManagerReimbursementsModule from './reimbursements/index'

export default {
    modules: {
        TeamAdminModule,
        ManagerLeaveModule,
        ManagerReimbursementsModule
    },
}