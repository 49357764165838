<template>
    <div>
        <card-general-inner class="green no-shadow gradient-green-text-dark mt-5">
            <div class="font-size-18 font-weight-500">Primary Investor details to be used on application form</div>
            <div class="disclaimer-text mt-n1">(Please note we will pre-populate the majority of the form and send the completed version to you to review and sign)</div>
            
            <div class="font-size-17 mt-4 fw-500">
                {{ userFullName }}
            </div>
            <div class="mt-1 lh-md">
                <div><span class="fw-500">ID:</span> {{ employee.id_number }}</div>
                <div><span class="fw-500">Income tax nr:</span> {{ employee.income_tax_number || '-' }}</div>
                <div><span class="fw-500">Cell nr:</span> {{ employee.cell_number || '-' }}</div>
            </div>
            <div class="mt-3" v-if="false">
                <label for="investing-on-behalf"> <input type="checkbox" id="investing-on-behalf" class="checkbox-custom" :disabled="areRetirementChangesSubmitted" v-model="investingOnBehalf"/>
                    <div class="d-inline ms-1 text-black fw-500 font-size-13 checkbox-label-text checkbox-label">If you are considering investing on behalf of someone like a dependent, please tick this box to inform your advisor</div>
                </label>
            </div>
            <div class="fw-500 font-size-13 mt-3 update-profile-link">*
                <router-link :to="{name: 'employee-profile'}">You can update your profile info here</router-link>
            </div>
        </card-general-inner>
        
        <card-general-inner class="green no-shadow gradient-green-text-dark mt-3" v-if="false">
            <div class="font-size-18 font-weight-500">Preferences</div>
            <div class="disclaimer-text mt-n1">(Please note we have defaulted your communication method to be via email. If you prefer a postal address, please indicate.)</div>
            <div class="mt-3">
                <label for="receive-via-post"> <input type="checkbox" id="receive-via-post" class="checkbox-custom" :disabled="areRetirementChangesSubmitted" v-model="receiveViaPost"/>
                    <div class="d-inline ms-1 text-black fw-500 font-size-13 checkbox-label-text checkbox-label">Receive communication via post as well</div>
                </label>
            </div>
        </card-general-inner>
        
        <card-general-inner class="green no-shadow gradient-green-text-dark mt-3">
            <div class="font-size-18 font-weight-500">Beneficiaries</div>
            <div class="disclaimer-text mt-n1">
                (NOTE: Section 37C of the Pension Funds Act, 24 of 1956 governs the distribution of benefits on a member’s death. In terms of this section, the board of trustees have a duty to apportion the benefits equitably between dependants and/or nominees. Your nomination will assist the board of trustees in making their decision, however, payment to your nominated beneficiary(ies) is not guaranteed.)
            </div>
            
            <!--testing-->
            <div v-if="false">
                <div><pre><small>showSecondBeneficiary: {{ showSecondBeneficiary }}</small></pre></div>
                <div><pre><small>showThirdBeneficiary: {{ showThirdBeneficiary }}</small></pre></div>
                <div><pre><small>showFourthBeneficiary: {{ showFourthBeneficiary }}</small></pre></div>
                <div><pre><small>isBeneficiary4Complete: {{ isBeneficiary4Complete }}</small></pre></div>
            </div>
            
            <div class="mt-4">
                
                <!--beneficiary 1-->
                <div class="beneficiary">
                    <div class="d-flex align-items-center">
                        <div class="font-size-15 fw-500 text-gray-main me-5 pe-4">
                            <img src="@/assets/v2/icons/person.svg" class="me-2 mb-1"/> <span>Beneficiary 1</span><span v-if="beneficiary1Name">: {{ beneficiary1Name }}</span>
                        </div>
                        <div class="position-relative">
                            <beneficiary-input
                                v-model="allocation" :input-value="allocation" label="Allocation"
                                invalid-feedback="Please enter a valid percentage between 1 and 100" :show-label="false"
                                :max-length-value="3" class="beneficiary-percentage-input"
                                type="number" :min-number="1" :max-number="100" :step="1"
                            />
                            <div class="percent-sign">%</div>
                        </div>
                    </div>
                    <div class="row g-3 mt-2">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="firstName" :input-value="firstName" label="Name"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="surname" :input-value="surname" label="Surname"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="idNumber" :input-value="idNumber" label="ID Number"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="relationship" :input-value="relationship" label="Relationship"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="occupation" :input-value="occupation" label="Occupation"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="contactNumber" :input-value="contactNumber" label="Contact Nr"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="emailAddress" :input-value="emailAddress" label="Email Address"/>
                        </div>
                    </div>
                </div>
                <div class="font-size-15 fw-500 text-gray-main me-5 pe-4 mt-4 cursor-pointer d-inline-block" v-if="!doesBeneficiary2HaveAnyValues && !areRetirementChangesSubmitted && !showSecondBeneficiary && isBeneficiary1Complete" @click="addSecondBeneficiaryHandler">
                    <img src="@/assets/v2/icons/person.svg" class="me-2 mb-1"/> <span>Add second beneficiary</span> <img src="@/assets/v2/icons/arrows/arrow-black-right.svg" class="arrow"/>
                </div>
                
                <!--beneficiary 2-->
                <div class="beneficiary mt-5 pt-3" v-if="doesBeneficiary2HaveAnyValues || showSecondBeneficiary">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <div class="font-size-15 fw-500 text-gray-main me-5 pe-4">
                                <img src="@/assets/v2/icons/person.svg" class="me-2 mb-1"/> <span>Beneficiary 2</span><span v-if="beneficiary2Name">: {{ beneficiary2Name }}</span>
                            </div>
                            <div class="position-relative">
                                <beneficiary-input
                                    v-model="allocation2" :input-value="allocation2" label="Allocation"
                                    invalid-feedback="Please enter a valid percentage between 1 and 100" :show-label="false"
                                    :max-length-value="3" class="beneficiary-percentage-input"
                                    :min-number="1" :max-number="100" :step="1"
                                    type="number"
                                />
                                <div class="percent-sign">%</div>
                            </div>
                        </div>
                        <div v-if="!doesBeneficiary3HaveAnyValues || !showThirdBeneficiary" class="text-secondary-800 fw-600 cursor-pointer" @click="removeBeneficiary2Handler">Remove <i class="font-size-16 far fa-times-circle"></i></div>
                    </div>
                    <div class="row g-3 mt-2">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="firstName2" :input-value="firstName2" label="Name"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="surname2" :input-value="surname2" label="Surname"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="idNumber2" :input-value="idNumber2" label="ID Number"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="relationship2" :input-value="relationship2" label="Relationship"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="occupation2" :input-value="occupation2" label="Occupation"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="contactNumber2" :input-value="contactNumber2" label="Contact Nr"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="emailAddress2" :input-value="emailAddress2" label="Email Address"/>
                        </div>
                    </div>
                </div>
                <div class="font-size-15 fw-500 text-gray-main me-5 pe-4 mt-4 cursor-pointer d-inline-block" v-if="isBeneficiary2Complete && !areRetirementChangesSubmitted && !showThirdBeneficiary && isBeneficiary2Complete" @click="addThirdBeneficiaryHandler">
                    <img src="@/assets/v2/icons/person.svg" class="me-2 mb-1"/> <span>Add third beneficiary</span> <img src="@/assets/v2/icons/arrows/arrow-black-right.svg" class="arrow"/>
                </div>
                
                <!--beneficiary 3-->
                <div class="beneficiary mt-5 pt-3" v-if="doesBeneficiary3HaveAnyValues || showThirdBeneficiary">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <div class="font-size-15 fw-500 text-gray-main me-5 pe-4">
                                <img src="@/assets/v2/icons/person.svg" class="me-2 mb-1"/> <span>Beneficiary 3</span><span v-if="beneficiary3Name">: {{ beneficiary3Name }}</span>
                            </div>
                            <div class="position-relative">
                                <beneficiary-input
                                    v-model="allocation3" :input-value="allocation3" label="Allocation"
                                    invalid-feedback="Please enter a valid percentage between 1 and 100" :show-label="false"
                                    :max-length-value="3" class="beneficiary-percentage-input"
                                    :min-number="1" :max-number="100" :step="1"
                                    type="number"
                                />
                                <div class="percent-sign">%</div>
                            </div>
                        </div>
                        <div v-if="!doesBeneficiary4HaveAnyValues || !showFourthBeneficiary" class="text-secondary-800 fw-600 cursor-pointer" @click="removeBeneficiary3Handler">Remove <i class="font-size-16 far fa-times-circle"></i></div>
                    </div>
                    <div class="row g-3 mt-2">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="firstName3" :input-value="firstName3" label="Name"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="surname3" :input-value="surname3" label="Surname"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="idNumber3" :input-value="idNumber3" label="ID Number"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="relationship3" :input-value="relationship3" label="Relationship"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="occupation3" :input-value="occupation3" label="Occupation"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="contactNumber3" :input-value="contactNumber3" label="Contact Nr"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="emailAddress3" :input-value="emailAddress3" label="Email Address"/>
                        </div>
                    </div>
                </div>
                <div class="font-size-15 fw-500 text-gray-main me-5 pe-4 mt-4 cursor-pointer d-inline-block" v-if="isBeneficiary3Complete && !areRetirementChangesSubmitted && !showFourthBeneficiary" @click="addFourthBeneficiaryHandler">
                    <img src="@/assets/v2/icons/person.svg" class="me-2 mb-1"/> <span>Add fourth beneficiary</span> <img src="@/assets/v2/icons/arrows/arrow-black-right.svg" class="arrow"/>
                </div>
                
                <!--beneficiary 4-->
                <div class="beneficiary mt-5 pt-3" v-if="doesBeneficiary4HaveAnyValues || showFourthBeneficiary">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                            <div class="font-size-15 fw-500 text-gray-main me-5 pe-4">
                                <img src="@/assets/v2/icons/person.svg" class="me-2 mb-1"/> <span>Beneficiary 4</span><span v-if="beneficiary4Name">: {{ beneficiary4Name }}</span>
                            </div>
                            <div class="position-relative">
                                <beneficiary-input
                                    v-model="allocation4" :input-value="allocation4" label="Allocation"
                                    invalid-feedback="Please enter a valid percentage between 1 and 100" :show-label="false"
                                    :max-length-value="3" class="beneficiary-percentage-input"
                                    :min-number="1" :max-number="100" :step="1"
                                    type="number"
                                />
                                <div class="percent-sign">%</div>
                            </div>
                        </div>
                        <div class="text-secondary-800 fw-600 cursor-pointer" @click="removeBeneficiary4Handler">Remove <i class="font-size-16 far fa-times-circle"></i></div>
                    </div>
                    <div class="row g-3 mt-2">
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="firstName4" :input-value="firstName4" label="Name"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="surname4" :input-value="surname4" label="Surname"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="idNumber4" :input-value="idNumber4" label="ID Number"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="relationship4" :input-value="relationship4" label="Relationship"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="occupation4" :input-value="occupation4" label="Occupation"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="contactNumber4" :input-value="contactNumber4" label="Contact Nr"/>
                        </div>
                        <div class="col-12 col-sm-6 col-md-4 col-lg-3">
                            <beneficiary-input v-model="emailAddress4" :input-value="emailAddress4" label="Email Address"/>
                        </div>
                    </div>
                </div>
                
                <div v-if="!areRetirementChangesSubmitted && !areAll4BeneficiariesComplete" class="fw-500 mt-4 font-size-13">
                    *Before adding a new beneficiary, please fill out all fields for the previous one.
                </div>
            </div>
        </card-general-inner>
    </div>
</template>

<script setup>
    import CardGeneralInner from "@/components/UI/v2/containers-cards-headers/CardGeneralInner.vue"
    import BeneficiaryInput from "@/components/benefits-leave-reimbursements/retirement/components/investment-options/components/BeneficiaryInput.vue"
    import {ref, watch, computed} from "vue"
    import {useStore} from "vuex"
    import debounce from "lodash/debounce"
    
    const store = useStore()
    
    const employee = store.getters.employee
    const userFullName = store.getters.userFullName
    
    const tempSelectedRetirementFundContractObj = computed(() => store.getters.tempSelectedRetirementFundContractObj)
    const areRetirementChangesSubmitted = computed(() => store.getters.areRetirementChangesSubmitted)
    
    const extraData = computed(() => tempSelectedRetirementFundContractObj.value?.extraData || null)
    
    // TODO: this is shitty and repetitive. needs to be rewritten, using smart repeater field. try formkit.
    
    // set values on load
    const investingOnBehalf = ref(extraData.value?.investing_on_behalf || '')
    const receiveViaPost = ref(extraData.value?.receive_via_post || '')
    
    const areAll4BeneficiariesComplete = computed(() => store.getters.areAll4BeneficiariesComplete)
    
    const isBeneficiary1Complete = computed(() => store.getters.isBeneficiary1Complete)
    // beneficiary 1 start values
    const allocation = ref(extraData.value?.beneficiaries[0]?.allocation || '')
    const firstName = ref(extraData.value?.beneficiaries[0]?.firstName || '')
    const surname = ref(extraData.value?.beneficiaries[0]?.surname || '')
    const idNumber = ref(extraData.value?.beneficiaries[0]?.idNumber || '')
    const relationship = ref(extraData.value?.beneficiaries[0]?.relationship || '')
    const occupation = ref(extraData.value?.beneficiaries[0]?.occupation || '')
    const contactNumber = ref(extraData.value?.beneficiaries[0]?.contactNumber || '')
    const emailAddress = ref(extraData.value?.beneficiaries[0]?.emailAddress || '')
    
    const beneficiary1Name = computed(() => {
        if (!tempSelectedRetirementFundContractObj.value) return null
        if (!tempSelectedRetirementFundContractObj.value.extraData) return null
        return tempSelectedRetirementFundContractObj.value.extraData?.beneficiaries[0]?.firstName + ' ' + tempSelectedRetirementFundContractObj.value.extraData?.beneficiaries[0]?.surname
    })
    
    // Beneficiary 2
    const isBeneficiary2Complete = computed(() => store.getters.isBeneficiary2Complete)
    const doesBeneficiary2HaveAnyValues = computed(() => store.getters.doesBeneficiary2HaveAnyValues)
    const addSecondBeneficiaryHandler = () => showSecondBeneficiary.value = true
    const showSecondBeneficiary = ref(false)
    // beneficiary 2 start values
    const allocation2 = ref(extraData.value?.beneficiaries[1]?.allocation || '')
    const firstName2 = ref(extraData.value?.beneficiaries[1]?.firstName || '')
    const surname2 = ref(extraData.value?.beneficiaries[1]?.surname || '')
    const idNumber2 = ref(extraData.value?.beneficiaries[1]?.idNumber || '')
    const relationship2 = ref(extraData.value?.beneficiaries[1]?.relationship || '')
    const occupation2 = ref(extraData.value?.beneficiaries[1]?.occupation || '')
    const contactNumber2 = ref(extraData.value?.beneficiaries[1]?.contactNumber || '')
    const emailAddress2 = ref(extraData.value?.beneficiaries[1]?.emailAddress || '')
    
    const beneficiary2Name = computed(() => {
        if (!tempSelectedRetirementFundContractObj.value) return null
        if (!tempSelectedRetirementFundContractObj.value.extraData) return null
        if (!tempSelectedRetirementFundContractObj.value.extraData?.beneficiaries[1]) return null
        return tempSelectedRetirementFundContractObj.value.extraData?.beneficiaries[1]?.firstName + ' ' + tempSelectedRetirementFundContractObj.value.extraData?.beneficiaries[1]?.surname
    })
    
    const removeBeneficiary2Handler = () => {
        allocation2.value = ''
        firstName2.value = ''
        surname2.value = ''
        idNumber2.value = ''
        relationship2.value = ''
        occupation2.value = ''
        contactNumber2.value = ''
        emailAddress2.value = ''
        showSecondBeneficiary.value = false
    }
    
    // Beneficiary 3
    const isBeneficiary3Complete = computed(() => store.getters.isBeneficiary3Complete)
    const doesBeneficiary3HaveAnyValues = computed(() => store.getters.doesBeneficiary3HaveAnyValues)
    const addThirdBeneficiaryHandler = () => showThirdBeneficiary.value = true
    const showThirdBeneficiary = ref(false)
    // beneficiary 2 start values
    const allocation3 = ref(extraData.value?.beneficiaries[2]?.allocation || '')
    const firstName3 = ref(extraData.value?.beneficiaries[2]?.firstName || '')
    const surname3 = ref(extraData.value?.beneficiaries[2]?.surname || '')
    const idNumber3 = ref(extraData.value?.beneficiaries[2]?.idNumber || '')
    const relationship3 = ref(extraData.value?.beneficiaries[2]?.relationship || '')
    const occupation3 = ref(extraData.value?.beneficiaries[2]?.occupation || '')
    const contactNumber3 = ref(extraData.value?.beneficiaries[2]?.contactNumber || '')
    const emailAddress3 = ref(extraData.value?.beneficiaries[2]?.emailAddress || '')
    
    const beneficiary3Name = computed(() => {
        if (!tempSelectedRetirementFundContractObj.value) return null
        if (!tempSelectedRetirementFundContractObj.value.extraData) return null
        if (!tempSelectedRetirementFundContractObj.value.extraData?.beneficiaries[2]) return null
        return tempSelectedRetirementFundContractObj.value.extraData?.beneficiaries[2]?.firstName + ' ' + tempSelectedRetirementFundContractObj.value.extraData?.beneficiaries[2]?.surname
    })
    
    const removeBeneficiary3Handler = () => {
        allocation3.value = ''
        firstName3.value = ''
        surname3.value = ''
        idNumber3.value = ''
        relationship3.value = ''
        occupation3.value = ''
        contactNumber3.value = ''
        emailAddress3.value = ''
        showThirdBeneficiary.value = false
    }
    
    // Beneficiary 4
    const isBeneficiary4Complete = computed(() => store.getters.isBeneficiary4Complete)
    const doesBeneficiary4HaveAnyValues = computed(() => store.getters.doesBeneficiary4HaveAnyValues)
    const addFourthBeneficiaryHandler = () => showFourthBeneficiary.value = true
    const showFourthBeneficiary = ref(false)
    // beneficiary 2 start values
    const allocation4 = ref(extraData.value?.beneficiaries[3]?.allocation || '')
    const firstName4 = ref(extraData.value?.beneficiaries[3]?.firstName || '')
    const surname4 = ref(extraData.value?.beneficiaries[3]?.surname || '')
    const idNumber4 = ref(extraData.value?.beneficiaries[3]?.idNumber || '')
    const relationship4 = ref(extraData.value?.beneficiaries[3]?.relationship || '')
    const occupation4 = ref(extraData.value?.beneficiaries[3]?.occupation || '')
    const contactNumber4 = ref(extraData.value?.beneficiaries[3]?.contactNumber || '')
    const emailAddress4 = ref(extraData.value?.beneficiaries[3]?.emailAddress || '')
    
    const beneficiary4Name = computed(() => {
        if (!tempSelectedRetirementFundContractObj.value) return null
        if (!tempSelectedRetirementFundContractObj.value.extraData) return null
        if (!tempSelectedRetirementFundContractObj.value.extraData?.beneficiaries[3]) return null
        return tempSelectedRetirementFundContractObj.value.extraData?.beneficiaries[3]?.firstName + ' ' + tempSelectedRetirementFundContractObj.value.extraData?.beneficiaries[3]?.surname
    })
    
    const removeBeneficiary4Handler = () => {
        allocation4.value = ''
        firstName4.value = ''
        surname4.value = ''
        idNumber4.value = ''
        relationship4.value = ''
        occupation4.value = ''
        contactNumber4.value = ''
        emailAddress4.value = ''
        showFourthBeneficiary.value = false
    }
    
    
    watch(
        [
            investingOnBehalf, receiveViaPost, allocation, allocation2, allocation3, allocation4, firstName, firstName2, firstName3, firstName4, surname, surname2, surname3, surname4, idNumber, idNumber2, idNumber3, idNumber4, relationship, relationship2, relationship3, relationship4, occupation, occupation2, occupation3, occupation4, contactNumber, contactNumber2, contactNumber3, contactNumber4, emailAddress, emailAddress2, emailAddress3, emailAddress4
        ],
        () => {
            debouncedTempCommit()
        }, { deep: true }
    )
    
    
    const debouncedTempCommit = debounce(() => {
        store.commit('setTempSelectedRetirementFundContractObj', {
            ...tempSelectedRetirementFundContractObj.value,
            extraData: {
                investing_on_behalf: investingOnBehalf.value,
                receive_via_post: receiveViaPost.value,
                beneficiaries: [
                    {
                        allocation: allocation.value,
                        firstName: firstName.value,
                        surname: surname.value,
                        idNumber: idNumber.value,
                        relationship: relationship.value,
                        occupation: occupation.value,
                        contactNumber: contactNumber.value,
                        emailAddress: emailAddress.value,
                    },
                    {
                        allocation: allocation2.value,
                        firstName: firstName2.value,
                        surname: surname2.value,
                        idNumber: idNumber2.value,
                        relationship: relationship2.value,
                        occupation: occupation2.value,
                        contactNumber: contactNumber2.value,
                        emailAddress: emailAddress2.value,
                    },
                    {
                        allocation: allocation3.value,
                        firstName: firstName3.value,
                        surname: surname3.value,
                        idNumber: idNumber3.value,
                        relationship: relationship3.value,
                        occupation: occupation3.value,
                        contactNumber: contactNumber3.value,
                        emailAddress: emailAddress3.value,
                    },
                    {
                        allocation: allocation4.value,
                        firstName: firstName4.value,
                        surname: surname4.value,
                        idNumber: idNumber4.value,
                        relationship: relationship4.value,
                        occupation: occupation4.value,
                        contactNumber: contactNumber4.value,
                        emailAddress: emailAddress4.value,
                    },
                ]
            },
        })
    }, 500)
</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .checkbox-label-text {
        position: relative;
        top: -2px;
    }
    .percent-sign {
        position: absolute;
        right: 22px;
        top: 7px;
        z-index: 100;
        font-size: 16px;
        font-weight: 500;
    }
    .update-profile-link {
        a:hover {
            text-decoration: underline;
        }
    }
    
    //custom checkbox
    .checkbox-custom {
        display: none;
    }
    
    .checkbox-custom + .checkbox-label {
        position: relative;
        padding-left: 25px;
        cursor: pointer;
    }
    
    .checkbox-custom + .checkbox-label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        border: 1px solid $gradient-green-text-dark;
        background: white;
    }
    
    .checkbox-custom:checked + .checkbox-label:before {
        background: $gradient-green-text-dark;
    }
    
    .checkbox-custom:checked + .checkbox-label:after {
        content: '✔';
        position: absolute;
        left: 3px;
        top: -3px;
        color: white;
    }
    
    .arrow {
        width: 14px;
        position: relative;
        top: -1px;
    }
</style>

