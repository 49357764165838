// employee medical aid addon (vitality) actions
import theApiCall from "@/hooks/api/the-api-call"
import {apiResponseHandler} from "@/hooks/api/api-helper-function"

export default {

    async loadMedicalAidPlanAddonList(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateMedicalAidPlanAddonList) { return }
        if (!context.getters.isEnabledMedicalAid) { return }

        const response = await theApiCall('medical_aid_plan_addons/')
        const responseData = await apiResponseHandler(response, 'Could not load medical aid addon list.', 'Medical Aid addon list resource not found. [404]')

        await context.commit('setMedicalAidPlanAddonList', responseData);
        await context.commit('setLastMedicalAidPlanAddonListFetch', { timestamp: new Date().getTime() });
    },

    // Addon Vitality Wizard Block
    async setTempSelectedAddonPlan(context, planId) {
        await context.commit('setTempSelectedAddonPlan', planId);
    }

}