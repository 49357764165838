<template>
    <div class="figure">
        
        <div class="label">{{ item.label }}:</div>
        
        <div class="value">
            
            <div class="api">
                <span v-if="( (item.api !== 0) && (item.api === false) )" class="no-value-note">
                    No API Value.<br>
                </span>
                <span v-else-if="item.api === 'required' " class="no-value-note required-from-bento-api">
                    Get from Bento<br>
                    API if possible<br>
                    as well.
                </span>
                <span v-else-if="item.api != null">
                    {{ item.api }} <span v-show="( item.api !== item.current) && (item.current)" class="api-diff">**</span>
                </span>
            </div>
            
            <div class="current">
                <span v-if="item.current === false" class="no-value-note">
                    No calc / current value. <br>API value only.
                </span>
                <span v-else-if="item.current === null" class="no-value-note">
                    Need to work out calc formula to get value
                </span>
                <span v-else>
                    {{ item.current }}
                </span>
            </div>
            
            <div class="planned">
                <span v-if="item.planned === false" class="no-value-note">
                    Does not change.<br>
                    Same as API/Current.
                </span>
                <span v-else-if="item.planned === null" class="no-value-note">
                    Need to work out calc formula to get value
                </span>
                <span v-else>
                    {{ item.planned }}
                </span>
            </div>
            
            <div class="note">
                {{ note }}
            </div>
            
        </div>
        
    </div>
</template>

<script>
    export default {
        name: "SalaryFiguresItemNonCurrency",

        props: {
            item: {
                required: true,
                type: Object,
            },
            note: {
                required: false,
            }
        },

    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .figure {
        font-size: 13px;
        display: flex;
        flex-wrap: nowrap;
        padding: 10px 0;
        border-bottom: 1px solid #efefef;
    }

    .label {
        flex: 0 0 320px;
        padding-right: 40px;
        display: flex;
        align-items: center;
    }

    .value {
        flex: 1 auto;
        display: flex;
        flex-wrap: nowrap;

        > div {
            flex: 0 0 124px;
            color: black;
            margin-right: 20px;
            display: flex;
            align-items: center;

            &.note {
                flex: 1 auto;
                font-size: 11px;
                font-weight: 500;
                margin-top: 3px;
            }

        }
    }

    strong {
        font-weight: 500;
    }

    /*Data Source Block*/
    span.source {
        display: inline-block;
        margin-left: 15px;
        background-color: #335160;
        color: #fff;
        font-size: 11px;
        padding: 4px 6px;
        font-weight: 700;
        border-radius: 1px;

        &.source-calc {
            background-color: $primary;
        }

        &.source-planned {
            background-color: $primary-light;
        }
    }

    span.no-value-note {
        font-size: 10px;
        text-align: left;
        width: 110px;
        line-height: 1.5;
        
        &.required-from-bento-api {
            font-weight: 700;
            color: red;
        }
    }
    
    .api-diff {
        color: red;
    }
</style>