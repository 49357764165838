// https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
import publicHolidaysFuture from "@/data/public-holidays/public-holidays-future"

export function formatDate(date, yearFirst = false, withSlashes = false) {
    const d = new Date(date)
    const month = String(d.getMonth() + 1).padStart(2, '0')
    const day = String(d.getDate()).padStart(2, '0')
    const year = d.getFullYear()
    let separator = '-'
    if (withSlashes) {
        separator = '/'
    }

    if (yearFirst) {
        return year + separator + month + separator + day
    }
    return day + separator + month + separator + year
}


// format a start date and end date into 1 string: 9 Feb - 11 Feb 2023
export function dateRangeFormatter(start, end) {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const dateFormatDayMonth = (date) => {
        const dayDate = date.getDate();
        const monthNumber = date.getMonth();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        const month = monthNames[monthNumber];
        return `${dayDate} ${month}`;
    };
    if (startDate.getTime() === endDate.getTime()) {
        return `${dateFormatDayMonth(startDate)} ${endDate.getFullYear()}`
    }
    if (startDate.getMonth() === endDate.getMonth()) {
        return `${startDate.getDate()} - ${dateFormatDayMonth(endDate)} ${endDate.getFullYear()}`
    }
    return `${dateFormatDayMonth(startDate)} - ${dateFormatDayMonth(endDate)} ${endDate.getFullYear()}`
}

export function longDateFormat(date) {
    if (!date) return null
    const dateObj = new Date(date)
    const dayDate = dateObj.getDate()
    const monthNumber = dateObj.getMonth()
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const month = monthNames[monthNumber]
    const year = dateObj.getFullYear()
    return `${dayDate} ${month} ${year}`
}

export function shortDateFormat(date) {
    if (!date) return null
    const dateObj = new Date(date)
    const dayDate = dateObj.getDate()
    const monthNumber = dateObj.getMonth()
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    const month = monthNames[monthNumber]
    const year = dateObj.getFullYear()
    return `${dayDate} ${month} ${year}`
}

// dont need to use this. 'days' is a property on the leave request object
export function leaveDaysTakenCalc(request) {

    const startDate = new Date(request.start_date)
    const endDate = new Date(request.end_date)

    const leaveDaysAll = []
    const dt = startDate
    while (dt <= endDate) {
        leaveDaysAll.push(new Date(dt))
        dt.setDate(dt.getDate() + 1)
    }

    // # remove weekends
    const leaveExWeekends = leaveDaysAll.filter(day => day.getDay() !== 6 && day.getDay() !== 0)

    // # remove public holidays
    const leaveDaysExWeekendsExHols = leaveExWeekends.filter(day => !publicHolidaysFuture.includes(formatDate(day)))
    const days = leaveDaysExWeekendsExHols.length

    if (days == 0) {
        return '0 days'
    } else if (request.hours == 4) {
        return `.5 (half day)`
    } else if (days == 1) {
        return `${days} day`
    }
    return `${days} days`
}

export function leaveDaysTakenText(days) {
    if (+days == 0) {
        return '0 days'
    } else if (+days == .5) {
        return `.5 (half day)`
    } else if (+days == 1) {
        return `${days} day`
    }
    return `${days} days`
}

export function getOrdinalNum(number) {
    let selector

    if (number <= 0) {
        selector = 4
    } else if ((number > 3 && number < 21) || number % 10 > 3) {
        selector = 0
    } else {
        selector = number % 10
    }

    return number + ['th', 'st', 'nd', 'rd', ''][selector];
}

export function ageFromDob(dobDateString) {
    const today = new Date()
    const birthDate = new Date(dobDateString)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
    }
    if (isNaN(age)) {
        return false
    }
    return age
}

export function ageOnLastDayOfFinancialYear(dobDateString, currentFinancialYear) {
    const financialYearEndDateString = `${currentFinancialYear}-02-28`
    const financialYearEndDate = new Date(financialYearEndDateString)
    const birthDate = new Date(dobDateString)
    let age = financialYearEndDate.getFullYear() - birthDate.getFullYear()
    const m = financialYearEndDate.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && financialYearEndDate.getDate() < birthDate.getDate())) {
        age--
    }
    if (isNaN(age)) {
        return false
    }
    return age
}

export function isDateBefore(theDate, isBeforeDate) {
    return new Date(theDate) < new Date(isBeforeDate); // true if theDate is before isBeforeDate
}