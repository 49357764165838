<template>
    <modal-basic v-model="showApiErrorModal" staticBackdrop modal-type="error-modal">
        <template #title>{{ apiErrorModalTitle }}</template>
        <template #body>
            <p>If the problem persists please
                <a href="mailto:hello@mybento.net" target="_blank" class="font-weight-600 text-decoration-underline">contact us.</a>
            </p>
            <p v-if="apiErrorMessage"><span class="font-weight-600">Error message:</span> {{ apiErrorMessage }}</p>
        </template>
        <template #button1 v-if="false">
            <button-bento @click="refreshPage" size="lg" button-color="secondary-light" class="mx-2 flex-grow-1 px-1">Refresh</button-bento>
            <button-bento @click="useLogOut" size="lg" button-color="secondary-light" class="mx-2 flex-grow-1 px-1" v-if="false">Log Out</button-bento>
        </template>
        <template #button2 v-if="false">
            <button-bento @click="emailSupportHandler" class="mx-2 flex-grow-1 px-1" size="lg">Email Support</button-bento>
        </template>
    </modal-basic>
</template>

<script setup>
    import ModalBasic from "@/components/UI/v2/modals/ModalBasic.vue"
    import {computed} from "vue"
    import {useStore} from "vuex"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento.vue"
    import {useLogOut} from "@/hooks/api/logout"
    
    const store = useStore()
    
    const showApiErrorModal = computed({
        get: () => store.getters.showApiErrorModal,
        set: showModal => store.commit('setShowApiErrorModal', showModal)
    })
    
    const apiErrorModalTitle = computed(() => store.getters.apiErrorModalTitle)
    
    const apiErrorMessage = computed(() => store.getters.apiErrorMessage)
    
    const emailSupportHandler = () => window.location.href = "mailto:hello@mybento.net"
    
    const refreshPage = () => window.location.reload()
</script>