// Simple Benefits (Perks) - we use dynamic segments to display different simple benefits
// And we use the :simpleBenefitId to display the simpleBenefit in question.
// this is different to essential benefits which all have their own contentCard. SimpleBenefits only have one component (SimpleBenefitPage).
// We use nested routes for the content cards
import SimpleBenefitPage from "@/components/simple-benefits/SimpleBenefitPage.vue";
import LearningResource from "@/components/learn-and-explore/LearningResource.vue";

export default [
    {
        name: 'simple-benefit',
        path: '/perk/:simpleBenefitCategoryId/:simpleBenefitCategory/:simpleBenefitId/:simpleBenefitSlug',
        component: SimpleBenefitPage,
        props: true,
        meta: {simpleBenefitPage: true},
        children: [
            {
                name: 'simple-benefit-resource',
                path: ':resourceSlug',
                component: LearningResource,
                props: true,
                meta: {learningResource: true, simpleBenefit: true}, // used in scrollBehaviour()
            },
        ]
    },
]