// employee/payslip-financials nested module [not namespaced]

// local modules
import getters from './getters';
import mutations from './mutations'
import actions from './actions'

// nested module
import cashPortionsModule from './cash-portions/index';
import allowancesModule from './allowances/index';
import deductionsModule from './deductions/index';
import employerContributionsModule from './employer-contributions/index';
import payeCalcsModule from "./paye-calcs/index";

export default {
    modules: {
        cashPortionsModule,
        allowancesModule,
        deductionsModule,
        employerContributionsModule,
        payeCalcsModule,
    },

    state() {
        return {
            // Employee payslips list '/employees/{{userApiId}}/payslips/'
            payslipsList: null,
            lastPayslipsListFetch: null,
        }
    },

    mutations,
    actions,
    getters,
}