<template>
    <div class="mb-3" id="dropdown-filter-cont">
        <div class="filter-name mb-2 d-none">{{ filterName }}</div>
        <MDBSelect
            ref="filterSelect"
            v-model:options="filterOptionsRef"
            v-model:selected="selectedOptionsStringRef"
            @change="optionSelectedHandler"
            multiple
            :label="filterName"
            clearButton
            :filter="filterSearch"
            :placeholder="placeholderText"
            :displayedLabels="1"
            size="lg"
            selectAllLabel="Select All"
            :optionsSelectedLabel="optionsSelectedLabel"
        >
            <button-bento color="primary" size="sm" @click="$refs.filterSelect.close()">Close</button-bento>
        </MDBSelect>
        
        <div v-if="false">
            <div class="mt-2" v-if="true">
                <pre>{{ filterName }} selectedOptionsString: {{ selectedOptionsString }}</pre>
            </div>
            <div class="mt-2" v-if="true">
                <pre>{{ filterName }} selectedOptionsStringRef: {{ selectedOptionsStringRef }}</pre>
            </div>
        </div>
        
        <button @click="$refs.filterSelect.setOption(1)" v-if="false">Set Option</button>
    
    </div>
</template>

<script setup>
    import {MDBSelect} from "mdb-vue-ui-kit"
    import {ref, computed, watch, onMounted} from "vue"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento";
    
    const props = defineProps({
        filterName: { type: String, required: true },
        filterIdentifier: { type: String, required: true },
        filterOptions: { type: Array, required: true },
        optionsSelectedLabel: { type: String, required: false, default: () => 'options selected'},
        selectedOptionsString: { type: String, required: false },
        placeholderText: { type: String, required: false, default: () => "Select ..." },
        filterSearch: { type: Boolean, required: false, default: () => true }
    })
    const filterSelect = ref(null) // used to reference the select so I can use the .close() method on it
    
    const filterOptionsRef = ref(props.filterOptions) // used v-model of the select options. (v-model needs a reactive property)
    const selectedOptionsStringRef = ref(props.selectedOptionsString) // used for v-model of user selected options. (v-model needs a reactive property). Initial value set with received prop.
    
    const selectedOptionsList = computed(() => {
        if (selectedOptionsStringRef.value) {
            return selectedOptionsStringRef.value.split(',')
        }
        return null
    })
    
    const emit = defineEmits(['optionSelected'])
    const optionSelectedHandler = () => {
        emit('optionSelected', selectedOptionsList, props.filterIdentifier)
    }
    
    watch(() => props.selectedOptionsString, (value) => {
        selectedOptionsStringRef.value = value
        if (!value) {
            filterSelect.value.clear()
        }
    })
    
    onMounted(() => {
        if (props.filterIdentifier === 'status-filter') {
            filterSelect.value.toggleSelectAll() // select all
            // filterSelect.value.setOption(0) // submitted
            // filterSelect.value.setOption(1) // approved
        }
        if (props.filterIdentifier === 'leave-type-filter') {
            filterSelect.value.toggleSelectAll() // select all
            // I don't know which option is going to be which, selecting annual for example
        }
    })

</script>

<style lang="scss" scoped>
    #dropdown-filter-cont {
        min-width: 242px;
    }
</style>