<template>
    <card-general>
        <section-header>
            Reimbursements
        </section-header>

        <card-grid>
            <summary-card-reimbursements-combined/>
            <summary-card-ad-hoc-expense-claims v-if="adHocReimbursementAllowance"/>
        </card-grid>
    </card-general>
</template>

<script setup>
    import SectionHeader from "../UI/v2/containers-cards-headers/SectionHeader";
    import CardGrid from "@/components/cards/CardGrid";
    import SummaryCardReimbursementsCombined from "@/components/cards/reimbursements/SummaryCardReimbursementsCombined";
    import SummaryCardAdHocExpenseClaims from "@/components/cards/reimbursements/SummaryCardAdHocExpenseClaims";
    
    import {computed} from "vue";
    import {useStore} from "vuex";
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral";
    
    const store = useStore();
    const adHocReimbursementAllowance = computed(() => store.getters.adHocReimbursementAllowance);
</script>