// ### employee actions
import {apiResponseHandler} from "@/hooks/api/api-helper-function"
import theApiCall from '@/hooks/api/the-api-call'

export default {

    tryAutoAcceptTerms(context) {
        if (!context.getters.isTermsAccepted) {
            if (localStorage.getItem('isTermsAccepted')) {
                context.commit('setIsTermsAccepted', true)
            }
        }
    },

    setIsTermsAccepted(context, payload) {
        context.commit('setIsTermsAccepted', payload);
    },

    tryAutoLoadTestingUserId(context) {
        const localStorageUserId = localStorage.getItem('userId');
        if (localStorageUserId) {
            context.commit('setUser', { userId: localStorageUserId });
        }
    },

    async loadEmployee(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateEmployee) { return }

        let currentUser = localStorage?.userId ?? context.getters.userApiId
        const employeeToFetch = payload.employee_id ?? currentUser

        const response = await theApiCall(`employees/${employeeToFetch}/`)
        const responseData = await apiResponseHandler(response, 'Could not load employee data.', 'Employee resource not found. [404]')

        // don't update the store, if we are fetching another user (HR Team Admin interface)
        if (!payload.employee_id || payload.employee_id == currentUser) {
            if (responseData.tc_date_of_acceptance) {
                localStorage.setItem('isTermsAccepted', 'true')
                context.commit('setIsTermsAccepted', true)
            }
            context.commit('setEmployee', responseData)
            context.commit('setLastEmployeeFetchTimestamp', {timestamp: new Date().getTime()})
        }

        return responseData
    },


    async loadPayslipsList(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdatePayslipsList) { return }
        if (!context.getters.isAuthenticated) { return }

        const userApiId = (localStorage.userId) ? localStorage.userId : context.getters.userApiId
        const response = await theApiCall(`employees/${userApiId}/payslips/`)
        const responseData = await apiResponseHandler(response, 'Could not load employee past payslips.')

        await context.commit('setPayslipsList', responseData)
        await context.commit('setLastPayslipsListFetchTimeStamp', { timestamp: new Date().getTime() });
    },
}