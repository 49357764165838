import {createApp, nextTick} from 'vue'
import {isLocalHost} from "@/hooks/api/app-environment-origin"

// 3rd party plugins.
// Doesn't make CSS bundle size bigger importing here VS importing in components where needed
import 'mdb-vue-file-upload/css/mdb-vue-file-upload.min.css'
import 'mdb-vue-calendar/css/mdb-vue-calendar.min.css'
import 'v-calendar/dist/style.css'
import VCalendar from 'v-calendar'
import posthogPlugin from "@/plugins/posthog"


import App from './App.vue'
import store from './store/index.js'
import router from './router/router.js'

// set up Sentry integration
import * as Sentry from "@sentry/vue"


// initialise our main app as a Vue instance
// App is the config object imported from ./App.vue file
const app = createApp(App)

// App use store and router
app.use(router)
app.use(store)

// Run on production and staging only - otherwise you get CORS erros on localhost
if ( !isLocalHost() ) {
    Sentry.init({
        app,
        dsn: "https://91c3e364329a4571883b0889ccaa4ab3@o1325455.ingest.sentry.io/6597342",
        integrations: [
            Sentry.browserTracingIntegration({router}),
            Sentry.replayIntegration(),
        ],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/api\.mybento\.net/, /^https:\/\/staging\.mybento\.net/],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    })
}

// Run on production only (causing CORS errors)
// if ( isProd() ) {
//     Sentry.init({
//         app,
//         dsn: "https://91c3e364329a4571883b0889ccaa4ab3@o1325455.ingest.sentry.io/6597342",
//         integrations: [
//             new BrowserTracing({
//                 routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//                 tracingOrigins: ["localhost", "app.mybento.net", "staging.mybento.net", /^\//],
//             }),
//         ],
//         // Set tracesSampleRate to 1.0 to capture 100%
//         // of transactions for performance monitoring.
//         // We recommend adjusting this value in production
//         tracesSampleRate: 1.0,
//     });
// }




// Use 3rd party packages
app.use(VCalendar, {})
app.use(posthogPlugin)

app.mount('#app')

router.afterEach((to, from, failure) => {
    if (!failure) {
        nextTick(() => {
            app.config.globalProperties.$posthog.capture('$pageview', { path: to.fullPath });
        });
    }
})