<template>
    <div class="pb-2">
        <div id="the-bar" class="overflow-visible position-relative w-100">
            
            <!--markers-->
            <div id="age-today-cont">
                <div id="age-today-marker" class="bar-round-marker text-black font-size-15">{{ age }}</div>
                <div id="age-today-label" class="marker-label">Age<br>today</div>
            </div>
            <div id="retirement-age-cont" :style="{left: `${retirementAgeMarkerPosition}%`}">
                <div id="retirement-age-marker" class="bar-round-marker">{{ retirementAge }}</div>
                <div id="retirement-age-label" class="marker-label">Retirement<br>age</div>
            </div>
            
            <!--bars-->
            <div :style="{width: currentScenarioBarWidth + '%'}" id="current-scenario-bar" class="calc-progress-bar">
                <div id="current-scenario-marker" class="bar-round-marker">{{ ageYouCanRetireUntil }}</div>
                <div id="current-scenario-label" class="marker-label">Current<br>scenario</div>
            </div>
            <div :style="{width: targetAgeBarWidth + '%'}" id="target-age-bar" class="calc-progress-bar" :class="deathAgeClasslist" v-if="deathAge > retirementAge">
                <div id="target-age-marker" class="bar-round-marker">{{ deathAge }}</div>
                <div id="target-age-label" class="marker-label">Target<br>age</div>
            </div>
        
        </div>
    </div>
</template>

<script setup>
    import {useStore} from "vuex"
    import {computed} from "vue"
    
    const store = useStore()
    const age = store.getters.age
    
    const props = defineProps({
        ageYouCanRetireUntil: {
            type: Number,
            required: true
        },
        deathAge: {
            type: Number,
            required: true
        },
        retirementAge: {
            type: Number,
            required: true
        }
    })
    
    const mainBarWidth = 100 - Number(age)
    const retirementAgeMarkerPosition = computed(() => ((props.retirementAge - Number(age)) / mainBarWidth) * 100)
    const currentScenarioBarWidth = computed(() => Math.min((props.ageYouCanRetireUntil - Number(age)) / mainBarWidth * 100, 100));
    const targetAgeBarWidth = computed(() => Math.min((props.deathAge - Number(age)) / mainBarWidth * 100, 100));
    
    const deathAgeClasslist = computed(() => {
        let onTop = false
        if (props.deathAge > 99 && props.ageYouCanRetireUntil > 99) {
            onTop = false
        } else if (props.deathAge < props.ageYouCanRetireUntil) {
            onTop = true
        }
        return {'z-index-on-top': onTop}
    })

</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    #the-bar {
        height: 30px;
        background-color: #D0D0D0;
        border-radius: 17px;
    }
    
    .calc-progress-bar {
        position: absolute;
        left: 0;
        top: 0;
        overflow: visible;
        border-radius: 8px;
        height: 30px;
    }
    
    .bar-round-marker {
        position: absolute;
        font-weight: 600;
        text-align: center;
    }
    .marker-label {
        background-color: $primary-50;
        border-radius: 1px 10px;
        padding: 5px;
        position: absolute;
        text-align: center;
        top: 44px;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.1;
        color: $gradient-green-text-dark;
    }
    
    // age today - marker only
    #age-today-cont {
        height: 30px;
        position: absolute;
        left: -3px;
        z-index: 3000;
    }
    #age-today-marker {
        border-radius: 50%;
        background-color: #79E0CD;
        width: 30px;
        height: 30px;
        line-height: 30px;
        top: 0;
        left: 0;
        overflow: visible;
    }
    #age-today-label {
        left: -9px;
    }
    
    // retirement age - marker only
    #retirement-age-cont {
        height: 30px;
        position: absolute;
        z-index: 4000;
        transition: all .1s ease;
    }
    #retirement-age-marker {
        border-radius: 50%;
        background-color: $primary;
        color: #fff;
        width: 40px;
        height: 40px;
        line-height: 40px;
        font-size: 18px;
        top: -6px;
        @include media-breakpoint-up(sm) {
            font-size: 19px;
        }
    }
    #retirement-age-label {
        left: -21px;
    }
    
    // current scenario - bar and marker
    #current-scenario-bar {
        background-color: $gradient-green-1;
        transition: all .3s ease;
        z-index: 2000;
    }
    #current-scenario-marker {
        border-radius: 50%;
        background-color: $danger;
        color: #fff;
        width: 44px;
        height: 44px;
        line-height: 44px;
        font-size: 18px;
        top: -8px;
        right: -30px;
        @include media-breakpoint-up(sm) {
            font-size: 19px;
        }
    }
    #current-scenario-label {
        top: -49px;
        right: -40px;
        position: absolute;
        background: transparent;
    }
    
    // target age - bar and marker
    #target-age-bar {
        background-color: #FA9789;
        transition: all .3s ease;
        z-index: 1000;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        &.z-index-on-top {
            z-index: 2000;
            background-color: transparent;
        }
    }
    #target-age-marker {
        border-radius: 50%;
        background-color: #EBEBEB;
        color: black;
        width: 30px;
        height: 30px;
        line-height: 30px;
        top: 0;
        right: -18px;
    }
    #target-age-label {
        right: -25px;
        position: absolute;
    }
</style>