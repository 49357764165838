<template>
    <div :class="displayClassList" class="mb-5 pb-5">
        <width-container>
            <page-header class="page-title mb-2">Colleagues</page-header>
            
            <div class="grid-header-cont d-flex justify-content-between align-items-center mb-4 flex-wrap flex-sm-nowrap">
                <div class="number-of-employees font-weight-500 text-gray-main font-size-16 mb-2 mb-sm-0">
                    {{ numberOfEmployees }} Employees
                </div>
                
                <div class="filters-and-options-cont d-flex justify-content-end align-items-center">
                    
                    <div class="search-cont me-3 border-border-1" id="search-cont">
                        <MDBInput
                            v-model="searchQuery"
                            inputGroup
                            :formOutline="false"
                            wrapperClass="mb-3"
                            placeholder="Search"
                        >
                            <MDBIcon icon="search" class="align-self-center ms-2"/>
                        </MDBInput>
                    </div>
                    
                    <MDBTooltip v-model="tooltipSort" direction="top">
                        <template #reference>
                            <div class="sort-button cursor-pointer me-2 bg-white p-2 font-weight-600">
                                <img v-if="sortType === 'asc'" src="@/assets/v2/icons/arrows/arrow-dark-green-down.svg" class="sort-arrow d-inline-block"/> <img v-else src="@/assets/v2/icons/arrows/arrow-dark-green-up.svg" class="sort-arrow d-inline-block"/> <span @click="firstNameSort">Name</span>
                            </div>
                        </template>
                        <template #tip>
                            Order by name
                        </template>
                    </MDBTooltip>
                    
                    
                    <div class="options-cont ms-2 d-flex justify-content-end align-items-center">
                        <MDBTooltip v-model="tooltipShowDetails" direction="top">
                            <template #reference>
                                <img src="@/assets/v2/icons/employee-directory/info-view.svg" alt="List" @click="toggleDisplayDetails" class="cursor-pointer me-2"/>
                            </template>
                            <template #tip>
                                Toggle Details
                            </template>
                        </MDBTooltip>
                        <img src="@/assets/v2/icons/employee-directory/list-view.svg" alt="List" @click="toggleIsListView(true)" class="cursor-pointer" v-if="!isListView"/> <img src="@/assets/v2/icons/employee-directory/grid-view.svg" alt="List" @click="toggleIsListView(false)" class="cursor-pointer" v-if="isListView"/>
                    </div>
                </div>
            </div>
            
            <div class="row g-3">
                <div
                    :class="employeeColClassList"
                    class="employee-card-col flex-nowrap align-items-stretch"
                    v-for="employee in searchedEmployees"
                    :key="employee.id"
                >
                    <div
                        class="profile-picture-cont bento-border-radius ratio ratio-1x1 shadow-3 cursor-pointer"
                        :style="{ backgroundImage: `url(${profilePicture(employee.profile_picture)})` }"
                        @click="$router.push(`/employee-directory/${employee.id}`)"
                    ></div>
                    
                    <div class="employee-content-card bento-border-radius shadow-3 cursor-pointer" @click="$router.push(`/employee-directory/${employee.id}`)">
                        <div class="name-pref-name-title-cont cursor-pointer">
                            <div class="full-name-and-preferred-name font-weight-600">
                                <div class="full-name">
                                    {{ getFirstWordTitleCase(employee.user.first_name) }} {{ employee.user.last_name }}
                                </div>
                                <div class="preferred-name" v-if="employee.preferred_name">
                                    ({{ toTitleCase(employee.preferred_name) }})
                                </div>
                            </div>
                            <div class="job-title" v-if="employee.job_title">
                                {{ employee.job_title }}
                            </div>
                            <div class="self-description pe-3 me-1" v-if="employee.self_description">
                                {{ textTruncater(employee.self_description, 110) }}
                            </div>
                        </div>
                        <div class="email-and-phone-cont">
                            <div class="email-phone-button email-button">
                                <a v-if="isBentoDemoCompany" href="mailto:example@email.com" @click.stop> <span>example@email.com</span></a>
                                
                                <a v-else :href="'mailto:' + employee.user.email" @click.stop> {{ employee.user.email }}</a>
                            </div>
                            <div class="email-phone-button phone-button">
                                <a v-if="employee.cell_number" :href="'tel:' + employee.cell_number" @click.stop> <span v-if="employee.cell_number.toString().length <= 20">{{ employee.cell_number }}</span><span v-else>Phone</span> </a> <a href="#" v-else @click.stop class="cursor-default">No phone number</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </width-container>
    </div>
</template>

<script setup>
    import {MDBInput, MDBIcon, MDBTooltip} from 'mdb-vue-ui-kit'
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer"
    import PageHeader from "@/components/UI/v2/containers-cards-headers/PageHeader.vue"
    import {getFirstWordTitleCase, toTitleCase, textTruncater} from "@/hooks/general/text-formatters"
    import {ref, computed} from "vue"
    import {useStore} from "vuex";
    import {isProd} from "@/hooks/api/app-environment-origin"
    
    const store = useStore()
    
    const tooltipSort = ref(false)
    const tooltipShowDetails = ref(false)
    
    
    const employeeDirectoryList = computed(() => store.getters.employeeDirectoryList)
    const numberOfEmployees = computed(() => (employeeDirectoryList.value) ? employeeDirectoryList.value.length : null)
    
    const displayCardDetails = ref(true)
    const toggleDisplayDetails = () => {
        displayCardDetails.value = !displayCardDetails.value
    }
    const displayClassList = computed(() => {
        return [{
            'card-details': displayCardDetails.value,
            'list-view': isListView.value,
            'card-view': !isListView.value
        }]
    })
    const isListView = computed(() => store.getters.isListView)
    
    const toggleIsListView = (bool) => {
        store.commit('toggleIsListView', bool)
    }
    
    const employeeColClassList = computed(() => {
        return {
            'card-details': displayCardDetails.value,
            'col-6 col-md-4 col-lg-3': !isListView.value,
            'd-flex col-12': isListView.value
        }
    })
    
    const profilePicture = (picture) => picture ? picture.replace('http://', 'https://') : require('@/assets/v2/placeholders/user-placeholder.png')
    const fullNameConcat = (employee) => employee.user.first_name + ' ' + employee.user.last_name
    const oneFirstNameAndLastName = (employee) => getFirstWordTitleCase(employee.user.first_name) + ' ' + employee.user.last_name
    
    // sort
    // check out xero sorting of contacts for sorting on other items as well using a drop down
    const sortItem = ref('firstName')
    const sortType = ref('asc')
    const sortedEmployees = computed(() => {
        const employeeList = employeeDirectoryList.value
        if (!employeeDirectoryList.value) {
            return null
        }
        
        return employeeList.sort(function (a, b) {
            
            if (sortItem.value === 'firstName') {
                if (a.user.first_name < b.user.first_name) {
                    if (sortType.value === 'asc') {
                        return -1
                    }
                    if (sortType.value === 'desc') {
                        return 1
                    }
                }
                if (a.user.first_name > b.user.first_name) {
                    if (sortType.value === 'asc') {
                        return 1
                    }
                    if (sortType.value === 'desc') {
                        return -1
                    }
                }
            }
            return 0
        })
    })
    
    const firstNameSort = () => {
        if (sortType.value === 'asc') {
            sortType.value = 'desc'
        } else {
            sortType.value = 'asc'
        }
    }
    
    // search
    const searchQuery = ref('')
    const searchedEmployees = computed(() => {
        if (!sortedEmployees.value) {
            return
        }
        return sortedEmployees.value.filter((employee) => {
            return (
                employee.user.first_name.toLowerCase().indexOf(searchQuery.value.toLowerCase()) != -1 ||
                employee.user.last_name.toLowerCase().indexOf(searchQuery.value.toLowerCase()) != -1 ||
                employee.preferred_name.toLowerCase().indexOf(searchQuery.value.toLowerCase()) != -1 ||
                fullNameConcat(employee).toLowerCase().indexOf(searchQuery.value.toLowerCase()) != -1 ||
                oneFirstNameAndLastName(employee).toLowerCase().indexOf(searchQuery.value.toLowerCase()) != -1
            )
        })
    })
    
    
    const employer = computed(() => store.getters.employer)
    const isBentoDemoCompany = computed(() => employer.value.name === 'Bento Demo' || (employer.value.id === 6 && isProd()) )
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    // header section
    .options-cont {
        margin-right: 4px;
        img {
            width: 26px;
        }
        @media (max-width: 559px) {
            display: none !important;
        }
    }
    
    #search-cont {
        :deep(.input-group) {
            margin-bottom: 0 !important;
            input.form-control {
                height: 39px;
                border-radius: 0;
                padding: 7px 10px;
            }
            i.fas {
                position: absolute;
                right: 10px;
                color: $gray-main;
            }
        }
    }
    
    .sort-button {
        height: 39px;
        color: $gradient-green-text-dark;
        border: 1px solid $gray-400;
        &:hover {
            background-color: $gray-50 !important;
        }
        img {
            position: relative;
            top: -1px;
            margin-right: 5px;
            width: 14px;
        }
    }
    
    
    // grid
    .employee-content-card {
        background-color: $primary-50;
        padding: 18px 14px 10px;
        margin-top: 7px;
        transition: all .2s ease-in-out;
        &:hover {
            background: darken($primary-50, 5%);
        }
    }
    
    .profile-picture-cont {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    
    .full-name-and-preferred-name {
        line-height: 1.3;
        font-size: 17px;
        @include media-breakpoint-up(sm) {
            font-size: 18px;
        }
        @include media-breakpoint-up(md) {
            font-size: 19px;
        }
    }
    
    .full-name {
        white-space: nowrap;
    }
    
    .preferred-name {
        white-space: nowrap;
    }
    
    .job-title {
        color: $gradient-green-text-dark;
        font-weight: 600;
        font-size: 15px;
        margin-top: 2px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @include media-breakpoint-up(md) {
            font-size: 16px;
        }
    }
    
    .email-and-phone-cont {
        display: none;
        margin-top: 8px;
    }
    
    .self-description {
        display: none;
    }
    
    .email-phone-button {
        text-align: center;
        a {
            text-align: center;
            padding: 5px 10px;
            border-radius: 20px;
            max-width: 100%;
            font-weight: 500;
            display: inline-block;
            background: $gradient-green;
            color: $gradient-green-text-dark;
            overflow-x: hidden;
            transition: all .2s ease-in-out;
            &:hover {
                background: $gradient-green-darken;
            }
        }
    }
    
    .email-phone-button {
        a {
            min-width: 90%;
        }
    }
    
    .phone-button {
        margin-top: 5px;
        a {
            min-width: 76%;
        }
    }
    
    // card view
    .card-view {
        .employee-content-card {
            min-height: 105px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        .full-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .preferred-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
    
    
    // cards with details: email and phone
    .card-details {
        .employee-content-card {
            min-height: 196px;
        }
        .email-and-phone-cont {
            display: block;
        }
    }
    
    // list view to look like card view if less than 560px
    @media (max-width: 559px) {
        .list-view {
            .employee-content-card {
                min-height: 105px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            .employee-card-col {
                flex: 0 0 auto !important;
                width: 50% !important;
                display: block !important;
            }
            .employee-content-card {
                min-height: 196px;
            }
            .full-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .preferred-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
    
    // list view
    @media (min-width: 560px) {
        .list-view {
            .employee-card-col {
                max-width: 100%;
            }
            .employee-content-card {
                margin-top: 0;
                flex: 1 1 auto;
                display: block;
                justify-content: space-between;
                align-items: center;
                min-height: 126px;
                padding-right: 20px;
                @media (min-width: 920px) {
                    display: flex;
                }
            }
            .profile-picture-cont {
                width: 126px;
                height: 126px;
                flex: 0 0 126px;
                margin-right: 10px;
                
                @media (min-width: 720px) {
                    width: 174px;
                    height: 174px;
                    flex: 0 0 174px;
                }
                
                @media (min-width: 920px) {
                    width: 126px;
                    height: 126px;
                    flex: 0 0 126px;
                }
            }
            .full-name-and-preferred-name {
                display: flex;
            }
            .full-name {
                margin-right: 5px;
            }
            .self-description {
                display: none;
                color: $gradient-green-text-light;
                font-weight: 500;
                @media (min-width: 720px) {
                    display: block;
                }
            }
            .email-and-phone-cont {
                display: none;
            }
            .email-phone-button {
                a {
                    min-width: 140px;
                }
            }
            .phone-button {
                margin-top: 0;
                margin-left: 4px;
            }
        }
        
        // list view + card details
        .list-view.card-details {
            .email-and-phone-cont {
                margin-top: 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                align-self: flex-end;
                @media (min-width: 920px) {
                    margin-top: 0;
                    justify-content: flex-end;
                }
            }
        }
    }
    
    // smaller mobile views
    @media (max-width: 559px) {
        .email-phone-button {
            a {
                min-width: 100%;
            }
        }
        .phone-button {
            a {
                min-width: 100%;
            }
        }
    }
    
    @media (max-width: 420px) {
        .email-and-phone-cont {
            display: none !important;
        }
        .preferred-name {
            display: none !important;
        }
        .employee-content-card {
            min-height: 76px !important;
        }
    }

</style>