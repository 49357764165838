// manager leave getterss
import {isValidArray} from "@/hooks/general/type-and-value-checks"
import {shouldUpdateCheck} from "@/hooks/general/getters-helpers"

export default {
    leaveApproveeList: state => state.leaveApproveeList,

    canDownloadLeaveReports: (_, getters) => getters.permissionsCanDownloadLeaveReports,
    isLeaveApprover: (_, getters) => isValidArray(getters.leaveApproveeList),
    isLeaveApproverOrDownloader: (_, getters) => getters.isLeaveApprover || getters.canDownloadLeaveReports,

    lastLeaveApproveeListFetch: state => state.lastLeaveApproveeListFetch,
    shouldUpdateLeaveApproveeList: (_, getters) => shouldUpdateCheck(getters.lastLeaveApproveeListFetch, new Date().getTime())
}