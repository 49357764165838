// medical aid employee - new endpoint

export default {

    // this is the employees medical aid figures taken from their payslip as well as their contract object
    employeeMedicalAidsList: (state) => state.employeeMedicalAidsList,
    lastEmployeeMedicalAidsListFetch: (state) => state.lastEmployeeMedicalAidsListFetch,
    shouldUpdateEmployeeMedicalAidsList: (_, getters) => {
        if (!getters.lastEmployeeMedicalAidsListFetch) { return true }
        const currentTimeStamp = new Date().getTime()
        return (currentTimeStamp - getters.lastEmployeeMedicalAidsListFetch) / 1000 > 900 // currently set to 900 seconds = 15 mins.
    },

    medicalAidContractObj: (_, getters) => getters.employeeMedicalAidsList?.[0] ?? null,
    // This endpoint is a list. So to get the actual object we fetch [0]
    // Documentation around this here: https://mybentonet.atlassian.net/browse/BAPP-408
    // This is a combination of API values and contract object values. It uses API payslip values as source of truth where possible.
    // [
    //     {
    //         "plan_id": 15,
    //         "name": "Classic Core",
    //         "provider": "Discovery",
    //         "main_member": "Ross Humble",
    //         "member_number": "987987",
    //         "adult_dependents": 1,
    //         "child_dependents": 0,
    //         "employer_contribution": "2000.00",
    //         "employee_contribution": "3406.00",
    //         "medical_aid_plan_addon": 2,
    //         "medical_aid_plan_addon_contribution": "430.00",
    //         "total_cost": "5406.00",
    //         "main_member_cost": "3022.00",
    //         "adult_dependent_cost": "2384.00",
    //         "child_dependent_cost": "1209.00",
    //         "max_paid_children": 3,
    //         "tax_credit": 728.0,
    //         "total_cost_per_payslip": 5406.0,
    //         "payslip_contributions_match_configured_plan": true
    //     }
    //]

    // this is the employer contribution
    medicalAidBenefit: (_, getters) => {
        const apiAmount = getters.hasMedicalAidContract ? Number(getters.medicalAidContractObj?.employer_contribution || 0) : 0
        let plannedFromContract = getters.hasMedicalAidContractPlanned ? Number(getters.medicalAidContractObjPlanned?.employer_contribution || 0) : apiAmount

        return {
            id: 'medical-aid-employer-api',
            label: 'Medical Aid (Employer)',
            api: apiAmount,
            current: false,
            planned: plannedFromContract,
        }
    },

    // this the employee deduction
    employeeMedicalAid(_, getters) {
        const apiAmount = getters.hasMedicalAidContract ? Number(getters.medicalAidContractObj.employee_contribution) : 0
        let plannedFromContract = (getters.hasMedicalAidContractPlanned) ? Number(getters.medicalAidContractObjPlanned.employee_contribution) : apiAmount
        return {
            id: 'employee-medical-aid',
            label: 'Medical Aid - Employee',
            api: apiAmount,
            current: false,
            planned: plannedFromContract,
        }
    },

}