<template>
    <essential-benefit-page>
        <template #benefitTitle>Leave</template>
        
        <template #benefitExtraContent>
            <div v-if="!hasLeaveApprover" class="mt-5 mb-0">
                <card-general><no-approver-notice/></card-general>
            </div>
            <div v-else-if="!leaveBalances">
                <card-general><no-leave-balances-notice/></card-general>
            </div>
            <div v-else>
                <leave-summary/>
                <leave-balances-cont v-if="selectedLeaveView === 'balances'"/>
                <leave-calendars-cont v-if="selectedLeaveView === 'calendar'" :selected-leave-type-from-balances="selectedLeaveType"/>
                <leave-requests-list-cont/>
            </div>
        </template>
        
        <template #resourcesSectionHeader>Learn more about leave</template>
        <template #learnContentCards v-if="isValidArray(learningResourcesLeaveApi)">
            <base-learn-card-small
                v-for="resource in learningResourcesLeaveApi"
                :key="resource.id"
                :resource="resource"
                :class="activeClass(resource)"
            />
        </template>
        
        <template #pageEndContent>
            <div v-if="isEnabledLeaveCalendarLink" class="mt-5">
                <leave-calendar-link/>
            </div>
        </template>
    </essential-benefit-page>
</template>

<script setup>
    import EssentialBenefitPage from "@/components/benefits-leave-reimbursements/EssentialBenefitPage"
    import {useStore} from "vuex"
    import BaseLearnCardSmall from "@/components/learn-and-explore/BaseLearnCardSmall.vue"
    import LeaveBalancesCont from "@/components/benefits-leave-reimbursements/leave/components/LeaveBalancesCont.vue"
    import LeaveCalendarsCont from "@/components/benefits-leave-reimbursements/leave/components/calendar-interface/LeaveCalendarsCont.vue"
    import {computed} from "vue"
    import LeaveSummary
        from "@/components/benefits-leave-reimbursements/leave/components/calendar-interface/LeaveSummary.vue";
    import LeaveRequestsListCont
        from "@/components/benefits-leave-reimbursements/leave/components/leave-requests-lists/LeaveRequestsListCont.vue";
    import LeaveCalendarLink from "@/components/benefits-leave-reimbursements/leave/components/calendar-interface/LeaveCalendarLink.vue"
    import NoApproverNotice from "@/components/benefits-leave-reimbursements/NoApproverNotice.vue"
    
    const store = useStore()
    
    import {useRoute} from "vue-router"
    import {isValidArray} from "@/hooks/general/type-and-value-checks"
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
    import NoLeaveBalancesNotice from "@/components/benefits-leave-reimbursements/leave/NoLeaveBalancesNotice.vue";
    const route = useRoute()
    const activeClass = (resource) => route.params.resourceSlug === resource.slug ? 'active-card' : ''
    
    const selectedLeaveView = computed(() => store.getters.selectedLeaveView) // 'balances' || 'calendar'
    const selectedLeaveType = computed(() => store.getters.selectedLeaveType)

    const learningResourcesLeaveApi = store.getters.learningResourcesLeaveApi
    const hasLeaveApprover = store.getters.hasLeaveApprover
    const isEnabledLeaveCalendarLink = store.getters.isEnabledLeaveCalendarLink
    const leaveBalances = store.getters.leaveBalances
</script>