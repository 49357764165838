<template>
    <div id="learning-resource">
        <card-general v-if="pageResource">
            <loading-full-screen v-model="isLoading" loadingText="Loading learning resource ..."/>
            
            <div>
                <div v-if="!isLoading">
                    <h2 class="text-primary-900 mb-4 pb-2">
                        {{ pageResource.title }}
                    </h2>
                    
                    <div class="resource-content-copy" v-html="pageResource.content"></div>
                </div>
            </div>
        </card-general>
    </div>
</template>

<script setup>
    import {computed, ref, watch} from 'vue'
    import {useStore} from 'vuex'
    import {useRouter} from 'vue-router'
    import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen"
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
    
    const store = useStore()
    const router = useRouter()
    
    const props = defineProps(['simpleBenefitCategory', 'resourceSlug', 'simpleBenefitId'])
    
    const isLoading = ref(false)
    const pageResource = ref({})
    const learningResources = ref([])
    
    const learningResourcesHomeApi = computed(() => store.getters.learningResourcesHomeApi)
    const learningResourcesMedicalApi = computed(() => store.getters.learningResourcesMedicalApi)
    const learningResourcesRetirementApi = computed(() => store.getters.learningResourcesRetirementApi)
    const learningResourcesInsuranceApi = computed(() => store.getters.learningResourcesInsuranceApi)
    const learningResourcesLeaveApi = computed(() => store.getters.learningResourcesLeaveApi)
    const learningResourcesReimbursementsApi = computed(() => store.getters.learningResourcesReimbursementsApi)
    const learningResourcesAdHocReimbursementsApi = computed(() => store.getters.learningResourcesAdHocReimbursementsApi)
    const learningResourcesBonusesAndCommissionsApi = computed(() => store.getters.learningResourcesBonusesAndCommissionsApi)
    const simpleBenefitsList = computed(() => store.getters.simpleBenefitsList)
    
    const loadLearningResources = (resourceSlug) => {
        if (router.currentRoute.value.meta.simpleBenefit) {
            const simpleBenefit = simpleBenefitsList.value.find(benefit => benefit.id == props.simpleBenefitId)
            pageResource.value = simpleBenefit.content_cards.find(resource => resource.slug === resourceSlug)
        } else {
            const path = router.currentRoute.value.path
            
            if (path.includes('/medical-aid/')) {
                learningResources.value = learningResourcesMedicalApi.value
            } else if (path.includes('/retirement/')) {
                learningResources.value = learningResourcesRetirementApi.value
            } else if (path.includes('/leave/')) {
                learningResources.value = learningResourcesLeaveApi.value
            } else if (path.includes('/insurance/')) {
                learningResources.value = learningResourcesInsuranceApi.value
            } else if (path.includes('/reimbursements/')) {
                learningResources.value = learningResourcesReimbursementsApi.value
            } else if (path.includes('/ad-hoc-reimbursements/')) {
                learningResources.value = learningResourcesAdHocReimbursementsApi.value
            } else if (path.includes('/bonuses-and-commissions')) {
                learningResources.value = learningResourcesBonusesAndCommissionsApi.value
            } else {
                learningResources.value = learningResourcesHomeApi.value
            }
            
            pageResource.value = learningResources.value.find(resource => resource.slug === resourceSlug)
        }
    }
    
    watch(() => props.resourceSlug, (newResourceSlug) => {
        if (newResourceSlug === 'contact-us') {
            router.push({name: 'contact-us'})
        }
        loadLearningResources(newResourceSlug)
    })
    
    loadLearningResources(props.resourceSlug)
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .resource-content-copy {
        line-height: 1.8;
        
        :deep(a) {
            font-weight: 500;
            color: $gradient-green-text-dark;
            @include bento_underline;
            &:before {
                background-color: $gradient-green-text-dark !important;
            }
            &:hover {
                color: darken($gradient-green-text-dark, 10%);
            }
        }
        
        :deep(p) {
            font-size: 14px;
        }
    }
</style>