// employer module

import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {

    state() {
        return {
            // from API
            employer: null,
            lastEmployerFetch: null,
        }
    },

    mutations,
    actions,
    getters,

}