// static contents module getters
// not namespaced

export default {

    staticContent: (state) => state.staticContent,

    currentFinancialYear(_, getters) {
        if (!getters.latestPayslip) {
            return null;
        }
        const payslipDate = new Date(getters.latestPayslip.date);
        const payslipYear = payslipDate.getFullYear();
        const payslipMonth = payslipDate.getMonth();
        let currentFinancialYear = payslipYear;
        // remember month is 0 rated. i.e. 0 = Jan, 1 = Feb, 2 = March
        if (payslipMonth >= 2 ) {
            currentFinancialYear++;
        }
        return currentFinancialYear;
    },
    // based on current payslip
    // don't need next financial year as it's just current + 1


    // TODO:
    //  need to select the financial table based on current payslip / next month payslip
    //  probably need to rename financial tables to 2023_financial_tables, 2024_financial tables etc ...
    //  not sure about this though, can just update in /admin/ dashboard
    currentYearFinancialTables(_, getters) {
        return getters.staticContent.find( obj => obj.name === 'current_year_financial_tables' ).content
    },

    nextYearFinancialTables(_, getters) {
        return getters.staticContent.find( obj => obj.name === 'next_year_financial_tables' ).content
    },

    shouldUpdateStaticContent(state) {
        const lastStaticContentFetch = state.lastStaticContentFetch;
        if (!lastStaticContentFetch) {
            return true;
        }
        const currentTimeStamp = new Date().getTime();
        // currently set to 900 seconds / 15 mins. Could also rather just check if the store has data
        return ( ( (currentTimeStamp - lastStaticContentFetch) / 1000 ) > 900);
    }

    // currentYearFinancialTables(state) {
    //     return state.currentYearFinancialTables;
    // },
    //
    // nextYearFinancialTables(state) {
    //     return state.currentYearFinancialTables;
    // },

}