<template>
    <div>
        <div class="font-size-17 font-weight-600" v-if="currentRetirementWizardStep == 1">Investment effects:</div>
        <div class="font-size-17 font-weight-600" v-else>Application for investment:</div>
        
        <div class="mt-4 mb-5">
            <div class="steps d-flex justify-content-between flex-wrap flex-md-nowrap">
                <div @click="stepNavigationHandler(1)" :class="{ active: currentRetirementWizardStep == 1 }">
                    <div class="number-circle d-inline-block d-md-block d-lg-inline-block">1</div>
                    <div class="d-inline-block d-md-block d-lg-inline-block">Investment details</div>
                </div>
                <div @click="stepNavigationHandler(2)" :class="{ active: currentRetirementWizardStep == 2 }">
                    <div class="number-circle d-inline-block d-md-block d-lg-inline-block">2</div>
                    <div class="d-inline-block d-md-block d-lg-inline-block">Your details</div>
                </div>
                <div @click="stepNavigationHandler(3)" :class="{ active: currentRetirementWizardStep == 3 }">
                    <div class="number-circle d-inline-block d-md-block d-lg-inline-block">3</div>
                    <div class="d-inline-block d-md-block d-lg-inline-block">Fees</div>
                </div>
                <div @click="stepNavigationHandler(4)" :class="{ active: currentRetirementWizardStep == 4 }">
                    <div class="number-circle d-inline-block d-md-block d-lg-inline-block">4</div>
                    <div class="d-inline-block d-md-block d-lg-inline-block">Advisor details</div>
                </div>
                <div @click="stepNavigationHandler(5)" :class="{ active: currentRetirementWizardStep == 5 }">
                    <div class="number-circle d-inline-block d-md-block d-lg-inline-block">5</div>
                    <div class="d-inline-block d-md-block d-lg-inline-block">Application Summary</div>
                </div>
            </div>
        </div>
        
        <div class="d-flex flex-wrap flex-sm-nowrap gradient-green-text-dark align-items-center" v-if="selectedFund">
            <div class="flex-grow-1 logo-col me-3 mb-3 mb-sm-0">
                <img :src="selectedFund.provider.logo" :alt="selectedFund.provider.name"/>
            </div>
            <div class="fw-400 font-size-18 mb-1">
                <div class="fw-600">{{ selectedFund.name }}</div>
                <div v-html="selectedFund.blurb" id="sub-title-blurb"></div>
            </div>
        </div>
        <div class="d-flex align-items-center pb-2" v-else>
            <div class="fw-600 font-size-18 mb-1 gradient-green-text-dark">
                No fund selected.
            </div>
        </div>
    
    </div>
</template>

<script setup>
    import {computed} from "vue"
    import {useStore} from "vuex"
    
    const store = useStore()
    
    const tempSelectedRetirementFundContractObj = computed(() => store.getters.tempSelectedRetirementFundContractObj)
    // const draftRetirementContract = computed(() => store.getters.draftRetirementContract)
    const retirementFundList = computed(() => store.getters.retirementFundList)
    
    const selectedFund = computed(() => {
        if (!tempSelectedRetirementFundContractObj.value) return null
        return retirementFundList.value.find(fund => fund.id == tempSelectedRetirementFundContractObj.value.fundId)
    })
    
    const currentRetirementWizardStep = computed(() => store.getters.currentRetirementWizardStep)
    
    const stepNavigationHandler = (step) => store.commit('setCurrentRetirementWizardStep', step)
</script>

<style lang="scss" scoped>
    @import '@/styles/global-scss/variables-and-mixins.scss';
    .logo-col {
        max-width: 132px;
    }
    
    .steps {
        & > div {
            margin-right: 20px;
            font-size: 14px;
            font-weight: 500;
            cursor: pointer;
            :hover {
                color: #000 !important;
            }
            
            @include media-breakpoint-down(md) {
                flex: 0 0 100%;
                margin-bottom: 24px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            
            .number-circle {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 2px solid $gradient-green-text-dark;
                font-weight: 600;
                color: $gradient-green-text-dark;
                text-align: center;
                line-height: 28px;
                margin-right: 4px;
                @include media-breakpoint-only(md) {
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                    margin-bottom: 3px;
                }
            }
            
            &:not(.active) {
                color: rgba(0, 0, 0, 0.5);
                > div {
                    color: rgba(0, 0, 0, 0.5);
                    border-color: rgba(0, 0, 0, 0.5);
                }
            }
            
            &:hover {
                color: #000;
                .number-circle {
                    color: $gradient-green-text-dark;
                    border-color: $gradient-green-text-dark;
                }
            }
            
        }
    }
    
    #sub-title-blurb {
        line-height: 1.2;
    }
</style>