<template>
    <summary-card :classList="classList" view-more-link="/ad-hoc-reimbursements" view-more-text="Submit an expense">
        
        <template #title>Ad hoc</template>
        <template #subHeading>Expense claims</template>
        
        <template #cardText>
            <div class="card-text-sub-div">
                Upload and submit your ad hoc expense claims to recieve your reimbursement.
            </div>
        </template>
        
        <template #viewMore>
            <div @click="goToReimbursementsAdHoc" class="view-more-detail-text">
                Submit ad hoc expense claim
            </div>
            <img @click="goToReimbursementsAdHoc" src="@/assets/v1/arrow-left.png" alt="View" class="arrow"/>
        </template>
    
    </summary-card>
</template>

<script setup>
    import SummaryCard from "@/components/cards/SummaryCard";
    import {computed} from "vue";
    
    const classList = computed(() => {
        const classes = {
            'reimbursements': true,
            'blue-card': true,
        };
        return classes;
    })
</script>