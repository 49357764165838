import {nextTick} from "vue";

export const scrollToHash = async (elementId, offset = 72) => {
    await nextTick()
    let element = document.getElementById(elementId)
    if (element) {
        const elementRect = element.getBoundingClientRect()
        const topPosition = elementRect.top + window.pageYOffset - offset
        window.scrollTo({top: topPosition, behavior: 'smooth'})
    }
}