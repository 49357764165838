<template>
    
    <home-section-simple-benefit
        v-for="categoryObj in simpleBenefitsCategoryList" :key="categoryObj.category"
        :category-title="categoryObj.categoryName"
        :simple-benefits-list="categoryObj.benefits"
    />
    
</template>

<script setup>
    import HomeSectionSimpleBenefit from "@/components/home-sections/HomeSectionSimpleBenefit"
    import {computed} from "vue"
    import {useStore} from "vuex"
    const store = useStore()
    
    const simpleBenefitsCategoryList = computed(() => store.getters.simpleBenefitsCategoryList)
</script>