<template>
    <div class="sticky-top">
        <!--bento admins helper buttons-->
        <div>
            <div class="admin-notice-viewing-outside" v-if="!isSelectedUserMe && !isSelectedUserBento">
                <MDBBadge color="danger">Viewing Non-Bento User -> {{ userId }}</MDBBadge>
            </div>
            <div class="admin-notice-viewing-outside" v-else-if="!isSelectedUserMe">
                <MDBBadge color="danger">Viewing Other Bento User -> {{ userId }}</MDBBadge>
            </div>
            <div class="admin-notice-back-to-my-user cursor-pointer" @click="forceBackToMyUserHandler" v-if="!isSelectedUserMe">
                <MDBBadge color="success">Back to My User</MDBBadge>
            </div>
        </div>
        
        <!--the navbar-->
        <nav class="container-fluid bg-black navbar-outer-container">
            <div class="row">
                <div class="col">
                    
                    <div class="navbar-content d-flex flex-nowrap align-items-center justify-content-between px-sm-3">
                        <div class="d-flex justify-content-start align-items-center">
                            <hamburger-menu class="d-xl-none" v-if="employee && !isLoginPage && !isUserTermsPage"/>
                            
                            <div v-if="employee && isTermsAccepted" class="navbar-logo" @click="logoClickHandler">
                                <img src="@/assets/v2/bento-logo/bento-logo-white.svg" alt="Bento" class="bento-full-logo d-none d-md-block"/>
                                <img src="@/assets/v2/bento-logo/bento-icon-white.svg" alt="Bento" class="bento-icon d-md-none"/>
                            </div>
                            <div v-else class="navbar-logo no-cursor-no-click-handler">
                                <img src="@/assets/v2/bento-logo/bento-logo-white.svg" alt="Bento" class="bento-full-logo d-none d-md-block"/>
                                <img src="@/assets/v2/bento-logo/bento-icon-white.svg" alt="Bento" class="bento-icon d-md-none"/>
                            </div>
                        </div>
                        
                        <div class="navbar-right-content text-white d-flex align-items-center justify-content-end pe-md-4" v-if="employee">
                            <div v-if="isTermsAccepted" class="remuneration d-none d-lg-block font-weight-500 rounded-5 py-1 px-2" @click="toggleShowRemSummaryModal" aria-controls="remSummaryModal">
                                <img src="@/assets/v2/icons/remuneration-eye-ball.svg" alt="Eye ball" class="remuneration-eye-ball me-1"><strong>Remuneration</strong>
                            </div>
                            <div v-if="arePayrollDraftChanges && isTermsAccepted" @click="toggleShowRemDraftModalCombined" class="changes-badge rounded-4 draft-change-badge cursor-pointer bg-secondary-100 text-secondary-700 font-weight-600 py-1 px-2 px-sm-3 ms-2 ms-md-3">
                                <span class="badge-full-text">Draft Changes</span><span class="badge-short-text">Draft</span>
                            </div>
                            <div v-if="arePayrollChangesSubmitted && isTermsAccepted" @click="toggleShowRemSubmittedModalCombined" class="changes-badge rounded-4 submitted-change-badge cursor-pointer bg-primary-100 text-primary-700 font-weight-600 py-1 px-2 px-sm-3 ms-2 ms-sm-3">
                                <span class="badge-full-text">Submitted Changes</span><span class="badge-short-text">Submitted</span>
                            </div>
                            <div class="account-drop-down ms-2 ms-sm-3 ms-md-4">
                                <div class="name-and-badge d-flex align-items-center">
                                    <the-header-drop-down/>
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </nav>
        
        <div class="position-fixed w-100 text-end pe-2" v-if="false">
            <strong>employee: {{ employee }}</strong><br>
        </div>
        
    </div>
</template>

<script setup>
    import TheHeaderDropDown from "@/components/layouts/header/TheHeaderDropDown"
    import {computed} from "vue"
    import {useRouter, useRoute} from "vue-router"
    import {useStore} from "vuex"
    import {MDBBadge} from "mdb-vue-ui-kit"
    import HamburgerMenu from "@/components/layouts/side-nav/side-nav-components/HamburgerMenu.vue"
    
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    
    // for testing
    
    // -----
    
    const employee = computed(() => store.getters.employee)
    
    const isSelectedUserBento = computed(() => store.getters.isSelectedUserBento)
    const isSelectedUserMe = computed(() => store.getters.isSelectedUserMe)
    const arePayrollDraftChanges = computed(() => store.getters.arePayrollDraftChanges)
    const arePayrollChangesSubmitted = computed(() => store.getters.arePayrollChangesSubmitted)
    const isTermsAccepted = computed(() => store.getters.isTermsAccepted)
    const userId = computed(() => store.getters.userId)
    
    const toggleShowRemSummaryModal = () => store.commit('toggleShowRemSummaryModal', true)
    const toggleShowRemDraftModalCombined = () => store.commit('toggleShowRemDraftModalCombined', true)
    const toggleShowRemSubmittedModalCombined = () => store.commit('toggleShowRemSubmittedModalCombined', true)
    
    const logoClickHandler = () => {
        if (route.name === 'home') {
            location.reload();
        } else {
            router.push({name: 'home'})
        }
    }
    const forceBackToMyUserHandler = () => {
        localStorage.setItem('userId', localStorage.getItem('actualUserId'))
        location.reload()
    }
    
    const isLoginPage = computed(() => route.name === 'login')
    const isUserTermsPage = computed(() => route.name === 'accept-terms')
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .sticky-top {
        z-index: 99999;
    }
    
    .admin-notice-viewing-outside {
        position: fixed;
        z-index: 99999999;
        top: 0;
        left: 0;
    }
    
    .admin-notice-back-to-my-user {
        position: fixed;
        z-index: 99999999;
        top: 0;
        right: 0;
    }
    
    :deep(span.badge.bg-success) {
        cursor: pointer;
        transition: all .2s ease-in-out;
        &:hover {
            background-color: darken(#2BBB6E, 10%) !important;
        }
    }
    
    .navbar-outer-container {
        min-height: 68px;
    }
    
    .navbar-content {
        margin-top: 16px;
        margin-bottom: 16px;
    }
    
    .navbar-logo {
        cursor: pointer;
        img.bento-full-logo {
            width: 130px;
            @media (min-width: 420px) {
                width: 138px;
            }
        }
        img.bento-icon {
            width: 24px;
        }
    }
    
    .remuneration {
        margin-top: 2px;
        cursor: pointer;
        transition: all .2s ease-in-out;
        &:hover {
            background-color: $primary;
        }
    }
    
    .remuneration-eye-ball {
        display: none;
        width: 20px;
        margin-bottom: 2px;
        @media (min-width: 330px) {
            display: inline-block;
        }
    }
    
    .changes-badge {
        transition: all .2s ease-in-out;
    }
    
    .draft-change-badge {
        &:hover {
            background-color: darken(#F5E0EA, 5%) !important;
        }
    }
    
    .submitted-change-badge {
        &:hover {
            background-color: darken(#BCF6EC, 5%) !important;
        }
    }
    
    .badge-full-text {
        display: none;
        @media (min-width: 576px) {
            display: inline-block;
        }
    }
    
    .badge-short-text {
        @media (min-width: 576px) {
            display: none;
        }
    }
    
    // implemented for the login page
    .no-cursor-no-click-handler {
        cursor: default;
    }
</style>