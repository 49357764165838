// Calculate payslip amounts from the payslip object

import {isValidArray} from "@/hooks/general/type-and-value-checks"

export const getPayrun = (payslip) => {
    if (!payslip) { return null }
    const payslipDate = new Date(payslip.date)
    const month = payslipDate.toLocaleString('default', {month: 'long'})
    const year = payslipDate.getFullYear()
    return month + ' ' + year
}


export const payslipIncomeCalc = (payslip) => {
    const sectionTotalValue = payslip ? Number(payslip.salary_before_deductions) : 0

    const cashPortionsList = isValidArray(payslip?.cash_portions) ? payslip.cash_portions : [{
        "internal_name": "basic_salary",
        "type_id": "1",
        "name": "Basic Salary",
        "amount": "0"
    }]

    return {
        id: 'salary-before-deductions-section',
        label: 'Salary Before Deductions',
        sectionTotal: {
            id: 'payslip-income-section-total',
            label: 'Salary Before Deductions',
            value: sectionTotalValue,
        },
        sectionList: cashPortionsList,
    }
}

export const payslipAllowancesCalc = (payslip) => {
    const allowancesList = payslip?.allowances || null

    let sectionTotalValue = 0
    if (isValidArray(allowancesList)) {
        for (const allowance of allowancesList) {
            sectionTotalValue += Number(allowance.amount)
        }
    }

    return {
        id: 'payslip-allowances-section',
        sectionTotal: {
            id: 'payslip-allowances-section-total',
            label: 'Total Allowances (incl. Expense Claim)',
            value: sectionTotalValue,
        },
        sectionList: allowancesList,
    }
}


export const payslipDeductionsCalc = (payslip) => {
    const deductionsList = payslip?.employee_deductions || null

    let sectionTotalValue = 0
    if (isValidArray(deductionsList)) {
        for (const deduction of deductionsList) {
            if (deduction.internal_name === 'employee_pension_fund_benefit') {
                continue
            } // don't count employee pension fund twice
            sectionTotalValue += Number(deduction.amount)
        }
    }

    return {
        id: 'payslip-deductions-section',
        sectionTotal: {
            id: 'payslip-deductions-section-total',
            label: 'Total Employee Deductions',
            value: sectionTotalValue,
        },
        sectionList: deductionsList,
    }
}


export const payslipReimbursementsCalc = (payslip) => {
    const reimbursementsList = payslip?.reimbursements || null

    let sectionTotalValue = 0
    if (isValidArray(reimbursementsList)) {
        for (const reimbursement of reimbursementsList) {
            sectionTotalValue += Number(reimbursement.amount)
        }
    }

    return {
        id: 'payslip-reimbursements-section',
        sectionTotal: {
            id: 'payslip-reimbursements-section-total',
            label: 'Total Employee Reimbursements',
            value: sectionTotalValue,
        },
        sectionList: reimbursementsList,
    }
}


export const payslipEmployerContributionsCalc = (payslip) => {
    const employerContributionsList = payslip?.employer_contributions || null
    const sectionTotalValue = Number(payslip?.total_employer_contributions) || 0

    return {
        id: 'payslip-employer-contributions-section',
        sectionTotal: {
            id: 'payslip-employer-contributions-section-total',
            label: 'Total Employer Contributions',
            value: sectionTotalValue,
        },
        sectionList: employerContributionsList,
    }
}

export const payslipBenefitsCalc = (payslip) => {
    const benefitsList = payslip?.benefits || null
    const sectionTotalValue = Number(payslip?.total_benefits) || 0

    return {
        id: 'payslip-benefits-section',
        sectionTotal: {
            id: 'payslip-benefits-section-total',
            label: 'Benefits',
            value: sectionTotalValue,
        },
        sectionList: benefitsList,
    }
}

export const payslipTaxableIncomeDeductionCalc = (payslip) => {
    const retirementDeduction = payslip?.retirement_deduction || 0
    const taxableIncomeDeductionsList = [
        {
            id: 'retirement-deduction',
            label: 'Retirement Deduction',
            name: 'Retirement Deduction',
            amount: retirementDeduction,
        }
    ]
    const sectionTotalValue = Number(payslip?.retirement_deduction) || 0

    return {
        id: 'payslip-benefits-section',
        sectionTotal: {
            id: 'payslip-taxable-income-deduction-section-total',
            label: 'Taxable Income Deduction',
            value: sectionTotalValue,
        },
        sectionList: taxableIncomeDeductionsList,
    }
}

export const payslipTaxCreditCalc = (payslip) => {
    const sectionTotalValue = Number(payslip?.medical_aid_tax_credit) || 0
    const taxCreditList = [
        {
            id: 'medical-aid-tax-credit',
            label: 'Medical Aid Tax Credit',
            name: 'Medical Aid Tax Credit',
            amount: payslip?.medical_aid_tax_credit || 0,
        }
    ]

    return {
        id: 'payslip-tax-credit-section',
        sectionTotal: {
            id: 'payslip-tax-credit-section-total',
            label: 'Tax Credit',
            value: sectionTotalValue,
        },
        sectionList: taxCreditList,
    }
}