<template>
    <menu-item :class="classList" :is-current="isCurrent">
        <template #title>
            Profile
        </template>
        <template #subMenuItems>
            <menu-item-second routeName="employee-personal-info">Profile</menu-item-second>
            <menu-item-second routeName="employee-financial-details">Financial Details</menu-item-second>
            <menu-item-second routeName="employee-calendar-links">Calendar Links</menu-item-second>
            <menu-item-second routeName="employee-directory">My Colleagues</menu-item-second>
        </template>
    </menu-item>
</template>

<script setup>
    import MenuItem from "@/components/layouts/side-nav/side-nav-components/MenuItem.vue"
    import MenuItemSecond from "@/components/layouts/side-nav/side-nav-components/MenuItemSecond.vue"
    import {computed} from "vue"
    
    import {useRoute} from 'vue-router'
    
    const route = useRoute()
    const isCurrent = computed(() => route.meta.menuParent === 'profile')
    const classList = computed(() => {
        return {'current-parent': isCurrent.value}
    })
</script>