<template>
    
    <div class="input-row">
        <div
            class="label"
            v-html="label"
        ></div>
        <div
            class="value"
            v-html="value"
        ></div>
        <div
            class="note"
            v-html="note"
        ></div>
    </div>
    
</template>

<script>
    export default {
        name: "EmployeeInputsRow",
        props: ['label', 'value', 'note']
    }
</script>

<style lang="scss" scoped>
    
    .input-row {
        display: flex;
        font-size: 13px;
        padding: 10px 2px;
        
        border-bottom: 1px solid #ddd;
        align-items: center;
    }
    .label {
        flex: 0 0 200px;
        font-weight: 400;
        padding-right: 20px;
    }
    .value {
        font-weight: 500;
        flex: 0 0 120px;
    }
    .note {
        flex: 1;
        font-size: 12px;
    }
    // flex default: 0 1 auto; flex-grow flex-shrink flex-basis
    // shorthand: flex: 1; -> flex: 1 1 0%; recommended as it sets other values intelligently.

</style>