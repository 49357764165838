export function stagingOrDevelopmentOrAppApiUrl(urlEndPoint) {
    // if there exists and VUE_APP_API_HOST environmental variable skip straight to that
    const apiHost = process.env.VUE_APP_API_HOST
    if (apiHost) {
        return `${apiHost}/api/1.0/${urlEndPoint}`
    }
    if (window.location.origin === 'https://staging.mybento.net' || window.location.origin.includes('http://localhost')) {
        return `https://staging.mybento.net/api/1.0/${urlEndPoint}`
    }
    if (window.location.origin === 'https://development.mybento.net') {
        return `https://development.mybento.net/api/1.0/${urlEndPoint}`
    }
    return `https://app.mybento.net/api/1.0/${urlEndPoint}`
}

export function isLocalHost() {
    return window.location.origin.includes('http://localhost')
}

export function isProd() {
    return window.location.origin.includes('//app.mybento.net')
}

export function isDevelopment() {
    return window.location.origin.includes('//development.mybento.net')
}

export function currentUrlOrigin() {
    // if there exists and VUE_APP_API_HOST environmental variable skip straight to that
    if (process.env.VUE_APP_API_HOST) {
        return process.env.VUE_APP_API_HOST
    }

    const locationOrigin = window.location.origin
    if (locationOrigin.includes('http://localhost')) { return 'https://staging.mybento.net' }
    if (locationOrigin === 'https://staging.mybento.net') { return locationOrigin }
    if (locationOrigin === 'https://development.mybento.net') { return locationOrigin }
    return 'https://app.mybento.net'
}