// employer module actions
import theApiCall from '@/hooks/api/the-api-call'
import {apiResponseHandler} from "@/hooks/api/api-helper-function"

export default {

    async loadEmployer(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateEmployer) { return }
        if (!context.getters.isAuthenticated) { return }

        const response = await theApiCall('employer')
        const responseData = await apiResponseHandler(response, 'Could not load employer data.')

        await context.commit('setEmployer', responseData);
        await context.commit('setLastEmployerFetchTimestamp', { timestamp: new Date().getTime()} );
    },

}