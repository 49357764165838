// employee getters

export default {


    // ### local data imported from statis js file
    // ----------------------------------
    essentialBenefits: (state) => state.essentialBenefits,


    // ### employee data : non-financial
    // ------------------------------------
    employee: (state) => state.employee,
    shouldUpdateEmployee: (state) => {
        const lastEmployeeFetch = state.lastEmployeeFetch
        if (!lastEmployeeFetch) return true
        const currentTimeStamp = new Date().getTime()
        return (currentTimeStamp - lastEmployeeFetch) / 1000 > 900
    },

    permissionsCanDownloadLeaveReports: (_, getters) => getters.employee ? getters.employee?.permissions.includes('view_leave_reports') : false,
    permissionsCanDownloadReimbursementReports: (_, getters) => getters.employee ? getters.employee?.permissions.includes('view_approved_claims') : false,

    isSelectedUserBento: (_, getters) => Number(getters.employee.employer_id) === 1,

    isSelectedUserMe: (_, getters) => {
        if (getters.employee) {
            const selectedUserId = getters.employee.id
            const actualId = localStorage.getItem('actualUserId')
            return selectedUserId == actualId
        }
        return true
    },

    userApiId: (state, getters) => state.userId || localStorage.userId || Number(getters.employee ? getters.employee.id : null),

    isTermsAccepted: state => !!state.isTermsAccepted,

    employer_id: (_, getters) => getters.employer.id,

    employeeNumber: (_, getters) => {
        const current = (getters.employee.employee_number) ? getters.employee.employee_number : null
        return {
            id: 'employee-number',
            label: 'Employee Number',
            current,
        }
    },

}