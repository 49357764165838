<template>
    <card-general class="mb-4 green">
        <div>
            <div class="mb-4 pb-2 ps-2">
                <img src="@/assets/v2/illustrations/crown-heart-insurance.svg" alt="Crown over a heart showing insurance safety"/>
            </div>
            <h3 class="text-black">{{ employerName }}'s Commitment to You</h3>
            <p class="premium-line">{{ employerName }} provides comprehensive life and funeral cover, because we value your peace of mind as much as you do.  As a company we contribute 100% of the <MDBTooltip v-model="tooltip1" tag="a">
                <template #reference>
                    <span class="cursor-pointer hover-text-shadow fw-600">premiums</span>
                </template>
                <template #tip>
                    <div class="font-size-12" v-html="extraInfoTooltip"></div>
                </template>
            </MDBTooltip>.</p>
        </div>
    </card-general>
</template>

<script setup>
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
    import {ref} from "vue"
    import {useStore} from "vuex"
    import {MDBTooltip} from "mdb-vue-ui-kit"
    
    const store = useStore()
    const employerName = store.getters.employerName
    
    
    const tooltip1 = ref(false)
    const extraInfoTooltip = "Premium is calculated as 0.68% of Risk Salary. There is a cap to the Risk salary at R75k, anyone earning over R75k would be tagged with a R75k Risk Salary. Risk Salary is defined as pensionable salary and basically 100% of base salary."
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";

    img {
        width: 80px;
    }
    
    .premium-line {
        max-width: 572px;
    }
</style>
