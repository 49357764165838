<template>
    <card-general>
        <leave-interface-switch/>
        
        <div
            class="gradient-green cursor-pointer rounded-2 d-flex justify-content-between align-items-center position-relative my-2 p-4 pb-3"
            v-for="leaveObj in leaveBalancesSorted"
            :key="leaveObj.id"
            @click="applyHandler(leaveObj)"
        >
            <div class="flex-grow-1">
                <div class="font-weight-600 font-size-16 mb-3 pb-1" id="name-and-balance">
                    <div>{{ leaveObj.name }} Leave</div>
                    <div v-if="showLeaveBalance(leaveObj.name)" class="the-dot px-2">&#x2022;</div>
                    <div v-if="showLeaveBalance(leaveObj.name)" class="dot-sub-title">{{ roundToTwoDecimal(leaveObj.balance) }} days available</div>
                </div>
                <div class="extra-note font-weight-500 font-size-15 mt-3" v-if="leaveNote(leaveObj)" v-html="leaveNote(leaveObj)">
                </div>
            </div>
            <div class="font-weight-600 p-1 ps-5 align-self-start">
                Apply
            </div>
        </div>
    </card-general>
</template>

<script setup>
import {useStore} from "vuex";
import {roundToTwoDecimal} from "@/hooks/general/currency-number-formatters"
import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue";
import LeaveInterfaceSwitch from "@/components/benefits-leave-reimbursements/leave/components/LeaveInterfaceSwitch.vue";
const store = useStore()

const leaveBalancesSorted = store.getters.leaveBalancesSorted

const applyHandler = (leaveObj) => {
    store.commit('setSelectedLeaveType', leaveObj)
    store.commit('setSelectedLeaveView', 'calendar')
}

const showLeaveBalance = (leaveTypeName) => {
    return !(
        leaveTypeName.toLowerCase().includes('parental') ||
        leaveTypeName.toLowerCase().includes('maternity') ||
        leaveTypeName.toLowerCase().includes('paternity') ||
        leaveTypeName.toLowerCase().includes('parent')
    )
}

const leaveNote = (leaveObject) => {
    const accrual = leaveObject.accrual
    if (leaveObject.name.toLowerCase().includes('annual') && accrual != 0) {
        return `You accrue ${roundToTwoDecimal(accrual)} annual leave days per month.`
    } else if (leaveObject.name.toLowerCase().includes('sick')) {
        return `You are entitled to 30 days of sick leave every 36 months.`
    } else if (leaveObject.name.toLowerCase().includes('family responsibility')) {
        return `Also known as compassionate leave. You accrue 3 days during a 12 month cycle.`
    } else if (+accrual > 0) {
        return `You accrue ${roundToTwoDecimal(accrual)} leave days per month.<br>The accrual rate is based on your company's leave policy. Leave balances are updated on a daily basis.`
    }
    return false
}
</script>

<style scoped>
    .extra-note {
        color: rgba(0,0,0,.42);
    }
    .the-dot {
        display: none;
    }
    @media (min-width: 580px) {
        .the-dot {
            display: inline-block;
        }
        #name-and-balance > div {
            display: inline-block;
        }
    }
</style>