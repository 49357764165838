// retirement contract mutations

export default {

    // temp retirement fund contract object
    setTempSelectedRetirementFundContractObj: (state, tempContractObj) => state.tempSelectedRetirementFundContractObj = tempContractObj,

    // retirment fund list
    setRetirementFundList: (state, payload) => state.retirementFundList = payload,
    setLastRetirementFundListFetch: (state, payload) => state.lastRetirementFundListFetch = (payload) ? (payload.timestamp) : null,


    // retirement fund contract change request list
    // same mutation for updating and deleting
    setRetirementFundContractChangeRequestList: (state, contractChangeRequestList) => state.retirementFundContractChangeRequestList = contractChangeRequestList,
    setLastRetirementFundContractChangeRequestListFetch: (state, payload) => state.lastRetirementFundContractChangeRequestListFetch = (payload) ? (payload.timestamp) : null,

}