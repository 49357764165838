// Simple benefits (free perks) store module [not namespaced]

import mutations from './mutations';
import actions from './actions';
import getters from './getters';

// local data objects
// import data from '../../../data/data';

export default {

    state() {
        return {

            // api data : updated by fetch() to Bento API
            simpleBenefitsList: null,
            lastSimpleBenefitsListFetch: null,

        }
    },

    mutations,
    actions,
    getters,


}