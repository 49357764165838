// employee profile getters

import {getFirstWord, toTitleCase} from "@/hooks/general/text-formatters";
import {isValidArray, rossIsNonEmptyObjectCheck} from "@/hooks/general/type-and-value-checks";

export default {

    lastBankAccountChangeRequestListFetch: state => state.lastBankAccountChangeRequestListFetch,
    shouldUpdateBankAccountChangeRequestList: (_, getters) => !getters.lastBankAccountChangeRequestListFetch || ((new Date().getTime() - getters.lastBankAccountChangeRequestListFetch) / 1000) > 300,

    bankAccountChangeRequestList: (state) => state.bankAccountChangeRequestList || null,

    submittedBankAccountChangeRequest: (_, getters) => {
        if (isValidArray(getters.bankAccountChangeRequestList)) {
            return getters.bankAccountChangeRequestList.find(changeRequest => changeRequest.status === 'submitted') || null
        }
        return null
    },
    areBankAccountChangesSubmitted: (_, getters) => rossIsNonEmptyObjectCheck(getters.submittedBankAccountChangeRequest),


    // Profile Information
    user: (_, getters) => getters.employee?.user || null,

    userOneFirstName: (_, getters) => getFirstWord(getters.user?.first_name) || null,
    userPreferredName: (_, getters) => getters.employee?.preferred_name || null,
    userFullName: (_, getters) => getters.user ? `${getters.user.first_name} ${toTitleCase(getters.user.last_name)}` : null,
    userInitials: (_, getters) => `${getters.userOneFirstName[0]}${getters.user.last_name[0]}`,

    jobTitle: (_, getters) => ({
        id: 'job-title',
        label: 'Job Title',
        current: getters.employee?.job_title || null,
    }),

    incomeTaxNumber: (_, getters) => ({
        id: 'income-tax-number',
        label: 'Income Tax Nr.',
        current: getters.employee?.income_tax_number || null,
    }),

    employeeAddress: (_, getters) => ({
        id: 'employee-address',
        label: 'Employee Address',
        current: getters.employee?.address || null,
    }),

    dateOfEmployment: (_, getters) => ({
        id: 'date-of-employment',
        label: 'Date of Employment',
        current: getters.employee?.date_of_employment || null,
    }),

    cellNumber: (_, getters) => ({
        id: 'cell-number',
        label: 'Cell Number',
        current: getters.employee?.cell_number || null,
    }),


    hrContact: (_, getters) => getters.employer.hr_contact || null,
    hrContactName: (_, getters) => getters.employer.hr_contact?.name || null,

    IDType: (_, getters) => {
        const apiName = getters.employee.id_type;
        const idTypeMap = {
            'rsa_id': 'RSA ID',
            'passport': 'Passport Number',
            'asylum_seekers_permit': 'Asylum Seekers Permit',
            'refugee_id': 'refugee_id',
        };
        return idTypeMap[apiName] || 'ID';
    },

    IDNumber: (_, getters) => ({
        id: 'id-number',
        label: getters.IDType,
        current: getters.employee?.id_number || null,
    }),

    dateOfBirth: (_, getters) => ({
        id: 'dob',
        label: 'DOB',
        current: getters.employee?.date_of_birth || null,
    }),

    employeeInformation: (state, getters) => ([
        {
            id: 'employer-name',
            label: 'Company',
            current: getters.employer.name,
        },
        {
            id: 'employer-address',
            label: 'Company Address',
            current: getters.employer.address,
        },
        {
            id: 'first-name-and-last-name',
            label: 'Employee Name',
            current: `${getters.user.first_name} ${getters.user.last_name}`,
        },
        getters.IDNumber,
        getters.dateOfBirth,
        getters.employeeNumber,
        getters.jobTitle,
        getters.incomeTaxNumber,
        getters.employeeAddress,
        getters.dateOfEmployment,
        {
            id: 'employee-user-email',
            label: 'Employee Email',
            current: getters.user.email,
        },
        getters.cellNumber,
        {
            id: 'employer-hr-contact',
            label: 'HR Contact',
            current: getters.hrContactName,
        },
    ]),


    lastEmployeeDirectoryListFetch: state => state.lastEmployeeDirectoryListFetch,
    shouldUpdateEmployeeDirectoryList: (_, getters) => !getters.lastEmployeeDirectoryListFetch || ((new Date().getTime() - getters.lastEmployeeDirectoryListFetch) / 1000) > 300,

    employeeDirectoryList: (state) => state.employeeDirectoryList || null,
}
