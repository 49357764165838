// manager reimbursement mutations

export default {
    setReimbursementApproveeList: (state, payload) => state.reimbursementApproveeList = payload,
    setLastReimbursementApproveeListFetch: (state, payload) => state.lastReimbursementApproveeListFetch = payload,

    setReimbursementAllowanceListAllApprovees: (state, payload) => state.reimbursementAllowanceListAllApprovees = payload,
    setLastReimbursementAllowanceListAllApproveesFetch: (state, payload) => state.lastReimbursementAllowanceListAllApproveesFetch = payload.timestamp,

    updateReimbursementApproveeList: (state, payload) => {
        const approveeIndex = state.reimbursementApproveeList.findIndex(approvee => approvee.id == payload.employeeID)
        const reimbursementClaimIndex = state.reimbursementApproveeList[approveeIndex].reimbursement_claims.findIndex(request => request.id == payload.requestID)
        state.reimbursementApproveeList[approveeIndex].reimbursement_claims[reimbursementClaimIndex].state = payload.updatedState
    }
}