<template>
    <modal-summary id="remDraftModal" labelledby="remDraftModalLabel" v-model="showRemDraftModalMedical" size="lg">
        <template #title>
            <span class="ms-3" v-if="areMedicalAidChangesSubmitted">Submitted Medical Aid Changes</span> <span class="ms-3" v-if="areMedicalAidDraftChanges">Draft Medical Aid Changes</span>
        </template>
        <template #body>
            <rem-draft-modal-medical-content v-if="areMedicalAidDraftChanges || areMedicalAidChangesSubmitted"/>
            <div v-else class="font-size-15 font-weight-500">No medical aid changes.</div>
        </template>
        <template #button1 v-if="areMedicalAidDraftChanges">
            <button-bento @click="proceedChangesHandler" button-color="dark" class="mx-2 flex-grow-1 shadow-hover" size="lg">Proceed with changes</button-bento>
        </template>
        <template #button2 v-if="areMedicalAidDraftChanges">
            <button-bento @click="showRemDraftModalMedical = false" button-color="white-bg-green" class="mx-2 flex-grow-1 shadow-hover" size="lg">Keep as draft</button-bento>
        </template>
    </modal-summary>
</template>

<script setup>
    import {computed} from "vue"
    import {useStore} from "vuex"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
    import ModalSummary from "@/components/summary-and-payslip/modal/base-modals/ModalSummary"
    import RemDraftModalMedicalContent from "@/components/summary-and-payslip/modal/draft-and-submitted/medical/RemDraftModalMedicalContent.vue"
    
    const store = useStore()
    
    const areMedicalAidDraftChanges = computed(() => store.getters.areMedicalAidDraftChanges)
    const areMedicalAidChangesSubmitted = computed(() => store.getters.areMedicalAidChangesSubmitted)
    
    const showRemDraftModalMedical = computed({
        get: () => store.getters.showRemDraftModalMedical,
        set: showValue => store.commit('toggleShowRemDraftModalMedical', showValue)
    })
    
    const proceedChangesHandler = () => {
        store.commit('toggleShowRemDraftModalMedical', false)
        store.commit('toggleShowConfirmMedicalAidChangesModal', true)
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .reset-icon {
        width: 18px;
        position: relative;
        top: -2px;
    }
    
    p a {
        color: darken($primary, 4%);
        &:hover {
            color: darken($primary, 10%);
        }
    }
    
    #submitting-changes-loader {
        min-height: 50px;
    }
</style>