// Employee medical addons store module [not namespaced]

import mutations from './mutations';
import actions from './actions';
import getters from './getters';


export default {

    state() {
        return {

            // api data : updated by fetch() to Bento API
            medicalAidPlanAddonList: null,
            lastMedicalAidPlanAddonListFetch: null,

            // Addon Vitality Wizard Block
            tempSelectedAddonPlan: 2, // Default to Vitality Premium

        }
    },

    mutations,
    actions,
    getters,


}