import store from "@/store"

export default function useRetirementCalculator(retirementAge, deathAge, savings, percentageSaved = 0, percentageRequiredPercent = 75, netReturnExpectedPercent = 9) {

    // Troubleshooting
    // console.log(
    //     'useRetirementCalculator running ...',
    //     '\nretirementAge: ', retirementAge,
    //     '\ndeathAge: ',deathAge,
    //     '\nsaving: ', savings,
    //     '\npercentageSaved: ', percentageSaved,
    //     '\npercentageRequiredPercent: ', percentageRequiredPercent,
    //     '\nnetReturnExpectedPercent:', netReturnExpectedPercent
    // )

    // TODO
    //  if there is no basic salary ive just hard coded this to 35000 for now
    //  make basic salary figure configurable (will need to do this for the website)
    //  BAPP-817 Retirement Calculator: Make Basic Salary Configurable
    let monthlyIncome = store.getters.monthlyIncome
    if (monthlyIncome < 1000) {
        // console.log('Using default monthly income of 35000')
        monthlyIncome = 35000
    }
    const annualIncome = monthlyIncome * 12

    const inflation = 0.06
    // const returnAbove = 0.03
    const expectedReturn = netReturnExpectedPercent / 100
    const currentAge = store.getters.age
    const percentageRequired = percentageRequiredPercent / 100

    // set up an initial year calculation object (doesn't have savings, growthOpeningBalance, growthSavings, closingBalance)
    const year = new Date().getFullYear() // current year
    let yearCalculation = {
        year: year,
        age: currentAge,
        openingBalance: savings,
        annualIncome: annualIncome,
        retirementAgeIndicator: currentAge <= retirementAge ? 1 : 0 // 1 for not retired, 0 for retired
    }

    /**
     * Calculate values dependent on year calculation object
     * @param yc the year calculation object
     * @returns {*} return updated year calculation object
     */
    const calculateDependantYearValues = yc => {
        yc.savings = yc.annualIncome * percentageSaved // Calculate annual savings from income
        yc.growthOpeningBalance = yc.openingBalance * expectedReturn // Calculate growth of opening balance
        yc.growthSavings = (yc.savings / 2) * expectedReturn // Calculate growth of savings (assuming savings happened in the middle of the year)
        yc.closingBalance = yc.openingBalance + yc.savings + yc.growthOpeningBalance + yc.growthSavings // Calculate closing balance
        return yc
    }

    /**
     * Simulate the passage of a year based on the previous year
     * @param pyc
     * @returns {*}
     */
    const tickPreviousYear = pyc => {
        let yc = {...pyc}
        yc.year += 1
        yc.age += 1

        yc.retirementAgeIndicator = yc.age <= retirementAge ? 1 : 0
        const retired = yc.retirementAgeIndicator === 0
        const retiredThisYear = retired && pyc.retirementAgeIndicator === 1
        //const dead = yc.age > deathAge // remove dead condition to continue with annual expenses after death. makes it possible to calculate how long you can retire for beyond death

        yc.openingBalance = pyc.closingBalance * yc.retirementAgeIndicator
        yc.annualIncome = pyc.annualIncome * (1 + inflation) * yc.retirementAgeIndicator

        if (!retired) {
            yc.prOpeningBalance = 0
            yc.incomeAfterRetirement = 0
        } else if (retiredThisYear) {
            yc.prOpeningBalance = pyc.closingBalance
            yc.incomeAfterRetirement = pyc.annualIncome * (1 + inflation)
        } else {
            yc.prOpeningBalance = pyc.prClosingBalance
            yc.incomeAfterRetirement = pyc.incomeAfterRetirement * (1 + inflation)
        }


        // remove dead condition to continue with annual expenses after death. makes it possible to calculate how long you can retire for beyond death
        // if (!retired || dead) {
        //     yc.prInvestmentIncome = 0
        //     yc.annualExpenses = 0
        // } else {
        //     yc.prInvestmentIncome = yc.prOpeningBalance * expectedReturn
        //     yc.annualExpenses = -yc.incomeAfterRetirement * percentageRequired
        // }
        yc.prInvestmentIncome = yc.prOpeningBalance * expectedReturn
        yc.annualExpenses = -yc.incomeAfterRetirement * percentageRequired


        yc.prOpeningBalance = Math.max(yc.prOpeningBalance, -1)

        // if (yc.prOpeningBalance === -1) {
        //     yc.annualExpenses = 0
        //     yc.prInvestmentIncome = 0
        // }

        yc.prClosingBalance = yc.prOpeningBalance + yc.annualExpenses + yc.prInvestmentIncome

        return yc
    }

    /**
     * Round float values to two decimal places
     * @param yc
     * @returns {*}
     */
    const roundYear = yc => {
        for (let k in yc) {
            if (typeof yc[k] === 'number') {
                yc[k] = Math.round(yc[k] * 100) / 100
            }
        }
        return yc
    }

    const years = []

    // run simulation for next 100 years
    const currentYear = new Date().getFullYear()
    while (yearCalculation.year <= Number(currentYear) + 100) {
        yearCalculation = calculateDependantYearValues(yearCalculation)
        yearCalculation = roundYear(yearCalculation)
        years.push(yearCalculation)
        const previousYear = yearCalculation
        yearCalculation = tickPreviousYear(previousYear)
    }

    // KEY VALUES CALCS
    const yearsTillRetirement = (retirementAge - currentAge) + 1 // take the year after your retirement year
    const yearsTillDeath = (deathAge - currentAge) + 1 // do the same here because of above

    // 1. target amount
    let targetAmount = 0
    let futureAnnualExpensesTillDeath = years.slice(yearsTillRetirement, yearsTillDeath).map(year => Math.abs(year['annualExpenses']))
    // console.log(years.slice(yearsTillRetirement, yearsTillDeath));
    // console.log(futureAnnualExpensesTillDeath);

    for (let i = 0; i < futureAnnualExpensesTillDeath.length; i++) {
        targetAmount += futureAnnualExpensesTillDeath[i] / Math.pow(1 + expectedReturn, i + 1)
    }

    // 2. projected amount
    const projectedAmount = years[yearsTillRetirement].prOpeningBalance

    // Calculate present value of target and projected amounts
    const targetAmountToday = targetAmount / Math.pow(1 + inflation, yearsTillRetirement - 1) // -1 because we're calculating the present value of the target amount
    const projectedAmountToday = projectedAmount / Math.pow(1 + inflation, yearsTillRetirement - 1) // -1 because we're calculating the present value of the projected amount

    // age you can retire until
    // skips the first year as it's the current year (and doesn't have prClosingBalance property)
    let ageYouCanRetireUntil = 120 // if you balance never goes negative, make age you can retire until 120
    for (let i = yearsTillRetirement; i < years.length; i++) {
        if (years[i].prClosingBalance < 0) {
            ageYouCanRetireUntil = years[i].age
            break
        } else {
            ageYouCanRetireUntil = 120 // if your post-retirement balance never goes negative, you can retire until you die
        }
    }
    ageYouCanRetireUntil = Math.min(ageYouCanRetireUntil, 120)

    // Troublehooting
    // console.log({
    //     targetAmount,
    //     projectedAmount,
    //     ageYouCanRetireUntil,
    //     targetAmountToday,
    //     projectedAmountToday,
    // })

    return {
        targetAmount,
        projectedAmount,
        ageYouCanRetireUntil,
        targetAmountToday,
        projectedAmountToday,
    }
}