<template>
    <summary-card :classList="classList" :view-more-link="viewMoreLink">
        
        <template #title>{{ simpleBenefit.name }}</template>
        <template #subHeading v-if="false">{{ simpleBenefit.category_name }}</template>
        
        <template #cardText>
            <div v-html="simpleBenefit.description" class="card-text-sub-div"></div>
        </template>
    
    </summary-card>
</template>

<script setup>
    import SummaryCard from "@/components/cards/SummaryCard"
    import {computed} from 'vue'
    import {convertToKebabCase} from "@/hooks/general/text-formatters"
    
    const props = defineProps({
        simpleBenefit: {
            type: Object,
            required: true,
        }
    });
    
    const classList = computed(() => {
        let classes;
        const category_id = Number(props.simpleBenefit.category);
        switch (category_id) {
            case 1:
                classes = {'personal-health': true}
                break;
            case 3:
                classes = {'work-environment': true}
                break;
            case 4:
                classes = {'self-development': true}
                break;
            case 5:
                classes = {'other-perks': true}
                break;
            case 6:
                classes = {'wellness-and-advice': true}
                break;
            case 9:
                classes = {'simple-essential-benefit': true}
                break;
            default:
                classes = {'free-perk': true};
        }
        if (classes['simple-essential-benefit']) {
            classes['green-card'] = true;
        } else {
            classes['green-alt-card'] = true;
        }
        return classes;
    })
    
    const viewMoreLink = computed(() => {
        const benefit = props.simpleBenefit;
        let categoryName = convertToKebabCase(benefit.category_name);
        categoryName = (categoryName === 'wellness-advice') ? 'wellness-and-advice' : categoryName;
        const slug = convertToKebabCase(benefit.name);
        return `perk/${benefit.category}/${categoryName}/${benefit.id}/${slug}`;
    })
</script>