// manager leave store module [not namespaced]
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    state() {
        return {
            leaveApproveeList: null,
            lastLeaveApproveeListFetch: null,
        }
    },
    mutations,
    actions,
    getters,
}