<template>
    <div class="component-container">
        
        <div class="title font-size-15 fw-600 mb-3 lh-sm text-primary">
            Personal and Company Details
        </div>
        
        <div class="all-items-container">
            <div class="item-container" v-for="item in filteredEmployeeInformation" :key="item.label">
                <div class="item" v-if="item.current">
                    <div class="item-label">
                        {{ item.label }}:
                    </div>
                    <div class="emp-info-value">
                        {{ item.current }}
                    </div>
                </div>
            </div>
        </div>
    
    </div>
</template>

<script setup>
    import {computed} from "vue"
    import {useStore} from "vuex"
    const store = useStore()
    
    const employeeInformation = computed(() => store.getters.employeeInformation)
    
    const filteredEmployeeInformation = computed(() => employeeInformation.value.filter( object => !!(object.current)))

</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .all-items-container {
        display: flex;
        flex-wrap: wrap;
        margin-left: -10px;
        margin-right: -10px;
    }
    
    .item-container {
        flex: 0 0 50%;
        padding: 6px 10px;
    }
    
    .item {
        margin-bottom: 15px;
        font-size: 12px;
        color: $grey_1;
    }
    
    .item-label {
        font-weight: 600;
        margin-bottom: 2px;
    }
    
    @media (min-width: 440px) {
        .item-container {
            flex: 0 0 50%;
            padding: 6px 10px;
        }
    }
    
    @include media-breakpoint-up(sm) {
        .item-container {
            flex: 0 0 25%;
            padding: 6px 10px;
        }
    }
    
    @include media-breakpoint-up(md) {
        .title {
            width: 140px;
            max-width: 100%;
        }
        .all-items-container {
            display: block;
            margin-left: 0;
            margin-right: 0;
        }
        .item-container {
            padding: 0;
        }
        .item {
            margin-bottom: 15px;
            font-size: 12px;
            color: $grey_1;
        }
        .item-label {
            font-weight: 600;
            margin-bottom: 2px;
        }
    }
    
</style>