// manager reimbursement actions (copy s actions)
import theApiCall from '@/hooks/api/the-api-call'
import {apiResponseHandler} from "@/hooks/api/api-helper-function"

export default {

    async loadReimbursementApproveeList(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateReimbursementApproveeList) { return }
        if (!context.getters.isEnabledReimbursements) { return }

        const userApiId = (localStorage.userId) ? localStorage.userId : context.getters.userApiId
        const response = await theApiCall(`employees/${userApiId}/reimbursement_approvees/`)
        const responseData = await apiResponseHandler(response, 'Could not load your reimbursement approvee list.', 'Reimbursement approvees resource not found. [404]')

        context.commit('setReimbursementApproveeList', responseData)
        context.commit('setLastReimbursementApproveeListFetch', {timestamp: new Date().getTime()})
    },

    async loadReimbursementAllowanceListAllApprovees(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateReimbursementAllowanceListAllApprovees) { return }

        // Get a list of reimbursement approvee IDs
        const reimbursementApproveeList = context.getters.reimbursementApproveeList
        const approveeIDs = reimbursementApproveeList.map(approvee => approvee.id)

        // Get a list of reimbursement allowance objects for each approvee
        // All objects must be in 1 single array, there mustn't be a nested array
        let allowanceList = []
        for (let i = 0; i < approveeIDs.length; i++) {
            const response = await theApiCall(`employees/${approveeIDs[i]}/reimbursement_allowances/`)
            const responseData = await apiResponseHandler(response, 'Failed to fetch Reimbursement Allowance List.', 'Reimbursement allowance list not found. [404]')
            allowanceList = allowanceList.concat(responseData)
        }

        context.commit('setReimbursementAllowanceListAllApprovees', allowanceList)
        context.commit('setLastReimbursementAllowanceListAllApproveesFetch', { timestamp: new Date().getTime()} )
    },

    // Manager update actions - PATCH state to 'approved' or 'rejected'
    async approveOrRejectReimbursementClaim(context, payload) {
        const requestID = payload.requestID
        const updatedState = payload.updatedState
        const employeeID = payload.employeeID

        // check if for own request or an approvee's request
        let userApiId = context.getters.userApiId
        let isUpdatingOwnRequest = true
        if (employeeID && employeeID !== userApiId) {
            isUpdatingOwnRequest = false
        }
        if (payload.employeeID) {
            userApiId = payload.employeeID
        }

        // if Ad hoc xero claim - needs to open in new tab
        if (payload.isAdHocClaim) {
            let action
            if (updatedState === 'approved') {
                action = 'approve'
            } else if (updatedState === 'rejected') {
                action = 'reject'
            }

            if (action === 'approve') {
                console.log(`%cClaim approval sent: ${requestID}`, "color: green; font-weight: bold;")
            } else {
                console.log(`%cClaim rejection sent: ${requestID}`, "color: red; font-weight: bold;")
            }

            try {
                const response = await theApiCall(`employees/${userApiId}/reimbursement_claims/${requestID}/${action}/`, 'GET')
                const responseData = await apiResponseHandler(response, 'Failed to update reimbursement claim', 'Reimbursement claim resource not found. [404]')

                if (response.ok) {
                    // Extract redirected status -> process 200 response and 302 routing differently differently
                    const redirected = response.redirected

                    const url = response.url
                    if (redirected) {
                        console.log(`%cRedirecting to Xero for authentication: ${requestID}`, "color: blue; font-weight: bold;")
                        window.open(url, '_blank', 'noopener,noreferrer');
                    } else {
                        console.log(`%cAlready Authenticated. Claim processed: ${requestID}`, "color: green; font-weight: bold;")
                        await context.dispatch('loadReimbursementApproveeList', {forceRefresh: true})
                        return responseData
                    }

                } else {
                    console.error('Failed to authenticate');
                    console.error('Error processing request', response.statusText)
                    return responseData
                    // Handle the error, possibly update the UI or show an error message
                }
            } catch (error) {
                console.error('Network error:', error)
                // Handle the network error, possibly update the UI or show an error message
            }

            // reload the list after approving declining via new tab approve / decline urls. But wait 5 seconds so the API has time to update
            // this may not always work because the trip to Xero may take longer than that
            setTimeout(async () => {
                await context.dispatch('loadReimbursementApproveeList', {forceRefresh: true});
            }, 5000)

        }
        // payroll claim approve or decline
        else {
            let body = JSON.stringify({state: updatedState})

            const response = await theApiCall(`employees/${userApiId}/reimbursement_claims/${requestID}/`, 'PATCH', body, 'application/json')
            const responseData = await apiResponseHandler(response, 'Failed to update reimbursement claim', 'Reimbursement claim resource not found. [404]')

            // update store to reflect on front-end for own reimbursement claim list
            if (isUpdatingOwnRequest) {
                const reimbursementObjectIndex = context.getters.reimbursementClaimList.findIndex(requestObject => requestObject.id == requestID)
                context.commit('updateReimbursementClaimState', {
                    leaveObjectIndex: reimbursementObjectIndex,
                    updatedState: responseData.state
                })
            }
            await context.dispatch('loadReimbursementAllowanceList', {forceRefresh: true})

            // update store for approvee list only if they are a reimbursement approver
            if (context.getters.isReimbursementApprover) {
                context.commit('updateReimbursementApproveeList', {
                    employeeID: employeeID,
                    requestID: requestID,
                    updatedState: responseData.state
                })
            }

            return (response.ok) ? {response, responseData} : null
        }
    },

}