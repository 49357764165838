<template>
    <modal-basic v-model="modelValueComputed">
        <template #title>
            Include a message with your request?
        </template>
        <template #body>
            You have not included a message with your leave request. This is not required but can be helpful for your leave approver.
        </template>
        <template #button1>
            <button-bento @click="closeModal" size="lg" button-color="secondary-light" class="mx-2 flex-grow-1 px-1">Include a message</button-bento>
        </template>
        <template #button2>
            <button-bento @click="submitLeaveRequestHandler" class="mx-2 flex-grow-1 px-1" size="lg">Submit without message</button-bento>
        </template>
    </modal-basic>
</template>

<script setup>
    import ModalBasic from "@/components/UI/v2/modals/ModalBasic.vue"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento.vue"
    import {computed} from "vue"
    
    const props = defineProps({
        modelValue: {
            required: true,
            type: Boolean
        },
    })
    
    const emit = defineEmits(['update:modelValue', 'submitLeaveRequest'])
    
    const modelValueComputed = computed({
        get: () => props.modelValue,
        set: (showModal) => emit('update:modelValue', showModal)
    })
    
    const closeModal = () => {
        emit('update:modelValue', false)
    }
    
    const submitLeaveRequestHandler = () => {
        emit('submitLeaveRequest')
    }

</script>

<style scoped lang="scss">

</style>