<template>
    <modal-summary
        id="remSummaryModal"
        v-model="showRemSummaryModal"
        size="lg"
    >
        <template #draft-badge>
            <div v-if="!isPayslipFinalised" class="text-danger font-weight-600 text-uppercase border border-2 border-danger px-1 font-size-15 draft-badge position-absolute">
                Unfinalised
            </div>
        </template>
        <template #title>
            Remuneration Summary
        </template>
        <template #header-badge>
            <div v-if="arePayrollDraftChanges" class="bg-secondary-100 text-secondary-700" :class="classList">
                <span class="d-none d-md-block">Changes Drafted</span><span class="d-md-none">Draft</span>
            </div>
            <div v-if="arePayrollChangesSubmitted" class="bg-primary-100 text-primary-700" :class="classList">
                <span class="d-none d-md-block">Changes Submitted</span><span class="d-md-none">Submitted</span>
            </div>
        </template>
        <template #body>
            <rem-summary-modal-content/>
        </template>
        <template #button1 v-if="arePayrollDraftChanges">
            <button-bento @click="showRemDraftModal" size="lg" button-color="secondary-light" class="mx-2 flex-grow-1">View drafted changes</button-bento>
        </template>
        <template #button2>
            <button-bento @click="viewPayslip" class="mx-2 flex-grow-1" size="lg">View full payslip</button-bento>
        </template>
    </modal-summary>
</template>

<script setup>
    import {computed} from "vue"
    import {useStore} from "vuex"
    import RemSummaryModalContent from "@/components/summary-and-payslip/modal/summary/RemSummaryModalContent"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
    import ModalSummary from "@/components/summary-and-payslip/modal/base-modals/ModalSummary"
    import {useRouter} from "vue-router"
    const store = useStore()
    const router = useRouter()

    const arePayrollDraftChanges = computed( () => store.getters.arePayrollDraftChanges)
    const arePayrollChangesSubmitted = computed( () => store.getters.arePayrollChangesSubmitted)
    const isPayslipFinalised = computed(() => store.getters.isPayslipFinalised)
    
    // https://skirtles-code.github.io/vue-examples/patterns/computed-v-model.html#alternatives
    const showRemSummaryModal = computed({
        get: () => store.getters.showRemSummaryModal,
        set: showValue => store.commit('toggleShowRemSummaryModal', showValue)
    })

    const showRemDraftModal = () => {
        store.commit('toggleShowRemSummaryModal', false)
        store.commit('toggleShowRemDraftModalMedical', true)
    }
    
    const viewPayslip = () => {
        store.commit('toggleShowRemSummaryModal', false)
        router.push({name: 'employee-payslip'})
    }
    
    const classList = computed( () => 'font-size-13 change-drafted-badge font-weight-600 rounded-5 py-1 px-3 me-1 text-center d-inline-block');
</script>

<style scoped lang="scss">
    .draft-badge {
        right: 5px;
        top: 4px;
        font-size: 13px !important;
    }
</style>