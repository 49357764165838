<template>
    <modal-confirmation v-model="showConfirmMedicalAidChangesModal">
        <template #title>Medical Aid Plan Changes</template>
        <template #body>
            <div v-if="!areMedicalAidChangesSubmitted">
                <p>When you submit your draft changes, Bento will begin the process of notifying all the relevant parties of your desired changes.</p>
                <p>You will be contacted to continue the required process to complete and implement changes to reflect correctly in Bento.</p>
                <p>You will not be able to continue playing around with your options and until the proposed changes have been accepted and implemented, which might take up to a month to implement.</p>
                <p class="font-weight-600">By submitting these proposed changes you agree to being informed adequitely about the impact these changes will have on your financial situation.</p>
                <p class="font-weight-600">Should you require advice before submitting these changes, please feel free to <a href="mailto:hello@mybento.net">contact us with your advice requirements.</a></p>
            </div>
            <div v-if="isSubmittingChanges" id="submitting-changes-loader" class="mt-4">
                <loading-small v-model="isSubmittingChanges" parentSelector="#submitting-changes-loader" loadingText="Submitting changes ..."/>
            </div>
            <div>
                <bento-alert color="success" static class="text-center" v-if="areMedicalAidChangesSubmitted && submittingChangesComplete">
                    <template #default>Your changes have been submitted.</template>
                </bento-alert>
                <bento-alert color="danger" static class="text-center" v-if="submittingChangesFailed">
                    <template #default>
                        <div>Failed to submit your changes.</div>
                        <div><a href="mailto:hello@mybento.net" class="font-weight-600 alert-danger-link">Contact us for assistance.</a></div>
                    </template>
                </bento-alert>
            </div>
        </template>
        <template #button1 v-if="!isSubmittingChanges && !areMedicalAidChangesSubmitted">
            <button-bento @click="cancelConfirmChangesHandler" button-color="dark" class="mx-2 flex-grow-1" size="lg">Cancel</button-bento>
        </template>
        <template #button2 v-if="!isSubmittingChanges && !areMedicalAidChangesSubmitted">
            <button-bento @click="confirmChangesHandler" class="mx-2 flex-grow-1" size="lg">Confirm changes</button-bento>
        </template>
    </modal-confirmation>
</template>

<script setup>
    import {ref, computed} from "vue"
    import {useStore} from "vuex"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
    import ModalConfirmation from "@/components/summary-and-payslip/modal/base-modals/ModalConfirmation"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert"
    import LoadingSmall from "@/components/UI/v2/loading/LoadingSmall"
    
    const store = useStore()
    
    const areMedicalAidChangesSubmitted = computed(() => store.getters.areMedicalAidChangesSubmitted)
    
    const showConfirmMedicalAidChangesModal = computed({
        get: () => store.getters.showConfirmMedicalAidChangesModal,
        set: showValue => store.commit('toggleShowConfirmMedicalAidChangesModal', showValue)
    })
    
    const confirmChangesModal = ref(false)
    const medicalAidContractObjPlanned = computed(() => store.getters.medicalAidContractObjPlanned)
    
    const cancelConfirmChangesHandler = () => {
        store.commit('toggleShowConfirmMedicalAidChangesModal', false)
        store.commit('toggleShowRemDraftModalMedical', true)
    }
    
    
    const isSubmittingChanges = ref(false)
    const submittingChangesFailed = ref(false)
    const submittingChangesComplete = ref(false)
    const confirmChangesHandler = async () => {
        isSubmittingChanges.value = true
        submittingChangesComplete.value = false
        
        const contractObject = {...medicalAidContractObjPlanned.value}
        contractObject.status = 'submitted'
        
        try {
            await store.dispatch('setMedicalAidContractChangeRequestList', contractObject)
        } catch (error) {
            submittingChangesFailed.value = true
            return error
        }
        
        submittingChangesComplete.value = true
        isSubmittingChanges.value = false
        
        setTimeout(() => {
            confirmChangesModal.value = false
        }, 2500)
        
    }

</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .reset-icon {
        width: 18px;
        position: relative;
        top: -2px;
    }
    
    p a {
        color: darken($primary, 4%);
        &:hover {
            color: darken($primary, 10%);
        }
    }
    
    #submitting-changes-loader {
        min-height: 50px;
    }
</style>