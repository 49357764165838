// static content module mutations
// not namespaced

export default {

    // modals
    toggleShowRemSummaryModal: (state, show) => state.showRemSummaryModal = show,

    // rem changes combined
    toggleShowRemDraftModalCombined: (state, show) => state.showRemDraftModalCombined = show,
    toggleShowRemSubmittedModalCombined: (state, show) => state.showRemSubmittedModalCombined = show,

    // medical
    toggleShowRemDraftModalMedical: (state, show) => state.showRemDraftModalMedical = show,
    toggleShowConfirmMedicalAidChangesModal: (state, show) => state.showConfirmMedicalAidChangesModal = show,

    // retirement modals
    toggleShowRemDraftModalRetirement: (state, show) => state.showRemDraftModalRetirement = show,
    toggleShowConfirmRetirementFundChangesModal: (state, show) => state.showConfirmRetirementFundChangesModal = show,
    // retirement interface
    setSelectedRetirementView: (state, leaveView) => state.selectedRetirementView = leaveView,
    setCurrentRetirementWizardStep: (state, step) => state.currentRetirementWizardStep = step,

    // side nav
    toggleIsSideNav: (state, isSideNav) => state.isSideNav = isSideNav,
    toggleIsSideNavSlim: (state, isSlim) => state.isSideNavSlim = isSlim,

    // employee directory
    toggleIsListView: (state, isListView) => state.isListView = isListView,

    // api error management
    setShowApiErrorModal: (state, showModal) => state.showApiErrorModal = showModal,
    setApiErrorModalTitle: (state, apiErrorModalTitle) => state.apiErrorModalTitle = apiErrorModalTitle,
    setApiErrorMessage: (state, apiErrorMessage) => state.apiErrorMessage = apiErrorMessage,

    // leave
    setSelectedLeaveView: (state, leaveView) => state.selectedLeaveView = leaveView,
    setSelectedLeaveType: (state, leaveTypeObj) => state.selectedLeaveType = leaveTypeObj,


}