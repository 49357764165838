// manager reimbursement store module
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    state() {
        return {

            // `employees/${userApiId}/reimbursement_approvees/`
            // list of approvees, including their reimbursement claims
            reimbursementApproveeList: null,
            lastReimbursementApproveeListFetch: null,

            // combined list of all reimbursement allowances for all approvees
            // this is built by making multiple api calls, one for each approvee, fetching their allowances
            reimbursementAllowanceListAllApprovees: null,
            lastReimbursementAllowanceListAllApproveesFetch: null,
        }
    },
    mutations,
    actions,
    getters,
}