<template>
    <div class="cont p-4 px-3 pe-5 text-white d-flex flex-column justify-content-between cursor-pointer">
        <div>
            <div class="mb-2 font-weight-600 font-size-18 title">{{ title }}</div>
            <div class="mb-2 font-weight-500 font-size-16 fst-italic sub-title" v-html="subtitle"></div>
            <div class="mb-5 mb-sm-4 pt-1 font-size-14 font-weight-400 lh-base">{{ description }}</div>
        </div>
        <div>
            <div class="mb-3 fw-500" v-if="label">
                {{ label }}
            </div>
            <div class="font-weight-500 font-size-15" v-if="factSheetLink">
                <a :href="factSheetLink" target="_blank"><img src="@/assets/v2/icons/pdf-icon.svg" class="d-inline-block me-4"/> <span>Download fact sheet</span></a>
            </div>
        </div>
    </div>
</template>

<script setup>
    import {computed} from "vue"
    import {useStore} from "vuex"
    const store = useStore()
    
    const props = defineProps({
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: true,
        },
        factSheetLink: {
            type: String,
            required: false,
        },
        fundId: {
            type: Number,
            required: true,
        }
    })
    
    
    const hasRetirementFundContract = computed(() => store.getters.hasRetirementFundContract)
    const retirementFundContractObj = computed(() => store.getters.retirementFundContractObj)
    
    const retirementFundContractObjSubmitted = computed(() => store.getters.retirementFundContractObjSubmitted)
    const retirementFundContractObjDraft = computed(() => store.getters.retirementFundContractObjDraft)
    
    const label = computed(() => {
        if (hasRetirementFundContract.value && props.fundId == retirementFundContractObj.value.retirement_fund.id) {
            return '-> Current Plan'
        }
        if (retirementFundContractObjSubmitted.value && props.fundId == retirementFundContractObjSubmitted.value.retirement_fund) {
            return '-> Submitted Plan'
        }
        if (retirementFundContractObjDraft.value && props.fundId == retirementFundContractObjDraft.value.retirement_fund) {
            return '-> Saved Draft Plan'
        }
        return null
    })
    
</script>

<style scoped lang="scss">
    @import '@/styles/global-scss/variables-and-mixins.scss';
    
    .cont {
        border-radius: 13px;
        @include media-breakpoint-up(md) { min-height: 360px; }
        @include media-breakpoint-up(lg) { min-height: 300px; }
        @include media-breakpoint-up(xl) { min-height: 337px; }
        
        &:hover, &.selected {
            transform: scale(1.028);
            box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.92);
        }
    }
    
    .sub-title {
        line-height: 1.25;
    }
    
    img {
        width: 18px;
    }
    
    a:hover {
        span {
            @include bento_underline;
            &:before {
                background-color: white;
            }
        }
    }
</style>
