<template>
    <div>
        <width-container v-if="!isEnabledRetirement && !isEnabledRetirementCalculator">
            <card-general>
                <section-header>Retirement not enabled for your employer</section-header>
            </card-general>
        </width-container>
        <essential-benefit-page v-else>
            <template #benefitTitle>Retirement</template>
            
            <template #benefitExtraContent>
                <retirement-page-intro-block v-if="isEnabledRetirement"/>
                <retirement-calculator v-if="(isEnabledRetirement || isEnabledRetirementCalculator) && (selectedRetirementView === 'retirement-calculator')"/>
                <retirement-investment-options v-if="(isEnabledRetirement) && (selectedRetirementView === 'retirement-investment-options')" id="retirement-investment-options"/>
                
                <!--testing-->
                <div v-if="false">
                    <h3>tempSelectedRetirementFundContractObj</h3>
                    <div>
                        <pre>{{ tempSelectedRetirementFundContractObj }}</pre>
                    </div>
                    <h3>areRetirementTempChangesExtraDataDifferentToDraft</h3>
                    <div>
                        <pre>{{ areRetirementTempChangesExtraDataDifferentToDraft }}</pre>
                    </div>
                    <h3>areRetirementTempChangesDifferentToDraft</h3>
                    <div>
                        <pre>{{ areRetirementTempChangesDifferentToDraft }}</pre>
                    </div>
                    <h3>retirementFundContractChangeRequestList</h3>
                    <div>
                        <pre>{{ retirementFundContractChangeRequestList }}</pre>
                    </div>
                    <h3>retirementFundContractObj</h3>
                    <div>
                        <pre>{{ retirementFundContractObj }}</pre>
                    </div>
                    <h3>areRetirementDraftChangesExtraDataDifferentToCurrent</h3>
                    <div>
                        <pre>{{ areRetirementDraftChangesExtraDataDifferentToCurrent }}</pre>
                    </div>
                    <hr class="my-5">
                    <h3>calculatorRetirementContract</h3>
                    <div>
                        <pre>{{ calculatorRetirementContract }}</pre>
                    </div>
                    <h3>retirementFundList</h3>
                    <div>
                        <pre>{{ retirementFundList }}</pre>
                    </div>
                    <h3>employeeRetirementFundContractList</h3>
                    <div>
                        <pre>{{ employeeRetirementFundContractList }}</pre>
                    </div>
                    <h3>employerRetirementFund</h3>
                    <div>
                        <pre>{{ employerRetirementFund }}</pre>
                    </div>
                    <h3>retirementFundChangeIndicators</h3>
                    <div>
                        <pre>{{ retirementFundChangeIndicators }}</pre>
                    </div>
                    <h3>textSummaryOfChangesRetirement</h3>
                    <div>
                        <pre>{{ textSummaryOfChangesRetirement }}</pre>
                    </div>
                </div>
            </template>
            
            <template #resourcesSectionHeader>Learn more about saving for retirement</template>
            <template #learnContentCards v-if="isValidArray(learningResourcesRetirementApi)">
                <base-learn-card-small
                    v-for="resource in learningResourcesRetirementApi"
                    :key="resource.id" :resource="resource"
                    :class="activeClass(resource)"
                />
            </template>
        </essential-benefit-page>
    </div>
</template>

<script setup>
    import EssentialBenefitPage from '@/components/benefits-leave-reimbursements/EssentialBenefitPage'
    import {useStore} from 'vuex'
    import CardGeneral from '@/components/UI/v2/containers-cards-headers/CardGeneral.vue'
    import BaseLearnCardSmall from '@/components/learn-and-explore/BaseLearnCardSmall.vue'
    import SectionHeader from '@/components/UI/v2/containers-cards-headers/SectionHeader.vue'
    import RetirementPageIntroBlock from '@/components/benefits-leave-reimbursements/retirement/RetirementPageIntroBlock.vue'
    import RetirementCalculator from '@/components/benefits-leave-reimbursements/retirement/components/RetirementCalculator.vue'
    import RetirementInvestmentOptions from '@/components/benefits-leave-reimbursements/retirement/components/RetirementInvestmentOptions.vue'
    import {computed, watch, ref} from 'vue'
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer.vue"
    import {isValidArray} from "@/hooks/general/type-and-value-checks"
    
    const store = useStore()
    
    import {useRoute} from "vue-router"
    const route = useRoute()
    const activeClass = (resource) => route.params.resourceSlug === resource.slug ? 'active-card' : ''
    
    const activeResourceSlug = ref('')
    
    watch(() => route.params.resourceSlug, (newSlug) => {
        activeResourceSlug.value = newSlug
    })
    
    const isEnabledRetirement = store.getters.isEnabledRetirement
    const isEnabledRetirementCalculator = store.getters.isEnabledRetirementCalculator
    const learningResourcesRetirementApi = store.getters.learningResourcesRetirementApi
    const selectedRetirementView = computed(() => store.getters.selectedRetirementView)
    
    // for testing purposes
    const retirementFundContractObj = computed(() => store.getters.retirementFundContractObj)
    const calculatorRetirementContract = computed(() => store.getters.calculatorRetirementContract)
    const retirementFundList = computed(() => store.getters.retirementFundList)
    const employeeRetirementFundContractList = computed(() => store.getters.employeeRetirementFundContractList)
    const retirementFundContractChangeRequestList = computed(() => store.getters.retirementFundContractChangeRequestList)
    const tempSelectedRetirementFundContractObj = computed(() => store.getters.tempSelectedRetirementFundContractObj)
    const employerRetirementFund = computed(() => store.getters.employerRetirementFund)
    const retirementFundChangeIndicators = computed(() => store.getters.retirementFundChangeIndicators)
    const textSummaryOfChangesRetirement = computed(() => store.getters.textSummaryOfChangesRetirement)
    const areRetirementDraftChangesExtraDataDifferentToCurrent = computed(() => store.getters.areRetirementDraftChangesExtraDataDifferentToCurrent)
    const areRetirementTempChangesExtraDataDifferentToDraft = computed(() => store.getters.areRetirementTempChangesExtraDataDifferentToDraft)
    const areRetirementTempChangesDifferentToDraft = computed(() => store.getters.areRetirementTempChangesDifferentToDraft)
</script>