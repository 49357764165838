<template>
    <div class="block">
        <h4>{{ title }}</h4>
        <div>
            <pre>
                <small>
                    {{ computedPropertyToDisplay }}
                </small>
            </pre>
        </div>
    </div>
</template>

<script>
    export default {
        name: "RawOutputBlock",
        props: {
            title: {
                required: true,
                type: String,
            },
            computedPropertyToDisplay: {
                required: true,
            }
        }
    }
</script>

<style scoped>
    
    .block {
        border: 1px solid black;
        padding: 15px;
        margin-bottom: 25px;
    }

</style>