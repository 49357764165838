<template>
    <card-general-inner class="green no-shadow gradient-green-text-dark">
        <div class="d-flex align-items-center">
            <div>
                <div class="fw-400">
                    <span class="fw-500">{{ employee.name }}</span>
                </div>
                <div class="fw-400 text-gray-main font-size-13">
                    <a v-if="isBentoDemoCompany" href="mailto:example@email.com" @click.stop> <span>example@email.com</span></a>
                    <a v-else :href="`mailto:${employee.email}`">{{ employee.email }}</a>
                </div>
            </div>
        </div>
    </card-general-inner>
</template>

<script setup>
    import CardGeneralInner from "@/components/UI/v2/containers-cards-headers/CardGeneralInner.vue"
    import {computed} from "vue";
    import {isProd} from "@/hooks/api/app-environment-origin";
    import {useStore} from "vuex";

    const store = useStore()
    const employer = computed(() => store.getters.employer)
    const isBentoDemoCompany = computed(() => employer.value.name === 'Bento Demo' || (employer.value.id === 6 && isProd()) )

    defineProps({
        employee: {type: Object, required: true}
    })
</script>

<style scoped lang="scss">
    .profile-picture-cont {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        flex: 0 0 40px;
        border-radius: 50%;
    }
</style>