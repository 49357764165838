<template>
    <div class="container-xl">
        <div class="row">
            <div class="col-12">
                <div class="px-1 px-md-2 px-md-3">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>