<template>
    <card-general>
        <section-header>{{ title }}</section-header>

        <div class="mt-n2">
            Claims Period: <span class="text-primary-800 fw-500">{{ selectedClaimsPeriodText }}</span>
        </div>

        <div class="mt-4 pt-2 mb-2">
            <MDBSelect
                v-model:options="claimsPeriodOptions"
                label="Claims period"
                size="lg"
            />
        </div>

        <button-bento class="mt-2 w-100 mb-2" size="lg" color="primary" @click="downloadReimbursementReportsHandler">
            Download Report
        </button-bento>
    </card-general>
</template>

<script setup>
import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral"
import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
import {ref, computed} from "vue"
import {useStore} from "vuex"
import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
import {currentUrlOrigin} from "@/hooks/api/app-environment-origin"
import {MDBSelect} from "mdb-vue-ui-kit"

const store = useStore()

defineProps({
    title: {
        type: String,
        default: "Reimbursement Claims Report"
    }
})

const getDaySuffix = (day) => {
    if (day > 3 && day < 21) return 'th'
    switch (day % 10) {
        case 1:
            return 'st'
        case 2:
            return 'nd'
        case 3:
            return 'rd'
        default:
            return 'th'
    }
}


const reimbursementClaimStartDate = new Date(store.getters.reimbursementClaimStartDate)
const startDay = reimbursementClaimStartDate.getDate()
const reimbursementClaimEndDate = new Date(store.getters.reimbursementClaimEndDate)
const endDay = reimbursementClaimEndDate.getDate()

// dynamically build last 12 months (include following month if after end day of current month)
const claimsPeriods = []
const date = new Date()
const currentDay = date.getDate()
const startMonthOffset = currentDay > endDay ? 1 : 0

for (let i = 11; i >= 0; i--) {
    const newDate = new Date(date.getFullYear(), date.getMonth() - i + startMonthOffset, 1)
    const currentMonthStartDate = new Date(newDate.getFullYear(), newDate.getMonth(), startDay)
    const monthName = newDate.toLocaleString('default', {month: 'long'})
    const year = newDate.getFullYear()
    const value = `${currentMonthStartDate.getFullYear()}-${('0' + (currentMonthStartDate.getMonth() + 1)).slice(-2)}-${('0' + currentMonthStartDate.getDate()).slice(-2)}`
    claimsPeriods.unshift({text: `${monthName} ${year}`, value: value})
}
const claimsPeriodOptions = ref(claimsPeriods)



const selectedClaimsPeriod = computed(() => claimsPeriodOptions.value.find(period => period.selected) || '')
const selectedClaimsPeriodText = computed(() => {
    if (!selectedClaimsPeriod.value) return ''
    const selectedDate = new Date(selectedClaimsPeriod.value.value)
    const startDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() - 1, startDay)
    const endDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), endDay)
    const startMonth = startDate.toLocaleString('default', {month: 'short'})
    const endMonth = endDate.toLocaleString('default', {month: 'short'})
    const year = endDate.getFullYear()
    return `${startDay}${getDaySuffix(startDay)} ${startMonth} - ${endDay}${getDaySuffix(endDay)} ${endMonth} ${year}`
})

const employerId = store.getters.employer_id
const claimsPeriodLink = computed(() => `${currentUrlOrigin()}/reports/reimbursements/employer_approved_claims_report/${employerId}/${selectedClaimsPeriod.value.value}`)
const downloadReimbursementReportsHandler = async () => {
    window.open(claimsPeriodLink.value, '_blank')
}
</script>