<template>
    <width-container>
        <page-header>
            <router-link :to="{ name: 'home' }">
                <slot name="benefitTitle"></slot>
            </router-link>
        </page-header>
        
        <!-- selector, wizard etc -->
        <div v-if="!$slots.benefitExtraConent" class="benefit-extra-content mt-4 mb-5">
            <slot name="benefitExtraContent"></slot>
        </div>
        
        <card-general id="benefit-resources" class="learn-more-about-benefit-container green mt-5" v-if="$slots.learnContentCards">
            <section-header>
                <slot name="resourcesSectionHeader"></slot>
            </section-header>
            
            <learn-grid>
                <slot name="learnContentCards"></slot>
            </learn-grid>
        </card-general>
        
        <router-view></router-view>
    
        <slot name="pageEndContent" v-if="$slots.pageEndContent"></slot>
        
        <footer-contact-help-card/>
    </width-container>
</template>

<script setup>
    import SectionHeader from "../UI/v2/containers-cards-headers/SectionHeader"
    import LearnGrid from "@/components/learn-and-explore/LearnGrid"
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer"
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral"
    import PageHeader from "@/components/UI/v2/containers-cards-headers/PageHeader.vue"
    import FooterContactHelpCard from "@/components/layouts/footer/FooterContactHelpCard.vue"
    import {nextTick, onMounted} from "vue"
    
    defineProps({
        resourceSlug: String,
    })
    
    const scrollToElement = async () => {
        await nextTick()
        const element = document.querySelector('#learning-resource')
        if (element) {
            const rect = element.getBoundingClientRect()
            const offset = 400
            const top = rect.top + window.pageYOffset - offset
            window.scrollTo({ top, behavior: 'smooth' })
        }
    }
    onMounted(scrollToElement)
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        a {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
    }
    
    .benefit-intro-text {
        color: black;
        font-size: 24px;
        line-height: 1.2;
        font-weight: 400;
        margin-top: 45px;
        margin-bottom: 40px;
        padding: 0 30px 35px;
        p {
            margin: 0;
        }
        span {
            font-weight: 600;
        }
    }
    
    .learn-more-about-benefit-container {
        margin-top: 105px;
    }
</style>