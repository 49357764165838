<template>
    <summary-card :classList="classList" view-more-link="/reimbursements" :view-more-text="isValidArray(reimbursementAllowanceListExclAdHoc) ? 'Submit an expense' : 'Reimbursements page'">
        
        <template #title>Reimbursements</template>
        <template #subHeading>
            <span v-if="!isValidArray(reimbursementAllowanceListExclAdHoc)">
                No allowances set up
            </span>
            <span v-else>
                {{ bentoCurrencyFormat(totalReimbursementAllowancesExAdHoc.api) }} Budget
            </span>
        </template>
        
        <template #cardText>
            <div class="card-text-sub-div">
                <div v-if="isValidArray(reimbursementAllowanceListExclAdHoc)">
                    <p>You have a total reimbursement monthly allowance of
                        <span>{{ bentoCurrencyFormat(totalReimbursementAllowancesExAdHoc.api) }}</span> for expenses.
                    </p>
                    <p>
                        Upload and submit your expenses to receive your reimbursement.
                    </p>
                    <p>Your allowances: {{ printAllowancesList }}</p>
                </div>
                <div v-else>
                    You don't current have any reimbursement allowances.
                </div>
            </div>
        </template>
    
    </summary-card>
</template>

<script setup>
    import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"
    import SummaryCard from "@/components/cards/SummaryCard"
    import {computed} from "vue"
    import {useStore} from "vuex"
    const store = useStore()
    import useInfoCard from "@/hooks/use-component/cards/info-card"
    import {isValidArray} from "@/hooks/general/type-and-value-checks"
    
    const reimbursementAllowanceListExclAdHoc = computed(() => store.getters.reimbursementAllowanceListExclAdHoc)
    const totalReimbursementAllowancesExAdHoc = computed(() => store.getters.totalReimbursementAllowancesExAdHoc)
    
    const {classList} = useInfoCard(null, ['reimbursements', 'blue-card'])
    
    const printAllowancesList = computed(() => {
        const list = reimbursementAllowanceListExclAdHoc.value;
        let listText = '';
        let iterations = list.length;
        for (const allowance of list) {
            if (!--iterations)
                listText += allowance.name + '.';
            else
                listText += allowance.name + ', ';
        }
        return listText;
    })
</script>