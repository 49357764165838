<template>
    
    <MDBDropdown align="end" v-model="dropdown24" tag="true" offset="6,12">
        <MDBDropdownToggle
            @click="toggleDropdownHandler"
            tag="div"
            class="drop-down-toggler-grp"
        >
            <div class="bento-badge rounded-circle d-flex align-items-center justify-content-center bg-primary">
                <span>{{ userInitials }}</span>
            </div>
        </MDBDropdownToggle>
        <MDBDropdownMenu class="p-4 pe-3 pe-sm-5 dropdown-container" tag="div">
            <div class="py-1">
                <h4>{{ userOneFirstName }} {{ user.last_name }}</h4>
                <div class="text-gray-500 mb-2">{{ user.email }}</div>
                
                <ul class="list-unstyled mt-4 profile-menu-list text-gray-600 mb-1">
                    <li class="parent-menu-li" v-if="isTermsAccepted && (isUserAdmin || isViewingAsOther) && isUserBento">
                        <MDBBtn
                            class="parent-menu-li-a"
                            tag="a"
                            @click="toggleCollapse1"
                            aria-controls="collapsibleContent1"
                            :aria-expanded="collapse1"
                            type="none"
                            role="link"
                        >
                            Bento Admin Links
                        </MDBBtn>
                        <MDBCollapse v-model="collapse1" id="collapsibleContent1" class="collapsible-parent-menu-item">
                            <ul>
                                <li><router-link to="/bento-admin/all-employees" @click="closeAll">All Employees</router-link></li>
                                <li><router-link to="/bento-admin/employee-selector" @click="closeAll">Employee Selector</router-link></li>
                                <li><router-link to="/bento-admin" @click="closeAll">All Admin Pages</router-link></li>
                            </ul>
                        </MDBCollapse>
                    </li>
                    <li v-if="isTermsAccepted">
                        <router-link to="/user-terms-and-privacy-policy" @click="closeAll">Terms & Privacy</router-link>
                    </li>
                    <li v-if="isTermsAccepted">
                        <router-link :to="{name: 'contact-us'}" @click="closeAll">Contact Us</router-link>
                    </li>
                    <li class="mt-4">
                        <a href="#" @click="logout"><img src="@/assets/v2/icons/log-out-hand.svg" width="15" class="me-1" alt="Bye"/><span>Logout</span></a>
                    </li>
                </ul>
            
            </div>
        </MDBDropdownMenu>
    </MDBDropdown>

</template>

<script setup>
    import {MDBBtn, MDBCollapse, MDBDropdown, MDBDropdownMenu, MDBDropdownToggle} from "mdb-vue-ui-kit"
    import {ref, computed} from 'vue'
    import {useStore} from "vuex"
    import {useRouter} from "vue-router"
    const store = useStore()
    const router = useRouter()
    
    const dropdown24 = ref(false)
    const collapse1 = ref(false)
    const collapse2 = ref(false)
    
    const toggleDropdownHandler = () => {
        dropdown24.value = !dropdown24.value
        collapseAdminLinks()
        collapseTeamLinks()
    }
    const closeDropdown = () => dropdown24.value = false
    const collapseAdminLinks = () => collapse1.value = false
    const collapseTeamLinks = () => collapse2.value = false
    const toggleCollapse1 = () => collapse1.value = !collapse1.value
    
    const closeAll = () => {
        collapseAdminLinks()
        collapseTeamLinks()
        closeDropdown()
    }
    
    const logout = async () => {
        closeAll()
        await router.replace({name: 'login'})
        await store.dispatch('logout')
        location.reload()
    }
    
    const userInitials = computed(() => store.getters.userInitials)
    const userOneFirstName = computed(() => store.getters.userOneFirstName)
    const user = computed(() => store.getters.user)
    const isTermsAccepted = computed(() => store.getters.isTermsAccepted)
    const isUserAdmin = computed(() => store.getters.isUserAdmin)
    const isViewingAsOther = computed(() => store.getters.isViewingAsOther)
    const isUserBento = computed(() => store.getters.isUserBento)
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .dropdown-toggle::after {
        display: none;
    }
    
    .bento-badge {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        transition: all .2s ease-in-out;
        span {
            font-weight: 600;
            position: relative;
            left: .5px;
            letter-spacing: 1px;
        }
        &:hover {
            background-color: darken($primary, 5%) !important;
        }
    }
    
    .drop-down-toggler-grp {
        display: flex;
        align-items: center;
    }
    
    .dropdown-container {
        min-width: 200px;
        @include media-breakpoint-up(sm) {
            min-width: 260px;
        }
    }
    
    .profile-menu-list {
        font-weight: 500;
        > li {
            margin: 3.5px 0;
            padding: 3.5px 0;
        }
        li.parent-menu-li {
            ul {
                padding: 3px 3px 6px 14px;
                list-style-type: none;
            }
        }
        a.parent-menu-li-a {
            margin: 0;
            text-transform: none;
            color: inherit;
            box-shadow: none;
            font-size: inherit;
            line-height: inherit;
            font-weight: inherit;
            border-radius: 0;
            text-align: left;
            display: inline-block;
            padding: 0;
            &::after {
                display: inline-block;;
                margin-left: 8px;
                vertical-align: 0.255em;
                content: "";
                border-top: 0.3em solid;
                border-right: 0.3em solid transparent;
                border-bottom: 0;
                border-left: 0.3em solid transparent;
            }
        }
        a:hover {
            color: $primary;
        }
    }
    
    .collapsible-parent-menu-item {
        li {
            margin: 3px 0;
        }
    }
    
    .pointer {
        cursor: pointer;
    }
</style>