<template>
    <div class="col-12 col-md-6 col-xl-4 card-col">
        <div class="card-container gradient-primary rounded p-3 d-flex flex-column justify-content-between cursor-pointer hover-shadow" @click="selectResource(resource)">
            <header>
                <div class="card-category text-white mb-2 text-capitalize font-weight-400">
                    {{ toTitleCase(resource.category) }}
                </div>
                <div class="card-title font-dm-sans font-weight-500 text-white">
                    {{ resource.title }}
                </div>
            </header>
            
            <footer>
                <div class="footer-text text-white font-weight-500">
                    {{ resource.footer_text }}
                </div>
            </footer>
        </div>
    </div>
</template>

<script setup>
    import {useRouter, useRoute} from 'vue-router'
    import {toTitleCase} from "@/hooks/general/text-formatters"
    const route = useRoute()
    
    const router = useRouter()
    defineProps({
        resource: {
            type: Object,
            required: true,
        },
    })
    
    const selectResource = (resource) => {
        let path = '/'
        
        // if on simple benefit page
        if (route.meta.simpleBenefitPage) {
            const simpleBenefitCategory = route.params.simpleBenefitCategory
            const simpleBenefitId = route.params.simpleBenefitId
            const resourceSlug = route.params.simpleBenefitSlug
            path = `/${simpleBenefitCategory}/${simpleBenefitId}/${resourceSlug}/${resource.slug}`
        }
        
        else {
            // else if on other page (home, medical, retirement, risk, bonuses & commissions, leave, reimbursements etc ...)
            switch (resource.tag) {
                case 'home-link':
                    path = resource.slug         // '/employee-survey' && '/contact-us' pages.
                    break;
                case 'medical':
                    path = '/benefits/medical-aid/' + resource.slug    // '/benefits/medical-aid/why-does-medical-aid-matter-anyway'
                    break;
                case 'retirement':
                    path = '/benefits/retirement/' + resource.slug     // '/benefits/retirement/why-does-medical-aid-matter-anyway'
                    break;
                case 'risk':
                    path = '/benefits/insurance/' + resource.slug
                    break;
                case 'bonuses_commissions':
                    path = '/benefits/bonuses-and-commissions/' + resource.slug
                    break;
                case 'leave':
                    path = '/leave/' + resource.slug
                    break;
                case 'reimbursements':
                    path = '/reimbursements/' + resource.slug
                    break;
                default: //
                    path = '/learn-and-explore/' + resource.slug          // e.g. '/learn-and-explore/make-the-most-of-your-earnings'
            }
        }
        
        router.push({
            path: path,
        })
    }
</script>

<style lang="scss" scoped>
    .card-container {
        height: 172px;
    }
    
    .card-title {
        font-size: 1.4rem;
        line-height: 1.25;
    }
</style>