<template>
    <essential-benefit-page>
        <template #benefitTitle>Reimbursements</template>

        <template #benefitExtraContent>
            <div v-if="isEnabledReimbursements">
                <div v-if="!hasReimbursementClaimApprover">
                    <card-general>
                        <no-approver-notice/>
                    </card-general>
                </div>
                <div v-else-if="!isValidArray(reimbursementAllowanceListExclAdHoc)">
                    <card-general>
                        <no-allowances-notice/>
                    </card-general>
                </div>
                <div v-else>
                    <card-general>
                        <div class="mb-4 pb-2">
                            <div class="font-size-17 fw-500 pe-2">
                                Submit Expense Claims for {{ forPayrollMonth }} Payroll
                            </div>
                            <div class="font-size-14 text-gradient-blue-text-dark fw-500 mt-1">
                                <span>{{ claimsPeriod }}</span><span class="d-inline-block ms-2"><info-tooltip :tip-content="extraInfoTooltip" v-if="extraInfoTooltip" class="gradient-blue-text-dark"/></span>
                            </div>
                            <div class="mt-1 text-gradient-blue-text-dark font-weight-500">
                                <div>
                                    <span>Approver<span v-if="employeeReimbursementApprovers.length > 1">s</span>:</span> {{ reimbursementApproversCommaSeparated }}
                                </div>
                                <div class="fw-500 mt-3 text-gradient-blue-text-dark font-size-15" v-if="false">
                                    <a class="cursor-pointer bento-underline-primary-hover hover-primary" @click="scrollToHash('reimbursements-requests-table')">View all reimbursement claims</a> ->
                                </div>
                            </div>
                        </div>

                        <reimbursement-wizard-block
                            class="mb-3 wizard-block"
                            v-for="reimbursementType in reimbursementAllowanceListExclAdHoc"
                            :reimbursement-type="reimbursementType"
                            :key="reimbursementType.id"
                        />

                        <div class="font-weight-600 text-gradient-blue-text-dark hover-blue mt-3">
                            <router-link :to="{name: 'ad-hoc-reimbursements'}">Click here for ad hoc reimbursement claims -></router-link>
                        </div>
                    </card-general>
                </div>
                <reimbursement-claims-list-cont id="reimbursements-requests-table" v-if="hasReimbursementClaimApprover && isValidArray(reimbursementAllowanceListExclAdHoc)"/>
            </div>
            <div v-else>
                <bento-alert color="warning" static>
                    Your employer has not enabled reimbursements.
                </bento-alert>
            </div>
        </template>

        <template #resourcesSectionHeader v-if="isValidArray(learningResourcesReimbursementsApi) && isEnabledReimbursements">Learn more about Reimbursements</template>
        <template #learnContentCards v-if="isValidArray(learningResourcesReimbursementsApi) && isEnabledReimbursements">
            <base-learn-card-small
                v-for="resource in learningResourcesReimbursementsApi"
                :key="resource.id"
                :resource="resource"
                :class="activeClass(resource)"
            />
        </template>
    </essential-benefit-page>
</template>

<script setup>
import EssentialBenefitPage from "@/components/benefits-leave-reimbursements/EssentialBenefitPage"
import BaseLearnCardSmall from "@/components/learn-and-explore/BaseLearnCardSmall"
import ReimbursementWizardBlock
    from "@/components/benefits-leave-reimbursements/reimbursements/wizard/ReimbursementWizardBlock"
import {useStore} from "vuex"
import {computed} from "vue"
import {isValidArray} from "@/hooks/general/type-and-value-checks"
import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
import ReimbursementClaimsListCont
    from "@/components/benefits-leave-reimbursements/reimbursements/components/reimbursement-claims-lists/ReimbursementClaimsListCont.vue"
import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
import {getOrdinalNum} from "@/hooks/general/date-helpers"
import NoAllowancesNotice from "@/components/benefits-leave-reimbursements/NoAllowancesNotice.vue"

import {useRoute} from "vue-router"
import NoApproverNotice from "@/components/benefits-leave-reimbursements/NoApproverNotice.vue"
import InfoTooltip from "@/components/UI/v2/misc/InfoTooltip.vue"
import {scrollToHash} from "@/hooks/general/navigation";

const route = useRoute()
const activeClass = (resource) => route.params.resourceSlug === resource.slug ? 'active-card' : ''

const store = useStore()

const learningResourcesReimbursementsApi = computed(() => store.getters.learningResourcesReimbursementsApi)
const reimbursementAllowanceListExclAdHoc = computed(() => store.getters.reimbursementAllowanceListExclAdHoc)
const hasReimbursementClaimApprover = store.getters.hasReimbursementClaimApprover
const employeeReimbursementApprovers = store.getters.employeeReimbursementApprovers
const reimbursementApproversCommaSeparated = store.getters.reimbursementApproversCommaSeparated

const isEnabledReimbursements = store.getters.isEnabledReimbursements
const reimbursementClaimEndDate = computed(() => store.getters.reimbursementClaimEndDate)
const claimsPeriod = computed(() => store.getters.claimsPeriod)
const forPayrollMonth = computed(() => {
    const endDate = new Date(reimbursementClaimEndDate.value)
    const endMonthNumber = endDate.getMonth();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];
    return monthNames[endMonthNumber];
})


const extraInfoTooltip = computed(() => {
    const endDate = new Date(reimbursementClaimEndDate.value)
    const endDay = endDate.getDate();
    const day = getOrdinalNum(endDay);
    return `Claims submitted after ${day} of the month will be reimbursed with the following month's salary.`;
})
</script>

<style lang="scss" scoped>
.wizard-block:last-child {
    margin-bottom: 0 !important;
}
</style>