<template>
    <MDBBtn :color="props.buttonColor" class="shadow-0 font-size-15 font-weight-600 text-center">
        <slot>Button</slot>
    </MDBBtn>
</template>

<script setup>
    import {MDBBtn} from "mdb-vue-ui-kit";
    
    const props = defineProps({
        buttonColor: {
            type: String,
            default: 'light',
        },
        // primary is primary green with white text
        // custom options:
        // default is light - which is the light green
        // 'secondary-light', 'plain-transparent'.
        // otherwise all the options from the docs.
        // 'dark' black and white
        // 'white-bg-green' - white background with green text
        
        // optional classes (fall through)
        // .shadow-hover : just adds a subtle shadow on hover
    })
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    button {
        text-transform: none;
        min-width: 120px;
    }
    
    .btn-sm {
        border-radius: 5px;
    }
    
    .btn-secondary {
        &:hover, &:active, &:focus {
            background-color: darken($secondary, 5%);
        }
    }
    
    .btn-dark {
        background-color: black;
        transition: all .2s ease-in-out;
        border: 1px solid black;
        &:hover:not(.shadow-hover) {
            background-color: rgba(0, 0, 0, 0.72);
        }
    }
    
    .btn-light {
        color: $primary;
        &:hover, &:focus, &.focus {
            color: $primary !important;
        }
    }
    
    .btn-secondary-light {
        background-color: #F5E0EA;
        color: $secondary;
        &:hover {
            color: $secondary;
            background-color: darken(#F5E0EA, 2%);
        }
        &:focus, &.focus  {
            color: $secondary;
        }
    }
    
    .btn-plain-transparent {
        background-color: transparent;
        color: $gray-main;
    }
    
    .btn-white-bg-green {
        color: $primary-600;
        border: 1px solid $primary-600;
        &:hover {
            color: $primary-600;
        }
        &:hover:not(.shadow-hover) {
            color: white;
            background: $primary-600;
        }
        &.shadow-hover:hover {
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2) !important;
        }
    }
</style>