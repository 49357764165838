<template>
    <bento-alert color="info" static dismiss v-model="calendarSync" class="mt-n2 mb-n2 shadow-3" icon-type="info">
        <template #title>Leave Calendar Sync</template>
        
        <template #default>
            <div class="d-flex flex-wrap flex-sm-nowrap">
                <p class="me-5 mb-0">
                    This URL provides a link (.ics) so you can subscribe to your leave calendar via a 3rd party calendar application, such as Google Calendar, Microsoft Outlook, Apple's iCal. <a class="instructions-link bento-underline" href="https://content.mybento.net/media/assets/Importing%20Bento%20Team%20Calendar%20into%20Google%20Calendar.pdf" target="_blank">Google cal instructions</a><a href="https://content.mybento.net/media/assets/Importing%20Bento%20Team%20Calendar%20into%20Google%20Calendar.pdf" target="_blank"><img class="external-link" src="@/assets/v2/icons/other/external-link.svg" alt="external-link"/></a>
                </p>
                <div class="me-3 copy-link-cont">
                    <button-bento button-color="info" @click="copyLink" class="mt-3 mt-sm-0">
                        <span v-if="!showLinkCopiedConfirmation">Copy Link</span>
                        <span v-else>Link Copied</span>
                    </button-bento>
                </div>
            </div>
        </template>
    </bento-alert>
</template>

<script setup>
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
    import {ref, computed} from 'vue'
    import {useStore} from "vuex";
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert";
    const store = useStore()
    
    const calendarSync = ref(true)
    const showLinkCopiedConfirmation = ref(false)
    
    const leaveCalenderUrl = computed(() => store.getters.leaveCalenderUrl)
    
    const copyLink = () => {
        navigator.clipboard.writeText(leaveCalenderUrl.value)
        showLinkCopiedConfirmation.value = true
        setTimeout(() => showLinkCopiedConfirmation.value = false, 3500)
    }
</script>

<style lang="scss" scoped>
    
    .instructions-link {
        &:hover {
            color: darken(#DAEDFA, 85%);
        }
    }
    
    .external-link {
        display: inline-block;
        width: 14px;
        margin-left: 6px;
        position: relative;
        top: -2px;
    }
    
    .copy-link-cont {
        min-width: 132px;
        button {
            width: 132px;
        }
    }
</style>