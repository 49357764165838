// employer module mutations

export default {

    setEmployer(state, payload) {
        state.employer = payload;
    },

    setLastEmployerFetchTimestamp(state, payload) {
        state.lastEmployerFetch = (payload) ? (payload.timestamp) : null
    },

}