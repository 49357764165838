<template>
    <div class="inner-green-block bg-primary-50 px-2 px-sm-3 px-md-5 py-4 bento-border-radius mb-5">
        <div>
            <h3 class="gradient-green-text-dark font-size-18 d-inline-block">{{ new Date().getFullYear() }} Annual Balance Summary</h3>
            <div class="font-size-11 gradient-green-text-light">{{ yearlyBalanceSummaryTooltip }}</div>
        </div>


        <div class="leave-summary-table mt-2 font-size-15 gradient-green-text-dark lh-sm">

            <div class="fw-500 mt-4">
                <span class="bento-underline-dark-green">Balances</span>
            </div>
            <div class="font-size-14">
                <div class="d-flex my-2">
                    <div class="label">Current balance:</div>
                    <div class="value">{{ currentBalance }} days
                        <info-tooltip
                            :tip-content="currentBalanceSummaryTooltip" v-if="currentBalanceSummaryTooltip"
                            class="gradient-green-text-dark d-inline-block ms-3"
                        />
                    </div>
                </div>
                <div class="d-flex my-2">
                    <div class="label">Leave remaining for {{ new Date().getFullYear() }}:</div>
                    <div class="value">{{ leaveDaysLeftInYear }} days
                        <info-tooltip
                            :tip-content="remainingBalanceSummaryTooltip" v-if="remainingBalanceSummaryTooltip"
                            class="gradient-green-text-dark d-inline-block ms-3"
                        />
                    </div>
                </div>
                <div class="font-size-11 gradient-green-text-light">* Balances also include any leave carried over from last year.</div>
            </div>

            <div class="fw-500 mt-4">
                <span class="bento-underline-dark-green">{{ new Date().getFullYear() }} Leave Accrual</span>
            </div>
            <div class="font-size-14">
                <div class="d-flex my-2">
                    <div class="label">Leave accrual / year:</div>
                    <div class="value">{{ annualAccrual }} days
                        <span class="font-size-11">({{ monthlyAccrual }}/month)</span></div>
                </div>
                <div class="d-flex my-2" v-if="isEmployementStartDateThisYear">
                    <div class="label">Pro rata days:</div>
                    <div class="value">{{ proRataAnnualAccrual }} days.
                        <span class="font-size-11">(Employment date: {{ dateOfEmployment }})</span></div>
                </div>
                <div class="d-flex my-2">
                    <div class="label">Leave accrued to date:</div>
                    <div class="value">{{ leaveAccruedToDateThisYear }} days</div>
                </div>
                <div class="d-flex my-2">
                    <div class="label">Remaining leave to accrue:</div>
                    <div class="value">{{ remainingDaysToAccrueThisYear }} days</div>
                </div>
            </div>


            <div class="fw-500 mt-4">
                <span class="bento-underline-dark-green">{{ new Date().getFullYear() }} Approved Requests</span>
            </div>
            <div class="font-size-14">
                <div class="d-flex my-2">
                    <div class="label">Total leave approved:</div>
                    <div class="value">{{ totalApprovedDaysThisYear }} days</div>
                </div>
                <div class="d-flex my-2">
                    <div class="label">Future approved days:</div>
                    <div class="value">{{ totalApprovedDaysThisYearFuture }} days</div>
                </div>
            </div>
            <div class="font-size-11 gradient-green-text-light fw-500 fst-italic mt-3">*Figures may vary based on your employment start date, the accrual rate, rounding and any leave carried over.</div>

            <div class="font-size-11 gradient-green-text-light mt-2">Employment date: {{ longDateFormat(dateOfEmployment) }}</div>
        </div>

        <div class="separator mb-4 mt-3"></div>

        <div class="mt-2">
            <div class="fw-500 ms-1 gradient-green-text-dark font-size-15 hover-primary my-2">
                <a class="cursor-pointer" @click="scrollToHash('leave-requests-table')">View all leave requests -></a>
            </div>
            <div class="fw-500 ms-1 gradient-green-text-dark font-size-15 hover-primary">
                <a class="cursor-pointer" @click="scrollToHash('benefit-resources')">Read more about {{ employerName }}'s leave policies -></a>
            </div>
        </div>

        <div v-if="false">
            <div>annualLeaveObj:
                <pre>{{ annualLeaveObj }}</pre>
            </div>
            <div>monthlyAccrual:
                <pre>{{ monthlyAccrual }}</pre>
            </div>
            <div>annualAccrual:
                <pre>{{ annualAccrual }}</pre>
            </div>
        </div>
    </div>
</template>

<script setup>
import {useStore} from "vuex"
import {computed} from "vue"
import {scrollToHash} from "@/hooks/general/navigation"
import InfoTooltip from "@/components/UI/v2/misc/InfoTooltip.vue"
import {longDateFormat} from "@/hooks/general/date-helpers"

const store = useStore()

const employerName = store.getters.employerName
const leaveBalancesSorted = store.getters.leaveBalancesSorted
const leaveRequestList = computed(() => store.getters.leaveRequestList)
const dateOfEmployment = store.getters.employee.date_of_employment

const isEmployementStartDateThisYear = computed(() => new Date(dateOfEmployment).getFullYear() === new Date().getFullYear())

const annualLeaveObj = computed(() => leaveBalancesSorted.find(leaveObj => leaveObj.name.toLowerCase().includes('annual')))
const currentBalance = computed(() => Number(annualLeaveObj.value?.balance) || 0)
const monthlyAccrual = computed(() => Number(annualLeaveObj.value?.accrual || 0))
const annualAccrual = computed(() => Math.round(monthlyAccrual.value * 12))

const proRataAnnualAccrual = computed(() => {
    const currentYear = new Date().getFullYear();
    const employmentDate = new Date(dateOfEmployment);
    const employmentYear = employmentDate.getFullYear();

    if (employmentYear === currentYear) {
        const monthsWorked = 12 - employmentDate.getMonth()
        const monthlyAccrual = annualAccrual.value / 12
        return Math.round(monthlyAccrual * monthsWorked)
    } else {
        return annualAccrual.value
    }
})

const leaveAccruedToDateThisYear = computed(() => {
    const currentDate = new Date()
    const startOfYear = new Date(currentDate.getFullYear(), 0, 1)
    const daysPassed = (currentDate - startOfYear) / (1000 * 60 * 60 * 24)
    const dailyAccrual = proRataAnnualAccrual.value / 365
    return (dailyAccrual * daysPassed).toFixed(2)
})

const remainingDaysToAccrueThisYear = computed(() => (proRataAnnualAccrual.value - leaveAccruedToDateThisYear.value).toFixed(2))


const leaveRequestsThisYear = computed(() => {
    const currentYear = new Date().getFullYear()
    return leaveRequestList.value.filter(request => {
        const startDate = new Date(request.start_date)
        return startDate.getFullYear() === currentYear
    })
})

const approvedLeaveRequestsThisYear = computed(() => {
    return leaveRequestsThisYear.value.filter(request => request.state === 'approved')
})

const totalApprovedDaysThisYear = computed(() => {
    return approvedLeaveRequestsThisYear.value.reduce((acc, request) => {
        return acc + request.days
    }, 0)
})

const totalApprovedDaysThisYearFuture = computed(() => {
    const currentDate = new Date()
    const futureApprovedRequests = approvedLeaveRequestsThisYear.value.filter(request => new Date(request.start_date) > currentDate)
    return futureApprovedRequests.reduce((acc, request) => acc + request.days, 0)
})

const leaveDaysLeftInYear = computed(() => {
    return (currentBalance.value + Number(remainingDaysToAccrueThisYear.value) - totalApprovedDaysThisYearFuture.value).toFixed(2)
})


const yearlyBalanceSummaryTooltip = computed(() => {
    return `You get ${annualAccrual.value} days of paid leave per year. You have made ${leaveRequestsThisYear.value.length} leave requests this year, ${approvedLeaveRequestsThisYear.value.length} of which have been approved, for a total of ${totalApprovedDaysThisYear.value} approved days. You have ${leaveDaysLeftInYear.value} days of leave left for the current year.`
})

const currentBalanceSummaryTooltip = computed(() => {
    return `<p>Your leave balance today. This does not include leave still to be accrued for the rest of the year and any future approved leave requests. I.e. this balance only decreases when you actually take the leave.</p>`
})

const remainingBalanceSummaryTooltip = computed(() => {
    return `<p>This is calculated using your current Balance + how many more days you will accrue this year - any future approved leave requests</p>`
})
</script>

<style lang="scss" scoped>
@import "@/styles/global-scss/variables-and-mixins.scss";
.leave-summary-table {
    .label {
        flex: 0 0 210px;
        font-weight: 400;
    }
    .value {
        font-weight: 500;
    }
}
</style>