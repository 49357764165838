<template>
    <footer class="pb-1">
        <div class="content d-flex flex-nowrap justify-content-center align-items-center m-auto pb-sm-0">
            <div class="image-col">
                <img src="@/assets/v2/illustrations/footer-cactus.svg" alt="Help Cactus"/>
            </div>
            <div class="text-col flex-grow-1">
                <div class="big-text">
                    <h4 class="mb-1">Need help?</h4>
                </div>
                <div class="small-text">
                    Ask us any questions &amp; get the help you need. <a href="mailto:hello@mybento.net" class="text-primary"><strong>Contact Us</strong></a>
                </div>
            </div>
        </div>
    </footer>
</template>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    footer {
        margin-top: 50px;
        position: relative;
    }
    
    .content {
        width: 340px;
        max-width: 100%;
    }
    
    .image-col {
        flex: 0 0 92px;
        margin-right: 18px;
    }
    
    .small-text {
        @include media-breakpoint-up(sm) {
            font-size: 15px;
        }
        a:hover {
            color: darken($primary, 10%) !important;
        }
    }
</style>