<template>
    <card-general>
        <div class="font-weight-500 text-gray-main">A summary of all your leave requests.</div>
        <div id="summary-calendar-container">
            <v-calendar
                is-expanded
                nav-visibility="hidden"
                :attributes="attributesComputed"
                transition="slide-h"
                ref="calendar"
                :columns="$screens({ default: 1, md: 2, lg: 3})"
                :step="1"
                :rows="1"
                @transition-start="calendarTransitionStart"
                :from-page="fromPage"
                :to-page="toPage"
                :key="calendarKey"
            />
        </div>
        <div class="calendar-navigation d-flex justify-content-center align-items-center mt-0">
            <div @click="prevMonth" class="nav-prev">
                <span><img src="@/assets/v1/icons/chevron-left.svg" alt="next"> Previous</span>
            </div>
            <div @click="setFromToPage" class="nav-current">
                <span>Current</span>
            </div>
            <div @click="nextMonth" class="nav-next">
                <span>Next <img src="@/assets/v1/icons/chevron-right.svg" alt="next"></span>
            </div>
        </div>

        <div class="separator mt-5"></div>

        <div class="mt-3 gradient-green-text-dark font-weight-500 font-size-15">
            <div class="mt-1">
                <span>Approver<span v-if="employeeLeaveApprovers.length > 1">s</span>:</span> {{ leaveApproversCommaSeparated }}
            </div>
            <div class="fw-500 mt-3 gradient-green-text-dark font-size-15">
                <a class="cursor-pointer bento-underline-primary-hover hover-primary" @click="scrollToHash('leave-requests-table')">View all leave requests</a> ->
            </div>
        </div>
    </card-general>
</template>

<script setup>
import publicHolidays from "@/data/public-holidays/public-holidays"
import {ref, computed} from "vue"
import {useStore} from "vuex"
import {isValidArray} from "@/hooks/general/type-and-value-checks"
import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
import {scrollToHash} from "@/hooks/general/navigation";

const store = useStore()

const calendarKey = ref(1)
const leaveRequestListData = ref(null)
const fromPage = ref(undefined)
const toPage = ref(undefined)

const leaveRequestList = computed(() => store.getters.leaveRequestList)
const employeeLeaveApprovers = computed(() => store.getters.employeeLeaveApprovers)

// prepare the array of attributes -> https://vcalendar.io/attributes.html
const attributesComputed = computed(() => {

    const submittedAndApproved = leaveRequestsForThisTypeAllDates.value.filter(
        request => request.state !== 'cancelled' && request.state !== 'declined' && request.state !== 'error'
    );

    const attributes = submittedAndApproved.map(
        request => ({
            dates: {
                start: new Date(request.start_date),
                end: new Date(request.end_date),
            },
            highlight: 'teal',
        })
    );

    // today
    attributes.unshift({
        key: 'today',
        bar: {
            color: 'yellow',
            style: {
                height: '4px',
                width: '65%',
            },
        },
        dates: new Date(),
    });

    // this year and next work annirversary
    const dateOfEmployment = store.getters.employee.date_of_employment
    const givenDate = new Date(dateOfEmployment)
    const month = givenDate.getMonth()  // JavaScript months are 0-based
    const day = givenDate.getDate()

    const currentYear = new Date().getFullYear()
    const nextYear = currentYear + 1

    const sameDayThisYear = new Date(currentYear, month, day)
    const sameDayNextYear = new Date(nextYear, month, day)
    const dates = [sameDayThisYear, sameDayNextYear]
    dates.forEach(date => {
        attributes.unshift({
            key: "anniversary",
            highlight: "pink",
            dates: date,
            popover: {
                label: 'Work Anniversary'
            }
        })
    })

    attributes.push(...publicHolidays)

    return attributes
})

const leaveApproversCommaSeparated = store.getters.leaveApproversCommaSeparated

const leaveRequestsForThisTypeAllDates = computed(() => {
    const unsorted = leaveRequestList.value
    if (!isValidArray(unsorted)) {
        return []
    }
    const sortedByDate = unsorted.sort(function (a, b) {
        return new Date(a.start_date) - new Date(b.start_date)
    })
    return sortedByDate
})

const setFromToPage = () => {
    // weirdly the month follows the convention of Jan -> 1, Feb -> 2.. as opposed to JS Date() where Jan -> 0
    const currentMonth = new Date().getMonth() + 1;
    const currentYear = new Date().getFullYear();

    let prevMonth = currentMonth - 1;
    let prevYear = currentYear;
    if (currentMonth === 1) {
        prevMonth = 12;
        prevYear--;
    }
    fromPage.value = {month: prevMonth, year: prevYear};

    let nextMonth = currentMonth + 1;
    let nextYear = currentYear;
    if (currentMonth === 12) {
        nextMonth = 1;
        nextYear++;
    }
    toPage.value = {month: nextMonth, year: nextYear};
}

const prevMonth = () => {
    // from becomes
    const fromCurrentMonth = fromPage.value.month;
    const fromCurrentYear = fromPage.value.year;
    let fromUpdatedMonth = fromCurrentMonth - 1;
    let fromUpdatedYear = fromCurrentYear;
    if (fromCurrentMonth === 1) {
        fromUpdatedMonth = 12;
        fromUpdatedYear--;
    }
    fromPage.value = {month: fromUpdatedMonth, year: fromUpdatedYear};

    // to becomes
    const toCurrentMonth = toPage.value.month;
    const toCurrentYear = toPage.value.year;
    let toUpdatedMonth = toCurrentMonth - 1;
    let toUpdatedYear = toCurrentYear;
    if (toCurrentMonth === 1) {
        toUpdatedMonth = 12;
        toUpdatedYear--;
    }
    toPage.value = {month: toUpdatedMonth, year: toUpdatedYear};

}

const nextMonth = () => {
    // from becomes
    const fromCurrentMonth = fromPage.value.month;
    const fromCurrentYear = fromPage.value.year;
    let fromUpdatedMonth = fromCurrentMonth + 1;
    let fromUpdatedYear = fromCurrentYear;
    if (fromCurrentMonth === 12) {
        fromUpdatedMonth = 1;
        fromUpdatedYear++;
    }
    fromPage.value = {month: fromUpdatedMonth, year: fromUpdatedYear};

    // to becomes
    const toCurrentMonth = toPage.value.month
    const toCurrentYear = toPage.value.year
    let toUpdatedMonth = toCurrentMonth + 1
    let toUpdatedYear = toCurrentYear
    if (toCurrentMonth === 12) {
        toUpdatedMonth = 1
        toUpdatedYear++
    }
    toPage.value = {month: toUpdatedMonth, year: toUpdatedYear}
}

const calendarTransitionStart = () => {
    // this is required to rerender the calendar
    calendarKey.value++
}

leaveRequestListData.value = store.getters.leaveRequestList;
setFromToPage();
</script>

<style lang="scss" scoped>
@import "@/styles/global-scss/variables-and-mixins.scss";

:deep(#summary-calendar-container) {
    margin-left: -15px;
    margin-right: -15px;

    .vc-arrows-container {
        display: none;
    }
    .vc-container {
        border: none;
        background: transparent;
    }
    .vc-title {
        font-weight: 500;
        font-size: 16px;
    }
    .vc-pane-layout {
        background-color: transparent;
    }
    .vc-pane {
        background-color: white;
        margin: 7px;
    }
    .vc-highlight:not(.vc-highlight-base-middle):not(.vc-highlight-base-end) {
        position: relative;
        left: .5px;
    }
}

.calendar-navigation {

    > div {
        flex: 1 auto;
        font-weight: 600;
        font-size: 15px;

        > span {
            cursor: pointer;
            display: inline-block;
            color: $primary;
            &:hover {
                color: darken($primary, 10%);
            }
        }

        img {
            display: inline-block;
            width: 24px;
            position: relative;
        }
    }
    .nav-prev {
        max-width: 120px;
        text-align: left;
        img {
            left: 2px;
        }
    }
    .nav-current {
        text-align: center;
    }
    .nav-next {
        max-width: 120px;
        text-align: right;
        img {
            left: -2px;
        }
    }
}

header {
    padding: 25px 32px 25px 15px;
    transition: all .15s ease-in-out;
    @include media-breakpoint-up(sm) {
        padding: 25px 20px;
    }
    @include media-breakpoint-up(md) {
        padding: 25px;
    }
    &.expanded {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
}

.block-expanded-content {
    padding: 25px 15px;
    line-height: 1.8;
    @include media-breakpoint-up(sm) {
        padding: 25px 20px;
    }
    @include media-breakpoint-up(md) {
        padding: 25px;
    }
}
</style>