<template>
    <summary-card :classList="classList" view-more-link="benefits/insurance" view-more-text="View more">
        <template #title>Insurance & Risk</template>
        <template #subHeading></template>
        
        <template #cardText>
            <div v-html="cardText"></div>
        </template>
    </summary-card>
</template>

<script setup>
    import SummaryCard from "@/components/cards/SummaryCard"
    import {useStore} from "vuex"
    import useInfoCard from "@/hooks/use-component/cards/info-card"
    
    const store = useStore()
    
    const benefit = store.getters.essentialBenefits.insurance
    const {classList} = useInfoCard(benefit)
    
    const cardText = 'Read more about insurance and risk'
</script>


