<template>
    <card-general class="mt-5 mb-4">
        <div class="text-center py-3">
            <div class="mb-3">
                <img src="@/assets/v2/illustrations/footer-help-at-hand.svg" alt="Help at hand"/>
            </div>
            <page-header>Help is always at hand</page-header>
            <div>Need help or input on anything?</div>
            <div class="mt-4 pt-2">
                <button-bento button-color="dark" @click="emailUsHandler">Email us</button-bento>
            </div>
        </div>
    </card-general>
</template>

<script setup>
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento.vue"
    import PageHeader from "@/components/UI/v2/containers-cards-headers/PageHeader.vue"
    
    const emailUsHandler = () => {
        const email = 'hello@mybento.net'
        window.location.href = `mailto:${email}`
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";


</style>
