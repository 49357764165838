import store from "@/store";

export function adminViewOnly() {
    const isUserAdmin = store.getters.isUserAdmin
    const isViewingAsOther = store.getters.isViewingAsOther
    const isUserBento = store.getters.isUserBento
    if ((isUserAdmin || isViewingAsOther) && isUserBento) {
        return true;
    } else {
        return '/';
    }
}