<template>
    <div class="figure">
        
        <div class="label">{{ itemApi.name }}:</div>
        
        <div class="value">
            
            <div class="api">
                <span v-if="( (itemApi.amount !== 0) && (itemApi.api === false) )" class="no-value-note">
                    No API Value.<br>
                </span>
                <span v-else-if="itemApi.amount != null">
                    {{ bentoCurrencyFormat(itemApi.amount) }}
                </span>
            </div>
            
            <div class="current">
                <span class="no-value-note">
                    API value only.
                </span>
            </div>
            
            <div class="planned">
                <span class="no-value-note">
                    Does not change.<br>
                    Same as API/Current.
                </span>
            </div>
            
            <div class="note" v-html="note"></div>
            
        </div>
        
    </div>
</template>

<script>
    import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters";
    export default {
        name: "SalaryFiguresItemApiList",

        props: {
            itemApi: {
                required: true,
                type: Object,
            },
            note: {
                required: false,
            }
        },
        
        methods: {
            bentoCurrencyFormat,
        
            differenceCheck(itemApi) {
                if (!itemApi.current) {
                    return false;
                }
                
                // build in a 50 cents difference
                if (Math.abs(Number(itemApi.api) - Number(itemApi.current)) > 0.5) {
                    return 'different';
                }
                return 'same';
            }
            
        },
        
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";

    .figure {
        font-size: 13px;
        display: flex;
        flex-wrap: nowrap;
        padding: 10px 0;
        border-bottom: 1px solid #efefef;
    }

    .label {
        flex: 0 0 320px;
        padding-right: 40px;
        display: flex;
        align-items: center;
    }

    .value {
        flex: 1 auto;
        display: flex;
        flex-wrap: nowrap;
        position: relative;

        > div {
            flex: 0 0 124px;
            color: black;
            margin-right: 20px;
            display: flex;
            align-items: center;

            &.note {
                flex: 1 auto;
                font-size: 11px;
                font-weight: 500;
                margin-top: 3px;
            }

        }
        
    }

    strong {
        font-weight: 500;
    }

    /*Data Source Block*/
    span.source {
        display: inline-block;
        margin-left: 15px;
        background-color: #335160;
        color: #fff;
        font-size: 11px;
        padding: 4px 6px;
        font-weight: 700;
        border-radius: 1px;

        &.source-calc {
            background-color: $primary;
        }

        &.source-planned {
            background-color: $primary-light;
        }
    }

    span.no-value-note {
        font-size: 10px;
        text-align: left;
        width: 110px;
        line-height: 1.5;
        
        &.required-from-bento-api {
            font-weight: 700;
            color: red;
        }
    }
    
    .api-diff {
    
    }
    .api-diff-same {
        img {
            display: inline-block;
            width: 18px;
            position: absolute;
            left: -30px;
        }
    }

</style>