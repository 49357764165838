<template>
    <card-general-inner class="green no-shadow gradient-green-text-dark" :id="fieldId">
        <div class="gradient-green-text-dark font-weight-500 font-size-18 d-flex justify-content-between">
            <div>{{ title }}</div>
            <info-tooltip :tip-content="extraInfoTooltip" v-if="extraInfoTooltip"/>
        </div>
        <div class="mt-1 justify-content-between">
            <div class="flex-grow-1 me-3 me-lg-5 field-description font-size-13 lh-sm">
                {{ fieldDescription }}
            </div>
            <div class="mt-3">
                <MDBRange v-model="userInput" :min="minValue" :max="maxValue" :step="step"/>
                <div class="d-flex justify-content-between font-size-13 fw-500 min-max-labels">
                    <div>{{ minValue }}<span v-if="isPercentage">%</span><span v-else>yrs</span></div>
                    <div>{{ maxValue }}<span v-if="isPercentage">%</span><span v-else>yrs</span></div>
                </div>
            </div>
        </div>
    </card-general-inner>
</template>

<script setup>
    import CardGeneralInner from "@/components/UI/v2/containers-cards-headers/CardGeneralInner.vue"
    import {computed} from "vue"
    import {MDBRange} from "mdb-vue-ui-kit"
    import InfoTooltip from "@/components/UI/v2/misc/InfoTooltip.vue"
    
    const emit = defineEmits(['update:modelValue'])
    
    const props = defineProps({
        fieldId: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
        fieldDescription: {
            type: String,
            required: true,
        },
        inputValue: {
            type: Number,
            required: true,
        },
        minValue: {
            type: Number,
            required: false,
            default: 0
        },
        ageField: {
            type: Boolean,
            required: false,
            default: false,
        },
        maxValue: {
            type: Number,
            required: false,
            default: 100
        },
        step: {
            type: Number,
            required: false,
            default: 1
        },
        retirementAge: {
            type: Number,
            required: false,
            default: 65
        },
        deathAge: {
            type: Number,
            required: false,
            default: 90
        },
        isPercentage: {
            type: Boolean,
            required: false,
            default: false,
        }
    })
    
    const userInput = computed({
        get: () => props.inputValue,
        set: (value) => {
            
            // min value validator
            if (value === '' || value === undefined || value < props.minValue) {
                value = Number(props.minValue)
            }
            
            // max value validator
            if (value > props.maxValue) {
                value = props.maxValue
            }
            
            // target age validator
            if (props.fieldId && props.fieldId === 'death-age') {
                if (value < props.retirementAge) {
                    value = props.retirementAge
                }
            }
            
            emit('update:modelValue', value)
        }
    })
    
    const extraInfoTooltip = computed(() => {
        if (props.fieldId === 'percentage-required') {
            return `<p class="fw-600">The percentage of your current income you will need in retirement.</p>
            <p class="fw-500">We have set your required retirement income to 75% of your current salary, based on the assumption that you'll have fewer expenses, like no more commuting, lower household or work related expenses, or loan payments for example. However, this is a general rule and may not suit everyone. Your needs could be higher if you have specific retirement goals, or lower if you have additional income streams</p>`
        }
        if (props.fieldId === 'net-return-expected') {
            return `<p class="fw-600">Refers to your returns after fees that are payable to fund administrators or financial advisors.</p>
                    <p class="fw-500">We have set this to 9%. This is based on the following assumptions:  average inflation rate of 6% plus an expected additional 3% return over and above inflation. If your retirement savings grow at inflationary rate or lower, your savings actually lose value. This means that you want to have an investment portfolio that helps you achieve an above-inflationary return. A commonly used benchmark is to aim for a real (inflation-adjusted) return of around 3% (conservative) to 6% (aggressive) annually.</p>`
        }
        if (props.fieldId === 'retirement-age') {
            return `<p class="fw-600">The age at which you plan to retire. The default is set at 65, but this field is adjustable to better align with your personal goals.</p>
                    <p class="fw-500">Retiring earlier means you'll need more savings to last through your retirement years, while pushing back your retirement age could allow for more time to accumulate savings.</p>`
        }
        if (props.fieldId === 'death-age') {
            return `<p class="fw-600">Life expectancy is the age until which you'll need your retirement savings to last. The default is set at 90 years, but you can adjust this based on your own expectations or family history.</p>
                    <p class="fw-500">The longer the time between your retirement age and life expectancy, the more savings you'll need to sustain your lifestyle.</p>`
        }
        return null
    })
    
</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .age-block {
        background-color: white;
        text-align: center;
        padding: 2px 10px 1px 8px;
        border-radius: 6px;
        border: 1px solid $gradient-green-text-dark;
    }
    
    .field-description {
        position: relative;
        top: -4px;
        min-height: 46px;
        @media (max-width: 361px) {
            margin-bottom: 29px;
        }
    }
    
    :deep(.range) {
        .thumb {
            transform: scale(1) !important;
        }
    }
    
    :deep(div.range) {
        input[type=range] {
            position: relative;
            z-index: 100;
            padding-bottom: 10px;
            height: 40px;
        }
        input[type=range]::-webkit-slider-thumb {
            width: 24px;
            height: 24px;
            position: relative;
            top: -2px;
        }
        input[type=range]::-moz-range-thumb {
            width: 24px;
            height: 24px;
            position: relative;
            top: -2px;
        }
    }
    
    .min-max-labels {
        margin-top: -15px;
    }
</style>