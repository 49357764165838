// medical aid addon mutations
export default {

    setMedicalAidPlanAddonList(state, payload) {
        state.medicalAidPlanAddonList = payload;
    },

    setLastMedicalAidPlanAddonListFetch(state, payload) {
        state.lastMedicalAidPlanAddonListFetch = payload.timestamp;
    },

    // Addon Vitality Wizard Block
    setTempSelectedAddonPlan(state, planId) {
        state.tempSelectedAddonPlan = planId;
    }

}