<template>
    <width-container>
        <div v-if="theSimpleBenefit">
            <page-header>{{ theSimpleBenefit.category_name }}</page-header>
            <card-general class="green">
                <section-header>{{ theSimpleBenefit.name }}</section-header>

                <div class="content gradient-green-text-dark font-size-15" v-html="theSimpleBenefitContent"></div>

                <learn-grid class="mt-4" v-if="isValidArray(theSimpleBenefit.content_cards)">
                    <base-learn-card-small
                        v-for="resource in theSimpleBenefit.content_cards"
                        :key="resource.id"
                        :resource="resource"
                        :class="activeClass(resource)"
                    />
                </learn-grid>
            </card-general>

            <router-view></router-view>
        </div>

        <div v-else>
            <page-header>Not found</page-header>
            <card-general class="green">
                <section-header>Benefit not available</section-header>

                <div class="content gradient-green-text-dark font-size-15">
                    <p>Your employer has not made this benefit available to you.<br>
                        Please contact your employer for more information.</p>
                    <p>Or contact us at <a href="hello@mybenot.net">hello@mybenot.net</a></p>
                </div>
            </card-general>
        </div>
    </width-container>
</template>

<script setup>
import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer"
import LearnGrid from "@/components/learn-and-explore/LearnGrid"
import BaseLearnCardSmall from "@/components/learn-and-explore/BaseLearnCardSmall"
import {isValidArray} from "@/hooks/general/type-and-value-checks"
import {computed} from "vue"
import {useStore} from "vuex"
import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
import PageHeader from "@/components/UI/v2/containers-cards-headers/PageHeader.vue"

const store = useStore()

import {useRoute} from "vue-router"

const route = useRoute()
const activeClass = (resource) => route.params.resourceSlug === resource.slug ? 'active-card' : ''

const props = defineProps([
    'simpleBenefitId',
    'simpleBenefitCategory',
    'simpleBenefitSlug'
])

const simpleBenefitsList = computed(() => store.getters.simpleBenefitsList)
const theSimpleBenefit = computed(() => simpleBenefitsList.value.find(benefit => benefit.id == props.simpleBenefitId))
const theSimpleBenefitContent = computed(() => (theSimpleBenefit?.value?.content) ? theSimpleBenefit?.value?.content : theSimpleBenefit?.value?.description)
</script>

<style lang="scss" scoped>
.content {
    line-height: 1.6;

    :deep(a) {
        font-weight: 500;

        &:hover {
            text-decoration: underline;
        }
    }

    :deep(ol) {
        font-size: 15px;
    }

    :deep(ul) {
        font-size: 15px;
    }
}
</style>