<template>
    <card-general-inner class="green no-shadow gradient-green-text-dark pb-3" :id="fieldId">
        <div class="gradient-green-text-dark font-weight-500 font-size-18 d-flex justify-content-between">
            <div>{{ title }} <span class="sub-title mt-n2" v-if="subTitle"><small>{{ subTitle }}</small></span></div>
            <info-tooltip :tip-content="extraInfoTooltip" v-if="extraInfoTooltip"/>
        </div>
        <div class="d-flex mt-1 justify-content-between desc-and-input-cont">
            <div class="flex-grow-1 me-3 me-lg-5 field-description font-size-13 lh-sm">
                {{ fieldDescription }}
            </div>
            <div class="position-relative">
                <CurrencyInput
                    class="currency-input-field"
                    v-model="userInput"
                    :options="{
                                    currency: 'ZAR',
                                    locale: 'en-ZA',
                                    currencyDisplay: 'symbol',
                                    hideCurrencySymbolOnFocus: false,
                                    hideGroupingSeparatorOnFocus: false,
                                    valueRange: { min: 0 },
                                    precision: 2
                                }"
                />
            </div>
        </div>
    </card-general-inner>
</template>

<script setup>
import CardGeneralInner from "@/components/UI/v2/containers-cards-headers/CardGeneralInner.vue"
import {computed} from "vue"
import InfoTooltip from "@/components/UI/v2/misc/InfoTooltip.vue"
import CurrencyInput from "@/components/UI/v2/forms/CurrencyInput.vue"

const emit = defineEmits(['update:modelValue'])

const props = defineProps({
    fieldId: {
        type: String,
        required: false,
    },
    title: {
        type: String,
        required: true,
    },
    subTitle: {
        type: String,
        required: false,
        default: null,
    },
    fieldDescription: {
        type: String,
        required: true,
    },
    inputValue: {
        type: Number,
        required: true,
    },
})

const userInput = computed({
    get: () => props.inputValue,
    set: (value) => {
        emit('update:modelValue', value)
    }
})

const extraInfoTooltip = computed(() => {
    if (props.fieldId === 'total-savings-cont') {
        return `<p class="fw-600">Refers to the accumulated sum of all your financial assets and investments aimed at funding your retirement.</p>
            <p class="fw-500">This could include employer-sponsored retirement plans, individual retirement accounts, pension plans, personal investments like stocks or bonds, real estate properties, and other valuable assets. This figure serves as a snapshot of your retirement savings up to this point and is crucial for calculating when you can comfortably retire.</p>`
    }
    if (props.fieldId === 'total-monthly-savings') {
        return `<p class="fw-600">Refers to the total amount you set aside each month for your retirement.</p>
            <p class="fw-500">This includes both your personal contributions and any contributions made by your employer, if applicable.</p>`
    }
    return null
})

</script>

<style scoped lang="scss">
@import "@/styles/global-scss/variables-and-mixins.scss";

.sub-title {
    font-size: 14px;
    font-weight: 400;
}

.desc-and-input-cont {
    @media (max-width: 361px) {
        flex-wrap: wrap;
    }
}

.field-description {
    position: relative;
    top: -4px;
    min-height: 46px;
}

input.currency-input-field {
    width: 160px;
    padding: 5px 8px;
    color: $gradient-green-text-dark;
    font-size: 17px;
    border: 1px solid #bdbdbd;
    border-radius: 5px;
    outline: none !important;
    margin-bottom: 2px;
    font-weight: 600;
    &:focus-visible {
        margin-bottom: 0;
        outline: none !important;
        border: 2px solid $gradient-green-text-dark;
    }
}
</style>