<template>
    <div class="summary-stat text-gray-500 font-dm-sans font-weight-700" :class="[{'change-accent': highlightChanges}, stat.id]">
        <div class="stat-label">
            {{ stat.label }}
        </div>
        <div class="stat-value">
            <span v-if="displayCurrentStat" class="text-black font-weight-500 current-stat-comparison">{{ bentoCurrencyFormat(displayCurrentStat) }} <img src="@/assets/v2/icons/arrows/arrow-black.svg" class="d-inline-block mx-1"/></span> <span>{{ bentoCurrencyFormat(displayStatType(stat)) }}</span>
        </div>
        <div class="d-none"><pre>{{ stat }}</pre></div>
    </div>
</template>

<script setup>
    import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters";
    import {computed} from "vue";
    
    const props = defineProps({
        stat: {
            type: Object,
            required: true,
        },
        statType: {
            type: String,
        },
    })
    
    const highlightChanges = computed(() => {
        if (props.statType === 'planned') {
            if (props.stat.id === 'reimbursive-allowances-budget-ex-ad-hoc') { return false }
            else if (
                props.stat.id === 'total-package' ||
                props.stat.id === 'total-employer-contributions-ex-sdl-uif' ||
                props.stat.id === 'gross-remuneration'
            ) {
                if (props.stat.current !== props.stat.planned) { return true }
            }
            else {
                if (props.stat.api !== props.stat.planned) { return true }
            }
        }
        return false
    })
    
    const displayStatType = (stat) => {
        // only api value
        if (stat.id === 'reimbursive-allowances-budget-ex-ad-hoc') {
            return stat.api;
        }
        else if (props.statType === 'planned') {
            return props.stat.planned;
        }
        // current calc values only hence don't use .api value
        else if (
            stat.id === 'total-package' ||
            stat.id === 'total-employer-contributions-ex-sdl-uif' ||
            stat.id === 'gross-remuneration'
        ) {
            return stat.current;
        } else {
            return props.stat.api;
        }
    }
    
    const displayCurrentStat = computed(() => {
        if (props.statType === 'planned') {
            if (props.stat.id === 'reimbursive-allowances-budget-ex-ad-hoc') {
                return null;
            }
            else if (
                props.stat.id === 'total-package' ||
                props.stat.id === 'total-employer-contributions-ex-sdl-uif' ||
                props.stat.id === 'gross-remuneration'
            ) {
                if (props.stat.current !== props.stat.planned) {
                    return props.stat.current;
                }
            }
            else {
                if (props.stat.api !== props.stat.planned) {
                    return props.stat.api;
                }
            }
        }
        return null
    })

</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .summary-stat {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        font-size: 1.2rem;
        line-height: 2;
        &.change-accent {
            color: #B03B73;
        }
        &.total-package {
            margin-bottom: 15px;
        }
        &.gross-remuneration {
            margin-bottom: 15px;
        }
        &.salary-before-deductions-api {
            margin-top: 15px;
            margin-bottom: 15px;
        }
        &.nett-pay-api {
            color: black;
            margin-top: 16px;
            font-size: 1.3rem;
        }
        &.nett-pay-calc {
            color: #642141;
            margin-top: 16px;
            font-size: 1.3rem;
        }
        .current-stat-comparison {
            font-size: 13px;
            img {
                transform: scaleX(-1);
                width: 12px;
                position: relative;
                top: -2px;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }
    
    .stat-label {
        padding-right: 20px;
    }

</style>