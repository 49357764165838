<template>
    <wizard-block>
        <template #title>Monthly Payments</template>
        <template #sub-title>{{ bentoCurrencyFormat(subTitle) }} pm (Current)</template>
        
        <template #expanded-content>
            <div class="monthly-payments-table">
                <wizard-block-monthly-payments-line-item :item="headerRow" :isHeader="true"/>
                <wizard-block-monthly-payments-line-item :item="planName"/>
                <wizard-block-monthly-payments-line-item :item="mainMember"/>
                <wizard-block-monthly-payments-line-item :item="adultDependantsCost"/>
                <wizard-block-monthly-payments-line-item :item="childDependantsCost"/>
                <wizard-block-monthly-payments-line-item :item="totalMonthlyCost" :isHeader="true"/>
                <wizard-block-monthly-payments-line-item :item="employerContribution"/>
                <wizard-block-monthly-payments-line-item :item="employeeContribution" :isLarge="true"/>
                <wizard-block-monthly-payments-line-item :item="taxCredit" v-if="hasPayslips"/>
            </div>
            <div class="payroll-changes-submitted" v-if="areMedicalAidChangesSubmitted">
                    Selected plan has been submitted for approval.
            </div>
            <div v-else>
                <div v-if="areMedicalAidDraftChanges && hasMedicalAidContractPlanned" class="clear-button-container">
                    <div class="proceed-changes-container">
                        <button-bento button-color="dark" @click="proceedWithChangesHandler">
                            Proceed with changes
                        </button-bento>
                    </div>
                </div>
                <div v-else-if="hasMedicalAidContractPlanned">
                    <div class="no-change-in-plan">
                        * Your selected medical aid plan is the same as your current plan.
                    </div>
                </div>
            </div>
        </template>
    </wizard-block>
</template>


<script>
    import {mapGetters} from "vuex"
    import WizardBlock from "@/components/benefits-leave-reimbursements/medical-aid/wizard/WizardBlock"
    import WizardBlockMonthlyPaymentsLineItem
        from "@/components/benefits-leave-reimbursements/medical-aid/wizard/WizardBlockMonthlyPaymentsLineItem"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
    import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"
    import {isValidArray} from "@/hooks/general/type-and-value-checks"
    
    export default {
        name: "WizardBlockMonthlyPayments",
        components: {ButtonBento, WizardBlockMonthlyPaymentsLineItem, WizardBlock},
        
        methods: {
            bentoCurrencyFormat,
            proceedWithChangesHandler() {
                this.$store.commit('toggleShowRemDraftModalMedical', true);
            },
        },
        
        computed: {
            
            ...mapGetters([
                'medicalAidContractObj',
                'medicalAidContractObjPlanned',
                'hasMedicalAidContract',
                'hasMedicalAidContractPlanned',
                'medicalAidPlanList',
                'medicalAidTaxCredit',
                'areMedicalAidDraftChanges',
                'areMedicalAidChangesSubmitted',
                'payslipsList'
            ]),

            hasPayslips() {
                return isValidArray(this.payslipsList)
            },
            
            currentMedicalAidPlanId() {
                return (this.hasMedicalAidContract) ? Number(this.medicalAidContractObj.plan_id) : null;
            },

            plannedMedicalAidPlanId() {
                if (this.medicalAidContractObjPlanned === null) {
                    return null;
                } else {
                    return Number(this.medicalAidContractObjPlanned.plan_id)
                }
            },
            
            currentMedicalAidPlan() {
                if (this.hasMedicalAidContract) {
                    return this.medicalAidPlanList.find(plan => Number(plan.id) === this.currentMedicalAidPlanId);
                } else {
                    return null;
                }
            },
            plannedMedicalAidPlan() {
                const plannedMedicalAidPlan = this.medicalAidPlanList.find(plan => Number(plan.id) === this.plannedMedicalAidPlanId );
                if (plannedMedicalAidPlan === undefined || plannedMedicalAidPlan === null) {
                    return false;
                }
                return plannedMedicalAidPlan;
            },
            
            subTitle() {
                // total medical aid cost
                if (this.hasMedicalAidContract) {
                    return Number(this.medicalAidContractObj.employer_contribution) + Number(this.medicalAidContractObj.employee_contribution);
                } else {
                    return null;
                }
            },
            
            /*Table Values*/
            headerRow() {
                return {
                    label: 'Breakdown of monthly payments',
                    value: 'Current Plan',
                    value_planned: 'Selected Plan',
                }
            },
            
            planName() {
                const current = (this.hasMedicalAidContract) ? this.currentMedicalAidPlan.name : 'No current plan';
                const planned = (this.hasMedicalAidContractPlanned) ? this.plannedMedicalAidPlan.name : null;
                return {
                    id: 'plan-name',
                    label: 'Discovery Plan Name',
                    value: current,
                    value_planned: planned,
                }
            },
            
            mainMember() {
                const current = (this.hasMedicalAidContract) ? Number(this.currentMedicalAidPlan.main_member_cost) : null;
                const planned = (this.hasMedicalAidContractPlanned) ? Number(this.plannedMedicalAidPlan.main_member_cost): null;
                return {
                    dontShowCurrent: false,
                    label: 'Main Member',
                    value: current,
                    value_planned: planned,
                }
            },
            
            adultDependantsCost() {
                let dependants = 0;
                let currentAdultDependantsCost = 0;
                if (this.hasMedicalAidContract) {
                    dependants = Number(this.medicalAidContractObj.adult_dependents)
                    currentAdultDependantsCost = dependants * Number(this.currentMedicalAidPlan.adult_dependent_cost);
                }
                
                let dependantsPlanned = 0;
                let plannedAdultDependantsCost = 0;
                if (this.hasMedicalAidContractPlanned) {
                    dependantsPlanned = this.medicalAidContractObjPlanned.adult_dependents;
                    plannedAdultDependantsCost = dependantsPlanned * Number(this.plannedMedicalAidPlan.adult_dependent_cost);
                }
                
                return {
                    dontShowCurrent: false,
                    label: `Adult Dependants <small>(Current: ${dependants}, Selected: ${dependantsPlanned})<small>`,
                    value: currentAdultDependantsCost,
                    value_planned: plannedAdultDependantsCost,
                }
            },
    
            childDependantsCost() {
                let dependants = 0;
                let currentChildDependantsCost = 0;
                if (this.hasMedicalAidContract) {
                    dependants = Number(this.medicalAidContractObj.child_dependents)
                    currentChildDependantsCost = dependants * Number(this.currentMedicalAidPlan.child_dependent_cost);
                }
                
                let dependantsPlanned = 0;
                let plannedChildDependantsCost = 0;
                if (this.hasMedicalAidContractPlanned) {
                    dependantsPlanned = Number(this.medicalAidContractObjPlanned.child_dependents)
                    plannedChildDependantsCost = dependantsPlanned * Number(this.plannedMedicalAidPlan.child_dependent_cost);
                }
                
                return {
                    dontShowCurrent: false,
                    label: `Child Dependants <small>(Current: ${dependants}, Selected: ${dependantsPlanned})<small>`,
                    value: Number(currentChildDependantsCost),
                    value_planned: Number(plannedChildDependantsCost),
                }
            },
            
            totalMonthlyCost() {
                let currentValue = 0;
                if (this.hasMedicalAidContract) {
                    currentValue = Number(this.medicalAidContractObj.employee_contribution) + Number(this.medicalAidContractObj.employer_contribution)
                }
                let plannedValue = 0;
                if (this.hasMedicalAidContractPlanned) {
                    plannedValue = Number(this.medicalAidContractObjPlanned.employee_contribution) + Number(this.medicalAidContractObjPlanned.employer_contribution);
                }
                return {
                    label: 'Total Monthly Cost',
                    value: currentValue,
                    value_planned: plannedValue,
                }
            },
            
            employerContribution() {
                let currentValue = 0;
                if (this.hasMedicalAidContract) {
                    currentValue = Number(this.medicalAidContractObj.employer_contribution);
                }
                let plannedValue = 0;
                if (this.hasMedicalAidContractPlanned) {
                    plannedValue = Number(this.medicalAidContractObjPlanned.employer_contribution);
                }
                return {
                    label: 'Employer Contribution',
                    value: currentValue,
                    value_planned: plannedValue,
                }
            },
            
            employeeContribution() {
                let currentValue = 0;
                if (this.hasMedicalAidContract) {
                    currentValue = Number(this.medicalAidContractObj.employee_contribution);
                }
                let plannedValue = 0;
                if (this.hasMedicalAidContractPlanned) {
                    plannedValue = Number(this.medicalAidContractObjPlanned.employee_contribution);
                }
                return {
                    label: 'Employee Contribution',
                    value: currentValue,
                    value_planned: plannedValue,
                }
            },
            
            taxCredit() {
                return {
                    id: 'tax-credit',
                    label: 'Tax Credit',
                    value: Number(this.medicalAidTaxCredit.current),
                    value_planned: Number(this.medicalAidTaxCredit.planned),
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .monthly-payments-table {
        margin-top: -10px;
        font-size: 14px;
    }

    .clear-button-container {
        margin-top: 45px;
        display: flex;
        justify-content: flex-end;
    }
    
    .delete-changes-container {
        margin-right: 5px;
        @include media-breakpoint-up(sm) {
            margin-right: 10px;
        }
    }
    
    .proceed-changes-container {
        margin-left: 5px;
        @include media-breakpoint-up(sm) {
            margin-left: 10px;
        }
    }
    
    .no-change-in-plan {
        text-align: right;
        padding-right: 15px;
        margin-top: 35px;
        font-weight: 500;
        color: black;
    }
    
    .payroll-changes-submitted {
        font-weight: 700;
        font-size: 18px;
        color: darken($primary, 10%);
        margin-top: 35px;
    }
</style>