<template>
    
    <div class="card-container-addons">
        
        <div class="top-sub-title-addons">
            Rewards
        </div>
        
        <div class="title-addons addons-title" v-html="cardHtml"></div>
        
        <div class="content-addons"></div>
        
        <div class="more-information-addons">
            <span class="text-link"><a href="https://www.discovery.co.za/vitality/rewards" target="_blank">More information</a></span>
            <span><a class="external-link-icon-addons" href="https://www.discovery.co.za/vitality/rewards" target="_blank"><img :src="externalLinkIcon" alt="more info"/></a></span>
        </div>
    
    </div>

</template>

<script>
    export default {
        name: "WizardBlockInfoCardAddons",
        props: [
            'cardHtml',
        ],
        
        computed: {
            
            imageDir() {
                return this.$store.getters.imageDir;
            },
            
            externalLinkIcon() {
                return this.imageDir + 'external.png';
            },
            
        }
    }
</script>

<style lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    // needs to be unscoped to reach tags in v-html
    
    .card-container-addons {
        background-color: white;
        border-radius: $border_radius;
        margin: 5px;
        padding: 15px 16px;
        height: 310px;
        width: 256px;
        position: relative;
        text-align: left;
        cursor: grab;
        
        &:active {
            cursor: grabbing;
        }
    }
    
    .top-sub-title-addons {
        color: $grey;
        font-size: 13px;
        font-weight: 500;
    }
    
    
    .addons-title {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 24px;
        line-height: 1.3;
        margin-top: 18px;
        
        span {
            font-weight: 700 !important;
        }
    }
    
    
    .content-addons {
        font-size: 13px;
        line-height: 1.5;
    }
    
    .more-information-addons {
        position: absolute;
        left: 20px;
        width: calc(100% - 40px);
        bottom: 20px;
        display: flex;
        
        a {
            position: relative;
            font-weight: 300;
            font-size: 13px;
            display: inline-block;
            width: 110px;
            
            &:hover {
                font-weight: 500;
            }
            
            &:after {
                content: '';
                width: 104px;
                position: absolute;
                height: 1px;
                bottom: -1px;
                background-color: black;
                left: 0;
            }
        }
    }
    
    .external-link-icon-addons {
        position: relative;
        top: 4px;
        display: inline-block;
        padding-left: 8px;
        
        &:after {
            display: none;
        }
        
        img {
            width: 16px;
        }
    }

</style>