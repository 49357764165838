// Employee paye calcs getters

import {isValidArray} from "@/hooks/general/type-and-value-checks"
import {ageFromDob, ageOnLastDayOfFinancialYear} from "@/hooks/general/date-helpers"
import {annualPayeTaxCalculator, regularPayeTaxCalculator} from "@/hooks/payslip-and-financials/paye-calculators"
import {createYtdGetter} from "@/hooks/general/getters-helpers"
import {isLocalHost} from "@/hooks/api/app-environment-origin"

export default {


    // Age
    age(_, getters) {
        // using DOB
        if (getters.dateOfBirth.current) {
            const dateString = new Date(getters.dateOfBirth.current);
            return ageFromDob(dateString);
        }
        return false;
    },

    ageOnLastDayOfTaxYear(_, getters) {
        const dobDateString = getters.dateOfBirth.current;
        const currentFinancialYear = getters.currentFinancialYear;
        if (!getters.dateOfBirth || !getters.currentFinancialYear) {
            return false;
        }
        return ageOnLastDayOfFinancialYear(dobDateString, currentFinancialYear);
    },


    // Tax Rebate
    taxRebate(_, getters) {
        const age = getters.ageOnLastDayOfTaxYear;
        const under65 = Number(getters.currentYearFinancialTables.taxRebate.under65);
        const over65 = Number(getters.currentYearFinancialTables.taxRebate.over65);
        const over75 = Number(getters.currentYearFinancialTables.taxRebate.over75);
        if (age < 65) {
            return under65;
        } else if (age >= 65 && age < 75) {
            return under65 + over65;
        } else if (age >= 75) {
            return under65 + over65 + over75;
        }
        // fallback
        return under65;
    },


    // income tax thresholds
    incomeTaxThreshold(_, getters) {
        const incomeTaxThresholds = getters.currentYearFinancialTables.taxThresholds;
        const age = getters.ageOnLastDayOfTaxYear;
        if (age >= 75) {
            return Number(incomeTaxThresholds.over75);
        } else if (age >= 65) {
            return Number(incomeTaxThresholds.over65);
        }
        return Number(incomeTaxThresholds.under65);
    },
    incomeTaxThresholdMonthly(_, getters) {
        return Number(getters.incomeTaxThreshold / 12);
    },

    // # Medical Aid Tax Credits
    medicalAidTaxCreditTableCurrent: (_, getters) => getters.currentYearFinancialTables.medicalAidTaxCreditTable,

    // we only have to use next years in March for projections. Because in March we show February's payslip (which is the current).
    // medicalAidTaxCreditTableFuture(_, getters) {
    //     return getters.currentYearFinancialTables.medicalAidTaxCredit;
    // },


    // ### Calculator Getters
    // ====================================
    // TODO:
    //  Work out a better way to do this
    medicalAidTaxCreditCalc: (_, getters) => {
        function taxCreditCalculator(adultDependants, childDependants) {
            const extraDependants = Number(adultDependants) + Number(childDependants)
            let mainAndOne = Number(getters.medicalAidTaxCreditTableCurrent.main) + Number(getters.medicalAidTaxCreditTableCurrent.oneAdditional)

            if (extraDependants === 0) {
                return getters.medicalAidTaxCreditTableCurrent.main
            } else if (extraDependants === 1) {
                return mainAndOne;
            } else if (extraDependants >= 2) {
                return ((extraDependants - 1) * getters.medicalAidTaxCreditTableCurrent.additional) + mainAndOne
            } else {
                return 0
            }
        }

        return {
            current: taxCreditCalculator(getters.adultDependants.current, getters.childDependants.current),
            planned: taxCreditCalculator(getters.adultDependants.planned, getters.childDependants.planned),
        }
    },


    /*##### PAYE CALCS #####*/
    /*======================*/


    // ### This is for 2022 Tax Year - Do for 2023!? Is it though..
    payeCalcCurrentMethod(_, getters) {

        const yearlyTaxableIncome = getters.taxableIncomeCalc.current * 12;
        const yearlyTaxableIncomePlanned = getters.taxableIncomeCalc.planned * 12;

        const medicalAidTaxCredit = getters.medicalAidTaxCredit.current;
        const medicalAidTaxCreditPlanned = getters.medicalAidTaxCredit.planned;

        const current = regularPayeTaxCalculator(yearlyTaxableIncome, medicalAidTaxCredit);
        const planned = regularPayeTaxCalculator(yearlyTaxableIncomePlanned, medicalAidTaxCreditPlanned);

        return {
            current: current,
            planned: planned,
        }

    },


    // *** payslipsList in employee module because it references employee state
    // /api/1.0/employees/2/payslips/
    payslipsListOrdered(_, getters) {
        return getters.payslipsList.sort((payslipA, payslipB) => new Date(payslipB.date) - new Date(payslipA.date));
    },

    payslipsListOrderedSynced(_, getters) {
        // :: sync payslips list with current latest payslip
        // # it might be more than 1 payslip ahead - need to account for this. Who knows how many payslips in the future an employer has created payslips.
        // # edge case of an early payslip in latest payslips (payslips[0]) list is ahead of employee.latest__payslip (no longer in use)
        // # then we need to shift() that payslip off payslips array until it is in sync
        // # The current payslip is the one being displayed on the front-end so this is the one we must use as the latest payslip for calcs
        // # current payslip is the last finalised payslip in SimplePay
        // ps console.log sorts order..
        let payslipsListOrderedSynced = [...getters.payslipsListOrdered];
        while (new Date(payslipsListOrderedSynced[0].date) > new Date(getters.latestPayslip.date)) {
            // console.log('latest payslip in payslips list is ahead of latestPayslip. Removing most recent payslip from payslips list until in sync with latestPayslip -> array.shift() to sync');
            payslipsListOrderedSynced.shift();
        }
        return payslipsListOrderedSynced;
    },

    // :: construct current financial year payslips array
    // TODO:
    //  sort this issue out of February not returning anything
    // :: ** this fucker is causing me issues because it's not returning anything if it's the month of February
    payslipsListOrderedSyncedCurrent(_, getters) {

        /*## get current financial year (of latest payslip)*/
        const latestPayslipDate = new Date(getters.latestPayslip.date);
        const latestPayslipMonth = latestPayslipDate.toLocaleString('default', {month: 'long'});
        const latestPayslipYear = Number(latestPayslipDate.getFullYear());
        // console.log(latestPayslipYear);

        let financialYear = latestPayslipYear + 1;
        if (latestPayslipMonth === 'January' || latestPayslipMonth === 'February') {
            financialYear -= 1
        }
        // console.log(financialYear);

        let payslips = [];

        const payslipsCount = Number(getters.payslipsListOrderedSynced.length);

        let i = 0;
        for (const payslip of getters.payslipsListOrderedSynced) {

            const payslipMonth = new Date(payslip.date).toLocaleString('default', {month: 'long'});
            let payslipYear = new Date(payslip.date).getFullYear();
            payslipYear = Number(payslipYear);

            // if block 1
            if (payslipMonth === 'February' && payslips.length > 0) {
                // we do the i > 0 check if the latest payslip is February we mustn't break, and just include February.
                // console.log('if block 1')
                return payslips;
            }

            // if block 2
            if (payslipYear < financialYear - 1) {
                // console.log('if block 2')
                return payslips;
            }

            // if block 3
            if ((payslipYear === financialYear - 1) && (payslipMonth === 'January' || payslipMonth === 'February')) {
                // console.log('if block 3');
                return payslips;
            }

            payslips.push(payslip);

            /*cant have more than 12 payslips*/
            // if block 4
            if (payslips.length === 12) {
                // console.log('if block 4');
                return payslips;
            }

            i++;
            // return payslips if we have finished looping through all payslips in the list
            if (i === payslipsCount) {
                // console.log('finished looping through all payslips');
                return payslips;
            }
        }
    },

    // https://www.simplepay.co.za/help/payroll-concepts/statutory-deductions-and-contributions/tax-paye/understanding-the-tax-trace
    // Also very helpful: https://www.simplepay.co.za/help/payroll-concepts/payslip-components
    // Using the Tax Trace Tool
    // TODO
    //  - planned calculation, little bit tricky. Can also test with SimplePay, by going forward a period and importing.

    // ******** Think of these income figures as Taxable Income figures. That is why we are removing non taxable allowances, benefits and contributions *********
    // Income (cash portions) + Allowances (not expense claims or non-taxable portions) + value of benefits (not including pension fund contribution from employer AND employee)
    ytdTotalIncome(_, getters) {
        let ytdTaxableIncomeCurrent = 0;

        const payslips = getters.payslipsListOrderedSyncedCurrent

        if (isValidArray(payslips)) {
            for (let payslip of payslips) {

                // cash income
                let cashIncomeCurrent = Number(payslip.salary_before_deductions)

                // cash allowances
                let taxableAllowances = 0
                if (isValidArray(payslip.allowances)) {
                    for (const allowance of payslip.allowances) {
                        taxableAllowances += Number(allowance.amount)
                    }
                }

                // ## Remove non-taxable items from cash allowances (forms part of regular income)
                // ** expense claim (non-taxable)
                // ** loan increase balance (non-taxable)
                // ** relocation allowance (non-taxable)
                // ** uniform allowance
                // ** TERS payout
                // ** 20% of Travel Allowance Fixed and Costs (20% is non taxable, 80% is subject to PAYE)
                // ** travel allowance reimbursive (non-taxable)
                let nonTaxableAllowances = 0
                if (isValidArray(payslip.allowances)) {
                    for (const allowance of payslip.allowances) {
                        if (allowance.internal_name === 'travel_allowance_fixed_and_costs') {
                            nonTaxableAllowances += .2 * Number(allowance.amount)
                        }
                        if (allowance.internal_name === 'travel_allowance_reimbursive') {
                            nonTaxableAllowances += Number(allowance.amount)
                        }
                        if (
                            allowance.internal_name === 'expense_claim' ||
                            allowance.internal_name === 'relocation_allowance_non_taxable' ||
                            allowance.internal_name === 'uniform_allowance' ||
                            allowance.internal_name === 'ters_payout' ||
                            allowance.internal_name === 'loan_increase_balance'
                        ) {
                            nonTaxableAllowances += Number(allowance.amount)
                        }
                    }
                }
                taxableAllowances -= nonTaxableAllowances;

                // ### value of benefits (medical aid less employee pension fund)

                // # medical aid benefit
                let medicalAidBenefitCurrent = 0
                for (const contribution of payslip.benefits) {
                    if (contribution.internal_name === 'medical_aid_benefit') {
                        medicalAidBenefitCurrent += Number(contribution.amount)
                        break
                    }
                }

                // # pension fund benefit
                let employeePensionFundCurrent = 0
                for (const deduction of payslip.employee_deductions) {
                    if (deduction.internal_name === 'employee_pension_fund') {
                        employeePensionFundCurrent += Number(deduction.amount)
                        break
                    }
                }

                // value of benefits is value of benefits less pension fund contributions from BOTH employer & employee
                // which is the same as saying medical aid benefit (employer contribution) less employee pension fund contribution
                let valueOfBenefitsCurrent = medicalAidBenefitCurrent - employeePensionFundCurrent;

                // ** 80% of Company Petrol Card is added to regular income for tax purposes. We are adding 80% because it has not been added to income automatically through allowances.
                //         Because it is not included in payslip. This allowance is not paid out / included in income, so you won't see it on the payslip.
                // let petrolCardTaxableBenefit = 0;
                // if (isValidArray(payslip.allowances)) {
                //     for (const allowance of payslip.allowances) {
                //         if (allowance.internal_name === 'travel_allowance_petrol_card_taxable') {
                //             petrolCardTaxableBenefit += .8 * Number(allowance.amount);
                //         }
                //     }
                // }
                // console.log(petrolCardTaxableBenefit);

                // ## ytdTotalIncome
                // cash income + taxable allowances + value of benefits

                // Add the current month calcs to the ytdCalcs
                ytdTaxableIncomeCurrent += cashIncomeCurrent + taxableAllowances + valueOfBenefitsCurrent;
            }
        }

        // ### Now we calculate ytdTaxableIncomePlanned based on current
        let ytdTaxableincomePlanned = ytdTaxableIncomeCurrent;

        // 1. Add another months Salary Before Deductions. We assume this doesn't change from the current month. BUT:
        // Unless there were irregular income items in current month.
        // Hence we use the salaryBeforeDeductionsExclIrregularIncome
        ytdTaxableincomePlanned += getters.salaryBeforeDeductionsExclIrregularIncome.current;


        // 2. Add taxableAllowances
        // We assume the same allowance inputs as the current month
        // Expense claim has no effect here because we don't include it anyway
        ytdTaxableincomePlanned += getters.totalAllowancesTaxable.current;

        // 3. Add planned Value of Benefits
        const plannedValueOfBenefits = getters.medicalAidBenefit.planned - getters.employeePensionFund.planned;
        ytdTaxableincomePlanned += plannedValueOfBenefits;

        return {
            label: 'YTD Total Income',
            current: ytdTaxableIncomeCurrent,
            planned: ytdTaxableincomePlanned,
        };
    },


    // Regular income: cash income + cash allowances + the value of benefits
    ytdRegularIncome(_, getters) {

        // planned is going to be the same as current. We don't know if an irregular income is going to be included
        // in next months payslip
        const current = getters.ytdTotalIncome.current - getters.ytdIrregularIncome.current;
        const planned = getters.ytdTotalIncome.planned - getters.ytdIrregularIncome.current;

        return {
            label: 'YTD Regular Income',
            current,
            planned,
        };
    },

    // Get these from Cash portions
    // SARS Code 3605 : Check Miro notes for helpful links
    // Irregular income items are: Annual Bonus, Annual Payment, Leave Paid Out
    // * Leave paid out is only applicable to when an employee's service ends, so not strictly applicable to show in Bento App.
    // ## Cash Portion Object Structure
    ytdAnnualBonus(_, getters) {
        return createYtdGetter(getters.payslipsListOrderedSyncedCurrent, 'cash_portions', 'annual_bonus');
    },
    ytdAnnualPayment(_, getters) {
        return createYtdGetter(getters.payslipsListOrderedSyncedCurrent, 'cash_portions', 'annual_payment');
    },
    ytdBroadBasedEmployeeSharePlan(_, getters) {
        return createYtdGetter(getters.payslipsListOrderedSyncedCurrent, 'allowances', 'broad_based_employee_share_plan');
    },

    ytdIrregularIncome(_, getters) {
        // planned is going to be the same as current. We don't know if an irregular income is going to be included
        // in next months payslip
        let current = getters.ytdAnnualBonus.current + getters.ytdAnnualPayment.current + getters.ytdBroadBasedEmployeeSharePlan.current
        let planned = current;
        return {
            label: 'YTD Irregular Income',
            current,
            planned,
        }
    },

    // this is the same for current and planned
    periodOfEmployment(_, getters) {
        let current = 0;

        if (isValidArray(getters.payslipsListOrderedSyncedCurrent)) {
            const startMonth = getters.payslipsListOrderedSyncedCurrent[getters.payslipsListOrderedSyncedCurrent.length - 1];
            const startMonthDate = new Date(startMonth.date);
            current = startMonthDate.getMonth() - 1;// actually + 1 (cos months are 0 indexed) and -2 because financial year starts on 1 March. So same as just -1.
        }

        return {
            label: 'Period of Employment',
            current: current,
            planned: current,
        }
    },

    // this is the same for current and planned
    periodsInYear(_, getters) {
        return {
            label: 'Periods in Year',
            current: 13 - getters.periodOfEmployment.current,
            planned: 13 - getters.periodOfEmployment.current,
        }
    },

    ytdPeriods(_, getters) {
        const currentPayslipDate = new Date(getters.latestPayslip.date);
        let current = currentPayslipDate.getMonth() - 1; // actually + 1 (cos months are 0 indexed) and -2 because financial year starts on 1 March. So same as just -1.
        let planned = current + 1;
        if (current === 0) {
            current = 12;
            planned = 1;
        }

        return {
            label: 'YTD Periods',
            current: current,
            planned: planned,
        }
    },

    annualisationRatio(_, getters) {
        const current = getters.periodsInYear.current / getters.ytdPeriods.current;
        const planned = getters.periodsInYear.current / getters.ytdPeriods.planned;
        return {
            label: 'Annualisation Ratio',
            current: current,
            planned: planned,
        }
    },

    annualisedTotalIncome(_, getters) {
        const current = (getters.ytdRegularIncome.current * getters.annualisationRatio.current) + getters.ytdIrregularIncome.current;
        const planned = (getters.ytdRegularIncome.planned * getters.annualisationRatio.planned) + getters.ytdIrregularIncome.planned;

        return {
            label: 'Annualised Total Income',
            current: current,
            planned: planned,
        }

    },

    taxOnAnnualisedTotalIncome(_, getters) {
        const taxCurrent = annualPayeTaxCalculator(getters.annualisedTotalIncome.current, getters.currentYearFinancialTables.brackets, getters.taxRebate);
        const taxPlanned = annualPayeTaxCalculator(getters.annualisedTotalIncome.planned, getters.currentYearFinancialTables.brackets, getters.taxRebate);

        return {
            label: 'Tax on Annualised Total Income',
            current: taxCurrent,
            planned: taxPlanned,
        }
    },

    annualisedTotalRegularIncome(_, getters) {
        const current = (getters.ytdRegularIncome.current * getters.annualisationRatio.current);
        const planned = (getters.ytdRegularIncome.planned * getters.annualisationRatio.planned);

        return {
            label: 'Annualised Total Regular Income',
            current: current,
            planned: planned,
        }
    },


    taxOnAnnualisedRegularIncome(_, getters) {
        const taxCurrent = annualPayeTaxCalculator(getters.annualisedTotalRegularIncome.current, getters.currentYearFinancialTables.brackets, getters.taxRebate);
        const taxPlanned = annualPayeTaxCalculator(getters.annualisedTotalRegularIncome.planned, getters.currentYearFinancialTables.brackets, getters.taxRebate);

        return {
            label: 'Tax on Annualised Regular Income',
            current: taxCurrent,
            planned: taxPlanned,
        }
    },

    taxOnIrregularIncome(_, getters) {
        const current = getters.taxOnAnnualisedTotalIncome.current - getters.taxOnAnnualisedRegularIncome.current;
        const planned = getters.taxOnAnnualisedTotalIncome.planned - getters.taxOnAnnualisedRegularIncome.planned;

        return {
            label: 'Tax on Irregular Income',
            current: current,
            planned: planned,
        }
    },

    ytdTax(_, getters) {
        const current = (getters.taxOnAnnualisedRegularIncome.current * (getters.ytdPeriods.current / 12)) + getters.taxOnIrregularIncome.current;
        const planned = (getters.taxOnAnnualisedRegularIncome.planned * (getters.ytdPeriods.planned / 12)) + getters.taxOnIrregularIncome.planned;

        return {
            label: 'YTD Tax',
            current: current,
            planned: planned,
        }
    },

    ytdMatc(_, getters) {
        let ytdMatc = 0;
        let planned = 0;

        if (isValidArray(getters.payslipsListOrderedSyncedCurrent)) {
            for (let payslip of getters.payslipsListOrderedSyncedCurrent) {
                ytdMatc += Number(payslip.medical_aid_tax_credit);
            }

            const matcCurrent = Number(getters.latestPayslip.medical_aid_tax_credit);
            planned = ytdMatc + matcCurrent;
        }

        return {
            label: 'YTD Medical Aid Tax Credits (MATC)',
            current: ytdMatc,
            planned: planned,
        }
    },

    ytdTaxPaid(_, getters) {
        let ytdTaxPaid = 0;
        let ytdTaxPaidPlanned = 0;

        if (isValidArray(getters.payslipsListOrderedSyncedCurrent)) {
            // payslips paye up until but not including current
            const payslipsNotIncludingCurrent = [...getters.payslipsListOrderedSyncedCurrent];
            payslipsNotIncludingCurrent.shift();

            // check if there is a payslip to iterate through, otherwise haven't paid any tax
            if (isValidArray(payslipsNotIncludingCurrent)) {
                for (let payslip of payslipsNotIncludingCurrent) {
                    const employeePaye = payslip.employee_deductions.find(deduction => deduction.internal_name === 'employee_paye');
                    ytdTaxPaid += Number(employeePaye.amount);
                }
            }

            const currentPaye = Number(getters.taxPayeApi.api);
            ytdTaxPaidPlanned = ytdTaxPaid + currentPaye;
        }

        return {
            label: 'YTD Tax Paid',
            current: ytdTaxPaid,
            planned: ytdTaxPaidPlanned,
        }
    },


    taxPayeAnnualisedCalc(_, getters) {
        if (isLocalHost()) {
            console.log('..calcpaye..');
        }
        if (!getters.latestPayslip) {
            return null;
        }

        const current = getters.ytdTax.current - getters.ytdTaxPaid.current - getters.ytdMatc.current;
        const planned = getters.ytdTax.planned - getters.ytdTaxPaid.planned - getters.ytdMatc.planned;

        return {
            id: 'tax-paye-annualised-calc',
            label: 'Income Tax (PAYE) - Annualised Averaging Method',
            api: getters.taxPayeApi.api,
            current,
            planned,
        }
    },


    taxPayeCurrentMethodCalc(_, getters) {
        return {
            id: 'tax-paye-current-method-calc',
            label: 'Tax (PAYE) - Current Payslip Method:',
            api: getters.taxPayeApi.api,
            current: getters.payeCalcCurrentMethod.current,
            planned: getters.payeCalcCurrentMethod.planned,
        }
    },
}