// employee/payslip-financials getters
import {getPayrun} from "@/hooks/payslip-and-financials/get-payslip-data-from-payslip"
import {isValidArray} from "@/hooks/general/type-and-value-checks"

export default {

    payslipsList: state => state.payslipsList,
    shouldUpdatePayslipsList: (state) => {
        if (!state.lastPayslipsListFetch) return true
        const currentTimeStamp = new Date().getTime()
        return (currentTimeStamp - state.lastPayslipsListFetch) / 1000 > 900
    },

    // ### salary data : SimplePay Figures : These are from the Bento API
    // --------------------------------------------------------------------


    latestPayslip: (_, getters) => {
        if (!isValidArray(getters.payslipsList)) return null
        const payslipsLatestFirst = [...getters.payslipsList].reverse()
        return payslipsLatestFirst[0]
    },

    isPayslipFinalised: (_, getters) => (getters.latestPayslip) ? getters.latestPayslip.finalised : false,

    getPayrun: (_, getters) => getPayrun(getters.latestPayslip),


    // E: CASH PORTIONS - From Bento API
    // Has it's own nested module now - "payslip-financials/cash-portions"

    // F : Reimbursements for Expense Claims
    // These are now in the reimbursements module

    // G : Total Allowances (treating this as a travel allowance 80/20 rule).
    // Leave these out for now
    // Just made these equal to zero
    // I think this is for things like travel allowance, phone allowance, relocation allowance, tool allowance etc
    // Check SimplePay for these - they will each have their own tax treatments
    // ### moved to allowances module


    /*### Employee Deductions ###*/

    // H: From Api
    // Taken from the 'medical_aid_contract' property value pair. Not the latest payslip. But these should be the same.
    // in payslip-financials/deductions module


    // I : From Api
    // Now in deductions module


    // J & K ### PAYE
    // API & Bento Calc they should match
    // Calc getter at bottom of sheet

    // J & K
    // taxPayeApi now in deductions module


    // L: From API
    // now in deductions module


    /*### Employer Contributions ###*/
    // ## All in "employer-contributions" module

    // M: From API
    // pension_fund_employer


    // N: SDL - Employer
    // Y * .01
    // 1% of Taxable Income
    // From Api & Calc values


    // O: From Api
    // :: Employer UIF


    // P: From Api (This is Medical Aid Benefit in the spreadsheet)
    // Now in employer-contributions module



    // Q: Pension Fund Benefit (Not sure what this is yet)
    // Shown under Benefit on Payslip, and represents taxable fringe benefit amount.
    // Not always the same as total Employer contribution to Pension Fund
    // Will need to account for this

    // R: Gross Remuneration -  Just Calc Value
    // R - calculated not available from API Yet
    // E + G + M + P
    // Basic Salary + Allowances (Taxable) + Pension Fund Employer + Medical Aid Employer
    // No API value
    grossRemuneration: (_, getters) => {
        return {
            id: 'gross-remuneration',
            label: 'Total Earnings (Gross Remuneration)',
            api: false,
            current: getters.salaryBeforeDeductions.api + getters.totalAllowancesExclExpenseClaim.current + getters.employerRetirementFund.api + getters.medicalAidBenefit.api,
            planned: getters.salaryBeforeDeductions.api + getters.totalAllowancesExclExpenseClaim.planned + getters.employerRetirementFund.planned + getters.medicalAidBenefit.planned,
        }
    },


    // S: Gross remuneration, taxable portion (before deductions)
    // Gross Remuneration, Taxable Portion (before Deductions) is only the taxable portion of the total gross amount before deductions are taken into account,
    // which is R36 385 (R32 500 (Basic Salary) + R960 (the taxable portion of the Travel Allowance) + R2 925 (Pension Fund Benefit).
    // Only need this when we start working with allowances I think
    // Not in use for now


    // T - Medical Aid - code 4005
    // NOT REPRESENTED BECAUSE I DON'T THINK I NEED IT
    // H + P
    // It is both employer and employee contribution. It is what your employer pays over to medical aid provider. on your behalf
    // PS: (it includes "deemed paid by the employee" which includes employer contributions)
    // https://www.sars.gov.za/wp-content/uploads/Ops/Guides/PAYE-AE-06-G06-Guide-for-Codes-Applicable-to-Employees-Tax-Certificates-2022-External-Guide.pdf


    // U: From API - SimplePay
    // U: E + F + G - H - I - K - L
    // Basic Salary + Expense Claim (non-taxable) + Travel Allowance fixed and costs - Medical Aid Employee - Pension Fund Employee - Tax(paye) - UIF (employee)
    nettPayApi(_, getters) {
        let apiAmount = 0;
        if (getters.latestPayslip) {
            apiAmount = Number(getters.latestPayslip.nett_pay);
        }

        return {
            id: 'nett-pay-api',
            label: 'Take Home Pay (Nett)',
            api: apiAmount,
        }
    },

    nettPayCalc(_, getters) {
        const current = getters.salaryBeforeDeductions.api + getters.totalAllowancesIncludingExpenseClaim.current + getters.totalReimbursementsPaidOut.current - getters.totalEmployeeDeductionsApi.api;
        const planned = getters.salaryBeforeDeductions.api + getters.totalAllowancesIncludingExpenseClaim.planned + getters.totalReimbursementsPaidOut.planned - getters.totalEmployeeDeductionsCalc.planned;
        return {
            id: 'nett-pay-calc',
            label: 'Take Home Pay (Nett)',
            api: getters.nettPayApi.api,
            current,
            planned,
        }
    },

    // V - Normal Rate
    // Don't know what this is, don't think I need it.


    // W - Retirement Annuity Fund - RAF - self payment
    // This is when I have my RA in a personal capacity and ask my employer to capture it on the. payroll even though the employer does not have any pension fund benefits in place
    // (this is so I can get the tax benefit right away - when salary is paid).


    // X - separator - values to the right of this are from Bento system
    // Not in SimplePay - Calculated Fields (RHS spreadsheet)
    // ========================================================


    // Y: Taxable Income
    // No API values
    // Calc values only

    // This is not too complex
    // =IF( (E + G *0,8) - I <= ($'Tax Tables'.$E$21/12);0;(E88+G88*0,8)-I88)
    // *Leaving G out of this for
    //=IF( (Basic Salary + (Travel Allowance * .8) ) - Pension Fund Employee Contribution <=  ( incomeTaxThresholdMonthly ) then 0, ELSE it is ( (Basic Salary + (Travel Allowance * .8) )

    // ### New way like annualized income ytdTotalIncome
    // ### Income (cash portions) + allowances (not expense claims and taxable portions of allowances) + value of benefits
    // ### Note there is not API value for this
    taxableIncomeCalc(_, getters) {
        if (!getters.latestPayslip) { return null }

        /*current calc*/
        const taxableIncome         = getters.salaryBeforeDeductions.api + getters.totalAllowancesTaxable.current + getters.medicalAidBenefit.api - getters.employeePensionFund.api;
        // console.log('current calc: ', getters.salaryBeforeDeductions.api, getters.totalAllowancesTaxable.current, getters.medicalAidBenefit.api, getters.employeePensionFund.api)

        /*planned calc*/
        const taxableIncomePlanned  = getters.salaryBeforeDeductions.api + getters.totalAllowancesTaxable.planned + getters.medicalAidBenefit.planned - getters.employeePensionFund.planned;
        // console.log('planned: ', getters.salaryBeforeDeductions.api, getters.totalAllowancesTaxable.planned, getters.medicalAidBenefit.planned, getters.employeePensionFund.planned);

        return {
            id: 'taxable-income-calc',
            label: 'Taxable Income',
            api: false,
            current: taxableIncome,
            planned: taxableIncomePlanned,
        }
    },





    // Z: Deductions : 3 Ways to the same figure
    // ** This does have API values **
    // Z: R - U - AJ + F
    // (R)Gross Remuneration(R) - (U)Nett Pay - (AJ)Total Employer Contributions (excl. SDL & UIF) + (F)Expense Claim Non Taxable

    // Now in payslip-financials/deductions module




    // AA: AB + AC etc
    // Captured in Bento as part of employer configuration
    // Bento CMS: Reimbursive Allowances Section
    // Represented as "reimbursive_allowances" in the API
    // This is what actual expenses will be captured against
    // *** Moved to employee/payslip-finanicals/reimbursements module




    // AB and AC - are the individual allowances which make up totalReimbursiveAllowances

    // AD : Total Expense Claims for the month
    // Hardcoded to 0 for now.
    expenseClaimsNonTaxableBento() {
        return {
            id: 'expense-claims-non-taxable-bento',
            label: 'Expense Claims (Non Taxable) - Bento Version',
            api: 0,
            current: false,
            planned: false,
        }
    },


    // AG: Total Package Value
    // AA + R
    // Gross Remuneration + Reimbursive Budget Allowances
    totalPackage(state, getters) {
        return {
            id: 'total-package',
            label: 'Total Remuneration Package',
            api: false,
            current: getters.grossRemuneration.current + getters.totalReimbursementAllowancesExAdHoc.api,
            planned: getters.grossRemuneration.planned + getters.totalReimbursementAllowancesExAdHoc.api,
        }
    },


    // AH: Total Retirement Fund Contributions
    // I + M
    // Pension Fund Employee + Pension Fund Employer
    totalRetirementFundContributions(_, getters) {
        return {
            id: 'total-retirement-fund-contributions',
            label: 'Total Retirement Fund Contributions',
            api: false,
            current: getters.employeePensionFund.api + getters.employerRetirementFund.api,
            planned: getters.employeePensionFund.planned + getters.employerRetirementFund.api,
        }
    },

    // AI: Basic + Other Cash Components
    // E + G



    // ### Totals and Summarries
    // ==================================


    /**** Not sure if these will always be the same, or if there is anything else that goes into Taxable income deduction ****/
    totalTaxableIncomeDeduction(_, getters) {
        let apiAmount = false;
        return {
            id: 'taxable-income-deduction',
            label: 'Taxable Income Deduction',
            api: apiAmount,
            current: getters.retirementDeduction.current,
            planned: getters.retirementDeduction.planned,
        }
    },

    payslipTaxableIncomeDeductions: (_, getters) => getters.retirementDeduction,


    // ### Payslip Summary Blocks
    // ==================================


    totalTaxCredit(_, getters) {
        let apiAmount = getters.medicalAidTaxCredit.api;
        let current = getters.medicalAidTaxCredit.current;
        let planned = getters.medicalAidTaxCredit.planned;
        return {
            id: 'total-tax-credit',
            label: 'Total Tax Credit',
            api: apiAmount,
            current,
            planned,
        }
    },

    payslipTaxCredit: (_, getters) => [getters.medicalAidTaxCredit],



    // ### Summary Block Figures Display
    // ==================================

    summaryStats(state, getters) {

        // 4 scenarios
        // --------------------
        // 1. Employer contribution only
        // 2. Reimbursement budget only
        // 3. both
        // 4. none

        // scenario 4
        if (!getters.totalReimbursiveAllowances.api && !getters.totalEmployerContributionsExSdlUif.current) {
            return [
                getters.salaryBeforeDeductions,
                getters.totalEmployeeDeductionsApi,
                getters.nettPayApi,
            ]
        }
        // scenario 3
        else if (getters.totalReimbursiveAllowances.api && getters.totalEmployerContributionsExSdlUif.current) {
            return [
                getters.totalPackage,
                getters.totalReimbursementAllowancesExAdHoc,
                getters.grossRemuneration,
                {
                    id: getters.totalEmployerContributionsExSdlUif.id,
                    label: 'Employer Contributions',
                    api: getters.totalEmployerContributionsExSdlUif.api,
                    current: getters.totalEmployerContributionsExSdlUif.current,
                    planned: getters.totalEmployerContributionsExSdlUif.planned,
                },
                getters.salaryBeforeDeductions,
                getters.totalEmployeeDeductionsApi,
                getters.nettPayApi,
            ]
        }
        // scenario 2 - Reimbursement budget only
        else if (getters.totalReimbursiveAllowances.api && !getters.totalEmployerContributionsExSdlUif.current) {
            return [
                getters.totalPackage,
                getters.totalReimbursementAllowancesExAdHoc,
                getters.grossRemuneration,
                getters.salaryBeforeDeductions,
                getters.totalEmployeeDeductionsApi,
                getters.nettPayApi,
            ]
        }
        // scenario 1
        else if (!getters.totalReimbursiveAllowances.api && getters.totalEmployerContributionsExSdlUif.current) {
            return [
                getters.grossRemuneration,
                {
                    id: getters.totalEmployerContributionsExSdlUif.id,
                    label: 'Employer Contributions',
                    api: getters.totalEmployerContributionsExSdlUif.api,
                    current: getters.totalEmployerContributionsExSdlUif.current,
                    planned: getters.totalEmployerContributionsExSdlUif.planned,
                },
                getters.salaryBeforeDeductions,
                getters.totalEmployeeDeductionsApi,
                getters.nettPayApi,
            ]
        }
        // catch all but it shouldn't get here
        else {
            return [
                getters.totalPackage,
                getters.grossRemuneration,
                {
                    id: getters.totalEmployerContributionsExSdlUif.id,
                    label: 'Employer Contributions',
                    api: getters.totalEmployerContributionsExSdlUif.api,
                    current: getters.totalEmployerContributionsExSdlUif.current,
                    planned: getters.totalEmployerContributionsExSdlUif.planned,
                },
                getters.totalReimbursementAllowancesExAdHoc,
                getters.salaryBeforeDeductions,
                getters.totalEmployeeDeductionsApi,
                getters.nettPayApi,
            ]
        }

    },

    summaryStatsPlanned(state, getters) {

        // 4 scenarios
        // --------------------
        // 1. Employer contribution only
        // 2. Reimbursement budget only
        // 3. both
        // 4. none

        // scenario 4
        if (!getters.totalReimbursiveAllowances.api && !getters.totalEmployerContributionsExSdlUif.current) {
            return [
                getters.salaryBeforeDeductions,
                getters.totalEmployeeDeductionsCalc,
                getters.nettPayCalc,
            ]
        }
        // scenario 3
        else if (getters.totalReimbursiveAllowances.api && getters.totalEmployerContributionsExSdlUif.current) {
            return [
                getters.totalPackage,
                getters.totalReimbursementAllowancesExAdHoc,
                {
                    id: getters.totalEmployerContributionsExSdlUif.id,
                    label: 'Employer Contributions',
                    api: getters.totalEmployerContributionsExSdlUif.api,
                    current: getters.totalEmployerContributionsExSdlUif.current,
                    planned: getters.totalEmployerContributionsExSdlUif.planned,
                },
                getters.salaryBeforeDeductions,
                getters.totalEmployeeDeductionsCalc,
                getters.nettPayCalc,
            ]
        }
        // scenario 2 - Reimbursement budget only
        else if (getters.totalReimbursiveAllowances.api && !getters.totalEmployerContributionsExSdlUif.current) {
            return [
                getters.totalPackage,
                getters.totalReimbursementAllowancesExAdHoc,
                getters.salaryBeforeDeductions,
                getters.totalEmployeeDeductionsCalc,
                getters.nettPayCalc,
            ]
        }
        // scenario 1
        else if (!getters.totalReimbursiveAllowances.api && getters.totalEmployerContributionsExSdlUif.current) {
            return [
                getters.grossRemuneration,
                {
                    id: getters.totalEmployerContributionsExSdlUif.id,
                    label: 'Employer Contributions',
                    api: getters.totalEmployerContributionsExSdlUif.api,
                    current: getters.totalEmployerContributionsExSdlUif.current,
                    planned: getters.totalEmployerContributionsExSdlUif.planned,
                },
                getters.salaryBeforeDeductions,
                getters.totalEmployeeDeductionsCalc,
                getters.nettPayCalc,
            ]
        }
        // catch all but it shouldn't get here
        else {
            return [
                getters.totalPackage,
                getters.grossRemuneration,
                {
                    id: getters.totalEmployerContributionsExSdlUif.id,
                    label: 'Employer Contributions',
                    api: getters.totalEmployerContributionsExSdlUif.api,
                    current: getters.totalEmployerContributionsExSdlUif.current,
                    planned: getters.totalEmployerContributionsExSdlUif.planned,
                },
                getters.totalReimbursementAllowancesExAdHoc,
                getters.salaryBeforeDeductions,
                getters.totalEmployeeDeductionsCalc,
                getters.nettPayCalc,
            ]
        }

    },

}