import AdminAllPagesArchive from "@/components/admin-restricted/AdminAllPagesArchive.vue"
import AdminApiVsCalculatedData from "@/components/admin-restricted/pages/AdminApiVsCalculatedData.vue"
import AdminReportsDownloadLinks from "@/components/admin-restricted/pages/AdminReportsDownloadLinks.vue"
import {adminViewOnly} from "@/router/funcs/routeGuarding"
import EmployeeSelectorPage from "@/components/admin-restricted/pages/EmployeeSelectorPage.vue"
import SalaryFiguresDeductions from "@/components/admin-restricted/salary-figures/SalaryFiguresDeductions.vue"
import AdminRestrictedPayeCalcsPage from "@/components/admin-restricted/paye-calcs/AdminRestrictedPayeCalcsPage.vue"
import AdminTestingPage from "@/components/admin-restricted/pages/AdminTestingPage.vue"
import AdminRestrictedFinancialTables from "@/components/admin-restricted/pages/AdminRestrictedFinancialTables.vue"
import AllEmployeesTable from "@/components/admin-restricted/pages/AllEmployeesTable.vue"

// Notes:
// could make these local admin page guards a global guard by using a meta property on the route. meta: {isAdminPage: true}
// then in global guard if it has that meta property, check user is admin otherwise redirect
// I think better for performance to keep checks to local routes. Otherwise, check will run on every route change

export default [
    { name: 'bento-admin-financial-tables', path: '/bento-admin/financial-tables', component: AdminRestrictedFinancialTables, beforeEnter: () => { return adminViewOnly() }},
    { name: 'bento-admin-all-employees', path: '/bento-admin/all-employees', component: AllEmployeesTable, beforeEnter: () => { return adminViewOnly() }},
    { name: 'bento-admin-reports-download-links', path: '/bento-admin/reports-download-links', component: AdminReportsDownloadLinks, beforeEnter: () => { return adminViewOnly() }},
    { name: 'bento-admin', path: '/bento-admin', component: AdminAllPagesArchive, beforeEnter: () => { return adminViewOnly() }},
    { name: 'bento-admin-api-vs-calculated-data', path: '/bento-admin/api-vs-calculated-data', component: AdminApiVsCalculatedData, beforeEnter: () => { return adminViewOnly() }},
    { name: 'bento-admin-employee-selector', path: '/bento-admin/employee-selector', component: EmployeeSelectorPage, beforeEnter: () => { return adminViewOnly() }},
    { name: 'bento-admin-deductions', path: '/bento-admin/deductions', component: SalaryFiguresDeductions, beforeEnter: () => { return adminViewOnly() }},
    { name: 'bento-admin-paye-calcs', path: '/bento-admin/paye-calcs', component: AdminRestrictedPayeCalcsPage, beforeEnter: () => { return adminViewOnly() }},
    { name: 'bento-admin-testing-page', path: '/bento-admin/testing-page', component: AdminTestingPage, beforeEnter: () => { return adminViewOnly() }},
]