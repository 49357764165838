<template>
    <div class="d-flex mb-4">
        <div id="switch-container" class="d-flex">
            <div
                :class="{selected: selectedLeaveView === 'balances'}" class="switch-option switch-option-1 me-2"
                @click="selectViewHandler('balances')"
            >
                Balances
            </div>
            <div class="switch-option-separator"></div>
            <div
                :class="{selected: selectedLeaveView === 'calendar'}" class="switch-option switch-option-2 ms-2"
                @click="selectViewHandler('calendar')"
            >
                Calendar
            </div>
        </div>
    </div>
</template>

<script setup>
    import {computed} from "vue"
    import {useStore} from "vuex";
    const store = useStore()
    const selectedLeaveView = computed(() => store.getters.selectedLeaveView) // 'balances' || 'calendar'
    const selectViewHandler = (option) => {
        store.commit('setSelectedLeaveView', option)
    }
    

</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    #switch-container {
        background-color: #FAFAFA;
        border: 1px solid rgba($card-border-color, .75);
        border-radius: 13px;
        height: 50px;
    }
    
    .selected {
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.08);
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
        border-radius: 13px;
    }
    
    .switch-option {
        height: 50px;
        padding: 0 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: -1px;
        width: 110px;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        @media (min-width: 420px) {
            width: 140px;
        }
        @include media-breakpoint-up(sm) {
            width: 180px;
            font-size: 15px;
        }
        @include media-breakpoint-up(md) {
            width: 195px;
            font-size: 16px;
        }
        
        &:hover {
            background-color: white;
            border: 1px solid rgba(0, 0, 0, 0.08);
            box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
            border-radius: 13px;
        }
        &.switch-option-1 {
            left: -1px;
        }
        &.switch-option-2 {
            right: -1px;
        }
    }
    
    .switch-option-separator {
        width: 1px;
        background-color: rgba(0, 0, 0, 0.2);
        height: 20px;
        position: relative;
        top: 15px;
    }
    
</style>