// Model in the MVVM: Plain JavaScript objects here for now
// i.e. will be replaced by API and updated through CMS
export default {

    retirementAnnuity: {
        id: 'retirement',
        slug: 'retirement',
        componentTag: 'retirement-annuity',
        title: 'Retirement',
        provider: 'Momentum',
        providerLogo: 'momentum.png',
        // subHeading: 'R1000 pm', content in single vue component card
        // cardExpandedContent: ``, content in single vue component card
        // viewMoreText: 'View your contributions',
        value: 2250,
        plannedValue: 2250,
        benefitIntroText: `
            <p>You are contributing  <span class="span-no-wrap">R 1 000 pm</span> towards your Momentum Retirement Fund.  You are receiving <span class=" span-no-wrap">R 110</span> in tax benefits.
        `,
    },


    insurance: {
        id: 'insurance',
        slug: 'insurance',
        title: 'Insurance',
        // subHeading: 'R1000 pm', content in single vue component card
        // cardExpandedContent: ``, content in single vue component card
        // viewMoreText: 'Check your cover', content in single vue component card
        value: 750,
        plannedValue: 750,

    },

    leave: {
        id: 'leave',
        slug: 'leave',
        title: 'Leave',
        // subHeading: '5 days', content in single vue component card
        // cardExpandedContent: ``, content in single vue component card
        // viewMoreText: 'View and apply for leave', content in single vue component card
    }

}