// route array partials
import employeeProfileAndDirectory from "@/router/routes/partials/employee-profile-and-directory"
import managerViews from "@/router/routes/partials/manager"
import bentoAdminViewOnly from "@/router/routes/partials/bento-admin-view-only"
import loginAndTerms from "@/router/routes/partials/login-and-terms"
import simpleBenefits from "@/router/routes/partials/simple-benefits"
import auxiliaryPages from "@/router/routes/partials/auxiliary-pages"
import leave from "@/router/routes/partials/leave"
import reimbursements from "@/router/routes/partials/reimbursements"
import benefits from "@/router/routes/partials/benefits"
import learnAndExplore from "@/router/routes/partials/learn-and-explore"
import home from "@/router/routes/partials/home"
export default [
    ...home,
    ...loginAndTerms,
    ...employeeProfileAndDirectory,
    ...managerViews,
    ...benefits,
    ...leave,
    ...reimbursements,
    ...learnAndExplore,
    ...simpleBenefits,
    ...bentoAdminViewOnly,
    ...auxiliaryPages,
]