import LoginPage from "@/components/pages/login-auth/LoginPage.vue";
import UserTermsPrivacyPolicyPage from "@/components/pages/user-terms-privacy-policy/UserTermsPrivacyPolicyPage.vue";
import UserTermsPrivacyPolicyPageReadOnly
    from "@/components/pages/user-terms-privacy-policy/UserTermsPrivacyPolicyPageReadOnly.vue";

export default [
    { name: 'login', path: '/login', component: LoginPage, meta: { isLoginPage: true } },
    { name: 'accept-terms', path: '/accept-user-terms-and-privacy-policy', component: UserTermsPrivacyPolicyPage, meta: { isTermsPage: true } },
    { name: 'terms-read-only', path: '/user-terms-and-privacy-policy', component: UserTermsPrivacyPolicyPageReadOnly },
]
