<template>
    
    <div
        class="card-container-plan"
        @click="updatetempSelectedMedicalAidContractObj(plan)"
        :class="{'selected': plan.id === tempSelectedMedicalAidContractObj.plan_id}"
    >
        
        <div class="plan-title">{{ plan.name }}</div>
        
        <div class="plan-cost-container">
            <div class="cost-intro-line">
                Total plan cost
            </div>
            <div class="plan-cost">
                {{ bentoCurrencyFormat(planTotalCost) }}
            </div>
            <div class="plan-based-on-line">
                <span class="based">Based on:</span>
                <ul>
                    <li>Main member</li>
                    <li v-if="adultDependants">+ {{ adultDependants }} adult dependant<span v-if="adultDependants>1">s</span></li>
                    <li v-if="childDependants">+ {{ childDependants }} child dependant</li>
                </ul>
            </div>
        </div>
        
        <div class="more-information-plan">
            <span class="text-link"><a :href="externalLink" target="_blank">More information</a></span>
            <span><a class="external-link-icon-plan" :href="externalLink" target="_blank"><img :src="externalLinkIcon" alt="more info"/></a></span>
        </div>
    </div>
</template>

<script>
    import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"
    import {mapGetters} from "vuex"
    
    export default {
        name: "WizardBlockInfoCardPlan",
        props: ['plan'],
        emits: ['planSelectHandler'],
        
        methods: {
            bentoCurrencyFormat,
            
            // updating the temp plan
            updatetempSelectedMedicalAidContractObj(plan) {
                // Get the slide index
                const slideIndex = plan.index
                this.$emit('planSelectHandler', slideIndex)
                
                const tempContractObj = {
                    ...this.tempSelectedMedicalAidContractObj,
                    plan_id: plan.id,
                };
                this.$store.dispatch('setTempSelectedMedicalAidContractObj', tempContractObj)
                
            },
        },
        
        computed: {
            ...mapGetters([
                'imageDir',
                'tempSelectedMedicalAidContractObj',
                'medicalAidPlanList',
            ]),
            
            adultDependants() {
              return (this.tempSelectedMedicalAidContractObj.adult_dependents) ? Number(this.tempSelectedMedicalAidContractObj.adult_dependents) : 0;
            },
    
            childDependants() {
                return (this.tempSelectedMedicalAidContractObj.child_dependents) ? Number(this.tempSelectedMedicalAidContractObj.child_dependents) : 0;
            },
            
            planTotalCost() {
                const adults = Number(this.adultDependants);
                const adultDependantCost = Number(this.plan.adult_dependent_cost);
                const children = Number(this.childDependants);
                const childDependantCost = Number(this.plan.child_dependent_cost);
                const mainMemberCost = Number(this.plan.main_member_cost);
                return mainMemberCost + (adults*adultDependantCost) + (children*childDependantCost);
            },
            
            externalLinkIcon() {
                return this.imageDir + 'external.png';
            },
            
            externalLink() {
                let link;
                switch (this.plan.series) {
                    case "Executive":
                        link = 'https://www.discovery.co.za/medical-aid/executive-plan';
                        break;
                    case "Comprehensive":
                        link = 'https://www.discovery.co.za/medical-aid/comprehensive-series';
                        break;
                    case "Core":
                        link = 'https://www.discovery.co.za/medical-aid/core-series'
                        break;
                    case "Saver":
                        link = 'https://www.discovery.co.za/medical-aid/saver-series';
                        break;
                    case "Priority":
                        link = 'https://www.discovery.co.za/medical-aid/priority-series';
                        break;
                    case "Smart":
                        link = 'https://www.discovery.co.za/medical-aid/smart-series';
                        break;
                    default:
                        link = 'https://www.discovery.co.za/medical-aid/';
                }
                return link;
            },
            
        }
    }
</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    // needs to be unscoped to reach tags in v-html
    
    .card-container-plan {
        background-color: white;
        border-radius: $border_radius;
        margin: 5px;
        padding: 15px;
        height: 262px;
        width: 256px;
        position: relative;
        text-align: left;
        cursor: pointer;
        
        &.selected {
            background-color: $primary-light;
        }
        
        &:hover {
            background-color: $primary-light;
        }
    }
    
    .plan-title {
        font-size: 17px;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 16px;
        line-height: 1.3;
    }
    
    .cost-intro-line {
        color: #979797;
        font-size: 13px;
        font-weight: 500;
    }
    
    .plan-cost {
        font-weight: 700;
        font-size: 24px;
        margin: 6px 0;
    }
    
    .plan-based-on-line {
        span.based {
            color: #979797;
            font-size: 12px;
            font-weight: 500;
        }
        font-size: 12px;
        ul {
            margin-top: 3px;
            padding-left: 15px;
        }
    }
    
    .more-information-plan {
        position: absolute;
        left: 20px;
        width: calc(100% - 40px);
        bottom: 20px;
        display: flex;
        
        a {
            position: relative;
            font-weight: 300;
            font-size: 13px;
            display: inline-block;
            width: 110px;
            
            &:hover {
                font-weight: 500;
            }
            
            &:after {
                content: '';
                width: 104px;
                position: absolute;
                height: 1px;
                bottom: -1px;
                background-color: black;
                left: 0;
            }
        }
    }
    
    .external-link-icon-plan {
        position: relative;
        top: 4px;
        display: inline-block;
        padding-left: 8px;
        
        &:after {
            display: none;
        }
        
        img {
            width: 16px;
        }
    }

</style>