<template>
    <width-container>
        <loading-full-screen v-model="isLoading" loadingText="Loading admin data ..."/>
        
        <div v-if="!isLoading">
            <salary-figures/>
            <employee-inputs/>
        </div>
        
    </width-container>
</template>

<script setup>
    import SalaryFigures from "@/components/admin-restricted/salary-figures/SalaryFigures"
    import EmployeeInputs from "@/components/admin-restricted/api-vs-payroll-data/EmployeeInputs"
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer"
    import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen"
    import {loadAllApiData} from "@/hooks/api/api-dispatch-calls"
    import {loadBentoAdminData} from "@/hooks/api/api-dispatch-calls"
    import {ref} from "vue"
    
    const isLoading = ref(false)
    const onLoad = async () => {
        isLoading.value = true
        await loadAllApiData()
        await loadBentoAdminData()
        isLoading.value = false
    }
    onLoad()
</script>