<template>
    <div class="menu-item-1st-container px-1" :class="classList">
        <div class="main-item font-weight-500 font-size-14" :class="{'cursor-default': isCurrent}" @click="isCurrent ? isExpanded = true : isExpanded = !isExpanded">
            <span class="d-inline-block">
                <slot name="title"></slot>
            </span>
        </div>
        <MDBCollapse class="submenu pt-1 pb-0" v-model="isExpanded" v-if="$slots.subMenuItems">
            <slot name="subMenuItems"></slot>
        </MDBCollapse>
    </div>
</template>

<script setup>
    import {MDBCollapse} from "mdb-vue-ui-kit"
    import {computed, onMounted, ref, watch} from "vue"
    
    const props = defineProps({
        isExpandedProp: {
            type: Boolean,
            default() {
                return false
            }
        },
        routeName: {
            type: String,
            required: false,
        },
        routePath: {
            type: String,
            required: false,
        },
        isCurrent: {
            type: Boolean,
            required: false,
            default() {
                return false
            }
        }
    })
    
    const classList = computed(() => {
        return {'expanded': props.isCurrent || isExpanded.value}
    })
    
    
    const isExpanded = ref(false)
    watch(() => props.isExpandedProp, (newValue) => {
        if (props.isCurrent) {
            isExpanded.value = true
        } else {
            isExpanded.value = newValue
        }
    })
    watch(() => props.isCurrent, (newValue) => {
        isExpanded.value = newValue
    })
    
    onMounted(() => {
        if (props.isCurrent) {
            isExpanded.value = true
        } else {
            isExpanded.value = props.isExpandedProp
        }
        
    })
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .menu-item-1st-container {
        margin-bottom: 28px;
    }
    
    .main-item {
        cursor: pointer;
        color: rgba(0,0,0,0.88);
        border-left: 2px solid rgba($primary, .15);
        padding: 2px 4px 2px 10px;
        &:hover {
            @include media-breakpoint-up(md) {
                border-left-color: $primary;
            }
        }
    }
    .current-parent {
        .main-item {
            border-left-color: $primary;
        }
    }
</style>