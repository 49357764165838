<template>
    <div class="mt-n3">
        
        <card-general-inner class="green no-shadow gradient-green-text-dark mb-4">
            <ul class="mb-0 list-unstyled font-weight-500 gradient-green-text-light">
                <li v-for="change in textSummaryOfChangesMedical" :key="change" v-html="change"></li>
            </ul>
        </card-general-inner>
        
        <bento-alert v-if="areMedicalAidDraftChanges" color="secondary" static v-model="theAlertDraft" style="line-height:1.9!important" class="mb-0">
            <template #title>Please be advised</template>
            <template #default>Be confident that you’ve received the right input from an advisor, or feel free to ask Bento for further guidance to have the peace of mind of making the right decisions.</template>
        </bento-alert>
        <bento-alert v-if="areMedicalAidChangesSubmitted" color="primary" static v-model="theAlertSubmitted" style="line-height:1.9!important" class="mb-0">
            Your medical aid changes have been submitted and are being processed.<br>
            You will be notified once the changes have been finalised.
        </bento-alert>
    </div>
</template>

<script setup>
    import {computed, ref} from "vue";
    import {useStore} from "vuex";
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert";
    import CardGeneralInner from "@/components/UI/v2/containers-cards-headers/CardGeneralInner.vue"
    const store = useStore()

    const areMedicalAidDraftChanges = computed(() => store.getters.areMedicalAidDraftChanges)
    const theAlertDraft = ref(true)
    const areMedicalAidChangesSubmitted = computed(() => store.getters.areMedicalAidChangesSubmitted)
    const theAlertSubmitted = ref(true)
    
    const textSummaryOfChangesMedical = computed(() => store.getters.textSummaryOfChangesMedical)
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    ul {
        line-height: 1.9;
        :deep(li span) {
            font-weight: 600;
            color: $gradient-green-text-dark;
        }
    }
    
    h5 {
        font-size: 1.3rem;
    }
</style>