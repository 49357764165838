<template>
    <div class="split-button-cont text-primary text-center font-weight-600 font-size-15 d-flex flex-nowrap justify-content-start" :class="{'expanded': expanded}">
        
        <div class="the-badge rounded-5" :class="{'cursor-pointer': ($route.name !== 'home' && !isTeamDash)}" @click="myDashClickHandler">
            <div>
                <slot></slot>
            </div>
        </div>
        
        <div
            class="the-dropdown-button rounded-5 cursor-pointer d-flex justify-content-center align-items-center"
            @click="$emit('toggleMenuDash')"
        >
            <div class="chevron">
                <img src="@/assets/v2/icons/chevron-primary.svg"/>
            </div>
        </div>
        
    </div>
</template>

<script setup>
    import {useRouter, useRoute} from "vue-router";
    const router = useRouter()
    const route = useRoute()
    const props = defineProps({
        expanded: {
            type: Boolean,
            default() {
                return false
            }
        },
        isTeamDash: {
            required: false,
            type: Boolean,
            default() {
                return false
            }
        }
    })
    
    const myDashClickHandler = () => {
        if (route.name !== 'home' && !props.isTeamDash) {
            router.push({ name: 'home' })
        }
    }
</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .the-badge {
        cursor: default;
        background-color: $gray-100;
        padding: 9px 12px 9px 12px;
        transition: all .2s ease-in-out;
        width: 180px;
        &.cursor-pointer {
            cursor: pointer;
            &:hover {
                background-color: darken($gray-100, 5%);
            }
        }
    }
    
    .the-dropdown-button {
        background-color: $gray-100;
        transition: all .2s ease-in-out;
        width: 30px;
        margin-left: 6px;
        &:hover {
            background-color: darken($gray-100, 5%);
        }
    }
    
    .chevron img {
        width: 7px;
        transition: all .1s ease-in-out;
        position: relative;
        top: -1px;
    }
    .expanded .chevron img {
        transform: rotate(90deg);
    }
</style>