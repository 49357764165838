/**
 * public-holidays-future.js
 *
 * Used in futureDaysCalc function in LeaveCalendarInterface.vue to calculate the amount of actual days a leave request represents
 * I.e. the number of future leave days between a start and end date,
 * excluding weekends and public holidays.
 *
 * public-holidays.js is used in the LeaveCalendarInterface.vue to highlight public holidays on the calendar.
 *
 * This file contains an array of public holidays for the years 2022-- onward.
 * NOTE: I do need to carry on adding to this manually, not ideal
 * Each holiday is represented as an object with a `date` and `label` property.
 *
 * - `date`: The date of the holiday in the format "YYYY, M, D".
 * - `label`: A descriptive label for the holiday.
 *
 * Functions:
 * - `formatDate`: Imported function to format Date objects.
 * - `Date.prototype.addDays`: Extends the Date prototype to add a specified number of days to a date.
 * - `isChristmas`: Function to check if a given date is Christmas.
 *
 * Special Logic:
 * - If a public holiday falls on a Sunday and it's not Christmas, an 'extra' Monday is added to the list.
 *
 * Exports:
 * - `arrayOfDateStrings`: An array of formatted date strings representing the public holidays, including 'extra' Mondays.
 *
 *  Output Example:
 *  [
 *     "09-08-2022",
 *     "24-09-2022",
 *     "16-12-2022",
 *     ........ etc
 * ]
 *
 */


const publicHols = [
    {
        date: "2022, 8, 9",
        label: "Women's Day",
    },
    {
        date: "2022, 9, 24",
        label: "Heritage Day",
    },
    {
        date: "2022, 12, 16",
        label: "Day of Reconciliation",
    },
    {
        date: "2022, 12, 25",
        label: "Christmas Day",
    },
    {
        date: "2022, 12, 26",
        label: "Day of Goodwill",
    },
    {
        date: "2022, 12, 27",
        label: "Declared Public Holiday",
    },

    // 2023
    {
        date: "2023, 1, 1",
        label: "New Year's Day",
    },
    {
        date: "2023, 3, 21",
        label: "Human Rights Day",
    },
    {
        date: "2023, 4, 7",
        label: "Good Friday",
    },
    {
        date: "2023, 4, 10",
        label: "Family Day",
    },
    {
        date: "2023, 4, 27",
        label: "Freedom Day",
    },
    {
        date: "2023, 5, 1",
        label: "Worker's Day",
    },
    {
        date: "2023, 6, 16",
        label: "Youth Day",
    },
    {
        date: "2023, 8, 9",
        label: "Women's Day",
    },
    {
        date: "2023, 9, 24",
        label: "Heritage Day",
    },
    {
        date: "2023, 12, 15",
        label: "Day of Hope, Celebration and Unity",
    },
    {
        date: "2023, 12, 16",
        label: "Day of Reconciliation",
    },
    {
        date: "2023, 12, 25",
        label: "Christmas Day",
    },
    {
        date: "2023, 12, 26",
        label: "Day of Goodwill",
    },

    // 2024
    {
        date: "2024, 1, 1",
        label: "New Year's Day",
    },
    {
        date: "2024, 3, 21",
        label: "Human Rights Day",
    },
    {
        date: "2024, 3, 29",
        label: "Good Friday",
    },
    {
        date: "2024, 4, 01",
        label: "Family Day",
    },
    {
        date: "2024, 4, 27",
        label: "Freedom Day",
    },
    {
        date: "2024, 5, 1",
        label: "Worker's Day",
    },
    {
        date: "2024, 6, 16",
        label: "Youth Day",
    },
    {
        date: "2024, 8, 9",
        label: "Women's Day",
    },
    {
        date: "2024, 9, 24",
        label: "Heritage Day",
    },
    {
        date: "2024, 12, 15",
        label: "Day of Hope, Celebration and Unity",
    },
    {
        date: "2024, 12, 16",
        label: "Day of Reconciliation",
    },
    {
        date: "2024, 12, 25",
        label: "Christmas Day",
    },
    {
        date: "2024, 12, 26",
        label: "Day of Goodwill",
    },

    // 2025
    {
        date: "2025, 1, 1",
        label: "New Year's Day",
    },
    {
        date: "2025, 3, 21",
        label: "Human Rights Day",
    },
    {
        date: "2025, 4, 18",
        label: "Good Friday",
    },
    {
        date: "2025, 4, 21",
        label: "Family Day",
    },
    {
        date: "2025, 4, 27",
        label: "Freedom Day",
    },
    {
        date: "2025, 5, 1",
        label: "Worker's Day",
    },
    {
        date: "2025, 6, 16",
        label: "Youth Day",
    },
    {
        date: "2025, 8, 9",
        label: "Women's Day",
    },
    {
        date: "2025, 9, 24",
        label: "Heritage Day",
    },
    {
        date: "2025, 12, 15",
        label: "Day of Hope, Celebration and Unity",
    },
    {
        date: "2025, 12, 16",
        label: "Day of Reconciliation",
    },
    {
        date: "2025, 12, 25",
        label: "Christmas Day",
    },
    {
        date: "2025, 12, 26",
        label: "Day of Goodwill",
    },
]

import {formatDate} from "@/hooks/general/date-helpers";

Date.prototype.addDays = function (days) {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

const isChristmas = (dateObj) => {
    const month = dateObj.getMonth();
    const dayOfMonth = dateObj.getDate();
    if (month === 11 && dayOfMonth === 25) {
        return true;
    }
    return false;
}

// Include 'extra' monday holidays for days holidays that fall on Sunday
const arrayOfDateStrings = publicHols.flatMap( day => {

    const currentDate = new Date(day.date)
    const isSunday = (currentDate.getDay() === 0)
    const currentDayObj = formatDate(currentDate)

    if (isSunday && !isChristmas(currentDate)) {

        return [
            currentDayObj,
            formatDate( currentDate.addDays(1) ),
        ]

    }
    return currentDayObj
})

export default arrayOfDateStrings