<template>
    <width-container>
        <page-header>Reports - Dowload Links</page-header>
        
        <card-general>
            <ul>
                <li>
                    <div><a :href="lifeContributionsReportLink" target="_blank">OM Life Contributions Report</a></div>
                    <ul>
                        <li>The above link uses current logged in user employer ID. Which will be Bento - Test. EID: 1</li>
                        <li>Only CCFO Cape Town (Employer ID: 14) using is this report currently</li>
                        <li><a href="https://app.mybento.net/reports/life/life_contributions/14/" target="_blank">https://app.mybento.net/reports/life/life_contributions/14/</a></li>
                    </ul>
                </li>
                <li>
                    <div><a href="https://app.mybento.net/reports/billing/employer_billing_report_xlsx/" target="_blank">Generate Monthly Billing Report</a></div>
                    <ul>
                        <li>CCFO Cape Town currently needs to be done manually to include all employees, until their employees are onboarded.</li>
                        <li>GinBar, except for the 5 RA employees, need to make all of their other employees inactive.</li>
                    </ul>
                </li>
                <li>
                    <div><span @click="downloadLeaveReportHandler">Leave Report</span></div>
                    <ul>
                        <li>For current logged in user's employer.</li>
                    </ul>
                </li>
            </ul>
        </card-general>
        
    </width-container>
</template>

<script setup>
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer.vue"
    import PageHeader from "@/components/UI/v2/containers-cards-headers/PageHeader.vue"
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
    import {ref} from "vue"
    import {useStore} from "vuex"
    import {currentUrlOrigin} from "@/hooks/api/app-environment-origin"
    
    const store = useStore()
    const apiDownloadError = ref(false)
    
    
    const employerId = store.getters.employer_id
    const lifeContributionsReportLink = `${currentUrlOrigin()}/reports/life/life_contributions/${employerId}/`
    
    const downloadLeaveReportHandler = async () => {
        try {
            await store.dispatch('downloadLeaveReport')
        } catch (error) {
            apiDownloadError.value = error.message
        }
    }
</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    
    li {
        margin: 30px 0;
        &:first-child { margin-top: 0; }
        &:last-child { margin-bottom: 0; }
        a, span {
            cursor: pointer;
            font-size: 17px;
            &:hover {
                color: $primary;
            }
        }
    }
    
    
    ul {
        ul {
            li {
                margin: 0;
            }
            a, li {
                font-size: 14px;
                color: $gray-main;
            }
        }
    }
</style>