// employee medical getters
import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"
import {isValidArray} from "@/hooks/general/type-and-value-checks"

export default {

    // currently taken from hard coded js file
    medicalAidSeriesList: state => state.medicalAidSeriesList,

    // temp selected medical aid contract object
    tempSelectedMedicalAidContractObj: state => state.tempSelectedMedicalAidContractObj,

    // medical aid plan list
    medicalAidPlanList: state => state.medicalAidPlanList,
    lastMedicalAidPlanListFetch: state => state.lastMedicalAidPlanListFetch,
    shouldUpdateMedicalAidPlanList: (_, getters) => {
        if (!getters.lastMedicalAidPlanListFetch) { return true }
        const currentTimeStamp = new Date().getTime()
        return ((currentTimeStamp - getters.lastMedicalAidPlanListFetch) / 1000) > 900
    },


    // medical aid contract change request list
    medicalAidContractChangeRequestList: (state) =>  (state.medicalAidContractChangeRequestList) ? (state.medicalAidContractChangeRequestList) : null,
    lastMedicalAidContractChangeRequestListFetch: state => state.lastMedicalAidContractChangeRequestListFetch,
    shouldUpdateMedicalAidContractChangeRequestList: (_, getters) => {
        if (!getters.lastMedicalAidContractChangeRequestListFetch) { return true }
        const currentTimeStamp = new Date().getTime()
        return (currentTimeStamp - getters.lastMedicalAidContractChangeRequestListFetch) / 1000 > 900
    },




    // Medical Aid Figures
    // ---------------------------------------------------------------------------------------
    // This is actually 3 values: Adult Dependants, Child Dependants, Medical Aid Plan Selected
    // D: Number of Medical Aid Members
    hasMedicalAidContract: (_, getters) => !!getters.medicalAidContractObj,
    hasMedicalAidContractPlanned: (_, getters) => !!getters.medicalAidContractObjPlanned,

    // taken from medicalAidContractObj
    medicalAidPlanId: (_, getters) => {
        const current = (getters.medicalAidContractObj) ? Number(getters.medicalAidContractObj.plan_id) : false
        let planned = current
        if (getters.medicalAidContractObjPlanned) {
            planned = getters.medicalAidContractObjPlanned.plan_id
        } else if (current === null) {
            planned = null
        }

        return {
            id: 'medical-aid-plan-id',
            label: 'Medical Aid Plan ID',
            api: current,
            current: current,
            planned: planned,
        }
    },

    medicalAidProvider: (_, getters) => {
        const current = (getters.medicalAidContractObj) ? (getters.medicalAidContractObj.provider) : null
        let planned = current
        if (getters.medicalAidContractObjPlanned) {
            planned = getters.medicalAidContractObjPlanned.provider
        } else if (current === null) {
            planned = null
        }

        return {
            id: 'medical-aid-provider',
            label: 'Medical Aid Provider',
            api: current,
            current: current,
            planned: planned,
        }
    },



    // taken from medicalAidContractObj && medicalAidContractObjPlanned
    medicalAidPlanName: (_, getters) => {
        const current = (getters.medicalAidContractObj) ? (getters.medicalAidContractObj.name) : null
        let planned = current
        if (getters.medicalAidContractObjPlanned) {
            planned = getters.medicalAidContractObjPlanned.name
        } else if (current === null) {
            planned = null
        }

        return {
            id: 'medical-aid-plan-id',
            label: 'Medical Aid Plan Name',
            api: current,
            current: current,
            planned: planned,
        }
    },

    medicalAidPlanAddonId: (_, getters) => getters.hasMedicalAidContract ? Number(getters.medicalAidContractObj.medical_aid_plan_addon) : false,


    // taken from medicalAidContractObj
    adultDependants: (_, getters) => {

        // taken from medicalAidContractObj && medicalAidContractObjPlanned
        let currentFromContract = 0
        if (getters.medicalAidContractObj) {
            currentFromContract = getters.medicalAidContractObj.adult_dependents
        }

        let plannedFromContract = 0
        if (getters.medicalAidContractObjPlanned) {
            plannedFromContract = getters.medicalAidContractObjPlanned.adult_dependents
        }

        return {
            id: 'adult-dependants',
            label: 'Adult Dependants',
            api: currentFromContract,
            current: currentFromContract,
            planned: plannedFromContract,
        }
    },

    // taken from medicalAidContractObj
    childDependants: (_, getters) => {
        // would be nice to get the SimplePay value as well.

        // taken from medicalAidContractObj && medicalAidContractObjPlanned
        let currentFromContract = 0
        if (getters.medicalAidContractObj) {
            currentFromContract = getters.medicalAidContractObj.child_dependents
        }

        let plannedFromContract = 0
        if (getters.medicalAidContractObjPlanned) {
            plannedFromContract = getters.medicalAidContractObjPlanned.child_dependents
        }

        return {
            id: 'child-dependants',
            label: 'Child Dependants',
            api: currentFromContract,
            current: currentFromContract,
            planned: plannedFromContract,
        }
    },


    medicalAidTaxCredit: (_, getters) => {

        return {
            id: 'medical-aid-tax-credit',
            label: 'Medical Aid Tax Credit',
            api: Number(getters.medicalAidContractObj?.tax_credit ?? 0),
            current: Number(getters.medicalAidContractObj?.tax_credit ?? 0),
            planned: getters.hasMedicalAidContractPlanned ? Number(getters.medicalAidTaxCreditCalc.planned) : Number(getters.medicalAidContractObj?.tax_credit ?? 0)
        }
    },

    // this can be 'draft' / 'submitted' / otherwise it will return null
    medicalAidContractObjPlanned: (state, getters) => getters.medicalAidContractObjDraft || getters.medicalAidContractObjSubmitted,
    medicalAidContractObjDraft: (state, getters) => {
        if (!isValidArray(getters.medicalAidContractChangeRequestList)) return null
        return getters.medicalAidContractChangeRequestList.find( obj => obj.status === 'draft')
    },
    medicalAidContractObjSubmitted: (state, getters) => {
        if (!isValidArray(getters.medicalAidContractChangeRequestList)) return null
        return getters.medicalAidContractChangeRequestList.find( obj => obj.status === 'submitted')
    },

    // *** not sure why I did the Object.keys.. check below
    // medicalAidContractObjPlanned(state, getters) {
    //     if (!isValidArray(getters.medicalAidContractChangeRequestList)) return null
    //
    //     const contractObj = getters.medicalAidContractChangeRequestList.find(
    //         obj => obj.status === 'draft' || obj.status === 'submitted'
    //     )
    //
    //     return contractObj && Object.keys(contractObj).length ? contractObj : null
    // },

    // Medical Aid Contract Object
    // --------------------------------------
    // "medical_aid_contract": {
    //     "plan_id": 1,
    //     "name": "Discovery Coastal Core",
    //     "provider": "Discovery",
    //     "main_member": "Horak, Ross Adrian",
    //     "member_number": "12345678910",
    //     "adult_dependents": 2,
    //     "child_dependents": 0,
    //     "employer_contribution": 2500,
    //     "employee_contribution": 985,
    // },

    textSummaryOfChangesMedical(_, getters) {
        const currentPlan = getters.medicalAidContractObj
        let newPlan = getters.medicalAidContractObjPlanned
        let changeDescriptionArray = []

        if (!currentPlan && !newPlan) {
            changeDescriptionArray.push('You do not currently have a medical aid plan or a selected medical aid plan')
            return changeDescriptionArray
        }

        if (getters.hasMedicalAidContract && getters.medicalAidPlanChangeIndicators) {
            if (getters.medicalAidPlanChangeIndicators.name) {
                changeDescriptionArray.push(`Your plan has changed from <span>${currentPlan.provider} ${currentPlan.name}</span> to <span>${newPlan.provider} ${newPlan.name}.</span>`)
            } else {
                changeDescriptionArray.push(`Your plan is <span>${newPlan.provider} ${newPlan.name}</span>`)
            }
            if (getters.medicalAidPlanChangeIndicators.provider) {
                changeDescriptionArray.push(`Your medical aid provider has changed from <span>${currentPlan.provider}</span> to <span>${newPlan.provider}.</span>`)
            }
            // if (getters.medicalAidPlanChangeIndicators.employer_contribution) {
            //     changeDescriptionArray.push(`Your employer contribution has changed from <span class='span-in-card'>${bentoCurrencyFormat(currentPlan.employer_contribution)}</span> to <span>${bentoCurrencyFormat(newPlan.employer_contribution)}.</span>`)
            // } else {
            //     changeDescriptionArray.push(`Your employer contribution is <span class='span-in-card'>${bentoCurrencyFormat(newPlan.employer_contribution)}</span>`)
            // }
            if (getters.hasMedicalAidContract) {
                changeDescriptionArray.push(`Your employer contribution is <span>${bentoCurrencyFormat(newPlan.employer_contribution)}</span>`)
            }
            if (getters.medicalAidPlanChangeIndicators.employee_contribution) {
                changeDescriptionArray.push(`Your own contribution has changed from <span>${bentoCurrencyFormat(currentPlan.employee_contribution)}</span> to <span>${bentoCurrencyFormat(newPlan.employee_contribution)}.</span>`)
            }
            if (getters.medicalAidPlanChangeIndicators.adult_dependents) {
                changeDescriptionArray.push(`Adult dependants has changed from <span>${currentPlan.adult_dependents}</span> to <span>${newPlan.adult_dependents}.</span>`)
            }
            if (getters.medicalAidPlanChangeIndicators.child_dependents) {
                changeDescriptionArray.push(`Child dependants has changed from <span>${currentPlan.child_dependents}</span> to <span>${newPlan.child_dependents}.</span>`)
            }
            changeDescriptionArray.push(`Your total medical aid package is <span>${bentoCurrencyFormat(Number(newPlan.employer_contribution) + Number(newPlan.employee_contribution))}</span>`);
            changeDescriptionArray.push(`You will receive a tax benefit of <span>${bentoCurrencyFormat(getters.medicalAidTaxCredit.planned)} pm</span>`);

        } else {
            changeDescriptionArray = [
                `Your new plan is <span>${newPlan.provider} ${newPlan.name}.</span>`,
                `You have <span>${newPlan.adult_dependents}</span> proposed adult dependants and <span>${newPlan.child_dependents}</span> proposed child dependants.`,
                `Your employer will contribute <span>${bentoCurrencyFormat(newPlan.employer_contribution)} pm</span> towards your plan`,
                `You will contribute <span>${bentoCurrencyFormat(newPlan.employee_contribution)}</span> pm towards the plan`,
                `You will receive a tax benefit of <span>${bentoCurrencyFormat(getters.medicalAidTaxCredit.planned)} pm.</span>`,
            ]
        }
        return changeDescriptionArray
    },

    // *** plan change object ***
    // adult_dependents: true
    // child_dependents: false
    // employee_contribution: true
    // employer_contribution: false
    // name: true
    // provider: false
    medicalAidPlanChangeIndicators(_, getters) {
        let planChangeObject = {}
        const currentPlan = getters.medicalAidContractObj
        const newPlan = getters.medicalAidContractObjPlanned
        if (!currentPlan || !newPlan) return false
        for (const key in currentPlan) {
            if (key !== 'plan_id' && key !== 'main_member' && key !== 'member_number') {
                planChangeObject[key] = (currentPlan[key] != newPlan[key])
            }
        }
        return planChangeObject
    }

}
