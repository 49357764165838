<template>
    <div :class="[{'col-md-6': !fullWidth},'col-12 card-col']" class="position-relative">
        <MDBTextarea
            :label="labelRequired" v-model="name" @input="$emit('update:modelValue', $event.target.value)"
            rows="3" class="rounded"
            :required="isRequired"
            :invalidFeedback="invalidFeedback"
            :validFeedback="validFeedback"
            :maxlength="maxLengthValue"
        />
        <div class="character-counter">{{ name.length }} / {{ maxLengthValue }}</div>
    </div>
</template>

<script setup>
    import {computed, ref} from "vue";
    import {MDBTextarea} from 'mdb-vue-ui-kit'

    const props = defineProps({
        label: {
            type: String,
            required: true
        },
        inputValue: {
            type: String || null,
            required: true,
            default() {
                return ''
            }
        },
        fullWidth: {
            type: Boolean,
            required: false,
            default() {
                return false
            }
        },
        isRequired: {
            type: Boolean,
            required: false,
            default() {
                return true
            }
        },
        invalidFeedback: {
            type: String,
            required: false,
        },
        validFeedback: {
            type: String,
            required: false,
        },
        maxLengthValue: {
            type: Number,
            required: false
        }
    })

    const labelRequired = computed(() => {
        if (props.isRequired) {
            return props.label + '*'
        }
        return props.label
    })
    const name = ref(props.inputValue)
</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    :deep(.form-outline) {
        
        .form-control {
            min-height: 52px;
            color: $gradient-green-text-dark;
        }
        
        label.form-label {
            font-size: 13px;
        }
        
    }
    
    .character-counter {
        width: 100%;
        position: absolute;
        font-size: 0.875em;
        color: $gray-main;
        text-align: right;
    }
</style>