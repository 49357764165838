<template>
    <div class="card-container">
        
        <div class="top-sub-title">
            {{ topSubTitle }}
        </div>
        
        <div class="title">
            {{ description.section }}
        </div>
        
        <div class="content" v-html="description.text"></div>
        
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    export default {
        name: "WizardBlockInfoCardBenefit",
        props: {
            description: {
                type: Object,
                required: true,
            }
        },
        
        computed: {
    
            ...mapGetters([
                'imageDir',
                'tempSelectedMedicalAidContractObj',
                'medicalAidPlanList',
            ]),
    
            topSubTitle() {
                return 'Benefits';
            },
            
            externalLink() {
                return 'https://www.discovery.co.za/medical-aid/compare-medical-aid-plans';
            },
            
            externalLinkIcon() {
               return this.imageDir + 'external.png';
            },
            
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .card-container {
        background-color: $primary-light;
        border-radius: $border_radius;
        margin: 5px;
        padding: 10px 15px;
        height: 320px;
        width: 256px;
        position: relative;
        text-align: left;
        cursor: grab;
        overflow-y: hidden;
        &:active {
            cursor: grabbing;
        }
    }
    
    .top-sub-title {
        color: $grey;
        font-size: 13px;
        font-weight: 500;
    }
    
    .title {
        font-size: 19px;
        font-weight: 600;
        margin-top: 3px;
        margin-bottom: 12px;
    }
    
    .content {
        font-size: 13px;
        line-height: 1.4;
        :deep(a) {
            @include bento_underline;
            font-weight: 500;
        }
    }
</style>