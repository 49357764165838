<template>
    <div>
        <loading-full-screen v-model="isUpdatingRequest" loadingText="Updating reimbursement claim ..."/>

        <loading-full-screen v-if="isLoading" loadingText="Loading reimbursement claims ..."/>

        <card-general v-else id="requests-table">
            <h3>Reimbursement Claims</h3>

            <div v-if="apiErrorMessage || successMessage" id="api-message-response-container">
                <div v-if="apiErrorMessage">
                    <bento-alert class="mt-4" static color="danger" dismiss v-model="showApiErrorMessage">
                        <span v-html="apiErrorMessage"></span>
                    </bento-alert>
                </div>
                <div v-if="successMessage">
                    <bento-alert icon-type="success-tick" class="mt-4" static dismiss v-model="showSuccessMessage">
                        {{ successMessage }}
                    </bento-alert>
                </div>
            </div>

            <div class="d-flex justify-content-between mt-4 align-items-center flex-wrap">
                <div class="flex-grow-1 d-flex align-items-center flex-wrap">
                    <data-table-filter
                        filter-name="Employee filter" filter-identifier="employee-filter" placeholder-text="Select employee ..."
                        :filter-options="filterEmployeeOptions" @option-selected="optionSelectedHandler" class="ms-0 mx-1"
                        :selected-options-string="filteredEmployeeListString"
                        options-selected-label="employees selected"
                    />
                    <reimbursement-claims-filter
                        filter-name="Reimbursement Allowance" filter-identifier="reimbursement-type-filter" placeholder-text="Select reimbursement allowance ..."
                        :filter-options="filterTypeOptions" @option-selected="optionSelectedHandler" class="me-2"
                        :selected-options-string="filteredTypeListString"
                        options-selected-label="allowances selected"
                    />
                    <data-table-filter
                        filter-name="Request Status" filter-identifier="status-filter" placeholder-text="Select claim status ..."
                        :filter-options="filterStatusOptions" @option-selected="optionSelectedHandler" class="mx-1"
                        :selected-options-string="filteredStatusListString"
                        options-selected-label="statuses selected"
                    />
                </div>

                <button-bento color="primary" @click="clearFilters" :disabled="!areSelectionsMade" class="filter-button mb-3">
                    <span v-if="areSelectionsMade">Clear Filters</span><span v-else>No filters applied</span>
                </button-bento>
            </div>

            <div v-if="reimbursementAllowanceListAllApprovees">
                <MDBDatatable
                    :dataset="tableDataset"
                    hover
                    fixedHeader
                    striped
                    :entries="25"
                    id="reimbursement-claims-table" class="my-3"
                    @render="renderActions"
                    sm
                    noFoundMessage="No matching reimbursement claims ..."
                />
            </div>
        </card-general>

        <modal-basic v-model="claimNoteModal">
            <template #title>Claim Note</template>
            <template #body>
                <p class="fst-italic">"{{ claimNoteModalText }}"</p>
                <p class="font-weight-500">- {{ claimNoteAuthor }}</p>
            </template>
        </modal-basic>

        <modal-basic v-model="attachmentNoteModal">
            <template #title>Claim Note</template>
            <template #body>
                <p class="fst-italic">Uploaded invoices / receipts for this claim:</p>
                <p class="font-weight-500" v-html="attachmentNoteLinks"></p>
            </template>
        </modal-basic>
    </div>
</template>

<script setup>
import {isValidArray} from "@/hooks/general/type-and-value-checks"
import {formatDate} from "@/hooks/general/date-helpers"
import {MDBDatatable} from 'mdb-vue-ui-kit'
import {computed, ref, nextTick} from "vue"
import {useStore} from "vuex"
import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral"
import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen"
import BentoAlert from "@/components/UI/v2/alerts/BentoAlert"
import ModalBasic from "@/components/UI/v2/modals/ModalBasic"
import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters";
import DataTableFilter from "@/components/manager/shared-ui-components/DataTableFilter.vue";
import {toTitleCase} from "@/hooks/general/text-formatters";
import ReimbursementClaimsFilter
    from "@/components/benefits-leave-reimbursements/reimbursements/components/reimbursement-claims-lists/ReimbursementClaimsFilter.vue";

const store = useStore()

const isUpdatingRequest = ref(false)
const apiErrorMessage = ref(null)
const showApiErrorMessage = ref(true)
const successMessage = ref(null)
const showSuccessMessage = ref(true)

// This is a users own reimbursement allowance list
const reimbursementAllowanceListAllApprovees = computed(() => store.getters.reimbursementAllowanceListAllApprovees)

const getAllowanceDetailsByID = (id) => {
    const allowance = reimbursementAllowanceListAllApprovees.value.find(allowance => allowance.id == id)
    if (allowance) {
        return {name: allowance.name, method: allowance.method}
    }
    return null
}

const renderActions = () => {
    setActionEventListeners()
    setDownloadListener()
    setNoteReadListener()
    setColspan()
}

// Data prep flow: 1.reimbursementApproveeList --> 2.rawData --> 3.data --> inserted in tableDataset as data.rows
const reimbursementApproveeList = computed(() => store.getters.reimbursementApproveeList)

// Options for filter dropdowns ( 1. employee, 2. reimbursement type 3. status)
// 1. The employee filter
const filterEmployeeOptions = []
for (const approvee of reimbursementApproveeList.value) {
    const option = {text: approvee.name, value: approvee.id}
    filterEmployeeOptions.push(option)
}

// 2. The reimbursement allowance type filter
const filterTypeOptions = computed(() => {
    const uniqueNames = []
    const options = []
    if (reimbursementAllowanceListAllApprovees.value) {
        for (const balanceObj of reimbursementAllowanceListAllApprovees.value) {
            if (!uniqueNames.includes(balanceObj.name)) {
                uniqueNames.push(balanceObj.name)
                const option = {text: balanceObj.name, value: balanceObj.name}
                options.push(option)
            }
        }
    }
    return options
})


// 3. The claims status filter
// value needs to be like this for filtering purposes as that is what is in the table
const filterStatusOptions = [
    {text: 'Submitted', value: '<button class="state-badge state-badge-submitted">Submitted</button>'},
    {text: 'Approved', value: '<button class="state-badge state-badge-approved">Approved</button>'},
    {text: 'Rejected', value: '<button class="state-badge state-badge-rejected">Rejected</button>'},
    {text: 'Cancelled', value: '<button class="state-badge state-badge-cancelled">Cancelled</button>'},
    {text: 'Error', value: '<button class="state-badge state-badge-error">Error</button>'}
]

// Takes the approvee list, and builds out a list of all reimbursement claims for all approvees
// Enrich the regular claim object with employee_name,
const rawData = computed(() => {
    let list = []
    for (const approvee of reimbursementApproveeList.value) {
        const reimbursementClaimsEnriched = approvee.reimbursement_claims.map(request => {
            return {
                ...request,
                employee_name: request.employee.name,
                formatted_amount: `${bentoCurrencyFormat(request.amount)}`,
            }
        })
        list.push(...reimbursementClaimsEnriched)
    }
    return list
})

const filteredEmployeeList = ref(null)
const filteredEmployeeListString = computed(() => isValidArray(filteredEmployeeList.value) ? filteredEmployeeList.value.join(',') : null)

const filteredTypeList = ref(null)
const filteredTypeListString = computed(() => isValidArray(filteredTypeList.value) ? filteredTypeList.value.join(',') : null)

const filteredStatusList = ref(null)
const filteredStatusListString = computed(() => isValidArray(filteredStatusList.value) ? filteredStatusList.value.join(',') : null)

const areSelectionsMade = computed(() => !!filteredStatusList.value || !!filteredEmployeeList.value)

const clearFilters = () => {
    filteredEmployeeList.value = null
    filteredTypeList.value = null
    filteredStatusList.value = null
}

const optionSelectedHandler = (selectedOptionsList, filterIdentifier) => {
    if (filterIdentifier === 'employee-filter') {
        filteredEmployeeList.value = selectedOptionsList.value
    }
    if (filterIdentifier === 'reimbursement-type-filter') {
        filteredTypeList.value = selectedOptionsList.value
    }
    if (filterIdentifier === 'status-filter') {
        filteredStatusList.value = selectedOptionsList.value
    }
}

// 1. inject extra content into each request row
// 2. apply filters - updates the computed property
const data = computed(() => {
    let datasetRows = rawData.value.map(requestRow => {
        const downloadIconSrc = require('@/assets/v2/icons/request-attachment.svg')
        const noteIconSrc = require('@/assets/v2/icons/request-note.svg')

        // Inside the data preparation loop
        const fileUrlsCommaSeparatedString = requestRow.files.map(fileObj => fileObj.file).join(", ")
        const allowanceDetails = getAllowanceDetailsByID(requestRow.allowance) ?? {name: undefined, method: undefined}
        const {name: allowanceName, method: allowanceMethod} = allowanceDetails
        const allowanceMethodNiceName = allowanceMethod === 'adhoc' ? 'Ad Hoc' : 'Payroll'

        // Need to escape inverted commas in notes
        const escapedNotes = requestRow.notes.replace(/"/g, '&quot;');

        return {
            ...requestRow,
            allowance_name: toTitleCase(allowanceName),
            allowance_method: toTitleCase(allowanceMethodNiceName),
            created_at: formatDate(requestRow.created_at, true),
            approver: requestRow.approver?.name,
            state: `<button class="state-badge state-badge-${requestRow.state}">${requestRow.state.charAt(0).toUpperCase() + requestRow.state.slice(1)}</button>`,
            actions: `
                            <button class="action-badge approve-badge approve-badge-for-claim ${requestRow.state}" data-request-id="${requestRow.id}" data-employee-id="${requestRow.employee.id}" data-employee-name="${requestRow.employee_name}" data-allowance-id="${requestRow.allowance}">Approve</button>
                            <button class="action-badge reject-badge reject-badge-for-claim ${requestRow.state}" data-request-id="${requestRow.id}" data-employee-id="${requestRow.employee.id}" data-employee-name="${requestRow.employee_name}" data-allowance-id="${requestRow.allowance}">Reject</button>`,
            docs: `
                            <img title="View note" src="${noteIconSrc}" class="request-icon view-note-icon cursor-pointer me-1 d-inline-block" data-request-id="${requestRow.id}" data-request-note="${escapedNotes}" data-employee-name="${requestRow.employee_name}">
                            <img title="Download attachment" src="${downloadIconSrc}" class="request-icon download-attachment-icon d-inline-block cursor-pointer" data-attachment-url="${fileUrlsCommaSeparatedString}">`
        }
    })
    // reorder on the basis of the latest request
    datasetRows.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

    // The employee filter
    if (isValidArray(filteredEmployeeList.value)) {
        const numbersInArray = filteredEmployeeList.value.map(stringElement => Number(stringElement))
        datasetRows = datasetRows.filter(row => numbersInArray.includes(row.employee.id))
    }


    // The reimbursement type filter
    if (isValidArray(filteredTypeList.value)) {
        datasetRows = datasetRows.filter(row => {
            return filteredTypeList.value.map(type => type?.toLowerCase()).includes(row.allowance_name?.toLowerCase())
        })
    }

    // The status filter
    if (isValidArray(filteredStatusList.value)) {
        datasetRows = datasetRows.filter(row => filteredStatusList.value.includes(row.state))
    }

    return datasetRows
})

const tableDataset = computed(() => {
    return {
        columns: [
            {label: "ID", field: "id", width: 50},
            {label: "Status", field: "state", width: 92},
            {label: "Employee", field: "employee_name"},
            {label: "Amount", field: "formatted_amount", width: 110},
            {label: "Allowance", field: "allowance_name", width: 160},
            {label: "Actions", field: "actions", sort: false, width: 156},
            {label: "Docs", field: "docs", sort: false, width: 68},
            {label: "Submitted on", field: "created_at"},
            {label: "Invoice Date", field: "invoice_date"},
            {label: "Method", field: "allowance_method"},
            {label: "Approver", field: "approver", width: 120},
            {label: "Date Approved", field: "approval_date"},
        ],
        rows: data.value
    }
})

const setActionListenerForClaims = (actionType) => {
    let className
    let updatedState
    switch (actionType) {
        case 'approve':
            className = 'approve-badge-for-claim'
            updatedState = 'approved'
            break
        case 'reject':
            className = 'reject-badge-for-claim'
            updatedState = 'rejected'
            break
        default:
            return
    }

    Array.from(document.getElementsByClassName(className)).forEach(actionBtn => {
        if (actionBtn.getAttribute("click-listener") !== "true") {
            actionBtn.addEventListener("click", () => {
                const requestID = actionBtn.attributes["data-request-id"].value
                const allowanceID = actionBtn.attributes["data-allowance-id"].value
                const employeeID = actionBtn.attributes["data-employee-id"].value
                const employeeName = actionBtn.attributes["data-employee-name"].value
                updateClaimStateHandler({
                    requestID,
                    allowanceID,
                    updatedState,
                    employeeID,
                    employeeName,
                })
            })
            actionBtn.setAttribute("click-listener", "true")
        }
    });
}
// Listeners are set and reset on component render / rerender (which are triggered with actions like sort)
const setActionEventListeners = () => {
    setActionListenerForClaims('approve')
    setActionListenerForClaims('reject')
};


// SET ATTACHMENT VIEWER
const attachmentNoteModal = ref(false)
const attachmentNoteLinks = ref(null)
const setDownloadListener = () => {
    Array.from(document.getElementsByClassName('download-attachment-icon')).forEach(downloadBtn => {
        const fileUrlsCommaSeparatedString = downloadBtn.attributes["data-attachment-url"].value

        if (!fileUrlsCommaSeparatedString) {
            downloadBtn.classList.add("d-none")
        }


        if (downloadBtn.getAttribute("click-listener") !== "true" && fileUrlsCommaSeparatedString) {
            downloadBtn.addEventListener("click", () => {
                attachmentNoteModal.value = true
                const urls = fileUrlsCommaSeparatedString.split(",")
                attachmentNoteLinks.value = urls.map(url =>
                    `<a href="${url}" target="_blank" style="display: inline-block; margin-bottom: 10px; margin-right: 10px;">
                        <img src="${url}" width="100" height="100"/>
                    </a>`
                ).join("")
            });
            downloadBtn.setAttribute("click-listener", "true")
        }
    })
}


// SET NOTE VIEWER
const claimNoteModal = ref(false)
const claimNoteModalText = ref(null)
const claimNoteAuthor = ref(null)
const setNoteReadListener = () => {
    Array.from(document.getElementsByClassName('view-note-icon')).forEach(noteIcon => {
        const requestNotes = noteIcon.attributes["data-request-note"].value
        const employeeName = noteIcon.attributes["data-employee-name"].value

        if (requestNotes == 'null' || !requestNotes || requestNotes.trim() == '') {
            noteIcon.classList.add("d-none")
        }

        if (noteIcon.getAttribute("click-listener") !== "true" && requestNotes) {
            noteIcon.addEventListener("click", () => {
                claimNoteModal.value = true
                claimNoteModalText.value = requestNotes
                claimNoteAuthor.value = employeeName
            });
            noteIcon.setAttribute("click-listener", "true")
        }
    })
}

const scrollToRequestsTable = () => {
    const requestsTable = document.getElementById('requests-table')
    if (requestsTable) {
        const rect = requestsTable.getBoundingClientRect()
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop
        const tablePosition = rect.top + scrollTop - 100
        window.scrollTo({
            top: tablePosition,
            behavior: 'smooth'
        })
    }
}

const processingRequestPayload = ref(null)
const updateClaimStateHandler = async (payload) => {
    apiErrorMessage.value = null
    successMessage.value = null
    isUpdatingRequest.value = true
    processingRequestPayload.value = payload

    // if ad hoc xero claim - needs to open in new tab using decision urls - because of Xero setup
    // BRYN:  That's not going to work with Xero requests - remember I send back a 302 redirect to Xero which the browser has to follow
    const allowanceDetails = getAllowanceDetailsByID(payload.allowanceID) ?? {name: undefined, method: undefined}
    const {method: allowanceMethod} = allowanceDetails
    if (allowanceMethod === 'adhoc') {
        payload.isAdHocClaim = true
    }

    let response
    try {
        response = await store.dispatch('approveOrRejectReimbursementClaim', payload)
        await store.dispatch('loadReimbursementApproveeList', {forceRefresh: true})
    } catch (error) {
        apiErrorMessage.value = error.message
        isUpdatingRequest.value = false
    }


    if (response && response.response.status === 200) {

        const employeeName = payload.employeeName
        let state = response.responseData.state
        if (state === 'error') {
            apiErrorMessage.value = `${response.responseData.error_message}.<br>
The claim has been archived with an error status. Please <a class="font-weight-700 text-decoration-underline" href="mailto:hello@mybento.net">contact us</a> if you require assistance.`
        } else {
            successMessage.value = `Claim for ${employeeName} ${state}.`
        }
    }


    isUpdatingRequest.value = false
    // After updating the claim and showing any success or error messages
    scrollToRequestsTable()
}


// full width td - no matching claims
const setColspan = async () => {
    await nextTick() // Wait for the next DOM update cycle
    const table = document.querySelector('#reimbursement-claims-table table')
    if (!table) return // Exit if the table is not found

    const tdTags = table.getElementsByTagName('td');
    let searchText = 'No matching claims ...'
    let found
    // set colspan
    for (let i = 0; i < tdTags.length; i++) {
        if (tdTags[i].textContent == searchText) {
            found = tdTags[i]
        }
        tdTags[i].setAttribute('title', tdTags[i].innerText)
    }
    const numberOfCols = table.getElementsByTagName('th').length
    if (found) {
        found.colSpan = numberOfCols
    }
}


// load approvee reimbursement allowances
const isLoading = ref(true)
const loadReimbursementAllowanceListAllApprovees = async () => {
    isLoading.value = true
    try {
        await store.dispatch('loadReimbursementAllowanceListAllApprovees', {forceRefresh: false})
    } catch (error) {
        apiErrorMessage.value = error.message
    }
    isLoading.value = false
}
loadReimbursementAllowanceListAllApprovees()

</script>

<style lang="scss" scoped>
@import '@/styles/global-scss/variables-and-mixins.scss';

.filter-button {
    min-width: 166px;
}

:deep(.datatable-pagination) {
    border-top: none;
    margin-top: 15px;
}

// adapted from: https://stackoverflow.com/questions/4094126/how-to-add-border-radius-on-table-row
:deep(#reimbursement-claims-table) {

    table {
        border-collapse: separate !important;
        border-spacing: 0;

        td, th {
            border: 1px none #C6C6C6;
        }

        // the borders
        th {
            border-top-style: solid;
        }
        tr:last-child td {
            border-bottom-style: solid;
        }
        th:first-child {
            border-left-style: solid;
        }
        th:last-child {
            border-right-style: solid;
        }
        tr td:first-child {
            border-left-style: solid;
        }
        tr td:last-child {
            border-right-style: solid;
        }

        tr:last-child {
            height: 45px;
        }

        // the radiuses
        th:first-child {
            border-top-left-radius: 6px;
        }
        th:last-child {
            border-top-right-radius: 6px;
        }
        tr:last-child td:first-child {
            border-bottom-left-radius: 6px;
        }
        tr:last-child td:last-child {
            border-bottom-right-radius: 6px;
        }
    }

    thead {
        th {
            padding: 14px 18px 14px 12px;
            background-color: $gray-50;
            i {
                left: 2px;
            }
        }
    }
    tbody {
        td {
            padding: 10px 12px;
        }
    }
    &.datatable.datatable-striped tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, .01);
    }
    button.action-badge,
    button.state-badge {
        border: none;
        box-shadow: none;
        font-weight: 500;
        font-size: 11px;
        border-radius: 6px;
        color: white;
        display: inline-block;
        transition: all .2s ease-in-out;
        width: 57px;

        // actions
        &.cancelled, &.error, &.rejected {
            display: none;
        }
        &.approve-badge {
            &.approved {
                display: none;
            }
            background: $primary;
            margin-right: 4px;
            &:hover {
                background: darken($primary, 8%);
            }
        }
        &.reject-badge {
            &.approved {
                display: none;
            }
            background: $secondary;
            &:hover {
                background: darken($secondary, 10%);
            }
        }
        &.cancel-badge {
            &.submitted {
                display: none;
            }
            background: $gray-main;
            &:hover {
                background: darken($gray-main, 8%);
            }
        }

        // state
        &.state-badge-submitted {
            background: black;
        }
        &.state-badge-approved {
            background: $primary;
        }
        &.state-badge-rejected {
            background-color: #CAA5A5;
        }
        &.state-badge-cancelled {
            background-color: #B4B4B4;
        }
        &.state-badge-error {
            background-color: #D5474C;
        }
    }
    button.state-badge {
        width: 68px;
    }

    .request-icon {
        width: 14px;
    }
}
</style>