<template>
    <essential-benefit-page>
        <template #benefitTitle>Medical Aid</template>
        
        <template #benefitExtraContent>
            <card-general>
                <wizard-block-intro/>
                
                <wizard-block-employer-contribution v-if="employerMedicalAidContributionStrategyObj?.value || false"/>
                <wizard-block-members/>
                <wizard-block-medical-aid-product/>
                <wizard-block-plan-addons/>
                <wizard-block-monthly-payments/>
                <!--testing to remove-->
                <wizard-block-testing-info v-if="false"/>
            </card-general>
        </template>
        
        <template #resourcesSectionHeader>Learn more about your options.</template>
        <template #learnContentCards>
            <base-learn-card-small
                v-for="resource in learningResourcesMedicalApi"
                :key="resource.id"
                :resource="resource"
                :class="activeClass(resource)"
            />
        </template>
    </essential-benefit-page>
</template>

<script setup>
    import EssentialBenefitPage from "@/components/benefits-leave-reimbursements/EssentialBenefitPage"
    import WizardBlockEmployerContribution
        from "@/components/benefits-leave-reimbursements/medical-aid/wizard/WizardBlockEmployerContribution"
    import WizardBlockMembers from "@/components/benefits-leave-reimbursements/medical-aid/wizard/WizardBlockMembers"
    import WizardBlockIntro from "@/components/benefits-leave-reimbursements/medical-aid/wizard/WizardBlockIntro"
    import WizardBlockTestingInfo
        from "@/components/benefits-leave-reimbursements/medical-aid/wizard/WizardBlockTestingInfo"
    import WizardBlockMedicalAidProduct
        from "@/components/benefits-leave-reimbursements/medical-aid/wizard/WizardBlockMedicalAidProduct"
    import WizardBlockMonthlyPayments
        from "@/components/benefits-leave-reimbursements/medical-aid/wizard/WizardBlockMonthlyPayments"
    import WizardBlockPlanAddons
        from "@/components/benefits-leave-reimbursements/medical-aid/wizard/WizardBlockPlanAddons"
    import {computed} from "vue"
    import {useStore} from "vuex"
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
    import BaseLearnCardSmall from "@/components/learn-and-explore/BaseLearnCardSmall.vue";
    const store = useStore()
    
    import {useRoute} from "vue-router"
    const route = useRoute()
    const activeClass = (resource) => route.params.resourceSlug === resource.slug ? 'active-card' : ''
    
    const learningResourcesMedicalApi = computed(() => store.getters.learningResourcesMedicalApi)
    const employerMedicalAidContributionStrategyObj = computed(() => store.getters.employerMedicalAidContributionStrategyObj)
</script>