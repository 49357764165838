// retirement contract actions actions
import {apiResponseHandler} from "@/hooks/api/api-helper-function"
import theApiCall from '@/hooks/api/the-api-call'

export default {

    // 1. GET retirement fund list. List of available retirement funds.
    async loadRetirementFundList(context, payload) {
        if ( (!payload.forceRefresh && !context.getters.shouldUpdateRetirementFundList) || (!context.getters.isEnabledRetirement)) { return }

        const response = await theApiCall('retirement_funds/')
        const responseData = await apiResponseHandler(response, 'Could not load retirement fund list.', 'Retirement fund list resource not found. [404]')

        context.commit('setRetirementFundList', responseData)
        context.commit('setLastRetirementFundListFetch', {timestamp: new Date().getTime()})
    },

    // 2. I don't seem to be using this endpoint: https://staging.mybento.net/api/1.0/retirement_fund_providers/


    // 3. The retirement fund contract an employee currently has.
    // payslip used as SoT where possible, otherwise their contract object
    async loadEmployeeRetirementFundContractList(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateRetirementFundContractList) { return }

        const userApiId = (localStorage.userId) ? localStorage.userId : context.getters.userApiId
        const response = await theApiCall(`employees/${userApiId}/retirement_funds/`)
        const responseData = await apiResponseHandler(response, 'Could not load employee retirement fund data.', 'Employee retirement fund resource not found. [404]')

        context.commit('setEmployeeRetirementFundContractList', responseData)
        context.commit('setLastEmployeeRetirementFundContractListFetch', {timestamp: new Date().getTime()})
        await context.dispatch('loadTempSelectedRetirementFundContractObj', false)
    },


    // 4. GET retirement fund contract change request list.
    // Then dispatch to tempSelectedRetirementFundContractObj (which loads or sets the tempSelectedRetirementFundContractObj)
    loadRetirementFundContractChangeRequestList: async (context, payload) => {
        if ((!payload.forceRefresh && !context.getters.shouldUpdateRetirementFundContractChangeRequestList) || (!context.getters.isEnabledRetirement)) { return }

        const userApiId = localStorage.userId ? localStorage.userId : context.getters.userApiId
        const response = await theApiCall(`employees/${userApiId}/retirement_fund_change_requests/`)
        const responseData = await apiResponseHandler(response, 'Could not load retirement fund contract change request list.', 'Retirement fund change request list resource not found. [404]',)

        context.commit('setRetirementFundContractChangeRequestList', responseData)
        context.commit('setLastRetirementFundContractChangeRequestListFetch', {timestamp: new Date().getTime()})
        await context.dispatch('loadTempSelectedRetirementFundContractObj', false)
    },

    // {"beneficiaries": [{"surname": "", "idNumber": "", "firstName": "kjkjhjkhkjhkjh", "allocation": "", "occupation": "", "emailAddress": "", "relationship": "", "contactNumber": "", "receiveViaPost": ""}], "receive_via_post": "", "investing_on_behalf": ""}

    // 6. Load temp
    loadTempSelectedRetirementFundContractObj: async (context, forceClear = false) => {

        // if forceClear is true (used in newEmployeeSelectionHandler)
        let tempContractObj
        if (forceClear) {
            context.commit('setTempSelectedRetirementFundContractObj', null)
            return
        }

        // the store has been updated already from our other asynchronous actions so use that to populate
        const currentObj = context.getters.retirementFundContractObj
        const plannedObj = context.getters.retirementFundContractObjPlanned

        // get the planned contract first if there is one, otherwise the current contract, otherwise don't return anything
        if (plannedObj) {
            tempContractObj = {
                fundId: plannedObj.retirement_fund,
                employeeContribution: Number(plannedObj.monthly_contribution),
                extraData: plannedObj.extra_data
            }
        } else if (currentObj) {
            tempContractObj = {
                fundId: currentObj.retirement_fund.id,
                employeeContribution: Number(currentObj.employee_contribution),
                extraData: currentObj.extra_data
            }
        }
        await context.commit('setTempSelectedRetirementFundContractObj', tempContractObj);
    },


    // 5. Submit changes
    async submitRetirementFundContractChangeRequest(context, retirementFundContractObj) {
        // check if there is a draft
        // the unique id identifier of the change request object to update if there is one
        // also identifies the endpoint location e.g. https://staging.mybento.net/api/1.0/employees/1/retirement_fund_change_requests/69/
        const contractApiId = context.getters.retirementFundContractObjDraft?.id || null
        const httpMethod = (contractApiId === undefined || contractApiId === null) ? 'POST' : 'PUT'
        const urlEndPoint = (httpMethod === 'PUT') ? `employees/2/retirement_fund_change_requests/${contractApiId}/` : 'employees/2/retirement_fund_change_requests/'
        let contentType = 'application/json'
        let body = JSON.stringify(retirementFundContractObj)

        const response = await theApiCall(urlEndPoint, httpMethod, body, contentType)
        await apiResponseHandler(response, 'Can not update retirement fund contract.', 'Retirement fund contract resource not found. Can not update. [404]')

        // After sending change request, we refetch and load the change request list : not sure if this is necessary
        await context.dispatch('loadRetirementFundContractChangeRequestList', {forceRefresh: true})
    },
    // retirement fund contract change request object
    // {
    //     "retirement_fund": 7,
    //     "monthly_contribution": 2500,
    //     "extra_data": {"name": "bla"}, // JSON object
    //     "status": "draft"
    // }



    // Deletes a draft retirement fund contract change request object
    deleteRetirementFundDraftContractObjPlanned: async (context) => {
        let retirementFundContractChangeRequestList = context.getters.retirementFundContractChangeRequestList

        const contractObjToDelete = retirementFundContractChangeRequestList.find(obj => obj.status === 'draft')
        if (!contractObjToDelete) return

        const userApiId = localStorage.userId || context.getters.userApiId
        let urlEndPoint = `employees/${userApiId}/retirement_fund_change_requests/${contractObjToDelete.id}/`
        const response = await theApiCall(urlEndPoint, 'DELETE')

        if (!response.ok) {
            const error = new Error('Failed to delete contract object!')
            throw error
        }

        await context.dispatch('loadRetirementFundContractChangeRequestList', { forceRefresh: true }) // which also loads the tempSelectedRetirementFundContractObj
        await context.dispatch('loadTempSelectedRetirementFundContractObj', true)
    },
}