<template>
    <card-general-inner class="green no-shadow gradient-green-text-dark">
        <div class="d-flex align-items-center">
            <div class="profile-picture-cont ratio ratio-1x1 me-3" :style="{ backgroundImage: `url(${profilePicture(employee.profile_picture)})` }"></div>
            <div>
                <div class="fw-400">
                    <span class="fw-500">{{ name }}</span>
                </div>
                <div class="fw-400 text-gray-main font-size-13 lh-1" v-if="jobTitle">
                    {{ jobTitle }}
                </div>
                <div class="fw-400 text-gray-main font-size-13">
                    <a :href="`mailto:${email}`">{{ email }}</a>
                </div>
            </div>
        </div>
    </card-general-inner>
</template>

<script setup>
    import CardGeneralInner from "@/components/UI/v2/containers-cards-headers/CardGeneralInner.vue"
    import {nameAndPreferredName} from "@/hooks/general/employee-info-utilities"
    import {computed} from "vue"
    import {isProd} from "@/hooks/api/app-environment-origin";
    import {useStore} from "vuex";

    const store = useStore()
    const employer = computed(() => store.getters.employer)
    const isBentoDemoCompany = computed(() => employer.value.name === 'Bento Demo' || (employer.value.id === 6 && isProd()) )
    
    const props = defineProps({
        employee: {type: Object, required: true}
    })

    const name = computed(() => nameAndPreferredName(props.employee))
    const jobTitle = computed(() => props.employee?.job_title || null)
    const email = computed(() => isBentoDemoCompany.value ? 'example@email.com' : props.employee?.user?.email || null)
    
    const profilePicture = (picture) => picture ? picture.replace('http://', 'https://') : require('@/assets/v2/placeholders/user-placeholder.png')
</script>

<style scoped lang="scss">
    .profile-picture-cont {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        flex: 0 0 40px;
        border-radius: 50%;
    }
</style>