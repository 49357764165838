// Financial Services

import store from "@/store"

export function annualPayeTaxCalculator(
    annualTaxableIncome,
    taxBrackets = store.getters.currentYearFinancialTables.brackets,
    taxRebate = store.getters.taxRebate
) {
    let annualPaye

    // tax exempt
    if (annualTaxableIncome <= store.getters.incomeTaxThreshold) {
        annualPaye = 0
    }
    // bracket 1
    else if (annualTaxableIncome <= taxBrackets.bracket1.upper) {
        annualPaye = annualTaxableIncome * taxBrackets.bracket1.taxRate;
    }
    // bracket 2
    else if (annualTaxableIncome <= taxBrackets.bracket2.upper) {
        annualPaye = ((annualTaxableIncome - taxBrackets.bracket2.upperPrev) * taxBrackets.bracket2.taxRate) + taxBrackets.bracket2.baseTax;
    }
    // bracket 3
    else if (annualTaxableIncome <= taxBrackets.bracket3.upper) {
        annualPaye = ((annualTaxableIncome - taxBrackets.bracket3.upperPrev) * taxBrackets.bracket3.taxRate) + taxBrackets.bracket3.baseTax;
    }
    // bracket 4
    else if (annualTaxableIncome <= taxBrackets.bracket4.upper) {
        annualPaye = ((annualTaxableIncome - taxBrackets.bracket4.upperPrev) * taxBrackets.bracket4.taxRate) + taxBrackets.bracket4.baseTax;
    }
    // bracket 5
    else if (annualTaxableIncome <= taxBrackets.bracket5.upper) {
        annualPaye = ((annualTaxableIncome - taxBrackets.bracket5.upperPrev) * taxBrackets.bracket5.taxRate) + taxBrackets.bracket5.baseTax;
    }
    // bracket 6
    else if (annualTaxableIncome <= taxBrackets.bracket6.upper) {
        annualPaye = ((annualTaxableIncome - taxBrackets.bracket6.upperPrev) * taxBrackets.bracket6.taxRate) + taxBrackets.bracket6.baseTax;
    }
    // bracket 7
    else if (annualTaxableIncome <= taxBrackets.bracket7.upper) {
        annualPaye = ((annualTaxableIncome - taxBrackets.bracket6.upperPrev) * taxBrackets.bracket6.taxRate) + taxBrackets.bracket6.baseTax;
    }

    // include rebate and min tax is 0
    (annualPaye - taxRebate > 0) ? (annualPaye -= taxRebate) : 0

    // MATC are accounted for outside this function
    return annualPaye
}


export function regularPayeTaxCalculator(
    yearlyTaxableIncome,
    taxCredit,
    taxBrackets = store.getters.currentYearFinancialTables.brackets,
    incomeTaxThreshold = store.getters.incomeTaxThreshold,
    taxRebate = store.getters.taxRebate
) {
    let payeYearly;

    // tax exempt
    if (yearlyTaxableIncome <= incomeTaxThreshold) {
        payeYearly = 0;
    }
    // bracket 1
    else if (yearlyTaxableIncome <= taxBrackets.bracket1.upper) {
        payeYearly = yearlyTaxableIncome * taxBrackets.bracket1.taxRate;
    }
    // bracket 2
    else if (yearlyTaxableIncome <= taxBrackets.bracket2.upper) {
        payeYearly = ((yearlyTaxableIncome - taxBrackets.bracket2.upperPrev) * taxBrackets.bracket2.taxRate) + taxBrackets.bracket2.baseTax;
    }
    // bracket 3
    else if (yearlyTaxableIncome <= taxBrackets.bracket3.upper) {
        payeYearly = ((yearlyTaxableIncome - taxBrackets.bracket3.upperPrev) * taxBrackets.bracket3.taxRate) + taxBrackets.bracket3.baseTax;
    }
    // bracket 4
    else if (yearlyTaxableIncome <= taxBrackets.bracket4.upper) {
        payeYearly = ((yearlyTaxableIncome - taxBrackets.bracket4.upperPrev) * taxBrackets.bracket4.taxRate) + taxBrackets.bracket4.baseTax;
    }
    // bracket 5
    else if (yearlyTaxableIncome <= taxBrackets.bracket5.upper) {
        payeYearly = ((yearlyTaxableIncome - taxBrackets.bracket5.upperPrev) * taxBrackets.bracket5.taxRate) + taxBrackets.bracket5.baseTax;
    }
    // bracket 6
    else if (yearlyTaxableIncome <= taxBrackets.bracket6.upper) {
        payeYearly = ((yearlyTaxableIncome - taxBrackets.bracket6.upperPrev) * taxBrackets.bracket6.taxRate) + taxBrackets.bracket6.baseTax;
    }
    // bracket 7
    else if (yearlyTaxableIncome <= taxBrackets.bracket7.upper) {
        payeYearly = ((yearlyTaxableIncome - taxBrackets.bracket6.upperPrev) * taxBrackets.bracket6.taxRate) + taxBrackets.bracket6.baseTax;
    }

    /*include rebate and min tax is 0*/
    (payeYearly - taxRebate > 0) ? (payeYearly -= taxRebate) : 0;

    /*deduct medical aid tax credit*/
    let payeMonthly = payeYearly / 12;
    payeMonthly -= taxCredit;

    payeMonthly = (payeMonthly < 0) ? 0 : payeMonthly;
    return payeMonthly;
}



