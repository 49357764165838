<template>
    <div>
        <div class="mt-4 pt-2 pt-md-0 mt-md-n5 mb-5 text-md-end">
            <button-bento @click="copyValuesFromCalculatorHandler" button-color="white-bg-green" class="shadow-hover" id="calculator-button"><img src="@/assets/v2/icons/other/calculator.svg">Copy values from calculator</button-bento>
        </div>
        
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap lh-1 font-size-15">
            
            <div class="figures-col flex-grow-0 gradient-green-text-dark pe-md-2 order-2 order-md-1">
                <div class="the-row figures-item d-flex align-items-center" v-if="false">
                    <div class="item-label">Retirement age</div>
                    <div class="item-value flex-grow-1">{{ retirementAge }}</div>
                </div>
                <div class="the-row figures-item d-flex align-items-center">
                    <div class="item-label">Your contribution</div>
                    <div class="item-value flex-grow-1">{{ bentoCurrencyFormat(employeeContributionNew, 0) }}</div>
                </div>
                <div class="the-row figures-item d-flex align-items-center">
                    <div class="item-label">Employer contribution</div>
                    <div class="item-value flex-grow-1">{{ bentoCurrencyFormat(employerContributionToShow, 0)}}</div>
                </div>
                <div class="the-row figures-item d-flex align-items-center">
                    <div class="item-label">Total monthly contribution</div>
                    <div class="item-value flex-grow-1">{{ bentoCurrencyFormat((+employeeContributionNew + employerContributionToShow), 0) }}</div>
                </div>
                <div class="the-row figures-item d-flex align-items-center">
                    <div class="item-label">Monthly tax benefit</div>
                    <div class="item-value flex-grow-1">{{ bentoCurrencyFormat(taxBenefitNew, 0) }}</div>
                </div>
                <div class="the-row figures-item d-flex align-items-center">
                    <div class="item-label">Take home pay</div>
                    <div class="item-value flex-grow-1">{{ bentoCurrencyFormat(takeHomePayNew, 0) }}</div>
                </div>
            </div>
            
            <div class="slider-col flex-grow-1 gradient-green-text-dark ms-md-4 order-1 order-md-2">
                <div class="the-row position-relative" v-if="false">
                    <div class="flex-grow-1">
                        <div class="slider-label">Retirement age: <span class="fw-500">{{ retirementAge }}</span></div>
                        <MDBRange v-model="retirementAge" :min="40" :max="80" :step="1"/>
                    </div>
                </div>
                <div class="the-row position-relative">
                    <div class="flex-grow-1">
                        <div class="fw-300 slider-label">Percentage of salary: <span class="fw-500">{{ percentageOfSalary }}%</span></div>
                        <MDBRange v-model="employeeContributionNew" :min="0" :max="maxContribution" :step="50"/>
                    </div>
                </div>
                <div class="the-row disclaimer-text d-none d-md-flex">
                    <div class="flex-grow-1">
                        <div v-if="retirementFundContributionStrategy === 'flat_rate'">
                            Your employer contributes a fixed amount as per the contribution policy.
                        </div>
                        <div v-else-if="retirementFundContributionStrategy === 'percentage_of_salary'">
                            Your employer contributes {{ roundToTwoDecimal(retirementFundContributionValue) }}% of your salary.
                        </div>
                        <div v-else>
                            Your employer has not set up a contribution policy.
                        </div>
                    </div>
                </div>
                <div class="the-row d-none d-md-flex"></div>
                <div class="the-row d-none d-md-flex disclaimer-text">
                    <div class="flex-grow-1" v-if="false">
                        Current: {{ bentoCurrencyFormat(taxBenefitCurrent, 0) }}
                    </div>
                </div>
                <div class="the-row d-none d-md-flex disclaimer-text">
                    <div class="flex-grow-1">
                        Current: {{ bentoCurrencyFormat(takeHomePayCurrent, 0) }}
                    </div>
                </div>
            </div>
        </div>
        

        
        <card-general-inner class="green no-shadow gradient-green-text-dark mt-3" v-if="showExplanationSummary">
            <div>
                <p class="mb-1">
                    Due to the <span class="fw-600">tax benefit</span>, it will only cost you <span class="fw-600">{{ bentoCurrencyFormat(costOfContribution, 0) }}</span> to put <span class="fw-600">{{ bentoCurrencyFormat(employeeContributionNew, 0) }}</span> towards your retirement savings!<br>
                    This doesn't include your employer contribution of <span class="fw-600">{{ bentoCurrencyFormat(employerContributionToShow, 0) }}</span>.
                    
                </p>
                <div class="disclaimer-text">** Please note <strong style="text-decoration: underline">this is an estimate</strong> and does not take into account any other deductions or tax benefits you may be eligible for.</div>
            </div>
        </card-general-inner>

        <!--testing-->
        <div class="mt-3" v-if="false">
            <div><pre>tempSelectedRetirementFundContractObj: {{ tempSelectedRetirementFundContractObj }}</pre></div>
            <div><pre>employeeContributionNew: {{ employeeContributionNew }}</pre></div>
            <div><pre>employerContributionToShow: {{ employerContributionToShow }}</pre></div>
            <div><pre>percentageOfSalary: {{ percentageOfSalary }} - this is what is being watched</pre></div>
            <div><pre>employeeContributionNew: {{ employeeContributionNew }} - this is what is being commited to temp, and then saved to draft</pre></div>
            <div><pre>retirementFundContractObj: {{ retirementFundContractObj }}</pre></div>
            <div><pre>basicIncomeCashPortion: {{ basicIncomeCashPortion }}</pre></div>
            <div><pre>monthlyIncome: {{ monthlyIncome }}</pre></div>
            <div><pre>basicPayCashPortion: {{ basicPayCashPortion }}</pre></div>
            <div><pre>monthlyIncome: {{ monthlyIncome }}</pre></div>
            <div><pre>taxBenefitCurrent: {{ taxBenefitCurrent }}</pre></div>
            <div><pre>taxBenefitNew: {{ taxBenefitNew }}</pre></div>
        </div>

    </div>
</template>

<script setup>
    import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"
    import {MDBRange} from "mdb-vue-ui-kit"
    import {ref, computed, watch} from "vue"
    import {useStore} from "vuex"
    import useRetirementTaxBenefitCalculator from "@/hooks/retirement/retirement-tax-benefit-calculator"
    import CardGeneralInner from "@/components/UI/v2/containers-cards-headers/CardGeneralInner.vue"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento.vue"
    import debounce from "lodash/debounce"
    import {roundToTwoDecimal} from "@/hooks/general/currency-number-formatters"
    const store = useStore()
    
    const tempSelectedRetirementFundContractObj = computed(() => store.getters.tempSelectedRetirementFundContractObj)
    const retirementFundContractObj = computed(() => store.getters.retirementFundContractObj)
    
    const employerRetirementFund = computed(() => store.getters.employerRetirementFund)
    const employerContributionToShow = computed(() => {
        const employerContribution = retirementFundContractObj.value?.employer_contribution
        return Number(employerContribution > 0 ? employerContribution : employerRetirementFund.value.planned)
    })

    const retirementFundContributionStrategy = store.getters.retirementFundContributionStrategy
    const retirementFundContributionValue = store.getters.retirementFundContributionValue

    const retirementAge = ref(65)

    const monthlyIncome = store.getters.monthlyIncome // this works out which one of the above is being used

    const maxContribution = monthlyIncome * 27.5/100

    const employeeContributionNew = ref(tempSelectedRetirementFundContractObj.value?.employeeContribution || 0)
    const percentageOfSalary = computed(() => {
        return Math.round(employeeContributionNew.value / monthlyIncome * 100)
    })
    
    const taxBenefitCurrent = 0
    const taxBenefitNew = ref(taxBenefitCurrent)
    
    const takeHomePayCurrent = store.getters.nettPayApi.api
    const takeHomePayNew = ref(takeHomePayCurrent)
    
    const costOfContribution = computed(() => employeeContributionNew.value - taxBenefitNew.value)
    
    // only commit to changes to tempSelectedRetirementFundContractObj if the user has stopped changing the slider for 200ms
    const debouncedTempCommit = debounce(() => {
        store.commit('setTempSelectedRetirementFundContractObj', {
            ...tempSelectedRetirementFundContractObj.value,
            employeeContribution: employeeContributionNew.value,
        })
    }, 200)
    
    // only use the run the debouncedCalculateHandler if the user has stopped changing the slider for 200ms (from watch -> percentageOfSalary)
    const debouncedCalculateHandler = debounce((commitToTemp = true) => {
        const {taxBenefitNew: taxBenefit, takeHomePayNew: takeHomePay} = useRetirementTaxBenefitCalculator(employeeContributionNew.value)
        taxBenefitNew.value = taxBenefit
        takeHomePayNew.value = takeHomePay
        
        if (commitToTemp) {
            debouncedTempCommit()
        }
    }, 200)
    
    
    const loadTempRetirementContract = () => {
        if (tempSelectedRetirementFundContractObj.value) {
            employeeContributionNew.value = tempSelectedRetirementFundContractObj.value.employeeContribution
        }
    }
    
    debouncedCalculateHandler(false)
    loadTempRetirementContract()
    
    watch(employeeContributionNew, () => {
        debouncedCalculateHandler(true)
    })
    
    const showExplanationSummary = computed(() => {
        return taxBenefitNew.value > 0 && employeeContributionNew.value > 0;
    })
    
    const calculatorRetirementContract = computed(() => store.getters.calculatorRetirementContract)
    const copyValuesFromCalculatorHandler = () => {
        employeeContributionNew.value = Number(calculatorRetirementContract.value.totalContribution) - Number(employerRetirementFund.value.planned)
        if (employeeContributionNew.value < 0) {
            employeeContributionNew.value = 0
        }
        store.commit('setTempSelectedRetirementFundContractObj', {
            ...tempSelectedRetirementFundContractObj.value,
            employeeContribution: employeeContributionNew.value
        })
    }
    
</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    #calculator-button {
        position: relative;
        top: -8px;
        img {
            position: relative;
            left: -6px;
        }
    }
    
    
    .slider-col, .figures-col {
        @include media-breakpoint-down(md) {
            flex: 0 0 100%;
        }
    }
    
    .the-row {
        line-height: 1.1;
        height: 40px;
        display: flex;
        align-items: center;
    }
    
    .slider-label {
        position: absolute;
        top: -4px;
        left: 0;
        font-size: 11px;
    }
    
    .item-label {
        flex: 1 auto;
        min-width: 210px;
        max-width: 210px;
    }
    
    .item-value {
        font-weight: 500;
    }
    
    :deep(.thumb-value) {
        position: absolute;
        top: -22px;
        left: 3px;
        color: #06957B !important;
        &:before {
            content: 'R';
            position: absolute;
            left: -8px;
        }
    }
</style>