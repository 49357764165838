// get info about employee
import {toTitleCase} from "@/hooks/general/text-formatters"

export const nameAndPreferredName = (employee) => {
    if (!employee) return ''
    if (employee.name) {
        return employee.name
    }
    let displayName = `${employee.user.first_name} ${employee.user.last_name}`
    if (employee.preferred_name && employee.preferred_name.trim() !== '') {
        displayName += ` (${employee.preferred_name})`
    }
    return displayName
}

export const getEmployeeFullName = (employee) => {
    if (!employee) return null
    return `${employee.user.first_name} ${toTitleCase(employee.user.last_name)}`
}