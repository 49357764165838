<template>
    
    <div class="employee-api">
        <h3>Employee Age, Rebates, Tax Thresholds</h3>
        
        <div class="input-table">
            
            <employee-inputs-row
                label="Age"
                :value="`${age} yrs`"
                note="Age at current date"
            />
    
            <employee-inputs-row
                label="Age on last day of tax year:"
                :value="`${ageOnLastDayOfTaxYear} yrs`"
                note="Used to determine income <strong>tax thresholds and rebates</strong>. Age has no effect on anything else as far as I know except for <strong>additional medical expenses tax credits</strong> which won't be applicable in the Bento App (think this is done outside the employer / employee relationship). Tax year is current financial tax year based on current employee payslip."
            />
    
            <employee-inputs-row
                label="Current financial tax year:"
                :value="currentFinancialYear"
                note="Based on employee <strong>current payslip</strong> being displayed in app."
            />
    
            <employee-inputs-row
                label="Income Tax Threshold:"
                :value="bentoCurrencyFormat(incomeTaxThreshold)"
                note="Based on financial tables and employee age (under 65, 65 and over, 75 and over)."
            />
    
            <employee-inputs-row
                label="Tax rebate:"
                :value="bentoCurrencyFormat(taxRebate)"
                note="Tax rebate is just the lowest income tax bracket rate (i.e. 18%) of the applicable tax threshold, but also taken directly from financial tables. Rebates are accumalative through the age brackets. View financial tables link to inspect values.<br> A set amount that SARS whacks off your total tax liability. Based on age as well obvs."
            />
    
            <div class="link-to-another-page">
                <router-link :to="{ path: '/bento-admin/financial-tables'}">View financial tables &#8599;</router-link>
            </div>
            
        </div>
        
    </div>

</template>

<script>
    import {mapGetters} from "vuex"
    import EmployeeInputsRow from "@/components/admin-restricted/api-vs-payroll-data/EmployeeInputsRow"
    import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"

    export default {
        name: "EmployeeInputs",
        components: {EmployeeInputsRow},
        computed: {
            ...mapGetters([
                'age',
                'ageOnLastDayOfTaxYear',
                'currentFinancialYear',
                'taxRebate',
                'incomeTaxThreshold',
            ])
        },
        methods: {
            bentoCurrencyFormat,
        }
    }
</script>

<style lang="scss" scoped>
    
    .employee-api {
        margin-top: 45px;
        margin-bottom: 45px;
        border: 1px solid black;
        padding: 15px 15px 30px;
    }
    
    h3 {
        margin-top: 6px;
        margin-bottom: 6px;
    }
    
    .input-table {
        margin-top: 20px;
    }
    
    
    pre {
        font-size: 14px;
    }
    
    .link-to-another-page {
        font-size: 13px;
        font-weight: 500;
        padding: 8px 2px;
        background-color: rgba(#75A2B6, .3);
        text-align: center;
        border-bottom: 1px solid #ddd;
        
        a {
            display: block;
        }
        &:hover {
            background-color: rgba(#75A2B6, .5);
        }
    }
    
</style>