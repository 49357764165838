<template>
    <MDBModal tabindex="-1" :class="classObject">
        <div class="p-3">
            <MDBModalHeader class="border-0 mb-1">
                <MDBModalTitle class="me-1">
                    <span><img v-if="modalType === 'error-modal'" src="@/assets/v2/icons/alert-icons/warning.svg" alt="Warning" class="mt-1 d-inline-block me-2 title-icon"/><slot name="title"/></span>
                </MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody class="pt-4 font-weight-500">
                <slot name="body"/>
            </MDBModalBody>
            <MDBModalFooter class="border-0 mt-2 align-center justify-content-center flex-wrap flex-sm-nowrap" v-if="$slots.button1 || $slots.button2">
                <div class="action-button-cont d-flex" v-if="$slots.button1">
                    <slot name="button1"/>
                </div>
                <div class="action-button-cont d-flex" v-if="$slots.button2">
                    <slot name="button2"/>
                </div>
            </MDBModalFooter>
        </div>
    </MDBModal>
</template>

<script setup>
    import {
        MDBModal,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalBody,
        MDBModalFooter,
    } from 'mdb-vue-ui-kit'
    import {computed} from "vue"
    
    const props = defineProps({
        hideCloseButton: {
            required: false,
            type: Boolean,
            default() {
                return false;
            }
        },
        modalType: {
            required: false,
            type: String,
            default() {
                return 'default-modal'
            }
        }
    })
    
    const classObject = computed(() => {
        return [
            {'hide-close-btn': props.hideCloseButton},
            props.modalType
        ]
    })
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .modal-title {
        font-size: 1.5rem;
    }
    
    .error-modal {
        .modal-title {
            color: $secondary-700;
        }
        .modal-body {
            color: $secondary-700;
        }
    }
    
    .title-icon {
        position: relative;
        top: -3px;
    }
    
    .hide-close-btn {
        :deep(.btn-close) {
            display: none !important;
        }
    }
    
    .action-button-cont {
        flex: 0 0 100%;
        @include media-breakpoint-up(sm) {
            flex: 0 0 50%;
        }
    }
    
    .modal-content {
        font-family: 'DM Sans', sans-serif;
    }
    
    :deep(.modal-xl), :deep(.modal-lg) {
        @include media-breakpoint-only(sm) {
            max-width: 560px !important;
        }
        @include media-breakpoint-only(md) {
            max-width: 720px !important;
        }
    }
</style>