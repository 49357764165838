<template>
    <div>
        <loading-full-screen v-if="isLoading" loadingText="Loading test page ..."/>
        
        <width-container>
            <card-general v-if="!isLoading">
                <div>
                    <header class="mb-5">
                        <h1>Testing Page</h1>
                        <p>General testing page</p>
                    </header>
                    
                    <button-bento class="d-inline-block" @click="triggerHandler1">
                        Trigger Handler 1
                    </button-bento>
                    <button-bento class="d-inline-block" @click="triggerHandler2">
                        Trigger Handler 2
                    </button-bento>
                    <h5 class="mt-4">Employee</h5>
                    <pre>{{ employee }}</pre>
                    
                    <h5 class="mt-4">Employer</h5>
                    <pre>{{ employer }}</pre>
                </div>
            </card-general>
        </width-container>
    </div>
</template>

<script setup>
    import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen"
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer"
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
    import {computed, ref} from "vue"
    import {useStore} from "vuex"
    import {loadAllApiData} from "@/hooks/api/api-dispatch-calls"
    const store = useStore();
    
    const employee = computed(() => store.getters.employee)
    const employer = computed(() => store.getters.employer)
    
    const showModal = ref(false)
    
    const triggerHandler1 = () => {
        onLoad()
    }

    const triggerHandler2 = () => {
        showModal.value = true
    }

    const isLoading = ref(false)
    const onLoad = async () => {
        isLoading.value = true
        await loadAllApiData(false)
        isLoading.value = false
    }
    onLoad()
</script>

<style scoped>
</style>