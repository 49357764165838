<template>
    <div class="summary-stat d-flex flex-nowrap justify-content-between my-1 py-1">
        <div class="stat-label">
            {{ stat.label }}
        </div>
        <div class="stat-value fw-500">
            <span class="current-stat-comparison">{{ bentoCurrencyFormat(displayStatType(stat)) }}</span>
        </div>
        <div v-if="false"><pre>{{ stat }}</pre></div>
    </div>
</template>

<script setup>
    import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"
    
    const props = defineProps({
        stat: {
            type: Object,
            required: true,
        },
        statType: {
            type: String,
        },
    })
    
    const displayStatType = (stat) => {
        // only api value
        if (stat.id === 'reimbursive-allowances-budget-ex-ad-hoc') {
            return stat.api;
        }
        else if (props.statType === 'planned') {
            return props.stat.planned;
        }
        // current calc values only hence don't use .api value
        else if (
            stat.id === 'total-package' ||
            stat.id === 'total-employer-contributions-ex-sdl-uif' ||
            stat.id === 'gross-remuneration'
        ) {
            return stat.current;
        } else {
            return props.stat.api;
        }
    }
</script>

<style lang="scss" scoped>
    // @import "@/styles/global-scss/variables-and-mixins.scss";
    .summary-stat {
        width: 420px;
        max-width: 100%;
        line-height: 1;
    }
</style>