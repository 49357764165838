// auth module

import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {

    state() {
        return {
            authToken: null,
            userId: null,
            actualUserId: null,
        }
    },

    mutations,
    actions,
    getters,

}