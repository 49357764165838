// employee reimbursements store module [not namespaced]

import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {

    state() {
        return {
            // `https://app.mybento.net/api/1.0/employees/${userApiId}/reimbursement_claims/`
            // list of claim request objects
            reimbursementClaimList: null,
            lastReimbursementClaimListFetch: null,


            // `https://app.mybento.net/api/1.0/employees/${userApiId}/reimbursement_allowances/`
            // list of reimbursement allowances (objects_ for the employee
            reimbursementAllowanceList: null,
            lastReimbursementAllowanceListFetch: null,
        }
    },

    mutations,
    actions,
    getters,
}