// retirement contract index
import mutations from './mutations';
import actions from './actions';
import getters from './getters';





export default {

    state() {
        return {

            // temp selected retirment contract object
            tempSelectedRetirementFundContractObj: null,


            // retirement fund list
            retirementFundList: null,
            lastRetirementFundListFetch: null,


            // retirement fund contract change request list
            retirementFundContractChangeRequestList: null,
            lastRetirementFundContractChangeRequestListFetch: null,


        }
    },

    mutations,
    actions,
    getters,
}