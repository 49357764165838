<template>
    <width-container>
        <card-general>
            <section-header>Calendar Links</section-header>
            <div class="font-size-14 text-gray-main font-weight-500">
                <p>
                    The URL's below provide a link (.ics) so you can subscribe to company available calendars, via a 3rd party calendar application, such as Google Calendar, Microsoft Outlook, Apple's iCal.
                </p>
                <p>
                    <a class="instructions-link bento-underline" href="https://content.mybento.net/media/assets/Importing_Bento_Calendar_into_Google_Calendar.pdf" target="_blank">Google calendar instructions</a><a href="https://content.mybento.net/media/assets/Importing%20Bento%20Team%20Calendar%20into%20Google%20Calendar.pdf" target="_blank"><img class="external-link" src="@/assets/v2/icons/other/external-link.svg" alt="external-link"/></a>
                </p>
            </div>
            
            <bento-alert color="primary" static v-model="leaveCalendar">
                <template #title>Leave Calendar</template>
                <template #default>
                    <div class="d-flex flex-wrap flex-sm-nowrap justify-content-between">
                        <p class="me-5 mb-0">
                            Subscribe to view your leave requests.
                        </p>
                        <div class="me-3 copy-link-cont">
                            <button-bento button-color="primary" @click="copyLink(leaveCalenderUrl, 'leave')" class="mt-3 mt-sm-0" id="leave-copy-link-btn">
                                <span v-if="!showLinkCopiedConfirmationLeave">Copy Link</span>
                                <span v-else>Link Copied</span>
                            </button-bento>
                        </div>
                    </div>
                </template>
            </bento-alert>
            
            <bento-alert color="secondary" static v-model="birthdayCalendar">
                <template #title>Birthday Calendar</template>
                <template #default>
                    <div class="d-flex flex-wrap flex-sm-nowrap">
                        <p class="me-5 mb-0">
                            Subscribe to view your colleague's birthdays.
                        </p>
                        <div class="me-3 copy-link-cont">
                            <button-bento button-color="secondary" @click="copyLink(birthdayCalendarUrl, 'birthday')" class="mt-3 mt-sm-0" id="birthday-copy-link-btn">
                                <span v-if="!showLinkCopiedConfirmationBirthday">Copy Link</span>
                                <span v-else>Link Copied</span>
                            </button-bento>
                        </div>
                    </div>
                </template>
            </bento-alert>
            
            <bento-alert color="info" static v-model="anniversaryCalendar">
                <template #title>Anniversary Calendar</template>
                <template #default>
                    <div class="d-flex flex-wrap flex-sm-nowrap">
                        <p class="me-5 mb-0">
                            Subscribe to view your colleague's work anniversaries.
                        </p>
                        <div class="me-3 copy-link-cont">
                            <button-bento button-color="info" @click="copyLink(anniversariesCalendarUrl, 'anniversaries')" class="mt-3 mt-sm-0" id="anniversary-copy-link-btn">
                                <span v-if="!showLinkCopiedConfirmationAnniversaries">Copy Link</span>
                                <span v-else>Link Copied</span>
                            </button-bento>
                        </div>
                    </div>
                </template>
            </bento-alert>
        
        </card-general>
    </width-container>
</template>

<script setup>
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer.vue"
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
    import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento.vue"
    
    import {useStore} from "vuex"
    import {ref, computed} from "vue"
    
    const store = useStore()
    
    const leaveCalenderUrl = computed(() => store.getters.leaveCalenderUrl)
    const showLinkCopiedConfirmationLeave = ref(false)
    const birthdayCalendarUrl = computed(() => store.getters.birthdayCalendarUrl)
    const showLinkCopiedConfirmationBirthday = ref(false)
    const anniversariesCalendarUrl = computed(() => store.getters.anniversariesCalendarUrl)
    const showLinkCopiedConfirmationAnniversaries = ref(false)
    
    
    const leaveCalendar = ref(true)
    const anniversaryCalendar = ref(true)
    const birthdayCalendar = ref(true)
    
    const copyLink = (link, button) => {
        navigator.clipboard.writeText(link)
        if (button === 'leave') {
            showLinkCopiedConfirmationLeave.value = true
        } else if (button === 'birthday') {
            showLinkCopiedConfirmationBirthday.value = true
        } else if (button === 'anniversaries') {
            showLinkCopiedConfirmationAnniversaries.value = true
        }
        
        setTimeout(() => {
                showLinkCopiedConfirmationLeave.value = false
                showLinkCopiedConfirmationBirthday.value = false
                showLinkCopiedConfirmationAnniversaries.value = false
            }, 3500
        )
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .instructions-link {
        &:hover {
            color: darken(#DAEDFA, 85%);
        }
    }
    
    .external-link {
        display: inline-block;
        width: 14px;
        margin-left: 6px;
        position: relative;
        top: -2px;
    }
    
    .copy-link-cont {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        min-width: 132px;
        button {
            width: 132px;
        }
    }
</style>