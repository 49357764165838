<template>
    <div>
        <card-general v-if="isEnabledOldMutualLifeContributions">
            <section-header>{{ title }}</section-header>

            <div id="loading-changes-outer-cont">
                <p class="mt-n2">
                    A generated report for Old Mutual Life contributions. The report only includes active employees.
                </p>

                <div class="mt-4 pt-2 mb-2">
                    <MDBSelect
                        v-model:options="payrollPeriodOptions"
                        label="Payroll period"
                        size="lg"
                    />
                </div>

                <button-bento class="mt-2 w-100" size="lg" color="primary" @click="downloadLifeContributionsReport">
                    Download Life Contributions Report
                </button-bento>
            </div>

        </card-general>

        <card-general v-else>
            <bento-alert static color="danger">You do not have access to this report.</bento-alert>
        </card-general>
    </div>
</template>

<script setup>
import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral"
import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
import {useStore} from "vuex"
import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue";
import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
import {currentUrlOrigin} from "@/hooks/api/app-environment-origin"
import { MDBSelect } from "mdb-vue-ui-kit"
import { ref, computed } from "vue"

const store = useStore()

defineProps({
    title: {
        type: String,
        default: "Download Life Contributions Report"
    }
})

// dynamically build last 6 months
const payrollPeriods = []
const date = new Date();
for (let i = 11; i >= 0; i--) {
    const newDate = new Date(date.getFullYear(), date.getMonth() - i, 1);
    const lastDay = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
    const monthName = newDate.toLocaleString('default', { month: 'long' });
    const year = newDate.getFullYear();
    const value = `${year}-${('0' + (newDate.getMonth() + 1)).slice(-2)}-${('0' + lastDay.getDate()).slice(-2)}`;
    payrollPeriods.unshift({ text: `${monthName} ${year}`, value: value });
}
const payrollPeriodOptions = ref(payrollPeriods)

const selectedPayrollPeriod = computed(() =>  payrollPeriodOptions.value.find(period => period.selected) || '')

const isEnabledOldMutualLifeContributions = store.getters.isEnabledOldMutualLifeContributions

const employerId = store.getters.employer_id
const lifeContributionsReportLink = computed(() => `${currentUrlOrigin()}/reports/life/life_contributions/${employerId}/${selectedPayrollPeriod.value.value}`)
const downloadLifeContributionsReport = async () => window.open(lifeContributionsReportLink.value, '_blank')
</script>

<style lang="scss" scoped>
#loading-changes-outer-cont {
    min-height: 50px;
}

#loading-changes-cont {
    min-height: 50px;
}
</style>