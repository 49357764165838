import {isValidArray} from "@/hooks/general/type-and-value-checks"

/**
 * Checks if an employee is a leave approver within a given list of employees.
 * Does this by looping through all employees in their company, and looking to see if the employee ID is present in leave_approvers attribute
 *
 * @param {Array} employeeList - The list of employees to search through.
 * @param {string|number} employeeID - The ID of the employee to check for approver status.
 * @returns {boolean} - Returns true if the employee with the given ID is a leave approver, otherwise returns false.
 */
export const isEmployeeLeaveApprover = (employeeList, employeeID) =>
    employeeList
        .some(employee => employee?.leave_approvers?.some(approver => approver.id == employeeID))

/**
 * Gets the names of approvers from an array and joins them into a single string.
 *
 * @param {Array} approversArray - The array containing approver objects.
 * @returns {string|boolean} - Returns a string of approver names joined by ' | ', or false if the array check fails.
 */
export const getApprovers = approversArray => {
    if (!isValidArray(approversArray)) return false
    const approvers = approversArray.map(approver => approver.name)
    return approvers.join(' | ')
}

/**
 * Gets an array of employees for whom the given employeeID is an approver.
 *
 * @param {Array} employeeList - The array containing employee objects.
 * @param {string|number} employeeID - The ID of the employee to check for.
 * @returns {Array|boolean} - Returns an array of employees for whom the person is an approver, or false if none.
 */
export const getLeaveApprovees = (employeeList, employeeID) => {
    const approvedEmployees = employeeList.filter(employee =>
        employee?.leave_approvers?.some(approver => approver.id == employeeID)
    )
    return approvedEmployees.length > 0 ? approvedEmployees : false
}


/**
 * Gets an array of employees for whom the given employeeID is an approver for reimbursements.
 *
 * @param {Array} employeeList - The array containing employee objects.
 * @param {string|number} employeeID - The ID of the employee to check for.
 * @returns {Array|boolean} - Returns an array of employees for whom the person is an approver for reimbursements, or false if none.
 */
export const getReimbursementApprovees = (employeeList, employeeID) => {
    const approvedEmployees = employeeList.filter(employee =>
        employee?.reimbursement_approvers?.some(approver => approver.id == employeeID)
    )
    return approvedEmployees.length > 0 ? approvedEmployees : false
}


/**
 * Returns a comma-separated string of employee names from the provided list.
 *
 * @function
 * @param {Array} employeeList - An array of employee objects. Each employee object should have a nested 'user' object with 'first_name' and 'last_name' properties.
 * @returns {string|false} - A comma-separated string of employee names. If the input array is not valid or empty, it returns `false`.
 *
 * @example
 * const employees = [
 *   { user: { first_name: 'John', last_name: 'Doe' } },
 *   { user: { first_name: 'Jane', last_name: 'Smith' } }
 * ];
 *
 * getNamesCommaSeparated(employees); // Returns "John Doe, Jane Smith"
 */
export const getNamesCommaSeparated = (employeeList) => {
    if (!isValidArray(employeeList)) {
        return false
    }

    const names = employeeList.map(item => `${item.user.first_name} ${item.user.last_name}`);
    return names.join(', ');
}
