<template>
    
    <div
        class="card-container-addon-plan"
        @click="selectAddonPlanHandler(vitalityPlan)"
        :class="{'selected': vitalityPlan.id === tempSelectedAddonPlan}"
    >
        
        <div class="plan-title">{{ vitalityPlan.name }}</div>
        
        <div class="content-plan-addon">
            
            <div class="vitality-plan-price">
                {{ bentoCurrencyFormat(pricingFrom) }}
            </div>
            <div class="vitality-plan-price-sub-text">
                Single member per month
            </div>
        
        </div>
        
        <div class="more-information-addon-plan">
            <span class="text-link"><a href="https://www.discovery.co.za/vitality/rewards" target="_blank">More information</a></span>
            <span><a class="external-link-icon-addons" href="https://www.discovery.co.za/vitality/rewards" target="_blank"><img :src="externalLinkIcon" alt="more info"/></a></span>
        </div>
    
    </div>

</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "WizardBlockInfoCardAddonPlan",
        props: {
            vitalityPlan: {
                type: Object,
                required: true,
            }
        },
        
        data() {
            return {
                selectedAddonPlanId: 2,
            }
        },
        
        methods: {
            
            // select addon vitality plan to show benefits
            selectAddonPlanHandler(vitalityPlan) {
                this.$store.dispatch('setTempSelectedAddonPlan', vitalityPlan.id);
            },
            
        },
        
        computed: {
            
            ...mapGetters([
                'tempSelectedAddonPlan'
            ]),
            
            imageDir() {
                return this.$store.getters.imageDir;
            },
            
            externalLinkIcon() {
                return this.imageDir + 'external.png';
            },
            
            pricingFrom() {
                const baseCost = this.vitalityPlan.pricing.base_cost;
                if (baseCost) {
                    return baseCost;
                } else {
                    return this.vitalityPlan.pricing.member_costs[1];
                }
            }
            
        }
    }
</script>

<style lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    // needs to be unscoped to reach tags in v-html
    .card-container-addon-plan {
        background-color: white;
        border-radius: $border_radius;
        margin: 5px;
        padding: 15px;
        height: 220px;
        width: 256px;
        position: relative;
        text-align: left;
        cursor: pointer;
        
        &.selected {
            background-color: $primary-light;
        }
        
        &:hover {
            background-color: $primary-light;
        }
    }
    
    .plan-title {
        font-size: 17px;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 16px;
        line-height: 1.3;
    }
    
    
    .content-plan-addon {
    
    }
    
    .vitality-plan-price {
        font-weight: 600;
        font-size: 23px;
        margin-bottom: 4px;
    }
    
    .vitality-plan-price-sub-text {
        color: #979797;
        font-size: 13px;
        font-weight: 500;
    }
    
    
    .more-information-addon-plan {
        position: absolute;
        left: 20px;
        width: calc(100% - 40px);
        bottom: 20px;
        display: flex;
        
        a {
            position: relative;
            font-weight: 300;
            font-size: 13px;
            display: inline-block;
            width: 110px;
            
            &:hover {
                font-weight: 500;
            }
            
            &:after {
                content: '';
                width: 104px;
                position: absolute;
                height: 1px;
                bottom: -1px;
                background-color: black;
                left: 0;
            }
        }
    }
    
    .external-link-icon-plan {
        position: relative;
        top: 4px;
        display: inline-block;
        padding-left: 8px;
        
        &:after {
            display: none;
        }
        
        img {
            width: 16px;
        }
    }


</style>