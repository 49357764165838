export default function useInfoCard(benefit, extraClasses) {
    let classList = []

    if (extraClasses) {
        classList = [...extraClasses]
    }

    if (benefit) {
        classList.push(benefit.slug)
    }

    return {
        classList: classList
    }
}


// Could apply to simple benefits as well