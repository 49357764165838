// employee medical contract actions
import {apiResponseHandler} from "@/hooks/api/api-helper-function"
import theApiCall from '@/hooks/api/the-api-call'
import store from "@/store"

export default {

    // temp selected contract obj : internal action - not a HTTP Call
    async setTempSelectedMedicalAidContractObj(context, tempContractObj) {
        await context.commit('setTempSelectedMedicalAidContractObj', tempContractObj);
    },

    // dispatched from BenefitPageMedicalAid.vue & action: deleteMedicalAidDraftContractObjPlanned (and a few other actions)
    // populate the temp contract object from planned first, if not planned, then use current
    // gets the medical aid contract object from "/employees/2/medical_aids/" endpoint
    // this needs to execute after medical_aids endpoint has loaded
    async loadTempSelectedMedicalAidContractObj(context, forceClear = false) {

        // if forceClear is true (used in newEmployeeSelectionHandler)
        let tempContractObj
        if (forceClear) {
            tempContractObj = {
                series: null,
                plan_id: null,
                adult_dependents: null,
                child_dependents: null,
            }
            await context.dispatch('setTempSelectedMedicalAidContractObj', tempContractObj)
            return
        }

        // the store has been updated already from our other asynchronous actions so use that to populate
        const currentObj = store.getters.medicalAidContractObj
        const plannedObj = store.getters.medicalAidContractObjPlanned

        let plan
        if (plannedObj) {
            plan = store.getters.medicalAidPlanList.find(plan => Number(plan.id) === Number(plannedObj.plan_id))
            tempContractObj = {
                series: plan.series,
                plan_id: plannedObj.plan_id,
                adult_dependents: plannedObj.adult_dependents,
                child_dependents: plannedObj.child_dependents,
            }
        } else if (currentObj) {
            plan = store.getters.medicalAidPlanList.find(plan => Number(plan.id) === Number(currentObj.plan_id))
            tempContractObj = {
                series: plan.series,
                plan_id: currentObj.plan_id,
                adult_dependents: currentObj.adult_dependents,
                child_dependents: currentObj.child_dependents,
            }
        } else {
            return
        }

        await context.dispatch('setTempSelectedMedicalAidContractObj', tempContractObj)
    },

    // GET medical aid plan list
    async loadMedicalAidPlanList(context, payload) {
        if ( (!payload.forceRefresh && !context.getters.shouldUpdateMedicalAidPlanList) || (!context.getters.isEnabledMedicalAid)) { return }

        const response = await theApiCall('medical_aid_plans/')
        const responseData = await apiResponseHandler(response, 'Could not load medical aid plan list.', 'Medical aid plans resource not found. [404]')

        context.commit('setMedicalAidPlanList', responseData)
        context.commit('setLastMedicalAidPlanListFetch', {timestamp: new Date().getTime()})

        try {
            await context.dispatch('loadMedicalAidContractChangeRequestList', {forceRefresh: false})
        } catch (error) {
            store.commit('setShowApiErrorModal', true)
            store.commit('setApiErrorMessage', error.message)
        }
    },

    // GET contract change request list. Dispatches the
    // the action loadTempSelectedMedicalAidContractObj() -> which requires loadMedicalAidPlanList() to be loaded
    async loadMedicalAidContractChangeRequestList(context, payload) {
        if ((!payload.forceRefresh && !context.getters.shouldUpdateMedicalAidContractChangeRequestList) || (!context.getters.isEnabledMedicalAid)) { return }

        const response = await theApiCall('medical_aid_plan_contract_change_request/')
        const responseData = await apiResponseHandler(response, 'Could not load medical aid contract change request list.', 'Medical aid contract change request list resource not found. [404]')

        // save to store (mutations are synchronous)
        context.commit('setMedicalAidContractChangeRequestList', responseData)

        // then load the tempSelectedMedicalAidContractObj from planned, then current
        if (context.getters.medicalAidPlanList) {
            await context.dispatch('loadTempSelectedMedicalAidContractObj')
        } else {
            throw new Error('Medical Aid plan list is not loaded.')
        }

        context.commit('setLastMedicalAidContractChangeRequestListFetch', {timestamp: new Date().getTime()});
    },


    // dispatched from medical aid wizard
    // No api post in this action
    // dispatches to another action to POST / PUT data to api
    // ** that POST / PUT action also dispatches to the GET request to load and update contract obj if the store object doesn't have an api value yet
    async setMedicalAidContractChangeRequestList(context, medicalAidContractObj) {

        // the unique id identifier of the change request object to update
        // also identifies the endpoint location e.g. https://app.mybento.net/api/1.0/medical_aid_plan_contract_change_request/69/
        const contractApiId = context.getters.medicalAidContractObjPlanned?.id || null

        let status = 'draft'
        if (medicalAidContractObj.status === 'submitted') {
            status = 'submitted'
        }

        // Then if no plan selected but we've updated the dependants
        if (medicalAidContractObj.plan_id === null) {
            console.warn('No valid plan selected..')
            return
        }

        // plan list
        const medicalAidPlanList = store.getters.medicalAidPlanList
        const selectedMedicalAidPlan = medicalAidPlanList.find(plan => plan.id === Number(medicalAidContractObj.plan_id))

        // employer contribution based on strategy on employer object
        // this is reduced downwards if greater than total cost
        // we only have flat rate strategy currently
        let employerContribution = 0
        if (context.getters.employerMedicalAidContributionStrategyObj.strategy === 'flat_rate') {
            employerContribution = Number(context.getters.employerMedicalAidContributionStrategyObj.value)
        }

        // employee contribution calculation
        const mainMemberCost = Number(selectedMedicalAidPlan.main_member_cost)
        const adultDependantCost = Number(selectedMedicalAidPlan.adult_dependent_cost)
        const childDependentCost = Number(selectedMedicalAidPlan.child_dependent_cost)

        // total cost
        const totalCost = mainMemberCost + (medicalAidContractObj.adult_dependents * adultDependantCost) + (medicalAidContractObj.child_dependents * childDependentCost)

        let employeeContribution = totalCost - employerContribution
        if (employeeContribution < 0) {
            employerContribution = totalCost
            employeeContribution = 0
        }

        // member number
        let memberNumber = 'New member'
        if (context.getters.hasMedicalAidContract) {
            memberNumber = context.getters.medicalAidContractObj.member_number
        }

        const changeRequestObject = {
            id: contractApiId,
            plan_id: medicalAidContractObj.plan_id,
            member_number: memberNumber,
            adult_dependents: medicalAidContractObj.adult_dependents,
            child_dependents: medicalAidContractObj.child_dependents,
            employer_contribution: employerContribution, // this should also be calculated on the back-end ideally
            status: status,
        }

        await context.dispatch('updateMedicalAidContractApi', changeRequestObject)
    },

    // Creates new 'draft'  OR  Updates 'submitted' medical_aid_contract object
    // POST / PUT request
    // This is dispatched by the action above -> setMedicalAidContractChangeRequestList
    async updateMedicalAidContractApi(context, changeRequestObject) {
        // TODO
        //  I should merge this with the above

        // if the contract OBJ doesn't have an api.ID then there isn't one in API
        // http request type depends on this
        // POST / PUT data to api depending on if there is an existing draft contract object
        let contractApiId = changeRequestObject.id
        const httpMethod = (contractApiId === undefined || contractApiId === null) ? 'POST' : 'PUT'

        const urlEndPoint = (httpMethod === 'PUT') ? `medical_aid_plan_contract_change_request/${contractApiId}/` : 'medical_aid_plan_contract_change_request/'
        let contentType = 'application/json'
        let body = JSON.stringify(changeRequestObject)

        const response = await theApiCall(urlEndPoint, httpMethod, body, contentType)
        await apiResponseHandler(response, 'Can not update medical aid contract.', 'Medical aid contract not found. Can not update. [404]')


        // After sending change request, we refetch and load the change request list
        await context.dispatch('loadMedicalAidContractChangeRequestList', {forceRefresh: true})
    },



    // TODO combine the below two actions into one and refactor this messyness
    //  copy what I've done on retirement
    async deleteMedicalAidDraftContractObjPlanned(context) {

        // we need to send the contract array to the mutation, deleting "draft" / "submitted" changes
        // it won't just be null or an empty array because there may be a "finalised" object in the array.
        let medicalAidContractChangeRequestList = context.getters.medicalAidContractChangeRequestList

        // get contractId to delete from api before deleting from store
        // can only delete 'draft' medical aid contract change request
        // dispatch that to the api action below to identify the object in the api
        const contractObjToDelete = medicalAidContractChangeRequestList.find(obj => obj.status === 'draft')
        if (!contractObjToDelete) return
        medicalAidContractChangeRequestList = medicalAidContractChangeRequestList.filter(obj => obj.status !== 'draft')

        context.commit('setMedicalAidContractChangeRequestList', medicalAidContractChangeRequestList)
        await context.dispatch('deleteMedicalAidContractApi', contractObjToDelete.id)

        // clear temp selected object
        // check first if we have an existing contract
        // set the tempSelectedMedicalAidContractObj back to the same as the current medical aid contract, if there is one
        let seriesName = null;
        let planId = null;
        let adultDependants = null;
        let childDependants = null;
        const currentContract = store.getters.medicalAidContractObj;
        const planList = store.getters.medicalAidPlanList;
        if (store.getters.hasMedicalAidContract) {
            planId = currentContract.plan_id;
            const plan = planList.find(plan => Number(plan.id) === Number(planId));
            seriesName = plan.series;
            adultDependants = currentContract.adult_dependents;
            childDependants = currentContract.child_dependents;
        }
        const tempSelectedMedicalAidContractObj = {
            series: seriesName,
            plan_id: planId,
            adult_dependents: adultDependants,
            child_dependents: childDependants,
        };
        await context.commit('setTempSelectedMedicalAidContractObj', tempSelectedMedicalAidContractObj);
    },


    // DELETE : Delete a change request
    async deleteMedicalAidContractApi(context, contractObjIdToDelete) {

        let urlEndPoint = `medical_aid_plan_contract_change_request/${contractObjIdToDelete}/`
        const response = await theApiCall(urlEndPoint, 'DELETE')


        if (!response.ok) {
            const error = new Error('Failed to delete contract obj!')
            throw error
        }

        await context.dispatch('loadTempSelectedMedicalAidContractObj')
    },
}