<template>
    <div class="three-dots">
        <img src="@/assets/v2/icons/three-dots.svg" alt="Select"/>
    </div>
</template>

<style scoped>
    .three-dots {
        min-width: 16px;
    }
</style>