// manager leave actions

import theApiCall from '@/hooks/api/the-api-call'
import {apiResponseHandler} from "@/hooks/api/api-helper-function"

export default {

    async loadLeaveApproveeList(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateLeaveApproveeList) {
            return
        }

        const userApiId = payload.otherUserId ? payload.otherUserId : (localStorage.userId ? localStorage.userId : context.getters.userApiId)
        const response = await theApiCall(`employees/${userApiId}/leave_approvees/`)
        const responseData = await apiResponseHandler(response, 'Could not load your leave approvee list.', 'Leave approvees resource not found. [404]')

        if (!payload.otherUserId) {
            context.commit('setLeaveApproveeList', responseData)
            context.commit('setLastLeaveApproveeListFetch', {timestamp: new Date().getTime()})
        } else {
            return responseData
        }
    },

    //  NO LONGER IN USE - DELETE ME
    // async downloadLeaveReport(context) {
    //     const employerId = context.getters.employer_id
    //
    //     let url = `https://staging.mybento.net/reports/leave/employer_leave_report_xlsx/${employerId}/`
    //     if (isProd()) {
    //         url = `https://app.mybento.net/reports/leave/employer_leave_report_xlsx/${employerId}/`
    //     }
    //     if (isDevelopment()) {
    //         url = `https://development.mybento.net/reports/leave/employer_leave_report_xlsx/${employerId}/`
    //     }
    //
    //     // can't use theApiCall func as this doesn't use the standard api url
    //     let headers = new Headers();
    //     headers.set('Authorization', 'Token  ' + localStorage.getItem('authToken'))
    //     const response = await fetch(url, {
    //             method: 'GET',
    //             headers: headers,
    //         }
    //     )
    //
    //     await apiResponseHandler(response, 'Could not download leave report.', 'Download leave report not found. [404]')
    //
    //
    //
    //
    //     // https://itnext.io/how-to-download-files-with-javascript-d5a69b749896
    //     const spreadSheet = await response.blob()
    //     const spreadSheetUrl = URL.createObjectURL(spreadSheet)
    //
    //     const anchor = document.createElement("a")
    //     anchor.href = spreadSheetUrl
    //     anchor.download = 'leave_report.xlsx'
    //
    //     document.body.appendChild(anchor)
    //     anchor.click()
    //     document.body.removeChild(anchor)
    //
    //     window.URL.revokeObjectURL(spreadSheetUrl)
    // },

    async getFutureLeaveBalanceForApprovee(context, payload) {
        const response = await theApiCall(`employees/${payload.approveeID}/future_leave_balances/?date=${payload.date}`)
        const responseData = await apiResponseHandler(response, 'Could not fetch future leave balance.', 'Future leave balance calc not found. [404]')
        return responseData
    },

}