// user terms module

import mutations from './mutations'
import actions from './actions'
import getters from './getters'

export default {

    state() {
        return {
            privacyPolicyContent: null,
            userTermsContent: null,
        }
    },

    mutations,
    actions,
    getters,

}
