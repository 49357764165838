<template>
    <div>
        <div v-if="!(isEnabledRisk || isEnabledRiskContent)">
            <width-container>
                <card-general>
                    <bento-alert static color="danger">You do not have permissions to access this page.</bento-alert>
                </card-general>
            </width-container>
        </div>
        <div v-else>
            <essential-benefit-page>
                <template #benefitTitle>{{ insurance.title }}</template>
                
                <template #benefitExtraContent v-if="isEnabledRisk">
                    <intro-block-insurance/>
                    <card-general>
                        <insurance-group-life-cover/>
                        <insurance-group-funeral-cover/>
                    </card-general>
                </template>
                
                <template #resourcesSectionHeader v-if="isEnabledRiskContent || isEnabledRisk">Learn more about insurance and risk</template>
                <template #learnContentCards>
                    <base-learn-card-small
                        v-for="resource in learningResourcesInsuranceApi"
                        :key="resource.id"
                        :resource="resource"
                        :class="activeClass(resource)"
                    ></base-learn-card-small>
                </template>
            </essential-benefit-page>
        </div>
    </div>
</template>

<script setup>
    import EssentialBenefitPage from "@/components/benefits-leave-reimbursements/EssentialBenefitPage"
    import BaseLearnCardSmall from "@/components/learn-and-explore/BaseLearnCardSmall.vue"
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
    import InsuranceGroupLifeCover from "@/components/benefits-leave-reimbursements/insurance/wizard/InsuranceGroupLifeCover.vue"
    import InsuranceGroupFuneralCover from "@/components/benefits-leave-reimbursements/insurance/wizard/InsuranceGroupFuneralCover.vue"
    import IntroBlockInsurance from "@/components/UI/v2/intro-block/IntroBlockInsurance.vue"
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer.vue"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
    import {computed} from "vue"
    import {useRoute} from "vue-router"
    import {useStore} from "vuex"
    
    const route = useRoute()
    const store = useStore()
    
    const isEnabledRiskContent = computed(() => store.getters.isEnabledRiskContent)
    const isEnabledRisk = computed(() => store.getters.isEnabledRisk)
    
    const activeClass = (resource) => route.params.resourceSlug === resource.slug ? 'active-card' : ''
    
    const learningResourcesInsuranceApi = store.getters.learningResourcesInsuranceApi
    const insurance = store.getters.essentialBenefits.insurance
</script>