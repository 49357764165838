<template>
    <menu-item :class="classList" :is-current="isCurrent">
        <template #title>
            Reimbursements
        </template>
        <template #subMenuItems>
            <menu-item-second routePath="/reimbursements">Reimbursement allowances</menu-item-second>
            <menu-item-second routePath="/ad-hoc-reimbursements">Ah hoc claims</menu-item-second>
        </template>
    </menu-item>
</template>

<script setup>
    import MenuItem from "@/components/layouts/side-nav/side-nav-components/MenuItem.vue"
    import MenuItemSecond from "@/components/layouts/side-nav/side-nav-components/MenuItemSecond.vue"
    import {computed} from "vue"
    
    import {useRoute} from 'vue-router'
    
    const route = useRoute()
    const isCurrent = computed(() => route.meta.menuParent === 'reimbursements')
    const classList = computed(() => {
        return {'current-parent': isCurrent.value}
    })
</script>