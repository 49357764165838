<template>
    <summary-card :classList="classList" view-more-link="/benefits/medical-aid" view-more-text="View medical aid">
        <template #title>Medical Aid</template>
        <template #subHeading>{{ cardSubTitle }}</template>
        
        <template #cardText>
            <div v-html="cardText"></div>
        </template>
    </summary-card>
</template>

<script setup>
    import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"
    import SummaryCard from "@/components/cards/SummaryCard"
    import {computed} from "vue"
    import {useStore} from "vuex"
    import useInfoCard from "@/hooks/use-component/cards/info-card"
    const store = useStore()
    
    const {classList} = useInfoCard(null, ['medical-aid'])
    
    const medicalAidContractObj = computed(() => store.getters.medicalAidContractObj)
    const hasMedicalAidContract = computed(() => store.getters.hasMedicalAidContract)
    const employeeMedicalAid = computed(() => store.getters.employeeMedicalAid)
    const medicalAidBenefit = computed(() => store.getters.medicalAidBenefit) // employer contribution
    const employer = computed(() => store.getters.employer)
    const employerMedicalAidContributionStrategyObj = computed(() => store.getters.employerMedicalAidContributionStrategyObj)
    
    const cardSubTitle = computed(() => {
        if (hasMedicalAidContract.value) {
            const totalContribution = bentoCurrencyFormat(medicalAidBenefit.value.api + employeeMedicalAid.value.api)
            return `${totalContribution} pm`
        } else {
            return 'No plan selected'
        }
    })
    
    // Medical Aid Text Options
    
    // 1. Already on a plan
    // 1.1 Where company covers the cost
    // 1.2 Where company also contribute
    // 1.3 Where only employees contribute
    
    // 2. Medical Aid - no plan selected
    // 2.1 Where company contributes OR where company covers the cost
    // 2.2 Where only employee contributes
    const cardText = computed(() => {
        if (hasMedicalAidContract.value && employerMedicalAidContributionStrategyObj.value?.value > 0) {
            return companyContributes.value
        }
        if (hasMedicalAidContract.value && !employerMedicalAidContributionStrategyObj.value?.value < 1) {
            return noCompanyContribution.value
        }
        if (!hasMedicalAidContract.value && employerMedicalAidContributionStrategyObj.value?.value > 0) {
            return noMedicalAidContractCompanyContributes.value
        }
        return noMedicalAidContractNoContribution.value
    })
    
    const noMedicalAidContractCompanyContributes = computed(() => {
        return `<p>Learn more about the medical aid plan options available to you and what to consider when choosing a medical aid plan, as well as <strong>${employer.value.name}'s</strong> contributions towards your medical aid cover.</p>`
    })
    
    const noMedicalAidContractNoContribution = computed(() => {
        return `<p>Learn more about the medical aid plan options available to you and what to consider when choosing a medical aid plan.</p>`
    })
    
    const noCompanyContribution = computed(() => {
        return `<p>You are currently on a <span>${medicalAidContractObj.value.provider} ${medicalAidContractObj.value.name}</span>.</p>
                <p>Learn more about your current cover and medical aid plan options.</p>`
    })
    
    const companyContributes = computed(() => {
        return `<p>You are currently on a <span>${medicalAidContractObj.value.provider} ${medicalAidContractObj.value.name}</span> and <strong>${employer.value.name}</strong> is helping you pay for it.</p>
                <p>Learn more about your current cover and medical aid plan options, as well as <strong>${employer.value.name}'s</strong> contributions towards your medical aid cover.</p>`
    })
</script>