// static contents module actions
import theApiCall from "@/hooks/api/the-api-call";
import {apiResponseHandler} from "@/hooks/api/api-helper-function";

export default {

    async loadStaticContent(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateStaticContent) { return }
        if (!context.getters.isAuthenticated) { return }

        const response = await theApiCall('static_content/')
        const responseData = await apiResponseHandler(response, 'Failed to fetch static content.', 'Static content resource not found. [404]')

        await context.commit('setStaticContent', responseData);
        await context.commit('setLastStaticContentFetch', { timestamp: new Date().getTime() });
    },

}