<template>
    <card-general class="mt-4">
        <div class="salary-figures" id="financial-figures">
        
            <div v-if="!latestPayslip">
                <div class="title-and-payrun">
                    <h3>No current / latest payslip</h3>
                    <small>Soon to be replaced by draft payslip if there is one.</small>
                </div>
            </div>
        
            <div v-else>
            
                <div class="title-and-payrun">
                    <h3>All Payroll & Financial Figures : API & Calculated</h3>
                    <div class="payrun-period">
                        Pay Run: {{ getPayrun }}
                    </div>
                </div>
            
                <div class="intro-paras">
                    <p>Planned values assume same payslip inputs as current (salary before deductions, allowances).</p>
                    <div class="tick-cross-key-container">
                        <div class="tick-cross-key">
                            <img src="@/assets/v1/icons/green-tick.png" alt="tick"/>
                            <span>API and calc value are within 50 cents of each other.</span>
                        </div>
                        <div class="tick-cross-key">
                            <img src="@/assets/v1/icons/red-cross.png" alt="tick"/>
                            <span>API and calc are different. i.e. Calc value is incorrect.</span>
                        </div>
                        <div class="tick-cross-key orange-warning">
                            <img src="@/assets/v1/icons/orange-warning.png" alt="warning"/>
                            <span>Current and planned are different when they shoudn't be. i.e. There haven't been payroll changes, therefore no expected changes.</span>
                        </div>
                    </div>
                    <div class="text-center">
                        <button-bento size="sm">
                            <router-link :to="{name: 'bento-admin-financial-tables'}">Financial Tables</router-link>
                        </button-bento>
                    </div>
                </div>
            
                <!--table key-->
                <div class="table-key">
                    <div class="figure">
                        <div class="label">Data Source:</div>
                        <div class="value">
                    <span class="api source-container">
                        <span class="source api-source">
                            Bento<br>
                            API
                        </span>
                    </span> <span class="current source-container">
                        <span class="source source-calc">Current (Calc)</span>
                    </span> <span class="planned source-container">
                        <span class="source source-planned">Planned</span>
                    </span>
                        </div>
                    </div>
                </div>
            
                <!--key figures-->
                <div class="section-container key-comparison-figures">
                    <h3 class="summary-title">Key Comparison Figures</h3>
                    <salary-figures-item :item="taxPayeAnnualisedCalc" note="PAYE calc using annualised averaging method. Should be the same as SP api value. Planned value assumes same payslip inputs as current and can be different even without submitted payroll changes."/>
                    <div class="link-to-another-page">
                        <router-link :to="{ path: '/bento-admin/paye-calcs'}">View tax trace annualised paye calcs &#8599;</router-link>
                    </div>
                    <salary-figures-item :item="taxPayeCurrentMethodCalc" note="PAYE calc based on current payslip only (non-annualised calculation).  This is innacurate with irregular income, income changes, etc. Expect it to vary from api value."/>
                    <salary-figures-item :item="employerSdl"
                                         note="Indicates whether underlying 'taxable income' calculation is correct<br>
                                 API value -> payslip.employer_contributions.employer_sdl<br>
                                 Calculated values -> 1% of Taxable Income.<br>
                                 If no payslip changes (medical aid contract, pension fund etc) then planned should equal api/current."
                    />
                    <salary-figures-item :item="nettPayCalc" note="API value -> payslip.nett_pay<br>
                                                        Calculated values -> Income (cash portions) + Allowances (excluding expense claim) + totalReimbursementsPaidOut (expense claims) - total employee deductions"
                    />
                </div>
            
                <div class="section-container section-container-gray-block section-container-payslip-block">
                    <h3>Payslip -> {{ getPayrun }}</h3>
                </div>
            
                <!--income (cash portions)-->
                <div class="section-container">
                    <h3 class="summary-title">Income (cash portions)</h3>
                    <div
                        class="cash-portion-item"
                        v-for="apiItem in cashPortionsList"
                        :key="apiItem.internal_name"
                    >
                        <salary-figures-item-api-list
                            :item-api="apiItem"
                            :note="'payslip.cash_portions.' + apiItem.internal_name"
                        />
                    </div>
                    <div class="section-container-total">
                        <salary-figures-item :item="salaryBeforeDeductions" note="Calc: Summed up total of Cash Portion items."/>
                    </div>
                </div>
            
                <!--allowances-->
                <div class="section-container section-container-gray-block">
                    <h3 class="summary-title">Allowances</h3>
                    <div
                        class="cash-portion-item"
                        v-for="apiItem in allowancesList"
                        :key="apiItem.internal_name"
                    >
                        <salary-figures-item-api-list
                            :item-api="apiItem"
                            :note="'payslip.allowances.' + apiItem.internal_name"
                        />
                    </div>
                    <div class="section-container-total">
                        <salary-figures-item :item="totalAllowancesIncludingExpenseClaim" note="Summed up total of allowance items."/>
                    </div>
                    <div class="section-container-total">
                        <salary-figures-item :item="totalAllowancesExclExpenseClaim"
                                             note="Summed up total of allowance items less expense claim.<br>
                                     SP put expense claim in allowances, doesn't really make sense there when there is a separate reimbursement section on the payslip."
                        />
                    </div>
                </div>
            
                <!--employer contributions-->
                <div class="section-container">
                    <h3 class="summary-title">Employer Contributions</h3>
                
                    <salary-figures-item :item="medicalAidBenefit"
                                         note="API value -> payslip.benefits.medical_aid_benefit<br>
                                Current value -> payslip.medical_aid_contract.employer_contribution (imported medical aid contract into bento admin)<br>
                                Planned value -> medical aid change request submitted object endpoint. If none submitted then planned = current"
                    />
                    <salary-figures-item :item="employerRetirementFund"
                                         note="API value -> payslip.employer_contributions.employer_pension_fund<br>
                                 *** API value only at the moment. I have just made current and planned equal to API value for now.<br>
                                 This will be like medical aid setup once we have pension fund contract objects & imports set up."
                    />
                    <salary-figures-item :item="employerSdl"
                                         note="API value -> payslip.employer_contributions.employer_sdl<br>
                                 Calculated values -> 1% of Taxable Income.<br>
                                 If no payslip changes (medical aid contract, pension fund etc) then planned should equal api/current."
                    />
                    <salary-figures-item :item="employerUif"
                                         note="API value -> payslip.employer_contributions.employer_uif<br>
                                 Calculated values -> 1% of Gross Remuneration, capped at 177,12.<br>"
                    />
                    <div class="section-container-total">
                        <salary-figures-item :item="totalEmployerContributions"
                                             note="API value -> payslip.total_employer_contributions<br>
                                     Calculated value -> employer_pension_fund + employer_sdl + employer_uif<br>
                                     ** On SP payslip this doesn't include Pension Fund Employer. It's included in a 'Benefit Section'."
                        />
                    </div>
                </div>
            
                <!--deductions-->
                <div class="section-container section-container-gray-block">
                    <h3>Deductions</h3>
                
                    <salary-figures-item :item="employeeMedicalAid"
                                         note="API value -> payslip.employee_deductions.employee_medical_aid<br>
                                Current value -> payslip.medical_aid_contract.employee_contribution (imported medical aid contract into bento admin)<br>
                                Planned value -> medical aid change request submitted object endpoint. If none submitted then planned = current"
                    />
                    <salary-figures-item :item="employeePensionFund"
                                         note="API value -> payslip.employee_deductions.employee_pension_fund<br>
                                 *** API value only at the moment. I have just made current and planned equal to API value for now.<br>
                                 This will be like medical aid setup once we have pension fund contract objects & imports set up."
                    />
                    <salary-figures-item :item="taxPayeAnnualisedCalc" note="PAYE calc using annualised averaging method. Should be the same as SP api value. Planned value assumes same payslip inputs as current."/>
                    <div class="link-to-another-page">
                        <router-link :to="{ path: '/bento-admin/paye-calcs'}">View tax trace annualised paye calcs &#8599;</router-link>
                    </div>
                
                    <salary-figures-item :item="employeeUif" note="API value and Calc value."/>
                    <div class="section-container-total">
                        <salary-figures-item :item="totalEmployeeDeductionsCalc" note="Gross Remuneration - Nett Pay - Total Employer Contributions (excl. SDL & UIF) + Expense Claim Non Taxable"/>
                    </div>
                </div>
            
                <!--taxable income deductions-->
                <div class="section-container">
                    <h3>Taxable Income Deduction</h3>
                    <salary-figures-item :item="retirementDeduction"
                                         note="API value -> payslip.employer_contributions.employer_pension_fund + payslip.employee_deductions.employee_pension_fund<br>
                                       Current value -> Taken from imported Pension fund contract (*dont have functionality yet)<br>
                                        Planned value -> Taken from pension fund change request object"
                    />
                    <div class="section-container-total">
                        <salary-figures-item :item="totalTaxableIncomeDeduction" note="Just the retirement deduction. Nothing else mapped or included in this yet. Not sure if there will be!"/>
                    </div>
                </div>
            
                <!--tax credit-->
                <div class="section-container section-container-gray-block">
                    <h3>Tax Credit</h3>
                    <salary-figures-item :item="medicalAidTaxCredit"
                                         note="API value -> payslip.medical_aid_tax_credit<br>
                                       Current value -> Taken from imported Medical Aid Contract<br>
                                        Planned value -> Taken from submitted Medical Aid Change request"
                    />
                    <div class="section-container-total">
                        <salary-figures-item :item="totalTaxCredit" note="Just the medical aid tax credit. Nothing else mapped or included in this yet. Not sure if there will be!"/>
                    </div>
                </div>
            
                <!--take home pay (nett)-->
                <div class="section-container section-container-nett-pay">
                    <h3>Take Home Pay (Nett)</h3>
                    <div class="nett-pay-payslip-total-container">
                        <salary-figures-item :item="nettPayCalc" note="API value -> payslip.nett_pay<br>
                                                        Calculated values -> Income (cash portions) + Allowances (excluding expense claim) + totalReimbursementsPaidOut (expense claims) - total employee deductions"
                        />
                    </div>
                </div>
            
                <div class="section-container section-container-gray-block section-container-payslip-block">
                    <h3>End of Payslip</h3>
                </div>
            
                <!--taxable income deductions-->
                <div class="section-container section-container-taxable-income">
                    <h3>Taxable Income</h3>
                    <salary-figures-item :item="taxableIncomeCalc"
                                         note="<span>Current value -> cash income (salary before deductions) + total allowances (taxable) + value of benefits (medical aid employer - employee pension fund)<br>
                                        Planned value -> use planned values for allowances and medical aid and pension fund<br>
                                        <strong>SDL is 1% of this figure</strong></span>"
                    />
                </div>
            
                <!--medical aid contract-->
                <div class="section-container section-container-gray-block">
                    <h3>Medical Aid Contract</h3>
                
                    <div class="has-medical-aid-plan">Has Medical Aid Contract: {{ hasMedicalAidContract }}</div>
                    <salary-figures-item-non-currency :item="medicalAidProvider" note="Taken from the medical_aid_contract"/>
                    <salary-figures-item-non-currency :item="medicalAidPlanId" note="Taken from the medical_aid_contract"/>
                    <salary-figures-item-non-currency :item="medicalAidPlanName" note="Current (calc) / Planned values are from medical_aid_contract && medical_aid_plan_contract_change_request respectively."/>
                    <salary-figures-item-non-currency :item="adultDependants" note="Would be nice to get the value from SimplePay as well. Current (calc) / Planned values are from medical_aid_contract && medical_aid_plan_contract_change_request respectively."/>
                    <salary-figures-item-non-currency :item="childDependants" note="Would be nice to get the value from SimplePay as well. Current (calc) / Planned values are from medical_aid_contract && medical_aid_plan_contract_change_request respectively."/>
                    <salary-figures-item :item="medicalAidTaxCredit" note="R332 for first 2 members, R224 for every member thereafter"/>
                    <!--<salary-figures-item :item="totalMedicalAidPlanCost" note="Calculated by lookup of Medical Aid Plan list. Includes employee and employer contribution."/>-->
                    <salary-figures-item :item="employeeMedicalAid" :note="`API value is taken from Simple Pay import, values hardcoded into payroll<br>
                                                                *Current/Calc value is taken from 'medical_aid_contract'. Values imported in CMS from Provider upload.<br>
                                                                *Planned value is calculated from draft / planned medical_aid_contract object from front-end wizard.`"/>
                    <salary-figures-item :item="medicalAidBenefit" note="**values derived same as above for Medical Aid (Employee)"/>
                </div>
            
            
            
                <h4>Salary Before Deductions / Income / Expense Claims / Allowances (Taxable)</h4>
            
                <salary-figures-item :item="expenseClaimsNonTaxable" note="Actual recorded expense claims. We will need to get this from SimplePay or Employer until such time as we have our own expense claim upload functionality. Does form part of the Nett Pay calculation."/>
                <salary-figures-item :item="totalAllowancesTaxable" note="Used in the Taxable income calculation. (Cash income + taxable allowances + value of taxable benefits."/>
                <div class="extra-standalone-note">**Reimbursive allowances, the company set budgets for non-taxable expense claims captured in Bento during employer configuration, appear further down.**</div>
            
            
            
                <div class="section-container section-container-gray-block">
                    <h3>Other Data - API / Derived / Bento configured</h3>
                    <salary-figures-item :item="grossRemuneration" note="Calculsted valued -> Income (cash portions) + Allowances (taxable + non taxable, but not expense claims) + Pension Fund Employer + Medical Employer."/>
                    <salary-figures-item :item="totalReimbursiveAllowances" note="Captured in Bento as part of employer configuration. No calc or planned values. 'reimbursive_allowances' in CMS/API"/>
                    <salary-figures-item :item="totalPackage" note="Gross Remuneration + Total Reimbursive Budget Allowances"/>
                </div>
            
            
                <div class="summary-block">
                    <h3 class="summary-title">Summary</h3>
                
                    <salary-figures-item :item="totalPackage" note="Total Earnings + Reimbursive Budget"/>
                    <salary-figures-item :item="totalReimbursiveAllowances" note="Captured in Bento as part of employer configuration. No calc or planned values. 'reimbursive_allowances' in CMS/API"/>
                    <salary-figures-item :item="grossRemuneration" note="Calculsted valued -> Income (cash portions) + Allowances (taxable + non taxable, but not expense claims) + Pension Fund Employer + Medical Employer."/>
                    <salary-figures-item :item="totalEmployerContributionsExSdlUif" note="Sum of Employer Medical & Employer Pension + Others potentially..."/>
                    <div class="salary-before-deductions">
                        <salary-figures-item :item="salaryBeforeDeductions" note="From Bento API field 'salary_before_deductions'. I think this is the total of 'Cash Portions' from Bento API. I think this is what should be used as a comparison to Basic Salary in SimplePay."/>
                    </div>
                    <salary-figures-item :item="totalEmployeeDeductionsCalc" note="Gross Remuneration - Nett Pay - Total Employer Contributions (excl. SDL & UIF) + Expense Claim Non Taxable"/>
                    <salary-figures-item :item="nettPayCalc" note="Nett pay taken from the latest payslip and calculated."/>
                </div>
        
            </div>
    
        </div><!--.salary-figures-->
    </card-general>
</template>

<script>
    import {mapGetters} from 'vuex';
    import SalaryFiguresItem from "./SalaryFiguresItem";
    import SalaryFiguresItemNonCurrency
        from "@/components/admin-restricted/salary-figures/SalaryFiguresItemNonCurrency";
    import SalaryFiguresItemApiList from "@/components/admin-restricted/salary-figures/SalaryFiguresItemApiList";
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento";
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral";
    
    export default {
        name: "SalaryFigures",
        components: {CardGeneral, ButtonBento, SalaryFiguresItemApiList, SalaryFiguresItemNonCurrency, SalaryFiguresItem},
        
        data() {
            return {}
        },
        
        computed: {
            
            ...mapGetters([
                
                'getPayrun',
                'latestPayslip',
                
                /*summary*/
                'totalPackage',
                'totalReimbursiveAllowances',
                'totalEmployeeDeductionsCalc',
                
                /*income*/
                'cashPortionsList',
                'salaryBeforeDeductions',
                
                /*allowances*/
                'allowancesList',
                'totalAllowancesIncludingExpenseClaim',
                'totalAllowancesExclExpenseClaim',
    
                /*employee deductions*/
                'employeeMedicalAid',
                'employeePensionFund',
                'taxPayeApi',
                'taxPayeCurrentMethodCalc',
                'taxPayeAnnualisedCalc',
                'employeeUif',
    
                /*employer contributions*/
                'employerRetirementFund',
                'medicalAidBenefit',
                'employerSdl',
                'employerUif',
                'totalEmployerContributions',
                
                /*taxable income deduction*/
                'retirementDeduction',
                'totalTaxableIncomeDeduction',
                
                /*tax credit*/
                'medicalAidTaxCredit',
                'totalTaxCredit',
                
                /*take home pay (nett)*/
                'nettPayCalc',
                
                /*### ---- end of payslip ---- ####*/
                
                /*taxable income*/
                'taxableIncomeCalc',

                /*medical aid contract*/
                'hasMedicalAidContract',
                'medicalAidProvider',
                'medicalAidPlanId',
                'medicalAidPlanName',
                'adultDependants',
                'childDependants',
                
                
                // 'totalMedicalAidPlanCost',
                'expenseClaimsNonTaxable',
                'totalAllowancesTaxable',
                
                
                /*gross & nett*/
                'grossRemuneration',
                
                
                // Data not in SimplePay - Derived / Bento configured
                'deductionsList',
                'expenseClaimsNonTaxableBento',
                
                
                /*total employer contributions*/
                'totalEmployerContributionsExSdlUif',
                'employerSdlUif',
                //totalReimbursiveAllowances, - mapped under summary section
                // 'totalPackage', - mapped under summary section
                'totalRetirementFundContributions',
            ]),
            
            
        },
        
        
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .title-and-payrun {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    h3 {
        font-weight: 700;
        font-size: 19px;
        margin-top: 0;
        margin-bottom: 1px;
    }
    
    h4 {
        font-weight: 600;
    }
    
    
    .payrun-period {
        color: darken(#00B5C0, 5%);
        font-weight: 600;
    }
    
    .intro-paras {
        margin-top: 12px;
        p {
            font-weight: 600;
            font-size: 13px;
            margin: 4px 0;
            color: black;
            text-align: center;
        }
    }
    
    .tick-cross-key-container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        h3 {
            margin-bottom: 20px;
        }
    }
    
    
    .tick-cross-key {
        margin-top: 8px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        &:first-child {
            margin-right: 20px;
        }
        img {
            width: 18px;
            display: inline-block;
            margin-right: 6px;
        }
        &.orange-warning {
            img {
                width: 20px;
            }
        }
    }
    
    .table-key {
        background-color: rgba(150, 150, 150, .8);
        margin: 20px -20px 0;
        padding: 0 20px;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        
        .figure {
            border-bottom: none;
        }
        .label {
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 600;
        }
    }
    
    .section-container {
        padding: 20px 20px 10px;
        margin: 10px -20px;
    }
    
    .section-container-gray-block {
        background-color: rgba(100, 100, 100, .03);
    }
    
    .section-container-payslip-block {
        padding: 15px 20px;
        margin-top: 0;
        background-color: rgba(100, 100, 100, .18);
        border-top: 1px solid rgba(black, .32);
        border-bottom: 6px solid black;
        h3 {
            text-align: center;
            margin-top: 2px;
            margin-bottom: 2px;
        }
    }
    
    .key-comparison-figures {
        margin-top: 0;
        margin-bottom: 0;
        background-color: lighten($gradient-green-2, .04);
    }
    
    .section-container-nett-pay {
        border-top: 1px solid black;
        border-bottom: 1px solid black;
        margin-top: -10px;
        background-color: rgba(100, 100, 100, .18);
        margin-bottom: 0;
    }
    
    
    .section-container-total {
        border-top: 1px solid rgba(0, 0, 0, .3);
        font-weight: 600;
        
        .figure {
            border-bottom: none;
        }
    }
    
    .nett-pay-payslip-total-container {
        font-weight: 600;
    
        .figure {
            border: none;
        }
    }
    
    .section-container-taxable-income {
        .figure {
            border: none;
        }
    }

    .link-to-another-page {
        font-size: 13px;
        font-weight: 500;
        padding: 8px 2px;
        background-color: rgba(#75A2B6, .3);
        text-align: center;
        border-bottom: 1px solid #ddd;
    
        a {
            display: block;
        }
        &:hover {
            background-color: rgba(#75A2B6, .5);
        }
    }
    
    h4 {
        margin-top: 40px;
        margin-bottom: 10px;
    }


    .employee-deduction-total {
        background-color: rgba(245, 145, 155, .15);
    }
    
    .paye-note {
        font-size: 13px;
        font-weight: 500;
        background-color: rgba(57, 255, 20, .25);
        text-align: center;
        padding: 4px;
    }
    
    /*Salary figure item must be in here too for manual blocks*/
    .figure {
        font-size: 13px;
        display: flex;
        flex-wrap: nowrap;
        padding: 9px 0;
        border-bottom: 1px solid #efefef;
    }
    
    .label {
        flex: 0 0 320px;
        padding-right: 40px;
        display: flex;
        align-items: center;
    }
    
    .value {
        flex: 1 auto;
        display: flex;
        flex-wrap: nowrap;
        
        span {
            flex: 0 0 124px;
            margin-right: 20px;
            color: black;
            display: inline-block;
        }
    }
    
    .note {
        flex: 1 auto;
        font-size: 11px;
        font-weight: 500;
        margin-top: 3px;
        margin-right: 0;
    }
    
    .extra-standalone-note {
        font-size: 13px;
        font-weight: 600;
        margin-top: 10px;
    }
    
    strong {
        font-weight: 500;
    }
    
    span.source-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
    
    /*Data Source Block*/
    span.source {
        max-width: 72px;
        flex: 1 auto;
        height: 36px;
        background-color: #335160;
        color: #fff;
        font-size: 11px;
        padding: 4px 6px;
        font-weight: 700;
        border-radius: 1px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        
        &.simple-pay-api-source {
            background-color: orange;
        }
        
        &.api-source {
        }
        
        &.source-calc {
            background-color: $primary;
        }
        
        &.source-planned {
            background-color: $secondary;
        }
    }
    
    span.data-source-note {
        font-size: 10px;
        font-weight: 600;
        text-align: center;
        background-color: pink;
        padding: 2px;
        flex: 0 0 72px;
    }
    
    .has-medical-aid-plan {
        font-size: 14px;
        font-weight: 600;
        color: $gradient-pink-text-light;
    }


</style>