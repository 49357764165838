// retirement employee index

import mutations from './mutations';
import actions from './actions';
import getters from './getters';


export default {

    state() {
        return {
            // retirement calculator :: overridden from /api/1.0/static_content/ if object with name: 'retirement_calculator_constants' exists
            retirementCalculatorInputs: {
                inflation: 0.06,
                returnAbove: 0.03,
                percentageRequired: .75,
                totalSavings: 950000,
                percentageSavedStart: .1,
                retirementAge: 65,
                deathAge: 90,
            },

            calculatorRetirementContract: {
                totalContribution: 0,
                fundId: null
            },


            // employees retirement fund figures taken from their payslip as well as their contract object
            employeeRetirementFundContractList: null,
            lastEmployeeRetirementFundContractListFetch: null,



            // ########### to delete and remove

            draftRetirementContract: {
                employeeContribution: 0,
                fundId: null
            },
        }
    },

    mutations,
    actions,
    getters,
}