<template>
    <div :class="{'custom-invalid-entry': customErrorMessage}">
        <div v-if="showLabel" class="font-size-13 text-black mb-1">{{ label }}</div>
        <MDBInput
            :disabled="areRetirementChangesSubmitted"
            v-model="name" :label="label" @input="$emit('update:modelValue', $event.target.value)"
            size="lg" :class="['rounded']"
            :required="true"
            :invalidFeedback="invalidFeedback"
            :validFeedback="validFeedback"
            :maxlength="maxLengthValue"
            counter
            :type="type"
            :max="maxNumber"
            :min="minNumber"
        />
        <div v-if="extraNote" class="extra-note">{{ extraNote }}</div>
        <div class="invalid-feedback-custom" v-if="customErrorMessage">{{ customErrorMessage }}</div>
    </div>
</template>

<script setup>
    import {computed, ref, watch} from "vue"
    import {MDBInput} from 'mdb-vue-ui-kit'
    import {useStore} from "vuex"
    const store = useStore()
    
    const extraData = computed(() => store.getters.tempSelectedRetirementFundContractObj?.extraData || null)
    
    const props = defineProps({
        label: {
            type: String,
            required: true
        },
        showLabel: {
            type: Boolean,
            required: false,
            default: false,
        },
        inputValue: {
            type: String || null,
            required: true,
            default: ''
        },
        invalidFeedback: {
            type: String,
            required: false,
        },
        validFeedback: {
            type: String,
            required: false,
        },
        customErrorMessage: {
            required: false,
            default: null
        },
        maxLengthValue: {
            type: Number,
            required: false,
            default: 60
        },
        extraNote: {
            type: String,
            required: false,
        },
        type: {
            type: String,
            required: false,
            default: 'text'
        },
        maxNumber: {
            type: Number,
            required: false,
        },
        minNumber: {
            type: Number,
            required: false,
        },
    })
    
    const areRetirementChangesSubmitted = computed(() => store.getters.areRetirementChangesSubmitted)
    const name = ref(props.inputValue)
    
    watch(extraData, (newVal) => {
        if(newVal === null) {
            name.value = ''
        }
    })
</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    :deep(.form-outline) {
        
        .form-control {
            min-height: 36px;
            color: $gradient-green-text-dark;
        }
        
        label.form-label {
            font-size: 13px;
        }
        
        input.invalid-entry {
            color: $danger !important;
            border-color: $danger !important;
        }
        
        input {
            background-color: white;
        }
        
        .form-counter {
            display: none;
        }
    }
    .beneficiary-percentage-input {
        :deep(.form-outline) {
            input {
                width: 108px;
                padding-right: 4px;
            }
        }
    }
    
    .custom-invalid-entry {
        :deep(.form-outline) {
            input.form-control {
                color: $danger !important;
            }
            label.form-label {
                color: $danger !important;
            }
            .form-notch {
                > div {
                    border-color: $danger !important;
                    &.form-notch-middle {
                        border-top: 1px solid transparent !important;
                    }
                }
            }
        }
    }
    
    .extra-note {
        width: 100%;
        font-size: 11px;
        color: $gray-main;
    }
    
    .invalid-feedback-custom {
        margin-top: -10.5px;
        font-size: 0.875rem;
        color: $danger-500;
        line-height: 1.3;
    }
</style>