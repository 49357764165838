import BenefitPageMedicalAid from "@/components/benefits-leave-reimbursements/medical-aid/BenefitPageMedicalAid.vue"
import LearningResource from "@/components/learn-and-explore/LearningResource.vue"
import BenefitPageInsurance from "@/components/benefits-leave-reimbursements/insurance/BenefitPageInsurance.vue"
import BenefitPageRetirement from "@/components/benefits-leave-reimbursements/retirement/BenefitPageRetirement.vue"

export default [
    // benefits
    // /benefits ** this redirect to home('/') is here in case user changes url to '/benefits' if they were on /benefits/medical-aid for example
    { path: '/benefits', redirect: '/' },

    // /benefits/:benefitID
    // SINGLE BENEFIT IS NOT A NESTED ROUTE, IT'S A STAND ALONE ROUTE
    // THE CONTENT CARD IS NESTED WITHIN THE BENEFIT THOUGH
    {
        name: 'medical-aid',
        path: '/benefits/medical-aid',
        component: BenefitPageMedicalAid,
        meta: { menuParent: 'essentials' },
        children: [
            {
                name: 'medical-aid-resource',
                path: ':resourceSlug',
                component: LearningResource,
                props: true,
                meta: {learningResource: true}, // used in scrollBehaviour()
            },
        ]
    },

    {
        name: 'retirement',
        path: '/benefits/retirement',
        component: BenefitPageRetirement,
        meta: { menuParent: 'essentials' },
        children: [
            {
                name: 'retirement-resource',
                path: ':resourceSlug',
                component: LearningResource,
                props: true,
                meta: {learningResource: true}, // used in scrollBehaviour()
            },
        ],
    },

    {
        name: 'insurance',
        path: '/benefits/insurance',
        component: BenefitPageInsurance,
        meta: { menuParent: 'essentials' },
        children: [
            {
                name: 'insurance-resource',
                path: ':resourceSlug',
                component: LearningResource,
                props: true,
                meta: {learningResource: true}, // used in scrollBehaviour()
            },
        ],
    },
]