// Taken from Simple Pay Constants
// The code is specific to SP
const banks = [
    {bankName: "ABSA Bank", bankCode: 1462381520},
    {bankName: "Capitec Bank", bankCode: 464954524},
    {bankName: "First National Bank", bankCode: 1809393886},
    {bankName: "Nedbank", bankCode: 189450181},
    {bankName: "Standard Bank", bankCode: 284678023},
    {bankName: "20twenty", bankCode: 1823577098},
    {bankName: "Access Bank South Africa Limited", bankCode: 946575179},
    {bankName: "African Bank", bankCode: 2081327577},
    {bankName: "Albaraka", bankCode: 2081327576},
    {bankName: "Bank of Transkei", bankCode: 560121584},
    {bankName: "Bank of Windhoek", bankCode: 1449522790},
    {bankName: "Bank Zero", bankCode: 2081327609},
    {bankName: "Bidvest Bank", bankCode: 2081327518},
    {bankName: "Boland Bank PKS", bankCode: 1208050630},
    {bankName: "Cape of Good Hope Bank", bankCode: 1057379156},
    {bankName: "Citibank South Africa", bankCode: 638517993},
    {bankName: "Commercial Bank of Namibia", bankCode: 1359491711},
    {bankName: "Discovery Bank", bankCode: 2081327605},
    {bankName: "Fidelity Bank", bankCode: 1102524399},
    {bankName: "Finbond Mutual Bank", bankCode: 2081327606},
    {bankName: "Future Bank Corporation", bankCode: 917512057},
    {bankName: "Grindrod Bank", bankCode: 2081327607},
    {bankName: "HBZ Bank", bankCode: 1651121208},
    {bankName: "HSBC", bankCode: 1229410299},
    {bankName: "Investec Bank", bankCode: 359476398},
    {bankName: "Ithala", bankCode: 2081327574},
    {bankName: "Mercantile Bank", bankCode: 207912213},
    {bankName: "Natal Building Society", bankCode: 2070105475},
    {bankName: "Old Mutual", bankCode: 1347296834},
    {bankName: "People's Bank", bankCode: 1695012897},
    {bankName: "Permanent Bank", bankCode: 302049463},
    {bankName: "Pick 'n Pay Go Banking", bankCode: 1044414317},
    {bankName: "Postbank", bankCode: 2081327517},
    {bankName: "Rand Merchant Bank", bankCode: 659107544},
    {bankName: "Saambou Bank", bankCode: 185186574},
    {bankName: "SASFIN Bank", bankCode: 2081327590},
    {bankName: "Tyme Digital Bank", bankCode: 2081327592},
    {bankName: "UBank (formerly Teba Bank)", bankCode: 2081327519},
    {bankName: "Unibank", bankCode: 2081327516},
    {bankName: "VBS Mutual Bank", bankCode: 2081327575},
]
export default banks


