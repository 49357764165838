// employee leave getters

import {isValidArray} from "@/hooks/general/type-and-value-checks";

export default {

    // TODO
    //  This is a hack for 'Annual Jonathan' Leave Type. For Jonathan Stewart (Root)
    //  Jonathan UID: 12
    leaveBalances: (_, getters) => {
        if (getters.employee && isValidArray(getters.employee.leave_balances)) {
            let filteredLeaveBalances
            if (getters.employee.id === 12) { // Jonothan Stewart has UID = 12
                filteredLeaveBalances = getters.employee.leave_balances.filter(item => item.id != 20) // Leave type "Annual" is ID 20
            } else {
                filteredLeaveBalances = getters.employee.leave_balances.filter(item => !item.name.toLowerCase().includes('jonathan')) // Leave type "Annual Jonathan". ID's are different between staging and app.
            }
            return filteredLeaveBalances
        }
        return null
    },


    leaveBalancesExlUnpaid: (_, getters) => (getters.leaveBalances) ? getters.leaveBalances.filter(leaveType => Number(leaveType.id) !== 6 || !leaveType.name.toLowerCase().includes('unpaid')) : null,

    leaveBalancesSorted: (_, getters) => {
        if (getters.leaveBalancesExlUnpaid) {
            return getters.leaveBalancesExlUnpaid.sort((a, b) => {
                if (a.name < b.name) {
                    return -1
                }
                if (a.name > b.name) {
                    return 1
                }
                return 0
            })
        }
        return null
    },

    // TODO
    //  also a hacky mess for 'Annual Jonathan' Leave Type. For Jonathan Stewart (Root)
    //  Jonathan UID: 12
    annualLeaveObj: (_, getters) => {
        if (!getters.leaveBalances) return null
        if (getters.employee.id === 12) {
            return getters.leaveBalances.find(item => item.name.toLowerCase().includes('jonathan'))
        }
        return getters.leaveBalances.find(item => item.name.toLowerCase().includes('annual') && !item.name.toLowerCase().includes('test'))
    },


    employeeLeaveApprovers: (_, getters) => getters.employee?.leave_approvers || null,
    leaveApproversCommaSeparated: (_, getters) => {
        if (!isValidArray(getters.employeeLeaveApprovers)) return false
        let approvers = []
        for (const approver of getters.employeeLeaveApprovers) {
            approvers.push(approver.name)
        }
        return approvers.join(', ')
    },

    hasLeaveApprover: (_, getters) => {
        const approversArray = getters.employeeLeaveApprovers
        return typeof approversArray != "undefined" && approversArray != null && approversArray.length != null && approversArray.length > 0;
    },

    leaveCalenderUrl: (_, getters) => getters.employee.leave_calendar_url,

    birthdayCalendarUrl: (_, getters) => getters.employee.birthday_calendar_url,

    anniversariesCalendarUrl: (_, getters) => getters.employee.anniversaries_calendar_url,

    // ## Employer object
    isEnabledLeaveCalendarLink(_, getters) {
        if (!isValidArray(getters.employerFeatureFlagsList)) {
            return false;
        }
        const found = getters.employerFeatureFlagsList.find((feature) => feature.name === 'calendar_link')
        return !!(found)
    },


    // ## Leave Request List
    lastLeaveRequestListFetch: (state) => state.lastLeaveRequestListFetch,
    shouldUpdateLeaveRequestList(_, getters) {
        if (!getters.lastLeaveRequestListFetch) return true
        const currentTimeStamp = new Date().getTime()
        return ((currentTimeStamp - getters.lastLeaveRequestListFetch) / 1000) > 300 // 300 seconds = 5 minutes
    },

    leaveRequestList: (state) => state.leaveRequestList,


    // Expiring Leave Getters

    leaveRequestListAnnualApproved: (_, getters) => {
        const currentDate = new Date()
        return getters.leaveRequestList.filter(item =>
            item.leave_type == getters.annualLeaveObj.id &&
            item.state == 'approved' &&
            new Date(item.start_date) > getters.mostRecentWorkAnniversary &&
            new Date(item.end_date) < currentDate
        )
    },

    mostRecentWorkAnniversary: (_, getters) => {
        const employmentDate = new Date(getters.employee.date_of_employment)
        const today = new Date()

        const thisYearAnniversary = new Date(today.getFullYear(), employmentDate.getMonth(), employmentDate.getDate())

        let mostRecentAnniversary
        if (today >= thisYearAnniversary) {
            mostRecentAnniversary = thisYearAnniversary
        } else {
            mostRecentAnniversary = new Date(today.getFullYear() - 1, employmentDate.getMonth(), employmentDate.getDate())
        }
        return mostRecentAnniversary
    },

    leaveExpiryDateAfterSixMonths: (_, getters) => {
        const givenDate = new Date(getters.mostRecentWorkAnniversary)
        const dateAfterSixMonths = new Date(givenDate.setMonth(givenDate.getMonth() + 6))
        return dateAfterSixMonths
    },


    isLeaveExpiring: () => {
        return true
    },

    // TODO
    //  doesn't take into account public holidays
    annualLeaveDaysTakenSinceAnniversary: (_, getters) => {
        function countWeekdays(startDate, endDate) {
            let count = 0
            const start = new Date(startDate)
            const end = new Date(endDate)
            for (let d = start; d <= end; d.setDate(d.getDate() + 1)) {
                if (d.getDay() % 6 !== 0) { // not a weekend
                    count++
                }
            }
            return count
        }

        let totalDays = 0;
        getters.leaveRequestListAnnualApproved.forEach(item => {
            totalDays += countWeekdays(item.start_date, item.end_date);
        })

        return totalDays
    },

    balanceAtLastAnniversary: (_, getters) => {
        return getters.annualLeaveObj.balance - getters.annualLeaveDaysTakenSinceAnniversary
    },

    leaveDaysExpiring: (_, getters) => {
        return getters.balanceAtLastAnniversary - getters.annualLeaveDaysTakenSinceAnniversary
    }

}