// retirement employee getters

export default {

    // retirement calculator
    retirementCalculatorInputs: (state, getters) => {
        const retirementCalculatorConstantsObj = getters.staticContent.find( contentObj => contentObj.name === 'retirement_calculator_constants')
        if (retirementCalculatorConstantsObj) {
            return retirementCalculatorConstantsObj.content
        }
        return getters.staticContent.find( contentObj => contentObj.name === 'retirement_calculator_constants') || state.retirementCalculatorInputs
    },
    calculatorRetirementContract: state => state.calculatorRetirementContract,

    // employees retirement figures taken from their payslip as well as their contract object
    employeeRetirementFundContractList: state => state.employeeRetirementFundContractList,
    lastEmployeeRetirementFundContractListFetch: state => state.lastEmployeeRetirementFundContractListFetch,
    shouldUpdateRetirementFundContractList: (_, getters) => {
        if (!getters.lastEmployeeRetirementFundContractListFetch) { return true }
        const currentTimeStamp = new Date().getTime()
        return (currentTimeStamp - getters.lastEmployeeRetirementFundContractListFetch) / 1000 > 900 // currently set to 900 seconds = 15 mins.
    },

    // retirement contract object
    retirementFundContractObj: (_, getters) => getters.employeeRetirementFundContractList?.[0] || null,


    // https://www.simplepay.co.za/help/payroll-processing/system-items-deductions/retirement-funds
    // We pay TAX on the benefit amount, not the employer contribution. Usually the same though
    // Explained in link above


    // Contribution Stategy
    // 1. flat_rate
    // 2. percentage_of_salary
    retirementFundContributionStrategy: (_, getters) => getters.employer.retirement_fund_contribution?.strategy || null,

    // Contribution Value
    // if percentage_of_salary then it's a percentage
    // if flat_rate then it is a nominal rand amount
    retirementFundContributionValue: (_, getters) => getters.employer.retirement_fund_contribution?.value || null,


    // This is the amount which appears unders Employer contributions (vs the amount which appears under Benefits on Payslip)
    // For now I am taking this from Bryns object here: https://staging.mybento.net/api/1.0/employees/1/retirement_funds/
    employerRetirementFund: (_, getters) => {
        // planned is based on the employer contribution model
        let planned = 0

        // flat_rate
        if (getters.employer.retirement_fund_contribution && getters.employer.retirement_fund_contribution.strategy === 'flat_rate') {
            planned = Number(getters.employer?.retirement_fund_contribution?.value || 0)
        }

        // percentage_of_salary
        if (getters.employer.retirement_fund_contribution && getters.employer.retirement_fund_contribution.strategy === 'percentage_of_salary') {
            const percentageOfSalary = getters.employer?.retirement_fund_contribution?.value || 0
            planned = Number(percentageOfSalary * getters.monthlyIncome / 100)
        }


        return {
            id: 'employer-pension-fund',
            label: 'Pension Fund (Employer)',
            api: Number(getters.retirementFundContractObj?.employer_contribution || 0) || 0,
            current: false,
            planned: planned,
        }
    },

    // This is the amount which appears unders Benefits (vs the amount which appears under Employer Contributions)
    // For now I am taking this from Bryns object here: https://staging.mybento.net/api/1.0/employees/1/retirement_funds/ (same as above)
    pensionFundBenefit: (_, getters) => {
        return {
            id: 'pension-fund-benefit',
            label: 'Pension Fund Benefit',
            api: getters.retirementFundContractObj ? Number(getters.retirementFundContractObj.employer_contribution) : 0,
            current: false,
            planned: getters.retirementFundContractObjPlanned ? getters.retirementFundContractObjPlanned.employer_contribution : 0,
        }
    },
}