<template>
    <div class="block-container mb-3">
        
        <header :class="{ expanded: blockExpanded }" @click="onToggleExpandOpposite">
            <div class="title-and-sub-title">
                <div class="title">
                    <h3 class="card-title">
                        <slot name="title"></slot>
                    </h3>
                </div>
    
                <div class="card-sub-title">
                    <slot name="sub-title"></slot>
                </div>
            </div>
    
            <three-dots-toggle/>
        </header>
        
        <div v-if="blockExpanded" class="block-expanded-content">
            <slot name="expanded-content"></slot>
        </div>
        
    </div>
</template>

<script setup>
    import {ref} from 'vue'
    import ThreeDotsToggle from "@/components/UI/v2/misc/ThreeDotsToggle.vue";
    
    const props = defineProps({
        blockExpandedProp: {
            type: Boolean,
            required: false,
            default() {
                return false
            }
        }
    })
    
    const blockExpanded = ref(props.blockExpandedProp)
    
    const onToggleExpandOpposite = () => {
        blockExpanded.value = !blockExpanded.value
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";

    .block-container {
        background-color: $gradient-green-2;
        border-radius: $border_radius;
    }
    
    header {
        cursor: pointer;
        padding: 25px 15px;
        border-radius: $border_radius;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        transition: all .15s ease-in-out;
        background: $gradient-green;
        
        @include media-breakpoint-up(sm) {
            padding: 25px 20px;
        }
        @include media-breakpoint-up(md) {
            padding: 25px;
        }
        &:hover {
            background: $gradient-green-darken;
        }
        &.expanded {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }
    }
    
    .title-and-sub-title {
        flex: 1 auto;
    }
    
    h3.card-title {
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 0;
        color: $gradient-green-text-dark;
    }
    
    .card-sub-title {
        font-size: 15px;
        color: $gradient-green-text-light;
        font-weight: 600;
    }
    
    .block-expanded-content {
        padding: 25px 15px;
        @include media-breakpoint-up(sm) {
            padding: 25px 20px;
        }
        @include media-breakpoint-up(md) {
            padding: 25px;
        }
    }
</style>