<template>
    <div class="block-container mb-3" :class="[ classList,  { 'card-expanded': cardExpanded } ]">
        
        <header @click="toggleCardExpanded" class="d-flex align-items-center cursor-pointer position-relative">
            <div class="title-and-sub-title">
                
                <h3 class="font-weight-700 font-size-18 mb-3 mt-0 gradient-green-text-dark">
                    <slot name="title"></slot>
                </h3>
                
                <div class="gradient-green-text-light font-size-15 fw-500">
                    <slot name="subTitle"></slot>
                </div>
            </div>
            
            <three-dots-toggle/>
        </header>
        
        <MDBCollapse v-model="cardExpanded">
            <div class="block-expanded-content">
                <div v-if="$slots.benefitBlockContent">
                    <slot name="benefitBlockContent"></slot>
                </div>
            
            </div>
        </MDBCollapse>
    
    </div>
</template>

<script setup>
    import {MDBCollapse} from "mdb-vue-ui-kit"
    import {ref} from "vue"
    import ThreeDotsToggle from "@/components/UI/v2/misc/ThreeDotsToggle.vue"
    
    defineProps({
        classList: {
            type: Object,
            required: false,
        }
    })
    
    const cardExpanded = ref(false)
    const toggleCardExpanded = () => cardExpanded.value = !cardExpanded.value

</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .block-container {
        background-color: $gradient-green-light-gray;
        border-radius: $border_radius;
    }
    
    header {
        border-radius: $border_radius;
        transition: all .15s ease-in-out;
        background: $gradient-green;
        padding: 25px 15px;
        @include media-breakpoint-up(sm) { padding: 25px 20px; }
        @include media-breakpoint-up(md) { padding: 25px; }
        &:hover {
            background: $gradient-green-darken;
        }
    }
    
    .card-expanded {
        header {
            transition: border-radius .01s ease-in-out;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
    
    .title-and-sub-title {
        flex: 1 auto;
    }
    
    .block-expanded-content {
        padding: 25px 15px;
        @include media-breakpoint-up(sm) {
            padding: 25px 20px;
        }
        @include media-breakpoint-up(md) {
            padding: 25px;
        }
    }
</style>