<template>
    <card-general>
        <section-header v-if="false">Envision your retirement today</section-header>
        <retirement-interface-switch/>

        <investment-options-carousel/>

        <!--hidden-->
        <div class="mt-3 d-none">
            <div class="font-size-17 font-weight-600">Investment options available through Bento:</div>
            <div class="row mt-2 g-3">
                <div class="col-md-4" v-for="(fund, index) in retirementFundList" :key="fund.id">
                    <investment-option-colour-block
                        :title="fund.name" :subtitle="fund.blurb" :description="fund.description" :fact-sheet-link="fund.fact_sheet_file || fund.fact_sheet || null"
                        :class="[{'gradient-blue-darkest': index === 0, 'gradient-orange': index === 1, 'gradient-primary': index === 2, 'selected': isSelected(fund.id)}]"
                        :fund-id="fund.id"
                        @click="selectFundHandler(fund.id)"
                    />
                </div>
            </div>
        </div>

        <div v-if="isValidArray(retirementFundList)">
            <div class="mt-5" id="steps-container">
                <steps-intro-numbers/>
                <step1-investment-details v-if="currentRetirementWizardStep == 1"/>
                <step2-your-details v-else-if="currentRetirementWizardStep == 2"/>
                <step3-fees v-else-if="currentRetirementWizardStep == 3"/>
                <step4-advisor-details v-else-if="currentRetirementWizardStep == 4"/>
                <step5-submit-application v-else-if="currentRetirementWizardStep == 5"/>
                <div v-else>Please select a step in the wizard above</div>
            </div>

            <div v-if="retirementFundContractObjSubmitted" class="mt-4">
                <bento-alert color="info" static v-model="youHaveSubmittedRequestNotice">
                    You have submitted a contract change request which is currently being processed.
                </bento-alert>
            </div>
            <div v-else>
                <bento-alert :color="noticeColor" static v-model="showSaveDraftNotice" v-if="showSaveDraftNotice" class="mb-0 mt-4">
                    {{ saveDraftNoticeText }}
                </bento-alert>
                <div id="loading-retirement-fund-changes" v-if="isSubmittingChanges">
                    <loading-small v-model="isSubmittingChanges" parentSelector="#loading-retirement-fund-changes" loadingText="Saving as draft ..."/>
                </div>
                <div class="mt-4 pt-2 mb-3 d-flex flex-wrap flex-md-nowrap" v-if="currentRetirementWizardStep != 5">
                    <button-bento color="dark" class="me-sm-2" @click="button1NavigationHandler">
                        <span>{{ button1Text }}</span> <img src="@/assets/v2/icons/arrows/arrow-right-white.svg" class="arrow"/> <img src="@/assets/v2/icons/arrows/arrow-right-black.svg" class="arrow-hover"/>
                    </button-bento>
                    <button-bento color="white-bg-green" class="ms-md-2 mt-3 mt-sm-0" @click="saveDraftRetirementContractHandler" v-if="![3, 4].includes(currentRetirementWizardStep)">
                        <span>Save as draft</span>
                    </button-bento>
                </div>
            </div>
            <bento-alert color="info" static dismiss v-model="readResourcesNotice" v-if="onStep1 && false">
                Read the helpful resource cards below to understand retirement funds and tax savings better.
            </bento-alert>
        </div>
        
        <!--testing-->
        <div v-if="false">
            <div v-if="false"><pre><small>tempSelectedRetirementFundContractObj: {{ tempSelectedRetirementFundContractObj }}</small></pre></div>
            <div><pre><small>isBeneficiary1Complete: {{ isBeneficiary1Complete }}</small></pre></div>
            <div><pre><small>isBeneficiary2Complete: {{ isBeneficiary2Complete }}</small></pre></div>
            <div><pre><small>isBeneficiary3Complete: {{ isBeneficiary3Complete }}</small></pre></div>
            <div><pre><small>isBeneficiary4Complete: {{ isBeneficiary4Complete }}</small></pre></div>
            <div><pre><small>areAllBeneficiariesComplete: {{ areAllBeneficiariesComplete }}</small></pre></div>
            <div><pre><small>areAll4BeneficiariesComplete: {{ areAll4BeneficiariesComplete }}</small></pre></div>
        </div>
        
    </card-general>
</template>

<script setup>
    import CardGeneral from '@/components/UI/v2/containers-cards-headers/CardGeneral.vue'
    import SectionHeader from '@/components/UI/v2/containers-cards-headers/SectionHeader.vue'
    import RetirementInterfaceSwitch from '@/components/benefits-leave-reimbursements/retirement/components/RetirementInterfaceSwitch.vue'
    import InvestmentOptionColourBlock from '@/components/benefits-leave-reimbursements/retirement/components/investment-options/InvestmentOptionColourBlock.vue'
    import ButtonBento from '@/components/UI/v2/buttons/ButtonBento.vue'
    import StepsIntroNumbers from "@/components/benefits-leave-reimbursements/retirement/components/investment-options/StepsIntroNumbers.vue"
    import Step1InvestmentDetails from "@/components/benefits-leave-reimbursements/retirement/components/investment-options/Step1InvestmentDetails.vue"
    import Step2YourDetails from "@/components/benefits-leave-reimbursements/retirement/components/investment-options/Step2YourDetails.vue"
    import Step3Fees from "@/components/benefits-leave-reimbursements/retirement/components/investment-options/Step3Fees.vue"
    import Step4AdvisorDetails from "@/components/benefits-leave-reimbursements/retirement/components/investment-options/Step4AdvisorDetails.vue"
    import Step5SubmitApplication from "@/components/benefits-leave-reimbursements/retirement/components/investment-options/Step5SubmitApplication.vue"
    
    import {computed, ref} from "vue"
    import {useStore} from "vuex"
    import {useSaveDraftRetirementContractHandler} from "@/hooks/retirement/save-draft-retirement-contract"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
    import LoadingSmall from "@/components/UI/v2/loading/LoadingSmall.vue"
    import InvestmentOptionsCarousel
        from "@/components/benefits-leave-reimbursements/retirement/components/investment-options/InvestmentOptionsCarousel.vue";
    import {isValidArray} from "@/hooks/general/type-and-value-checks";
    
    const store = useStore()
    
    const retirementFundContractObjSubmitted = computed(() => store.getters.retirementFundContractObjSubmitted)
    const retirementFundList = computed(() => store.getters.retirementFundList)
    const readResourcesNotice = ref(true)
    
    const isSelected = (fundId) => selectedFund.value && fundId === selectedFund.value.id
    
    const youHaveSubmittedRequestNotice = ref(true)
    const hasRetirementFundContract = computed(() => store.getters.hasRetirementFundContract)
    
    // testing ----------
    const isBeneficiary1Complete = computed(() => store.getters.isBeneficiary1Complete)
    const isBeneficiary2Complete = computed(() => store.getters.isBeneficiary2Complete)
    const isBeneficiary3Complete = computed(() => store.getters.isBeneficiary3Complete)
    const isBeneficiary4Complete = computed(() => store.getters.isBeneficiary4Complete)
    const areAllBeneficiariesComplete = computed(() => store.getters.areAllBeneficiariesComplete)
    const areAll4BeneficiariesComplete = computed(() => store.getters.areAll4BeneficiariesComplete)
    // ------------------
    
    // step 5 has it's own button
    const currentRetirementWizardStep = computed(() => store.getters.currentRetirementWizardStep)
    const button1Text = computed(() => {
        if (currentRetirementWizardStep.value == 1) {
            if (hasRetirementFundContract.value) return "Change my investment"
            return "Apply for this investment"
        } else if (currentRetirementWizardStep.value == 2) {
            return "View fees"
        } else if (currentRetirementWizardStep.value == 3) {
            return "Meet your advisor"
        } else if (currentRetirementWizardStep.value == 4) {
            return "Review application"
        } else {
            return "Next"
        }
    })
    
    const tempSelectedRetirementFundContractObj = computed(() => store.getters.tempSelectedRetirementFundContractObj)
    const selectedFund = computed(() => {
        if (!tempSelectedRetirementFundContractObj.value) return null
        return retirementFundList.value.find(fund => fund.id == tempSelectedRetirementFundContractObj.value.fundId)
    })
    
    
    const button1NavigationHandler = () => {
        saveDraftNoticeText.value = null
        showSaveDraftNotice.value = false
        
        if (currentRetirementWizardStep.value == 5) {
            store.commit('toggleShowRemDraftModalRetirement', true)
        } else {
            store.commit('setCurrentRetirementWizardStep', currentRetirementWizardStep.value + 1)
            let element = document.getElementById('steps-container')
            if (element) {
                const elementRect = element.getBoundingClientRect()
                const offset = 70
                const topPosition = elementRect.top + window.pageYOffset - offset
                
                window.scrollTo({top: topPosition, behavior: 'smooth'})
            }
        }
    }
    
    const onStep1 = computed(() => currentRetirementWizardStep.value == 1)
    
    const selectFundHandler = (fundId) => {
        saveDraftNoticeText.value = null
        showSaveDraftNotice.value = false
        store.commit('setTempSelectedRetirementFundContractObj', {
            ...tempSelectedRetirementFundContractObj.value,
            fundId: fundId
        })
    }
    
    const isSubmittingChanges = ref(false)
    const noticeColor = ref('primary')
    const showSaveDraftNotice = ref(false)
    const saveDraftNoticeText = ref(null)
    
    const saveDraftRetirementContractHandler = () => {
        useSaveDraftRetirementContractHandler(tempSelectedRetirementFundContractObj, showSaveDraftNotice, saveDraftNoticeText, noticeColor, isSubmittingChanges)
    }
</script>


<style scoped lang="scss">
    @import '@/styles/global-scss/variables-and-mixins.scss';
    
    .arrow,
    .arrow-hover {
        margin-left: 10px;
        margin-bottom: 2px;
        width: 16px;
    }
    button {
        .arrow-hover {
            display: none;
        }
        &:hover {
            .arrow {
                display: none;
            }
            .arrow-hover {
                display: inline;
            }
        }
        flex: 0 0 100%;
        @include media-breakpoint-up(sm) {
            flex: 0 1 auto;
        }
    }
    
    .contact-us-link:hover {
        text-decoration: underline;
    }
    
    #loading-retirement-fund-changes {
        min-height: 50px;
        margin-top: 25px;
        margin-bottom: 25px;
    }
</style>
