<template>
    <essential-benefit-page>
        <template #benefitTitle>Ad Hoc Expense Claims</template>
        
        <template #benefitExtraContent>
            <div v-if="isEnabledReimbursements">
                <div v-if="!hasReimbursementClaimApprover">
                    <card-general><no-approver-notice/></card-general>
                </div>
                <div v-else-if="!isValidArray(reimbursementAllowanceListAdHocOnly)">
                    <card-general><no-ad-hoc-allowance-notice/></card-general>
                </div>
                <card-general v-else>
                    <div v-if="hasReimbursementClaimApprover && isValidArray(reimbursementAllowanceListAdHocOnly)">
                        <div class="mb-4 pb-2">
                            <div class="font-size-17 fw-500 pe-2">
                                Submit Expense Claims
                            </div>
                            <div class="font-size-14 text-gradient-blue-text-dark fw-500 mt-1">
                                Ad hoc expense reimbursements are processed separately from your salary.
                            </div>
                        </div>
                    </div>
                    <reimbursement-wizard-block
                        class="mb-3 wizard-block"
                        v-for="reimbursementType in reimbursementAllowanceListAdHocOnly"
                        :is-ad-hoc="true"
                        :reimbursement-type="reimbursementType"
                        :key="reimbursementType.id"
                    />
                    <div class="font-weight-600 text-gradient-blue-text-dark hover-blue mt-3">
                        <router-link :to="{name: 'reimbursements'}">Click here for salary reimbursement allowances -></router-link>
                    </div>
                </card-general>
                <reimbursement-claims-list-cont v-if="hasReimbursementClaimApprover && isValidArray(reimbursementAllowanceListAdHocOnly)" :is-ad-hoc="true"/>
            </div>
            <div v-else>
                <bento-alert color="warning" static>
                    Your employer has not enabled reimbursements.
                </bento-alert>
            </div>
        </template>
        
        <template #resourcesSectionHeader v-if="isValidArray(learningResourcesAdHocReimbursementsApi) && isEnabledReimbursements">Learn more about Reimbursements</template>
        <template #learnContentCards v-if="isValidArray(learningResourcesAdHocReimbursementsApi) && isEnabledReimbursements">
            <base-learn-card-small
                v-for="resource in learningResourcesAdHocReimbursementsApi"
                :key="resource.id"
                :resource="resource"
                :class="activeClass(resource)"
            />
        </template>
    </essential-benefit-page>
</template>

<script setup>
    import {isValidArray} from "@/hooks/general/type-and-value-checks"
    import EssentialBenefitPage from "@/components/benefits-leave-reimbursements/EssentialBenefitPage"
    import BaseLearnCardSmall from "@/components/learn-and-explore/BaseLearnCardSmall"
    import ReimbursementWizardBlock from "@/components/benefits-leave-reimbursements/reimbursements/wizard/ReimbursementWizardBlock"
    import {useStore} from "vuex"
    import ReimbursementClaimsListCont from "@/components/benefits-leave-reimbursements/reimbursements/components/reimbursement-claims-lists/ReimbursementClaimsListCont.vue"
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
    const store = useStore()
    
    import {useRoute} from "vue-router"
    import NoApproverNotice from "@/components/benefits-leave-reimbursements/NoApproverNotice.vue"
    import NoAdHocAllowanceNotice from "@/components/benefits-leave-reimbursements/NoAdHocAllowanceNotice.vue"
    const route = useRoute()
    const activeClass = (resource) => route.params.resourceSlug === resource.slug ? 'active-card' : ''
    
    const learningResourcesAdHocReimbursementsApi = store.getters.learningResourcesAdHocReimbursementsApi
    const hasReimbursementClaimApprover = store.getters.hasReimbursementClaimApprover
    const reimbursementAllowanceListAdHocOnly = store.getters.reimbursementAllowanceListAdHocOnly
    
    const isEnabledReimbursements = store.getters.isEnabledReimbursements
</script>

<style lang="scss" scoped>
    .wizard-block:last-child {
        margin-bottom: 0 !important;
    }
</style>