// content getters

import {isValidArray} from "@/hooks/general/type-and-value-checks";

export default {

    shouldUpdateContentCards(state) {
        const lastContentFetch = state.lastContentFetch;
        if (!lastContentFetch) {
            return true;
        }
        const currentTimeStamp = new Date().getTime();
        // currently set to 300 seconds / 5 mins. Could also rather just check if the store has data
        return (currentTimeStamp - lastContentFetch) / 1000 > 300;
    },

    contentCards(state) {
        return state.contentCards;
    },

    learningResourcesHomeApi(_, getters) {
        if (!getters.contentCards) { return null }
        const resources = getters.contentCards.filter(element => (element.tag === 'home' || element.tag === 'home-link'))
        return (isValidArray(resources)) ? resources : null
    },

    learningResourcesMedicalApi(_, getters) {
        if (!getters.contentCards) { return null }
        return getters.contentCards.filter(element => element.tag === 'medical');
    },

    learningResourcesRetirementApi(_, getters) {
        if (!getters.contentCards) { return null }
        return getters.contentCards.filter(element => element.tag === 'retirement');
    },

    learningResourcesInsuranceApi(_, getters) {
        if (!getters.contentCards) { return null }
        return getters.contentCards.filter(element => element.tag === 'risk');
    },

    learningResourcesLeaveApi(_, getters) {
        if (!getters.contentCards) { return null }
        return getters.contentCards.filter(element => element.tag === 'leave');
    },

    learningResourcesReimbursementsApi(_, getters) {
        if (!getters.contentCards) { return null }
        return getters.contentCards.filter(element => element.tag === 'reimbursements');
    },

    learningResourcesAdHocReimbursementsApi(_, getters) {
        if (!getters.contentCards) { return null }
        return getters.contentCards.filter(element => element.tag === 'ad-hoc-reimbursements');
    },

    learningResourcesBonusesAndCommissionsApi(_, getters) {
        if (!getters.contentCards) { return null }
        return getters.contentCards.filter(element => element.tag === 'bonuses_commissions');
    },

}