<template>
    <width-container>
        <card-general id="learn" class="green">
            <section-header class="green">Learn More & Explore</section-header>
            
            <learn-grid>
                <base-learn-card
                    v-for="resource in learningResourcesHomeApi"
                    :key="resource.id"
                    :resource="resource"
                ></base-learn-card>
            </learn-grid>
        </card-general>
        <router-view></router-view>
    </width-container>
</template>

<script setup>
    import BaseLearnCard from "../learn-and-explore/BaseLearnCard"
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer"
    import LearnGrid from "@/components/learn-and-explore/LearnGrid"
    import {computed} from "vue"
    import {useStore} from "vuex"
    import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
    
    const store = useStore()
    
    const learningResourcesHomeApi = computed(() => store.getters.learningResourcesHomeApi)
</script>

<style lang="scss" scoped>
    header {
        a {
            display: flex;
            align-items: center;
            justify-content: flex-start;
        }
    }
</style>