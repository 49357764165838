import LeaveManagementPage from "@/components/manager/leave/LeaveManagementPage.vue"
import TeamAdminReportingLinesPage from "@/components/manager/team-admin/TeamAdminReportingLinesPage.vue"
import TeamAdminDownloadReports from "@/components/manager/team-admin/TeamAdminDownloadReports.vue"
import TeamAdminUpdateEmployees from "@/components/manager/team-admin/TeamAdminUpdateEmployees.vue"
import LeaveManagementBalanceLookup from "@/components/manager/leave/LeaveManagementBalanceLookup.vue"
import ReimbursementsManagementPage from "@/components/manager/reimbursements/ReimbursementsManagementPage.vue";

export default [

    // team admin
    {
        name: 'team-admin-update-employee',
        path: '/my-team/update-employee',
        component: TeamAdminUpdateEmployees,
        meta: { menuParent: 'team-admin' },
    },
    {
        name: 'team-admin-reporting-lines',
        path: '/my-team/reporting-lines',
        component: TeamAdminReportingLinesPage,
        meta: { menuParent: 'team-admin' },
    },
    {
        name: 'team-admin-download-reports',
        path: '/my-team/download-reports',
        component: TeamAdminDownloadReports,
        meta: { menuParent: 'team-admin' },
    },

    // leave management
    {
        name: 'my-team-leave-management',
        path: '/my-team/leave-management',
        component: LeaveManagementPage,
        meta: { menuParent: 'leave-management' },
    },
    {
        name: 'my-team-leave-balance-lookup',
        path: '/my-team/leave-balance-lookup',
        component: LeaveManagementBalanceLookup,
        meta: { menuParent: 'leave-management' },
    },

    // reimbursements management
    {
        name: 'my-team-reimbursements',
        path: '/my-team/reimbursements',
        component: ReimbursementsManagementPage,
        meta: { menuParent: 'reimbursement-management' },
    },
]