<template>
    <div class="mt-n3">
        
        <!--medical aid submitted changes-->
        <div v-if="areMedicalAidChangesSubmitted">
            <card-general-inner class="green no-shadow gradient-green-text-dark mb-4">
                <div class="font-size-16 fw-600 mb-1">Medical Aid Changes</div>
                <ul class="mb-0 mt-2 list-unstyled font-weight-500 gradient-green-text-light">
                    <li v-for="change in textSummaryOfChangesMedical" :key="change" v-html="change"></li>
                </ul>
            </card-general-inner>
        </div>
        
        <!--submitted retirement changes-->
        <div v-if="areRetirementChangesSubmitted">
            <card-general-inner class="green no-shadow gradient-green-text-dark mb-4">
                <div class="fw-600 font-size-16 mb-1">Retirement Fund Changes</div>
                <ul class="mb-0 mt-2 list-unstyled font-weight-500 gradient-green-text-light">
                    <li v-for="change in textSummaryOfChangesRetirement" :key="change" v-html="change"></li>
                </ul>
            </card-general-inner>
        </div>
        
        <!--submitted bank account changes-->
        <div v-if="areBankAccountChangesSubmitted">
            <card-general-inner class="green no-shadow gradient-green-text-dark mb-4">
                <div class="fw-600 font-size-16 mb-1">Bank Account Changes</div>
                <ul class="mb-0 mt-2 list-unstyled font-weight-500 gradient-green-text-light">
                    <li><span class="fw-500">Bank:</span> {{ submittedBankAccountChangeRequest.bank }}</li>
                    <li><span class="fw-500">Account number:</span> {{ submittedBankAccountChangeRequest.account_number }}</li>
                    <li><span class="fw-500">Branch code:</span> {{ submittedBankAccountChangeRequest.branch_code }}</li>
                    <li><span class="fw-500">Account type:</span> {{ toTitleCase(submittedBankAccountChangeRequest.account_type) }}</li>
                    <li><span class="fw-500">Account holder:</span> {{ optionDisplayText(holderRelationshipOptions, submittedBankAccountChangeRequest.holder_relationship) }}</li>
                </ul>
            </card-general-inner>
        </div>
    
    </div>
</template>

<script setup>
    import {computed} from "vue"
    import {useStore} from "vuex"
    import CardGeneralInner from "@/components/UI/v2/containers-cards-headers/CardGeneralInner.vue"
    import {toTitleCase} from "@/hooks/general/text-formatters"
    
    const store = useStore()
    
    const areMedicalAidChangesSubmitted = computed(() => store.getters.areMedicalAidChangesSubmitted)
    const textSummaryOfChangesMedical = computed(() => store.getters.textSummaryOfChangesMedical)
    
    const areRetirementChangesSubmitted = computed(() => store.getters.areRetirementChangesSubmitted)
    const textSummaryOfChangesRetirement = computed(() => store.getters.textSummaryOfChangesRetirement)
    
    const submittedBankAccountChangeRequest = computed(() => store.getters.submittedBankAccountChangeRequest)
    const areBankAccountChangesSubmitted = computed(() => store.getters.areBankAccountChangesSubmitted)
    
    
    let holderRelationshipOptions
    if (submittedBankAccountChangeRequest.value) {
        holderRelationshipOptions = [
                {text: "Self Held Account", secondaryText: "The bank account is held solely by you", value: 'employee', selected: submittedBankAccountChangeRequest.value?.holder_relationship === 'employee'},
                {text: "Joint Account", secondaryText: "The account is a joint account", value: 'joint', selected: submittedBankAccountChangeRequest.value?.holder_relationship === 'joint'},
                {text: "Third Party", secondaryText: "Account is held by a third party", value: 'third party', selected: submittedBankAccountChangeRequest.value?.holder_relationship === 'third party'},
            ]
    }
    
    const optionDisplayText = (optionArray, optionValue) => {
        if (optionValue && optionValue.trim()) {
            const found = optionArray.find(element => element.value === optionValue)
            return found ? found.text : null
        }
        return null
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    ul {
        line-height: 1.9;
        :deep(li span) {
            font-weight: 600;
            color: $gradient-green-text-dark;
        }
    }
    
    
    h5 {
        font-size: 1.3rem;
    }
</style>