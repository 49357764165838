<template>
    <div class="col-12 col-md-6 card-col" :class="[ classList,  { 'card-expanded': cardExpanded } ]">
        
        <header @click="toggleCardExpanded" class="rounded p-3 d-flex align-items-center position-relative cursor-pointer">
            <div class="card-title font-weight-600 m-0 font-size-16">
                <slot name="title"></slot>
            </div>
            <div class="card-sub-title font-weight-600 ms-2 d-none d-sm-block d-md-none d-lg-block" v-if="hasSubHeadingContent">
                &bull; <span><slot name="subHeading"></slot></span>
            </div>
            <div class="three-dots">
                <img src="@/assets/v2/icons/three-dots.svg" alt="Select"/>
            </div>
        </header>
        
        <MDBCollapse v-model="cardExpanded">
            <div class="expanded-content rounded-bottom p-3 text-gray-main font-weight-500">
                <div v-if="$slots.cardText" class="card-text">
                    <slot name="cardText"></slot>
                </div>
        
                <div class="font-size-14 mt-4 text-gray-700 font-weight-600 text-center cursor-pointer">
                    <span @click="viewBenefit" class="view-more-text font-weight-600 bento-underline">{{ viewMoreText }}</span>
                </div>
            </div>
        </MDBCollapse>
        
    </div>
</template>

<script setup>
    import { MDBCollapse } from "mdb-vue-ui-kit";
    import {computed, ref, useSlots, watch} from "vue";
    import {useRoute, useRouter} from "vue-router";
    const router = useRouter();
    const route = useRoute();
    
    const props = defineProps({
        classList: {
            type: Object,
            required: false,
        },
        viewMoreText: {
            type: String,
            required: false,
            default() {
                return "View more";
            }
        },
        viewMoreLink: {
            type: String,
            required: false,
        }
    })
    
    
    const slots = useSlots()
    const hasSubHeadingContent = computed(() => {
        return !!slots.subHeading && slots.subHeading().length > 0
    })
    
    const cardExpanded = ref(false)
    const toggleCardExpanded = () => cardExpanded.value = !cardExpanded.value
    
    const viewBenefit = () => router.push(props.viewMoreLink)
    
    watch(route, () => cardExpanded.value = false)
</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    header {
        min-height: 68px;
        transition: border-radius .5s ease-in-out;
    }
    
    .card-expanded {
        header {
            transition: border-radius .01s ease-in-out;
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
    
    .three-dots {
        position: absolute;
        top: 50%;
        right: 22px;
        transform: translateY(-50%);
    }
    
    .expanded-content {
        overflow: hidden;
    }
    
    .card-text {
        line-height: 1.4;
        :deep(p:last-child) {
            margin-bottom: 0;
        }
        :deep(span) {
            font-weight: 600;
        }
    }
    
    // ### card colours
    .card-col {
        header {
            background: $gradient-green;
            &:hover {
                background: $gradient-green-darken;
            }
            .card-title {
                color: $gradient-green-text-dark;
            }
            .card-sub-title {
                color: $gradient-green-text-light;
            }
        }
        &.card-expanded {
            header {
                background: $gradient-green-darken;
            }
        }
        .expanded-content {
            background-color: $gradient-green-2;
        }
        .view-more-text {
            transition: all .2s ease-in-out;
            color: $gradient-green-text-dark;
            &:hover {
                color: darken($gradient-green-text-dark, 15%);
            }
            &:before {
                background-color: $gradient-green-text-dark;
            }
        }
        // default colours above, specific below
        &.green-card {
            header {
                background: $gradient-green;
                &:hover {
                    background: $gradient-green-darken;
                }
                .card-title {
                    color: $gradient-green-text-dark;
                }
                .card-sub-title {
                    color: $gradient-green-text-light;
                }
            }
            &.card-expanded {
                header {
                    background: $gradient-green-darken;
                }
            }
            .expanded-content {
                background-color: $gradient-green-2;
            }
            .view-more-text {
                color: $gradient-green-text-dark;
                &:hover {
                    color: darken($gradient-green-text-dark, 15%);
                }
                &:before {
                    background-color: $gradient-green-text-dark;
                }
            }
        }
        &.pink-card {
            header {
                background: $gradient-pink;
                &:hover {
                    background: $gradient-pink-darken;
                }
                .card-title {
                    color: $gradient-pink-text-dark;
                }
                .card-sub-title {
                    color: $gradient-pink-text-light;
                }
            }
            &.card-expanded {
                header {
                    background: $gradient-pink-darken;
                }
            }
            .expanded-content {
                background-color: $gradient-pink-2;
            }
            .view-more-text {
                color: $gradient-pink-text-dark;
                &:hover {
                    color: darken($gradient-pink-text-dark, 10%);
                }
                &:before {
                    background-color: $gradient-pink-text-dark;
                }
            }
        }
        &.blue-card {
            header {
                background: $gradient-blue;
                &:hover {
                    background: $gradient-blue-darken;
                }
                .card-title {
                    color: $gradient-blue-text-dark;
                }
                .card-sub-title {
                    color: $gradient-blue-text-light;
                }
            }
            &.card-expanded {
                header {
                    background: $gradient-blue-darken;
                }
            }
            .expanded-content {
                background-color: $gradient-blue-2;
            }
            .view-more-text {
                color: $gradient-blue-text-dark;
                &:hover {
                    color: darken($gradient-blue-text-dark, 25%);
                }
                &:before {
                    background-color: $gradient-blue-text-dark;
                }
            }
        }
        &.green-alt-card {
            header {
                background: $gradient-green-alt;
                &:hover {
                    background: $gradient-green-alt-darken;
                }
                .card-title {
                    color: $gradient-green-alt-text-dark;
                }
                .card-sub-title {
                    color: $gradient-green-alt-text-light;
                }
            }
            &.card-expanded {
                header {
                    background: $gradient-green-alt-darken;
                }
            }
            .expanded-content {
                background-color: $gradient-green-alt-2;
            }
            .view-more-text {
                color: $gradient-green-alt-text-dark;
                &:hover {
                    color: darken($gradient-green-alt-text-dark, 15%);
                }
                &:before {
                    background-color: $gradient-green-alt-text-dark;
                }
            }
        }
        
    }
</style>