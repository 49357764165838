// simple benefits (free perks) actions
import theApiCall from "@/hooks/api/the-api-call";
import {apiResponseHandler} from "@/hooks/api/api-helper-function";

export default {

    async loadSimpleBenefitsList(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateSimpleBenefitsList) { return }

        const response = await theApiCall('simple_benefits/')
        const responseData = await apiResponseHandler(response, 'Could not load simple benefits data.', 'Simple benefits resources not found. [404]')

        context.commit('setSimpleBenefitsList', responseData)
        context.commit('setLastSimpleBenefitsListFetch', { timestamp: new Date().getTime() })
    },
}