// employee mutations

export default {
    setEmployee: (state, payload) => state.employee = payload,

    setLastEmployeeFetchTimestamp: (state, payload) => state.lastEmployeeFetch = (payload) ? (payload.timestamp) : null,

    setIsTermsAccepted: (state, payload) => state.isTermsAccepted = payload,

    // employee payslips List - '/employees/{{userApiId}}/payslips/'
    setPayslipsList: (state, payload) => state.payslipsList = payload,
    setLastPayslipsListFetchTimeStamp: (state, payload) => state.lastPayslipsListFetch = (payload) ? (payload.timestamp) : null,
}