// user term actions
import theApiCall from "@/hooks/api/the-api-call"
import {apiResponseHandler} from "@/hooks/api/api-helper-function"
import store from "@/store"

export default {

    // Doing a GET to /api/1.0/employees/2/accept_tc/ will set a timestamp on employee.tc_date_of_acceptance
    async setAcceptTerms(context) {
        const userApiId = (localStorage.userId) ? localStorage.userId : context.getters.userApiId
        const response = await theApiCall(`employees/${userApiId}/accept_tc/`)
        const responseData = await apiResponseHandler(response, 'Could not set user terms as accepted.', 'Employee resource not found. [404]')
        await context.commit('setEmployee', responseData)
        await context.commit('setIsTermsAccepted', true)
        await localStorage.setItem('isTermsAccepted', 'true')
    },


    // need to use try / catch block like this because of external api call with fetch can cause CORS erros and not be caught by the try / catch block in the api call
    async fetchUserTerms(context) {
        let url = 'https://content.mybento.net/wp-json/wp/v2/pages/94?_fields=acf'
        try {
            const response = await fetch(url, {method: 'GET'})
            const responseData = await apiResponseHandler(response, 'Could not load user terms.', 'User terms resource not found. [404]')

            let userTermsContent = responseData.acf
            context.commit('setUserTerms', userTermsContent)
        } catch (error) {
            store.commit('setShowApiErrorModal', true)
            store.commit('setApiErrorMessage', 'Failed to fetch user terms.')
        }
    },

    async fetchPrivacyPolicy(context) {
        let url = 'https://content.mybento.net/wp-json/wp/v2/pages/109?_fields=acf';
        try {
            const response = await fetch(url, {method: 'GET'})
            const responseData = await apiResponseHandler(response, 'Could not load privacy policy.', 'Privacy Policy resource not found. [404]')

            let privacyPolicyContent = responseData.acf
            context.commit('setPrivacyPolicy', privacyPolicyContent)
        } catch (error) {
            store.commit('setShowApiErrorModal', true)
            store.commit('setApiErrorMessage', 'Failed to fetch user terms.')
        }
    },
}