<template>
    <div class="carousel-container">
        
        <div v-if="false">
            <pre>
            {{ filteredSortedSeriesPlans }}
            </pre>
        </div>
        <div v-if="false">
            <pre>
            {{ filteredSortedSeriesPlansWithIndex }}
            </pre>
        </div>
        
        <Carousel
            :settings="settings"
            :breakpoints="breakpoints"
            ref="planCarousel"
        >
            <template #slides>
                <Slide v-for="slide in filteredSortedSeriesPlansWithIndex" :key="slide">
                    <div class="carousel__item">
                        <wizard-block-info-card-plan :plan="slide" @plan-select-handler="onPlanSelectHandler"/>
                    </div>
                </Slide>
            </template>
            
            <template #addons>
                <Navigation/>
                <Pagination/>
            </template>
        </Carousel>
        
    </div>
</template>

<script>
    // docs: https://ismail9k.github.io/vue3-carousel/examples.html
    
    import {defineComponent} from 'vue'
    import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel';
    
    import 'vue3-carousel/dist/carousel.css';
    import WizardBlockInfoCardPlan
        from "@/components/benefits-leave-reimbursements/medical-aid/wizard/card-carousel/plan/WizardBlockInfoCardPlan";
    import {mapGetters} from "vuex";
    
    export default defineComponent({
        name: 'WizardBlockCardCarouselPlan',
        components: {
            WizardBlockInfoCardPlan,
            Carousel,
            Slide,
            Pagination,
            Navigation,
        },
        
        computed: {
            
            ...mapGetters([
                'medicalAidPlanList',
                'tempSelectedMedicalAidContractObj',
                'hasMedicalAidContract'
            ]),
            
            filteredSortedSeriesPlans() {
                const filteredPlans = this.medicalAidPlanList.filter(plan => plan.series === this.tempSelectedMedicalAidContractObj.series);
                const filteredSortedPlans = filteredPlans.sort((series1, series2) => (Number(series1.main_member_cost) < Number(series2.main_member_cost)) ? 1 : -1);
                return filteredSortedPlans;
            },
            filteredSortedSeriesPlansWithIndex() {
                const planListSorted = this.filteredSortedSeriesPlans;
                const planListWithIndex = [];
                let i = 0;
                for (const seriesObject of planListSorted) {
                    planListWithIndex.push(
                        {
                            ...seriesObject,
                            index: i,
                        }
                    )
                    i++;
                }
                return planListWithIndex;
            },
            
        },
        
        methods: {
            
            onPlanSelectHandler(slideIndex) {
                const planCarousel = this.$refs.planCarousel;
                if (planCarousel && slideIndex && slideIndex != 0) {
                    planCarousel.slideTo(slideIndex);
                }
            },
            
            centerSelectedPlan() {
                if (this.tempSelectedMedicalAidContractObj.plan_id) {
                    const selectedPlanId = this.tempSelectedMedicalAidContractObj.plan_id;
                    const selectedPlanObjectWithIndex = this.filteredSortedSeriesPlansWithIndex.find(obj => obj.id == selectedPlanId);
                    const slideIndex = selectedPlanObjectWithIndex.index;
                    const planCarousel = this.$refs.planCarousel;
                    planCarousel.slideTo(slideIndex);
                }
            },
            
        },
        
        data: () => ({
            
            // carousel settings
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
                wrapAround: true,
            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 3,
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                },
            },
            
        }),
        
        mounted() {
            this.centerSelectedPlan();
        }
        
    });
</script>

<style scoped>
    /*Carousel styles come from unscoped WizardBlockCardCarouselBenefit*/
</style>