// Employee medical store module [not namespaced]

import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import data from "@/data/data";


export default {

    state() {
        return {

            // temp selected contract object
            // only 3 things can change (plan_id, adult_deps, child_deps)
            // need series name for selection wizard
            tempSelectedMedicalAidContractObj: {
                series: null,
                // series_id: null, --> ideally use this rather
                plan_id: null,
                adult_dependents: null,
                child_dependents: null,
            },


            // api data
            medicalAidPlanList: null,
            lastMedicalAidPlanListFetch: null,

            // hard coded data from js file
            medicalAidSeriesList: data.medicalAidSeriesList,


            // contract change request list
            medicalAidContractChangeRequestList: null,
            lastMedicalAidContractChangeRequestListFetch: null,
        }
    },

    mutations,
    actions,
    getters,


}