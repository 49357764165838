// medical aid employee - new endpoint

import mutations from './mutations';
import actions from './actions';
import getters from './getters';


export default {

    state() {
        return {

            // this is the employees medical aid figures taken from their payslip as well as their contract object
            employeeMedicalAidsList: null,
            lastEmployeeMedicalAidsListFetch: null,
        }
    },

    mutations,
    actions,
    getters,
}