// hr admin actions
import theApiCall from "@/hooks/api/the-api-call"
import {apiResponseHandler} from "@/hooks/api/api-helper-function"

export default {

    async updateLeaveOrReimbursementApprover(context, payload) {
        const response = await theApiCall(`employees/${payload.employeeToUpdate}/`, 'PATCH', payload.body, 'application/json')
        const responseData = await apiResponseHandler(response, 'Could not update approver.', 'Failed to update approver. Resource not found. [404]')
        return {response, responseData}
    },

}
