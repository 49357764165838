<template>
    <div>
        <div v-if="!selectedFund">
            <div class="fw-600 font-size-18 mb-1 gradient-green-text-dark">
                Please select a fund above.
            </div>
        </div>
        <div v-else>
            <card-general-inner class="green no-shadow gradient-green-text-dark mt-5">
                <div v-html="selectedFund.fees_description"></div>
            </card-general-inner>
        </div>
    </div>
</template>

<script setup>
    import CardGeneralInner from '@/components/UI/v2/containers-cards-headers/CardGeneralInner.vue'
    import {computed} from "vue"
    import {useStore} from "vuex"
    const store = useStore()
    
    const tempSelectedRetirementFundContractObj = computed(() => store.getters.tempSelectedRetirementFundContractObj)
    const retirementFundList = computed(() => store.getters.retirementFundList)
    const selectedFund = computed(() => {
        if (!tempSelectedRetirementFundContractObj.value) return null
        return retirementFundList.value.find(fund => fund.id == tempSelectedRetirementFundContractObj.value.fundId)
    })
</script>

<style scoped lang="scss">
</style>
