export const isValidArray = arrayName => {
    return !!( (typeof arrayName != "undefined") && (arrayName != null) && (arrayName.length != null) && (arrayName.length > 0))
}

export const rossIsNonEmptyObjectCheck = objectName => {
    return (typeof objectName === 'object') && (objectName !== null) && (Object.keys(objectName).length > 0)
}

export const isNonEmptyString = (param) => {
    return typeof param === 'string' && param.trim().length > 0
}