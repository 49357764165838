<template>
    <div class="d-flex mb-4">
        <div id="switch-container" class="d-flex">
            <div
                :class="{selected: selectedRetirementView === 'retirement-calculator'}" class="switch-option switch-option-1 me-2 text-center"
                @click="selectViewHandler('retirement-calculator')"
            >
                Retirement Calculator
            </div>
            <div class="switch-option-separator"></div>
            <div
                :class="{selected: selectedRetirementView === 'retirement-investment-options'}" class="switch-option switch-option-2 ms-2 text-center"
                @click="selectViewHandler('retirement-investment-options')"
            >
                Investment Options
            </div>
        </div>
    </div>
</template>

<script setup>
    import {computed} from "vue"
    import {useStore} from "vuex";
    const store = useStore()
    const selectedRetirementView = computed(() => store.getters.selectedRetirementView) // 'retirement-calculator' || 'retirement-investment-options'
    const selectViewHandler = (option) => store.commit('setSelectedRetirementView', option)
</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    #switch-container {
        background-color: #FAFAFA;
        border: 1px solid rgba(102, 102, 102, 0.25);
        border-radius: 13px;
        height: 50px;
    }
    
    .selected {
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.08);
        box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
        border-radius: 13px;
    }
    
    .switch-option {
        line-height: 1.2;
        height: 50px;
        padding: 0 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        top: -1px;
        width: 120px;
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        @media (min-width: 420px) {
            width: 140px;
        }
        @include media-breakpoint-up(sm) {
            width: 170px;
            font-size: 15px;
        }
        @include media-breakpoint-up(md) {
            width: 220px;
            font-size: 16px;
        }
        
        &:hover {
            background-color: white;
            border: 1px solid rgba(0, 0, 0, 0.08);
            box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
            border-radius: 13px;
        }
        &.switch-option-1 {
            left: -1px;
        }
        &.switch-option-2 {
            right: -1px;
        }
    }
    
    .switch-option-separator {
        width: 1px;
        background-color: rgba(0, 0, 0, 0.2);
        height: 20px;
        position: relative;
        top: 15px;
    }
    
</style>