// employee/payslip-financials/allowances getters

import {createPayslipGetter} from "@/hooks/general/getters-helpers"
import {isValidArray} from "@/hooks/general/type-and-value-checks"


// ### Notes on allowances
// README: https://www.simplepay.co.za/help/payroll-concepts/payslip-components#allowance
// An allowance is an amount of money given to an employee by an employer to incur business-related expenditure on the employer’s behalf, without an obligation on the employee to prove or account for such expenditure. The amount of the allowance is based on the expected expenditure.
// Generally allowances are fully taxable - reported under code 3713
// Custom Items added as allowance type are going to be fully taxable
// Certain allowances have special tax treatment and reporting requirements

// RH to confirm
// Allowances are then added to income (Salary before deductions), they will increase your Nett Pay by that amount
// They must also be added to taxable income, where applicable, effects PAYE calculation

export default {

    allowancesList: (_, getters) => getters.latestPayslip?.allowances || null,

    // ## Standard SimplePay Allowances
    // ## https://www.simplepay.co.za/help/payroll-processing/system-items-allowances
    // ## =============================

    // Irregular income - goes to irregular income
    // https://www.simplepay.co.za/help/payroll-processing/system-items-allowances/broad-based-employee-share-plan
    broadBasedEmployeeSharePlan: (_, getters) => createPayslipGetter(getters.allowancesList, 'broad_based_employee_share_plan'),

    // 100% taxable
    // https://www.simplepay.co.za/help/payroll-processing/system-items-allowances/computer-allowance.
    computerAllowance: (_, getters) => createPayslipGetter(getters.allowancesList, 'computer_allowance'),

    // This 'Expense Claim' is a bit of an anomaly
    // It is listed under allowances, where as other custome reimbursements are
    // listed under Reimbursements.
    // This is also what will be returned to us from expense claims submitted through Bento App

    // So this just get's added to Nett Pay
    // It obviously doesn't form part of income (regular or irregular)
    expenseClaimsNonTaxable(_, getters) {
        let apiAmount = 0;
        let itemLabel = null;
        let internalName = 'expense_claim';
        let itemList = getters.allowancesList;
        if (isValidArray(itemList)) {
            let item = itemList.find(item => item.internal_name === internalName);
            if (item) {
                apiAmount = Number(item.amount);
                itemLabel = item.name;
            }
        }

        // TODO:
        //  doing this manually because I need to base planned expense claims on currently approved expense claims submitted.
        //  and current should take last periods approved expense claims as a comparison
        let current = apiAmount;
        let planned = apiAmount;

        return {
            id: internalName,
            label: itemLabel,
            api: apiAmount,
            current,
            planned,
        }
    },

    // Not sure how to treat this
    // Edge case
    // TODO:
    //  find out if any employees have this allowance and then account for it
    // https://www.simplepay.co.za/help/payroll-processing/system-items-allowances/subsistence-allowance
    internationalSubsistenceUnderLimit: (_, getters) => createPayslipGetter(getters.allowancesList, 'international_subsistence_under_limit'),

    // Then there is also subsistenceAllowance Local - with a tricky calculation.

    // not 100% sure how to treat this
    // this isn't the actual loan balance - this is the amount the loan balance has been increased by
    // shown in allowances and included in Nett Pay
    loanIncreaseBalance: (_, getters) => createPayslipGetter(getters.allowancesList, 'international_subsistence_under_limit'),

    // 100% taxable
    // https://www.simplepay.co.za/help/payroll-processing/system-items-allowances/phone-allowance
    phoneAllowance: (_, getters) => createPayslipGetter(getters.allowancesList, 'phone_allowance'),

    relocationAllowanceNonTaxable: (_, getters) => createPayslipGetter(getters.allowancesList, 'relocation_allowance_-_non-taxable'),

    relocationAllowanceTaxable: (_, getters) => createPayslipGetter(getters.allowancesList, 'relocation_allowance_-_taxable'),


    // The employer acts as the conduit for these payments and therefore the payments should not attract PAYE, UIF or SDL.
    // So in theory it shouldn't form part of Regular Income (Taxable)
    // And it should just increase Nett Pay
    // https://www.simplepay.co.za/help/covid-19/ters-payouts
    tersPayout: (_, getters) => createPayslipGetter(getters.allowancesList, 'tersPayout'),


    // :: Travel Allowances
    // https://www.simplepay.co.za/help/payroll-processing/system-items-allowances/travel-allowance

    // Fixed allowance paid regularly
    // Code 3701 - not subject to employees tax
    // Reimbursed for expenses gets included in this amount
    // 80 / 20 rule applies
    travelAllowanceFixedAndCosts: (_, getters) => createPayslipGetter(getters.allowancesList, 'travel_allowance_fixed_and_costs'),

    // Not paid out (makes sense you are using a company petrol card)
    // Doesn't appear anywhere on the payslip
    // A taxable benefit still arises.
    // The 80 / 20 rule applies
    // That's why this getter isn't actaully used anywhere.
    // TODO
    //  can't get this to map
    travelAllowancePetrolCardTaxable(_, getters) {
        return createPayslipGetter(getters.allowancesList, 'travel_allowance_petrol_card_taxable');
    },

    travelAllowanceReimbursive: (_, getters) => createPayslipGetter(getters.allowancesList, 'travel_allowance_reimbursive'),

    travelAllowanceReimbursiveTaxable: (_, getters) => createPayslipGetter(getters.allowancesList, 'travel_allowance_reimbursive_-_taxable'),


    // 100% taxable
    // https://www.simplepay.co.za/help/payroll-processing/system-items-allowances/tool-allowance
    toolAllowance: (_, getters) => createPayslipGetter(getters.allowancesList, 'tool_allowance'),


    // #### TOTALS SUMMED AND DEDUCED
    // ---------------------------------------------

    // # Total Allowances (Manually summed / no API)
    totalAllowancesIncludingExpenseClaim(_, getters) {
        let current = 0
        let planned = 0
        if (getters.allowancesList) {
            for (const allowance of getters.allowancesList) {
                current += Number(allowance.amount)
            }
        }

        // assume allowances for next period are the same as this period
        planned = current

        // TODO
        //  expense claims are variable though. We should probably remove expense claims and put currently approved expense claims in our system in planned instead.

        return {
            id: 'total-allowances-including-expense-claim',
            label: 'Total Allowances (incl. Expense Claim)',
            api: false,
            current,
            planned,
        }
    },

    totalAllowancesExclExpenseClaim(_, getters) {
        let current = Number(getters.totalAllowancesIncludingExpenseClaim?.current || 0) - Number((getters.expenseClaimsNonTaxable?.current || 0))
        let planned = Number(getters.totalAllowancesIncludingExpenseClaim?.planned || 0) - Number(getters.expenseClaimsNonTaxable?.planned ||0)
        
        return {
            id: 'total-allowances-excluding-expense-claim',
            label: 'Total Allowances (excl. expense claim)',
            api: false,
            current,
            planned,
        }
    },

    standardRecognisedAllowances(_, getters) {
        return [
            getters.broadBasedEmployeeSharePlan,
            getters.computerAllowance,
            getters.expenseClaimsNonTaxable,
            getters.internationalSubsistenceUnderLimit,
            getters.loanIncreaseBalance,
            getters.phoneAllowance,
            getters.relocationAllowanceNonTaxable,
            getters.relocationAllowanceTaxable,
            getters.tersPayout,
            getters.travelAllowanceFixedAndCosts,
            getters.travelAllowanceReimbursive,
            getters.travelAllowanceReimbursiveTaxable,
            getters.toolAllowance
        ];
    },

    standardRecognisedAllowancesTotal(_, getters) {
        let current = 0;
        for (const allowance of getters.standardRecognisedAllowances) {
            current += Number(allowance.api);
        }
        return {
            id: 'standard-recognised-allowances',
            label: 'Standard Recognised Allowances',
            api: false,
            current,
            planned: current,
        }
    },

    // custom allowances total, straight 100% taxable
    customAllowancesTotal(_, getters) {
        let current = Number(getters.totalAllowancesIncludingExpenseClaim.current) - Number(getters.standardRecognisedAllowancesTotal.current);
        return {
            id: 'custom-allowances-total',
            label: 'Custom Allowances Total',
            api: false,
            current,
            planned: current,
        }
    },

    // NB important
    // Used in taxableIncomeCalc() getter
    totalAllowancesTaxable(_, getters) {
        if (!getters.latestPayslip) {
            return null
        }
        let current = getters.totalAllowancesIncludingExpenseClaim.current
        let nonTaxableAllowances = 0
        if (isValidArray(getters.latestPayslip.allowances)) {
            for (const allowance of getters.latestPayslip.allowances) {
                if (allowance.internal_name === 'travel_allowance_fixed_and_costs') {
                    nonTaxableAllowances += .2 * Number(allowance.amount);
                }
                if (allowance.internal_name === 'travel_allowance_reimbursive') {
                    nonTaxableAllowances += Number(allowance.amount);
                }
                if (
                    allowance.internal_name === 'expense_claim' ||
                    allowance.internal_name === 'relocation_allowance_non_taxable' ||
                    allowance.internal_name === 'uniform_allowance' ||
                    allowance.internal_name === 'ters_payout' ||
                    allowance.internal_name === 'loan_increase_balance'
                ) {
                    nonTaxableAllowances += Number(allowance.amount);
                }
            }
        }
        current -= nonTaxableAllowances;

        // We assume that taxable allowances in the next period are the same as the current period
        let planned = current

        return {
            id: 'total-allowances-taxable',
            label: 'Total Allowances (Taxable)',
            api: false,
            current,
            planned,
        }
    },

}