// admin-super-user getters
import {shouldUpdateCheck} from "@/hooks/general/getters-helpers"

export default {
    employeeList: state => state.employeeList,
    lastEmployeeListFetch: state => state.lastEmployeeListFetch,
    shouldUpdateEmployeeList: (_, getters) => shouldUpdateCheck(getters.lastEmployeeListFetch, new Date().getTime()),

    minimalEmployeeList: state => state.minimalEmployeeList,
    lastMinimalEmployeeListFetch: state => state.lastMinimalEmployeeListFetch,
    shouldUpdateMinimalEmployeeList: (_, getters) => shouldUpdateCheck(getters.lastMinimalEmployeeListFetch, new Date().getTime()),

    employerList: state => state.employerList,
    lastEmployerListFetch: state => state.lastEmployerListFetch,
    shouldUpdateEmployerList: (_, getters) => shouldUpdateCheck(getters.lastEmployerListFetch, new Date().getTime()),

    employeeListWithLeaveApprovees: state => state.employeeListWithLeaveApprovees,
    lastEmployeeListWithLeaveApproveesFetch: state => state.lastEmployeeListWithLeaveApproveesFetch,
    shouldUpdateEmployeeListWithLeaveApprovees: (_, getters) => shouldUpdateCheck(getters.lastEmployeeListWithLeaveApproveesFetch, new Date().getTime())
}