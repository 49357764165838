import { onMounted, onBeforeUnmount, ref } from 'vue'
import {useStore} from "vuex"

export function useSendHeartBeat() {
    const store = useStore()
    const interval = ref(null)

    store.dispatch('sendHeartBeat')

    // Run when the component is mounted
    onMounted(async () => {
        // Start the interval, running the sendHeartbeat function every 10 minutes
        interval.value = setInterval(sendHeartBeat, 15 * 60 * 1000)
    })

    // Run before the component is unmounted
    onBeforeUnmount(() => {
        // Clear the interval to prevent it from running after the component is unmounted
        clearInterval(interval.value)
    })

    // Define the sendHeartbeat function
    const sendHeartBeat = () => store.dispatch('sendHeartBeat')

    return { sendHeartBeat }
}