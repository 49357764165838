// manager leave mutations

export default {
    setLeaveApproveeList: (state, payload) => state.leaveApproveeList = payload,
    setLastLeaveApproveeListFetch: (state, payload) => state.lastLeaveApproveeListFetch = payload,

    updateLeaveApproveeList: (state, payload) => {
        const approveeIndex = state.leaveApproveeList.findIndex(approvee => approvee.id == payload.employeeID)
        const leaveRequestIndex = state.leaveApproveeList[approveeIndex].leave_requests.findIndex(request => request.id == payload.requestID)
        state.leaveApproveeList[approveeIndex].leave_requests[leaveRequestIndex].state = payload.updatedState
    }
}