// Model: Plain JavaScript objects for now
export default [
    {
        id: 6,
        provider: "Discovery",
        name: "Smart",
        cost_from: 1565,
        description: "The most cost-effective in-hospital cover, essential chronic medicine cover plus limited day-to-day cover if you're willing to use providers in a specified network.",
    },
    {
        id: 5,
        provider: "Discovery",
        name: "Core",
        cost_from: 2281,
        description: "A value-for-money hospital plan that provides unlimited private hospital cover and essential cover for chronic medicine with no day-to-day cover.",
    },
    {
        id: 4,
        provider: "Discovery",
        name: "Saver",
        cost_from: 2673,
        description: "The most economical in-hospital cover, essential chronic medicine cover and day-to-day benefits through a Medical Savings Account.",
    },
    {
        id: 3,
        provider: "Discovery",
        name: "Priority",
        cost_from: 4531,
        description: "Cost-effective in-hospital cover, essential chronic medicine cover and day-to-day benefits with a limited Above Threshold Benefit.",
    },
    {
        id: 2,
        provider: "Discovery",
        name: "Comprehensive",
        cost_from: 7163,
        description: "Comprehensive cover for in-hospital and day-to-day cover with extended chronic medicine cover and an unlimited Above Threshold Benefit.",
    },
    {
        id: 1,
        provider: "Discovery",
        name: "Executive",
        cost_from: 10303,
        description: "The Executive Plan offers you the most extensive cover for in-hospital and day-to-day benefits with extended chronic medicine cover and an unlimited Above Threshold Benefit.",
    },
];