<template>
    <modal-summary
        id="remDraftModal"
        labelledby="remDraftModalLabel"
        v-model="showRemSubmittedModalCombined"
        size="lg"
    >
        <template #title>
            <span class="ms-3">Submitted Payroll Changes</span>
        </template>
        <template #body>
            <rem-submitted-modal-combined-content v-if="arePayrollChangesSubmitted"/>
            <div v-else class="font-size-15 font-weight-500">No payroll changes have been submitted.</div>
        </template>
    </modal-summary>
</template>

<script setup>
    import {computed} from "vue"
    import {useStore} from "vuex"
    import ModalSummary from "@/components/summary-and-payslip/modal/base-modals/ModalSummary"
    import RemSubmittedModalCombinedContent from "@/components/summary-and-payslip/modal/draft-and-submitted/RemSubmittedModalCombinedContent.vue"
    
    const store = useStore()
    
    const showRemSubmittedModalCombined = computed({
        get: () => store.getters.showRemSubmittedModalCombined,
        set: showValue => store.commit('toggleShowRemSubmittedModalCombined', showValue)
    })
    
    const arePayrollChangesSubmitted = computed(() => store.getters.arePayrollChangesSubmitted)
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .reset-icon {
        width: 18px;
        position: relative;
        top: -2px;
    }
    
    p a {
        color: darken($primary, 4%);
        &:hover {
            color: darken($primary, 10%);
        }
    }
    
    #submitting-changes-loader {
        min-height: 50px;
    }
</style>