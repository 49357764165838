<template>
    <summary-card :classList="classList" view-more-link="/leave" view-more-text="View and submit leave">
        <template #title>Leave &amp; Work Life Balance</template>
        <template #subHeading v-if="annualLeaveObj">{{ annualLeaveObj.balance }} days</template>
        
        <template #cardText>
            <div v-html="cardText" class="card-text-sub-div"></div>
        </template>
    </summary-card>
</template>

<script setup>
    import SummaryCard from "@/components/cards/SummaryCard"
    import {computed} from "vue"
    import {useStore} from "vuex"
    import useInfoCard from "@/hooks/use-component/cards/info-card"
    
    const store = useStore()
    
    const employer = store.getters.employer
    const annualLeaveObj = store.getters.annualLeaveObj
    
    const benefit = store.getters.essentialBenefits.leave
    const {classList} = useInfoCard(benefit)
    
    const cardText = computed(() => {
        if (annualLeaveObj) {
            return`
            <p>You currently have a balance of <span>${annualLeaveObj.balance} annual leave days.</span></p>
            <p>See your full leave balances, or learn more about the <span>${employer.name} leave policies.</span></p>`
        }
        return 'Get in touch with your HR contact to set up your leave balances.'
    });
</script>