<template>
    <menu-item :class="classList" :is-current="isCurrent">
        <template #title>
            Salary
        </template>
        <template #subMenuItems>
            <menu-item-second routeName="employee-payslip">Payslip</menu-item-second>
            <menu-item-second v-if="arePayrollDraftChanges" class="drafted-changes-item cursor-pointer" @click="toggleShowRemDraftModalCombined">Draft Changes</menu-item-second>
            <menu-item-second v-if="arePayrollChangesSubmitted" class="changes-submitted-item cursor-pointer" @click="toggleShowRemSubmittedModalCombined">Submitted Changes</menu-item-second>
        </template>
    </menu-item>
</template>

<script setup>
    import MenuItem from "@/components/layouts/side-nav/side-nav-components/MenuItem.vue"
    import MenuItemSecond from "@/components/layouts/side-nav/side-nav-components/MenuItemSecond.vue"
    import {useStore} from "vuex"
    import {computed} from "vue"
    import {useRoute} from 'vue-router'
    const store = useStore()
    
    const arePayrollDraftChanges = computed(() => store.getters.arePayrollDraftChanges)
    const arePayrollChangesSubmitted = computed(() => store.getters.arePayrollChangesSubmitted)
    
    const route = useRoute()
    const isCurrent = computed(() => route.meta.menuParent === 'salary')
    const classList = computed(() => {
        return {'current-parent': isCurrent.value}
    })
    
    const toggleShowRemDraftModalCombined = () => store.commit('toggleShowRemDraftModalCombined', true)
    const toggleShowRemSubmittedModalCombined = () => store.commit('toggleShowRemSubmittedModalCombined', true)
</script>