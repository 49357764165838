<template>
    <MDBTooltip v-model="tooltip1" tag="a">
        <template #reference>
            <span class="d-inline-block me-1 cursor-pointer" v-if="tipTitle">{{ tipTitle }}</span>
            <i class="fas font-size-16 fa-info-circle cursor-pointer" v-if="includeTipInfoIcon"></i>
        </template>
        <template #tip>
            <div class="font-size-12" v-html="tipContent"></div>
        </template>
    </MDBTooltip>
</template>

<script setup>
    import {ref} from "vue"
    import {MDBTooltip} from "mdb-vue-ui-kit"
    
    defineProps({
        tipContent: {
            type: String,
            required: true,
        },
        tipTitle: {
            type: String,
            required: false,
        },
        includeTipInfoIcon: {
            type: Boolean,
            required: false,
            default: true,
        },
    })
    
    const tooltip1 = ref(false)
</script>