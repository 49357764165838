<template>
    <div v-if="!isHrAdmin">
        <width-container>
            <bento-alert static color="danger">You do not have permissions to access this page.</bento-alert>
        </width-container>
    </div>
    <div v-else>
        
        
        <width-container>
            <h1 class="mb-4 pb-2">
                <span class="d-inline-block me-4">Team Admin</span> <span class="d-inline-block me-3 title-chevron"><img src="@/assets/v2/icons/chevron.svg" alt="Chevron right"/></span> <span class="d-inline-block font-weight-400">Reports</span>
            </h1>
            
            <download-leave-report title="Leave Report" v-if="isEnabledLeave"/>

            <download-reimbursements-report title="Reimbursements Report" v-if="isEnabledReimbursements"/>

            <download-old-mutual-life-contributions-report title="Old Mutual Life Contributions Report" v-if="isEnabledOldMutualLifeContributions"/>
            
            <footer-contact-help-card/>
        </width-container>
    </div>
</template>

<script setup>
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert"
    import FooterContactHelpCard from "@/components/layouts/footer/FooterContactHelpCard.vue"
    import DownloadLeaveReport from "@/components/manager/leave/leave-management/DownloadLeaveReport.vue"
    
    import {useStore} from "vuex"
    import DownloadOldMutualLifeContributionsReport from "@/components/manager/team-admin/download-reports/DownloadOldMutualLifeContributionsReport.vue"
    import DownloadReimbursementsReport
        from "@/components/manager/reimbursements/reimbursement-management/DownloadReimbursementsReport.vue";
    
    const store = useStore()

    const isHrAdmin = store.getters.isHrAdmin

    const isEnabledLeave = store.getters.isEnabledLeave
    const isEnabledReimbursements = store.getters.isEnabledReimbursements
    const isEnabledOldMutualLifeContributions = store.getters.isEnabledOldMutualLifeContributions

</script>

<style lang="scss" scoped>
</style>