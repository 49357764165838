import store from "@/store"

// local guards can be added in the routes config object with beforeEnter OR in the component with beforeRouteEnter()
export function globalBeforeEach(to) {

    // step 1 : check logged in
    // check user authenticated (logged in)
    const isAuthenticated = store.getters.isAuthenticated
    const isLoginPage = !!(to.meta.isLoginPage)
    if (!isLoginPage && !isAuthenticated) {
        return {
            name: 'login',
            query: { redirect: to.fullPath } // this adds query string to url - used in LoginPageForm.vue to build url
        }
    } else if (isLoginPage && isAuthenticated) {
        return { name: 'home'}
    }

    // step 2 : check terms accepted (after login)
    let isTermsAccepted = store.getters.isTermsAccepted
    const isTermsPage = !!(to.meta.isTermsPage)
    if (!isLoginPage && !isTermsPage && !isTermsAccepted) {
        return '/accept-user-terms-and-privacy-policy'
    } else if (!isLoginPage && isTermsPage && isTermsAccepted) {
        return { name: 'home' }
    }

    // step 3 : logged in, terms accepted, not on login page, not on terms page. go to page as normal ...
    return true
}