// manager reimbursement getters (copy from leave)
import {isValidArray} from "@/hooks/general/type-and-value-checks"
import {shouldUpdateCheck} from "@/hooks/general/getters-helpers"

export default {
    canDownloadReimbursementReports: (_, getters) => getters.permissionsCanDownloadReimbursementReports,
    isReimbursementApprover: (_, getters) => isValidArray(getters.reimbursementApproveeList),
    isReimbursementApproverOrDownloader: (_, getters) => getters.isReimbursementApprover || getters.canDownloadReimbursementReports,

    reimbursementApproveeList: state => state.reimbursementApproveeList,
    lastReimbursementApproveeListFetch: state => state.lastReimbursementApproveeListFetch,
    shouldUpdateReimbursementApproveeList: (_, getters) => shouldUpdateCheck(getters.lastReimbursementApproveeListFetch, new Date().getTime()),

    reimbursementAllowanceListAllApprovees: state => state.reimbursementAllowanceListAllApprovees,
    lastReimbursementAllowanceListAllApproveesFetch: state => state.lastReimbursementAllowanceListAllApproveesFetch,
    shouldUpdateReimbursementAllowanceListAllApprovees: (_, getters) => shouldUpdateCheck(getters.lastReimbursementAllowanceListAllApproveesFetch, new Date().getTime())
}