<template>
    <div class="items-block" v-if="sectionTotal > 0 || sectionId == 'payslip-income-section'">
        <div class="item item-header">
            <div class="label">
                {{ sectionHeader }}
            </div>
            <div class="value">
                {{ bentoCurrencyFormat(sectionTotal) }}
            </div>
        </div>
        <template v-for="item in sectionList" :key="item.id">
            <div
                v-if="
                    (item.amount > 0 && item.internal_name !== 'employee_pension_fund_benefit') ||
                    (item.internal_name == 'basic_salary') ||
                    (item.internal_name == 'employee_paye')"
                class="item">
                <div class="label">
                    {{ itemName(item) }}
                </div>
                <div class="value" :class="{ 'negative-value': item.amount < 0 }">
                    {{ bentoCurrencyFormat(item.amount) }}
                </div>
            </div>
        </template>
    </div>
</template>

<script setup>
    import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters";
    
    defineProps({
        sectionHeader: {
            required: true,
            type: String,
        },
        sectionTotal: {
            required: true,
            type: Number,
            default: 0,
        },
        sectionList: {
            required: true,
            type: [Array, null],
            default: () => [],
        },
        sectionId: {
            required: false,
            type: String,
        }
    })
    
    
    const itemName = (item) => {
        switch (item.internal_name) {
            case 'expense_claim':
                return 'Expense Claim (non-taxable)';
            case 'employee_medical_aid':
                return 'Medical Aid - Employee';
            case 'employer_pension_fund':
                return 'Pension Fund - Employer';
            case 'employee_pension_fund':
                return 'Pension Fund - Employee';
            case 'employer_sdl':
                return 'SDL - Employer';
            case 'employee_sdl':
                return 'SDL - Employee';
            case 'employer_uif':
                return 'UIF - Employer';
            case 'employee_uif':
                return 'UIF - Employee';
            default:
                return item.name;
        }
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .items-block {
        color: $grey-darkish;
        font-size: 15px;
        margin-bottom: 30px;
    }
    
    .item {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        margin: 3px 0;
        padding-left: 14px;
        font-size: 14px;
        font-weight: 500;
        @include media-breakpoint-up(md) {
            padding-left: 24px;
            font-size: 15px;
        }
    }
    
    .item.item-header {
        padding-left: 0;
        .label {
            padding-right: 10px;
        }
        .label, .value {
            font-weight: 700;
            font-size: 16px;
            color: #000;
            @include media-breakpoint-up(sm) {
                font-size: 17px;
            }
            @include media-breakpoint-up(md) {
                font-size: 18px;
            }
        }
    }
    
    .label {
        font-size: 14px;
    }
    
    .value {
        font-size: 14px;
        &.negative-value {
            color: $gradient-pink-text-light;
        }
    }

</style>