// employee profile actions
import {apiResponseHandler} from "@/hooks/api/api-helper-function"
import theApiCall from '@/hooks/api/the-api-call'
import {isValidArray} from "@/hooks/general/type-and-value-checks"

export default {

    // PATCH update employee info
    async updateEmployeeInfo(context, payload) {
        let currentUser = localStorage?.userId ?? context.getters.userApiId
        const employeeToUpdate = payload.employee_id ?? currentUser

        let formData = new FormData()
        formData.append('method', 'PATCH')
        if (payload.profile_picture) {
            formData.append('profile_picture', payload.profile_picture)
        }
        formData.append('preferred_name', payload.preferred_name)
        formData.append('cell_number', payload.cell_number)
        formData.append('self_description', payload.self_description)

        formData.append('physical_address.complex', payload.physical_address.complex)
        formData.append('physical_address.unit_number', payload.physical_address.unit_number)
        formData.append('physical_address.street_number', payload.physical_address.street_number)
        formData.append('physical_address.street', payload.physical_address.street)
        formData.append('physical_address.suburb', payload.physical_address.suburb)
        formData.append('physical_address.city', payload.physical_address.city)
        formData.append('physical_address.postal_code', payload.physical_address.postal_code)
        formData.append('physical_address.country', payload.physical_address.country)

        formData.append('emergency_contact.name', payload.emergency_contact.name)
        formData.append('emergency_contact.relationship', payload.emergency_contact.relationship)
        formData.append('emergency_contact.cell_number', payload.emergency_contact.cell_number)

        // formData.append('title', payload.title)
        // formData.append('gender', payload.gender)
        // formData.append('marital_status', payload.marital_status)

        const response = await theApiCall(`employees/${employeeToUpdate}/`, 'PATCH', formData)
        const responseData = await apiResponseHandler(response, 'Failed to update profile personal info.')

        // don't update the store, if we are fetching another user (HR Team Admin interface)
        if (!payload.employee_id || payload.employee_id == currentUser) {
            context.commit('setEmployee', responseData)
        }
        return responseData
    },

    // POST a bank change request / PUT if updating existing request
    async updateFinancialInfo(context, payload) {
        let currentUser = localStorage?.userId ?? context.getters.userApiId
        const employeeToUpdate = payload.employee_id ?? currentUser

        // if current user is the employee, the we can use the store
        let submittedBankAccountChangeRequestList = null
        let submittedBankAccountChangeRequest = context.getters.submittedBankAccountChangeRequest
        if (payload.employee_id && payload.employee_id != currentUser) {
            submittedBankAccountChangeRequestList = await context.dispatch('loadBankAccountChangeRequestList', {forceRefresh: true, employee_id: employeeToUpdate})
            submittedBankAccountChangeRequest = null
            if (isValidArray(submittedBankAccountChangeRequestList)) {
                submittedBankAccountChangeRequest = submittedBankAccountChangeRequestList.find(changeRequest => changeRequest.status === 'submitted') || null
            }
        }

        let httpMethod = 'POST'
        if (submittedBankAccountChangeRequest && submittedBankAccountChangeRequest.id) {
            httpMethod = 'PUT'
        }

        let urlEndPoint = `employees/${employeeToUpdate}/bank_account_change_request/`
        if (httpMethod === 'PUT' && submittedBankAccountChangeRequest != null) {
            urlEndPoint = `employees/${employeeToUpdate}/bank_account_change_request/${submittedBankAccountChangeRequest.id}/`
        }

        const body = JSON.stringify(payload)
        const contentType = 'application/json'

        const response = await theApiCall(urlEndPoint, httpMethod, body, contentType)
        const responseData = await apiResponseHandler(response, 'Failed to update financial info.')
        return responseData
    },


    // GET bank account change request list
    // /api/1.0/employees/2/bank_account_change_request/
    async loadBankAccountChangeRequestList(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateBankAccountChangeRequestList) { return }
        let currentUser = localStorage?.userId ?? context.getters.userApiId
        const employeeToFetch = payload.employee_id ?? currentUser

        const response = await theApiCall(`employees/${employeeToFetch}/bank_account_change_request/`)
        const responseData = await apiResponseHandler(response, 'Could not load bank account change request list.', 'Bank account change request list resource not found. [404]')

        // don't update the store, if we are fetching another user (HR Team Admin interface)
        if (!payload.employee_id || payload.employee_id == currentUser) {
            context.commit('setBankAccountChangeRequestList', responseData)
            context.commit('setLastBankAccountChangeRequestListFetch', { timestamp: new Date().getTime() });
        }

        return responseData
    },


    // GET employee directory -> /api/1.0/employee_directory/
    async loadEmployeeDirectoryList(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateEmployeeDirectoryList) { return }

        const response = await theApiCall('employee_directory/')
        const responseData = await apiResponseHandler(response, 'Could not load employee directory list.', 'Employee directory list not found [404]')

        if (isValidArray(responseData)) { context.commit('setEmployeeDirectoryList', responseData) }
        context.commit('setLastEmployeeDirectoryListFetch', { timestamp: new Date().getTime()} )
    }
}