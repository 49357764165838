<template>
    <h1 class="fw-600">
        <slot></slot>
    </h1>
</template>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    h1 {
        margin-bottom: 24px;
    }
    
    .green {
        h1 {
            color: $gradient-green-text-dark;
        }
    }
</style>