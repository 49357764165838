// medical aid employee - new endpoint

import theApiCall from "@/hooks/api/the-api-call"
import {apiResponseHandler} from "@/hooks/api/api-helper-function"

export default {

    async loadEmployeeMedicalAidsList(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateEmployeeMedicalAidsList) { return }

        const userApiId = (localStorage.userId) ? localStorage.userId : context.getters.userApiId
        const response = await theApiCall(`employees/${userApiId}/medical_aids/`)
        const responseData = await apiResponseHandler(response, 'Could not load employee medical aid data.', 'Employee medical aid resource not found. [404]')

        context.commit('setEmployeeMedicalAidsList', responseData)
        context.commit('setLastEmployeeMedicalAidsListFetch', {timestamp: new Date().getTime()});
    },
    
}