<template>
    <div>
        <div v-if="(!permissionsCanDownloadLeaveReports && !isLeaveApprover && !isHrAdmin)">
            <width-container>
                <bento-alert static color="danger">You do not have permissions to access this page.</bento-alert>
            </width-container>
        </div>
        
        
        <div v-else>
            <div v-if="isLoading">
                <loading-full-screen v-model="isLoading" loadingText="Updating leave request ..."/>
            </div>
            
            <width-container v-else>
                <div class="pb-5">
                    <div>
                        <h1 class="mb-4 pb-2">
                            <span class="d-inline-block me-4">My Team</span> <span class="d-inline-block me-3 title-chevron"><img src="@/assets/v2/icons/chevron.svg" alt="Chevron right"/></span> <span class="d-inline-block font-weight-400">Leave Management</span>
                        </h1>
                        
                        <div v-if="isLeaveApprover">
                            <leave-manager-calendar-cont id="manager-leave-calendar"/>
                            <leave-management-approvees v-if="false"/>
                            <leave-management-requests id="manager-leave-requests"/>
                        </div>
                        
                        <download-leave-report id="download-leave-report" v-if="permissionsCanDownloadLeaveReports || isHrAdmin"/>
                    </div>
                </div>
            </width-container>
        </div>
    
    </div>
</template>

<script setup>
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer"
    import {ref, computed, onMounted, watch, nextTick} from "vue"
    import {useRoute} from 'vue-router'
    import {useStore} from "vuex"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert"
    import LeaveManagementRequests from "@/components/manager/leave/leave-management/LeaveManagementRequests"
    import LeaveManagementApprovees from "@/components/manager/leave/leave-management/LeaveManagementApprovees"
    import DownloadLeaveReport from "@/components/manager/leave/leave-management/DownloadLeaveReport"
    import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen"
    import LeaveManagerCalendarCont from "@/components/manager/leave/LeaveManagerCalendarCont.vue"
    
    const store = useStore()
    
    const isLoadingEmployee = ref(false)
    const isLoadingLeaveApproveeList = ref(false)
    const isLoading = computed(() => isLoadingEmployee.value || isLoadingLeaveApproveeList.value)
    
    const isLeaveApprover = computed(() => store.getters.isLeaveApprover)
    const permissionsCanDownloadLeaveReports = computed(() => store.getters.permissionsCanDownloadLeaveReports)
    const isHrAdmin = computed(() => store.getters.isHrAdmin)
    
    
    const route = useRoute()
    const isInitialLoad = ref(true)

    const scrollToSection = hash => {
        nextTick(() => {
            requestAnimationFrame(() => {
                const elementId = hash.replace('#', '')
                const element = document.getElementById(elementId)
                if (element && element.offsetParent !== null) {
                    const yOffset = -100
                    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
                    window.scrollTo({top: y, behavior: 'smooth'})
                }
            })
        })
    }
    
    onMounted(() => {
        if (route.hash) {
            scrollToSection(route.hash)
        }
        isInitialLoad.value = false
    })
    
    watch(() => route.hash, (newHash) => {
        if (newHash && !isInitialLoad.value) {
            scrollToSection(newHash)
        }
    })

</script>

<style lang="scss" scoped>
    .title-chevron {
        position: relative;
        top: -2px;
    }
</style>