import BenefitPageLeave from "@/components/benefits-leave-reimbursements/leave/BenefitPageLeave.vue"
import LearningResource from "@/components/learn-and-explore/LearningResource.vue"

export default [
    {
        name: 'leave',
        path: '/leave',
        component: BenefitPageLeave,
        meta: { menuParent: 'essentials' },
        children: [
            {
                name: 'leave-resource',
                path: ':resourceSlug',
                component: LearningResource,
                props: true,
                meta: {learningResource: true}, // used in scrollBehaviour()
            },
        ],
    },
]