<template>
    <div class="tax-trace-item" :class="{ aqua: color === 'aqua' }">
        <div class="label">{{ taxTraceItem.label }}:</div>
        
        <div class="current" v-if="financial">{{ bentoCurrencyFormat(taxTraceItem.current) }}</div>
        <div class="current" v-else>{{ roundToThreeDecimal(taxTraceItem.current) }}</div>
        
        <div class="planned" v-if="financial">{{ bentoCurrencyFormat(taxTraceItem.planned) }}</div>
        <div class="planned" v-else>{{ roundToThreeDecimal(taxTraceItem.planned) }}</div>
        
        <div class="note" v-html="note"></div>
    </div>
</template>

<script>
    import {roundToThreeDecimal} from "@/hooks/general/currency-number-formatters"
    import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"

    export default {
        name: "TaxTraceItem",
        props: {
            taxTraceItem: {
                type: Object,
                required: true,
            },
            note: {
                type: String,
                required: false,
            },
            color: {
                type: String,
                required: false,
            },
            financial: {
                type: Boolean,
                required: false,
                default: true,
            },
        },
        methods: {
            bentoCurrencyFormat,
            roundToThreeDecimal
        }
    
    }
</script>

<style lang="scss" scoped>
    
    .tax-trace-item {
        margin-top: 20px;
        display: flex;
        flex-wrap: nowrap;
        border-bottom: 1px solid #efefef;
        border-top: 1px solid #efefef;
        padding: 15px 10px;
        background-color: rgba(20,20,20,.05);
        
        &.aqua {
            background-color: rgba(aqua,.15);
        }
    }
    
    .label {
        flex: 0 0 180px;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding-right: 5px;
    }
    
    .current {
        padding-left: 5px;
        flex: 0 0 120px;
        font-weight: 600;
        display: flex;
        align-items: center;
    }
    
    .planned {
        padding-left: 5px;
        flex: 0 0 120px;
        font-weight: 600;
        display: flex;
        align-items: center;
    }
    
    .note {
        flex: 1 auto;
        line-height: 1.4;
        font-size: 12px;
        font-weight: 400;
        display: flex;
        align-items: center;
    }

</style>