// ### Scroll Behaviour ###

// docs: https://next.router.vuejs.org/guide/advanced/scroll-behavior.html
// this scrollBehaviour() method is called by Vue Router whenever your page changes
// automatically receives the to, from, and savedPosition arguments
// to & from are route objects
// savedPosition is only set if you are using the browser back/forward button. savedPosition is an object with a left and top property.
// the scrollBehaviour method should return an object describing where the browser should scroll to on the new page : return {left: x, top: y};
export function scrollBehavior(to, from, savedPosition) {

    // this is managed in component LeaveManagerPage.vue
    if (to.name === 'my-team-leave-management') {
        return false
    }

    // console.log('to', to);
    // console.log('from', from);
    // console.log('savedPosition', savedPosition);

    // savedPosition works with the browser back & forward button actions
    // if savedPosition is set then the browser will go back to that position
    // console.log(savedPosition);
    if (savedPosition) {
        return savedPosition
    }

    if (to.meta.learningResource) {
        const element = document.querySelector("#learning-resource");
        if (element) {
            return {
                behavior: "smooth",
                el: '#learning-resource',
                left: 0,
                top: 400,
            };
        }
    }
    if (from.meta.learningResource && to.name === 'home') {
        return {
            top: 0,
            left: 0,
        }
    }
    if (to.name === 'home' && from.name === 'learn-and-explore-resource') {
        return {
            el: '#learn-grid',
            left: 0,
            top: 100,
        }
    }
    return { left: 0, top: 0 }
}