// root getters [everything is not namespaced]

export default {
    // temp image directory location
    imageDir: () => 'https://content.mybento.net/media/images/',

    // is admin viewing as other employee
    isViewingAsOther: (_, getters) => {
        const localStorageEmail = localStorage.getItem('email')

        let isMyBentoUser = localStorageEmail && localStorageEmail.includes('@mybento.net')

        return isMyBentoUser && !getters.isUserAdmin
    },
}