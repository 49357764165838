import store from "@/store"

export const useSaveDraftRetirementContractHandler = async (tempSelectedRetirementFundContractObj, showSaveDraftNotice, saveDraftNoticeText, noticeColor, isSubmittingChanges) => {
    // console.log(tempSelectedRetirementFundContractObj.value)
    isSubmittingChanges.value = true
    saveDraftNoticeText.value = null
    showSaveDraftNotice.value = false
    noticeColor.value = 'primary'

    if (tempSelectedRetirementFundContractObj.value.fundId == null) {
        saveDraftNoticeText.value = "Please select a retirement fund above before saving."
        showSaveDraftNotice.value = true
        noticeColor.value = 'warning'
        isSubmittingChanges.value = false
        return
    }

    const retirementContractObject = {
        retirement_fund: tempSelectedRetirementFundContractObj.value.fundId,
        monthly_contribution: tempSelectedRetirementFundContractObj.value.employeeContribution,
        extra_data: tempSelectedRetirementFundContractObj.value.extraData,
        status: "draft"
    }

    try {
        await store.dispatch('submitRetirementFundContractChangeRequest', retirementContractObject)
    } catch (error) {
        saveDraftNoticeText.value = error
        showSaveDraftNotice.value = true
        noticeColor.value = 'secondary'
        isSubmittingChanges.value = false
        setTimeout(() => {
            showSaveDraftNotice.value = false
        }, 4000)
        return
    }

    showSaveDraftNotice.value = true
    saveDraftNoticeText.value = "Your draft retirement contract has been saved."
    isSubmittingChanges.value = false

    setTimeout(() => {
        showSaveDraftNotice.value = false
    }, 3200)
}
