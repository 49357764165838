<template>
    <card-general>
        <section-header>Your Essentials</section-header>
        
        <card-grid>
            <template #default>
                <leave-card v-if="isEnabledLeave" />
                <medical-aid-card v-if="isEnabledMedicalAid"/>
                <retirement-card v-if="isEnabledRetirement"/>
                <retirement-calculator-card v-if="!isEnabledRetirement && isEnabledRetirementCalculator"/>
                <insurance-card v-if="isEnabledRisk"/>
                
                <template v-if="essentialSimpleBenefitsList">
                    <simple-benefit-card
                        v-for="benefit in essentialSimpleBenefitsList"
                        :key="benefit.id"
                        :simple-benefit="benefit"
                    />
                </template>
                
<!--                <template>-->
<!--                    <simple-benefit-card simple-benefit=""-->
<!--                </template>-->
            </template>
        </card-grid>
    </card-general>
</template>

<script setup>
    import SimpleBenefitCard from "@/components/cards/simple-benefits/SimpleBenefitCard"
    import LeaveCard from "@/components/cards/leave/LeaveCard"
    import MedicalAidCard from "@/components/cards/essential-benefits/MedicalAidCard"
    import CardGrid from "@/components/cards/CardGrid"
    import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader"
    
    import {useStore} from "vuex"
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral"
    import RetirementCard from "@/components/cards/essential-benefits/RetirementCard.vue"
    import InsuranceCard from "@/components/cards/essential-benefits/InsuranceCard.vue";
    import RetirementCalculatorCard from "@/components/cards/essential-benefits/RetirementCalculatorCard.vue"
    const store = useStore()
    
    const isEnabledMedicalAid = store.getters.isEnabledMedicalAid
    const isEnabledRetirement = store.getters.isEnabledRetirement
    const isEnabledRetirementCalculator = store.getters.isEnabledRetirementCalculator
    const isEnabledRisk = store.getters.isEnabledRisk
    const isEnabledLeave = store.getters.isEnabledLeave
    
    
    const simpleBenefitsList = store.getters.simpleBenefitsList
    const essentialSimpleBenefitsList = simpleBenefitsList.filter( benefit => Number(benefit.category) === 9)
</script>