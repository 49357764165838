<template>
    <wizard-block>
        <template #title>Employer Contribution</template>
        <template #sub-title>{{ bentoCurrencyFormat(employerContributionPayslip) }} pm</template>
        
        <template #expanded-content>
            <div v-html="blockText"></div>
            <p class="contact-us-p">
                Please
                <router-link to="/contact-us">contact us</router-link>
                if you have any further questions in this regard.
            </p>
        </template>
    
    </wizard-block>
</template>

<script setup>
    import WizardBlock from "@/components/benefits-leave-reimbursements/medical-aid/wizard/WizardBlock"
    import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"
    import {useStore} from "vuex"
    import {computed} from "vue"
    
    const store = useStore()
    
    const hasMedicalAidContract = computed(() => store.getters.hasMedicalAidContract)
    const medicalAidBenefit = computed(() => store.getters.medicalAidBenefit)
    const employerMedicalAidContributionStrategyObj = computed(() => store.getters.employerMedicalAidContributionStrategyObj)
    
    const employerContributionPayslip = computed(() => medicalAidBenefit.value.api)
    
    const blockText = computed(() => {
        if (hasMedicalAidContract.value) {
            return companyContributes.value
        }
        return noMedicalAidContractCompanyContributes.value
    })
    
    
    const noMedicalAidContractCompanyContributes = computed(() => {
        if (employerMedicalAidContributionStrategyObj.value.strategy === 'flat_rate' && employerMedicalAidContributionStrategyObj.value.value > 0) {
            return `<p>You do not currently have medical aid. Your employer will contribute up to <span>${bentoCurrencyFormat(employerMedicalAidContributionStrategyObj.value.value)}</span> towards your medical aid of choice. When selecting or changing your medical aid options, please bear in mind that only the difference (costs over and above the <span>${bentoCurrencyFormat(employerMedicalAidContributionStrategyObj.value.value)}</span> employer contribution) will be deducted from your salary. You will still receive the full tax credit.</p>`
        }
        return `<p>You do not currently have medical aid.</p>`
    })
    
    const companyContributes = computed(() => {
        if (employerMedicalAidContributionStrategyObj.value.strategy === 'flat_rate' && employerMedicalAidContributionStrategyObj.value.value > 0) {
            return `<p>Your employer is currently contributing ${bentoCurrencyFormat(employerContributionPayslip.value)} towards your Medical Aid. The maximum contribution from your employer is <span>${bentoCurrencyFormat(employerMedicalAidContributionStrategyObj.value.value)}</span>. When selecting or changing your medical aid options, please bear in mind that only the difference (costs over and above the <span>${bentoCurrencyFormat(employerMedicalAidContributionStrategyObj.value.value)}</span> employer contribution) will be deducted from your salary. You will still receive the full tax credit.</p>`
        }
        return `<p>Your employer contributes towards your medical aid.</p>`
    })
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    :deep(p) {
        margin-top: 0;
        line-height: 1.5;
        &:last-child {
            margin-bottom: 0;
        }
        a {
            color: $primary;
            font-weight: 600;
            position: relative;
            transition: all .2s ease-in-out;
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                bottom: -1px;
                height: 2px;
                background-color: $primary;
            }
            &:hover {
                color: $primary;
            }
        }
    }
    
    p.contact-us-p {
        margin-top: 12px;
    }
</style>