<template>
    <div v-if="!isHrAdmin">
        <width-container>
            <bento-alert static color="danger">You do not have permissions to access this page.</bento-alert>
        </width-container>
    </div>
    <div v-else>
        <div v-if="isLoading">
            <loading-full-screen v-model="isLoading" loadingText="Loading your team ..."/>
        </div>
        
        <width-container v-else>
            <h1 class="mb-4 pb-2">
                <span class="d-inline-block me-4">Team Admin</span> <span class="d-inline-block me-3 title-chevron"><img src="@/assets/v2/icons/chevron.svg" alt="Chevron right"/></span> <span class="d-inline-block font-weight-400">Update Employee</span>
            </h1>
            
            <card-general>
                <section-header>Select employee</section-header>
                <MDBSelect
                    ref="employeeSelect"
                    v-model:options="optionsEmployee"
                    v-model:selected="selectedEmployee"
                    filter
                    :preselect="false"
                    label="Employee" size="lg" placeholder="Select employee ..."
                />
            </card-general>
            
            <div v-if="selectedEmployee && selectedEmployeeObj">
                <team-admin-employee-profile-personal-update :employeeProp="selectedEmployeeObj"/>
                <team-admin-employee-financial-details-update :employee-prop="selectedEmployeeObj"/>
            </div>
            <card-general v-else><div class="fw-500 font-size-15">No employee selected.</div></card-general>
            
            <card-general>
                <section-header>{{ getEmployeeFullName(selectedEmployeeObj) }}'s Team (Approvees)</section-header>
                
                <div class="fw-500 font-size-15 mb-1">Leave Approver For:</div>
                <div v-if="getLeaveApprovees(employeeList, selectedEmployee)">{{ getNamesCommaSeparated(getLeaveApprovees(employeeList, selectedEmployee)) }}</div>
                <div v-else>None</div>
                
                <div class="fw-500 font-size-15 mb-1 mt-4">Reimbursement Approver For:</div>
                <div v-if="getReimbursementApprovees(employeeList, selectedEmployee)">{{ getNamesCommaSeparated(getReimbursementApprovees(employeeList, selectedEmployee)) }}</div>
                <div v-else>None</div>
                
                <div class="fw-500 mt-5 font-size-15">
                    <router-link :to="{ name: 'team-admin-reporting-lines' }" class="cursor-pointer gradient-green-text-dark hover-primary">Update reporting lines and approvers here -></router-link>
                </div>
            </card-general>
            
            <footer-contact-help-card/>
        </width-container>
    </div>
</template>

<script setup>
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer"
    import {ref, computed} from "vue"
    import {useStore} from "vuex"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert"
    import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen";
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
    import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
    import {loadHrManagerData} from "@/hooks/api/api-dispatch-calls"
    import {MDBSelect} from "mdb-vue-ui-kit"
    import FooterContactHelpCard from "@/components/layouts/footer/FooterContactHelpCard.vue"
    import TeamAdminEmployeeProfilePersonalUpdate from "@/components/manager/team-admin/update-employees/TeamAdminEmployeeProfilePersonalUpdate.vue"
    import TeamAdminEmployeeFinancialDetailsUpdate from "@/components/manager/team-admin/update-employees/TeamAdminEmployeeFinancialDetailsUpdate.vue"
    import {getLeaveApprovees, getReimbursementApprovees, getNamesCommaSeparated} from "@/hooks/general/approvers"
    import {getEmployeeFullName} from "@/hooks/general/employee-info-utilities"
    
    const store = useStore()
    
    const loggedInEmployee = store.getters.employee
    const isHrAdmin = store.getters.isHrAdmin
    
    // 1. employee select
    const employeeSelect = ref(null) // can use methods on it
    const employeeList = computed(() => store.getters.employeeList)
    const optionsEmployee = computed(() => {
        const arr = []
        for (const employee of employeeList.value) {
            if (!employee.is_active || !employee.is_currently_employed) continue
            const isSelected = (employee.id == loggedInEmployee.id)
            arr.push({
                selected: isSelected,
                text: `${employee.user.first_name} ${employee.user.last_name}`,
                value: Number(employee.id)
            })
        }
        return arr
    })
    
    const selectedEmployee = ref("")
    const selectedEmployeeObj = computed(() => employeeList.value.find(employee => employee.id === selectedEmployee.value))
    
    
    // On component creation / load
    const isLoading = ref(false)
    const onCreated = async () => {
        isLoading.value = true
        await loadHrManagerData()
        isLoading.value = false
    }
    onCreated()
</script>

<style lang="scss" scoped>
    .title-chevron {
        position: relative;
        top: -2px;
    }
</style>