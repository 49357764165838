<template>
    <card-general class="green">
        <MDBSelect
            ref="leaveTypeSelect"
            v-model:options="leaveTypes"
            v-model:selected="selectedLeaveType"
            size="lg"
            class="mb-4"
            id="leave-type-dropdown-selector"
            :visibleOptions="10"
        />

        <div class="d-flex flex-wrap flex-md-nowrap mt-4">
            <div id="calendar-cont">
                <MDBCalendar
                    ref="combinedLeaveCalendar"
                    :events="leaveRequestsAndHolidays"
                    mondayFirst
                    readonly
                    blur
                    :viewSelect="false"
                />
            </div>

            <div id="team-and-status-col" class="ms-md-4 ps-1 ps-md-2 mt-3 mt-md-5 pt-1 d-flex d-md-block flex-wrap flex-grow-1 justify-content-between">
                <div class="mb-md-5">
                    <div class="font-size-17 fw-600">Team</div>
                    <div class="mt-3 font-size-13">
                        <div class="mb-2 border-bottom">
                            <MDBCheckbox label="Select All" v-model="selectAllCheckbox" />
                        </div>
                        <div v-for="approvee in leaveApproveeList" :key="approvee.id" class="mb-1">
                            <MDBCheckbox :label="approvee.name" v-model="selectedEmployees[approvee.id]"/>
                        </div>
                    </div>
                </div>

                <div class="mb-md-5">
                    <div class="fw-600 font-size-16">Request Status</div>
                    <div class="mt-3 font-size-13">
                        <div class="mb-1">
                            <MDBCheckbox label="Approved" v-model="isApprovedFilter"/>
                        </div>
                        <div class="mb-1">
                            <MDBCheckbox label="Submitted" v-model="isSubmittedFilter"/>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="font-size-12 mt-4 lh-sm text-gray-main fw-500">Use the table below to approve, decline, or cancel leave requests.</div>
                </div>

                <div id="future-leave-requests-count" class="mt-3 mt-0 d-none">
                    <div class="mt-3 font-size-14 fw-500">
                        {{ futureLeaveRequests.length }} leave requests
                    </div>
                    <div class="font-size-11 mt-1 lh-1 text-gray-main fw-500">*current and future requests only</div>
                </div>
            </div>
        </div>

        <div class="gradient-green-text-dark fw-500 font-size-16 mt-5">
            <router-link :to="{name:'my-team-leave-balance-lookup'}"><span class="bento-underline-dark-green-hover">Get future leave balances of your team here</span> -></router-link>
        </div>
    </card-general>
</template>

<script setup>
    import {textTruncaterWords} from "@/hooks/general/text-formatters"
    import {MDBSelect, MDBCheckbox} from "mdb-vue-ui-kit"
    import {MDBCalendar, dayjs} from "mdb-vue-calendar"
    import {ref, reactive, computed, toRefs, watch} from "vue"
    import CardGeneral from "@/components/UI/v2/containers-cards-headers/CardGeneral.vue"
    import {useStore} from "vuex"
    import useGetLeaveTypeFromID from "@/hooks/leave/get-leave-type-from-id"
    import {toTitleCase} from "@/hooks/general/text-formatters"
    import publicHolidays from "@/data/public-holidays/public-holidays"
    
    const store = useStore()
    
    // the filter dropdown
    const leaveBalancesSorted = store.getters.leaveBalancesSorted
    const leaveTypeSelect = ref(null) // used to reference the select so I can use methods on it. (v-model property below is read only)
    
    const optionsArr = leaveBalancesSorted.map(leaveObj => {
        return {
            text: leaveObj.name,
            value: leaveObj.id
        }
    })
    optionsArr.unshift({
        text: 'All Leave Types',
        value: 'all'
    })
    const leaveTypes = ref(optionsArr)
    const selectedLeaveType = ref('all')
    
    // selected employees checkboxes --------------
    const leaveApproveeList = computed(() => store.getters.leaveApproveeList)
    const selectedEmployees = reactive({})
    leaveApproveeList.value.forEach(approvee => {
        selectedEmployees[approvee.id] = true
    })
    
    const selectAllCheckbox = ref(true)
    watch(selectAllCheckbox, (newVal) => {
        leaveApproveeList.value.forEach(approvee => {
            selectedEmployees[approvee.id] = newVal
        })
    })
    
    // Convert the reactive object to references
    const selectedEmployeesRefs = toRefs(selectedEmployees)
    
    
    // the calendar -----------------------------
    const combinedLeaveCalendar = ref(null)
    
    const colorPalettes = [
        {background: "#cfe0fc", foreground: "#0a47a9"},
        {background: "#fdd8de", foreground: "#790619"},
        {background: "#c7f5d9", foreground: "#0b4121"},
        {background: "#ebcdfe", foreground: "#6e02b1"},
        {background: "#fdeca6", foreground: "#8b5e00"}, // GPT generated
        {background: "#a6e3fe", foreground: "#005082"}, // GPT generated
        {background: "#fda6a1", foreground: "#8b0000"}, // GPT generated
        {background: "#d5a6fd", foreground: "#4b0082"}, // GPT generated
        {background: "#a6fdcc", foreground: "#006400"}, // GPT generated
        {background: "#292929", foreground: "#f5f5f5"}
    ]
    const employeeColorMap = new Map();
    
    
    // --- CALENDAR DATA ---
    
    
    // Data prep flow: 1.leaveApproveeList -- xx filtering steps xx --> 2.calendarData
    // 1.leaveApproveeList
    
    
    // 1A - employee filter
    const approveeListFiltered = computed(() => {
        return leaveApproveeList.value.filter(approvee => selectedEmployeesRefs[approvee.id].value === true)
    })
    
    // 1B - leave type filter - selectedLeaveType.value
    
    // 1C - status filter
    const isApprovedFilter = ref(true)
    const isSubmittedFilter = ref(true)
    
    // 2. calendarData - list of objects, where each object represents a leave request
    const leaveRequests = computed(() => {
        let list = []
        let colorIndex = 0 // To keep track of which color to assign next
        
        for (const approvee of approveeListFiltered.value) {
            // Check if the employee already has a color assigned
            if (!employeeColorMap.has(approvee.id)) {
                employeeColorMap.set(approvee.id, colorPalettes[colorIndex])
                colorIndex = (colorIndex + 1) % colorPalettes.length // Cycle back to the start if we run out of colors
            }
            
            const leaveRequestsEnriched = approvee.leave_requests
                .filter(request => request.state === 'approved' || request.state === 'submitted')
                .filter(request =>
                    (isApprovedFilter.value && isSubmittedFilter.value) ||
                    (isApprovedFilter.value && request.state === 'approved') ||
                    (isSubmittedFilter.value && request.state === 'submitted')
                )
                .filter(request => {
                    return selectedLeaveType.value === 'all' || request.leave_type == selectedLeaveType.value
                })
                .map(request => {
                    const color = employeeColorMap.get(approvee.id)
                    return {
                        summary: `${approvee.name}  • ${useGetLeaveTypeFromID(request.leave_type).name} ${toTitleCase(request.state)}`,
                        description: `${useGetLeaveTypeFromID(request.leave_type).name} ${toTitleCase(request.state)}• ${textTruncaterWords(request.notes, 60)}`,
                        start: {
                            date: dayjs(request.start_date).format('DD/MM/YYYY'),
                        },
                        end: {
                            date: dayjs(request.end_date).format('DD/MM/YYYY'),
                        },
                        color: color,
                        employee_id: approvee.id,
                        leave_type: request.leave_type,
                    }
                })
            list.push(...leaveRequestsEnriched)
        }
        return list
    })
    
    const leaveRequestsAndHolidays = computed(() => {
        let attributes = []
        attributes.push(...leaveRequests.value)
        
        const publicHolidaysFormatted = publicHolidays.map(holiday => {
            return {
                summary: holiday.popover.label,
                start: {
                    date: dayjs(holiday.dates).format("DD/MM/YYYY"),
                },
                end: {
                    date: dayjs(holiday.dates).format("DD/MM/YYYY"),
                },
                color: {
                    background: "#ffcc99",
                    foreground: "#cc3300",
                },
            }
        })
        
        attributes.push(...publicHolidaysFormatted)
        return attributes
    })
    
    
    const currentDate = new Date();
    const futureLeaveRequests = computed(() => {
        return leaveRequests.value.filter(request => {
            // Split the date by either '-' or '/' using a regular expression
            // Need to do this for comparison purposes
            const endDate = new Date(request.end.date.split(/[-/]/).reverse().join('-'))
            return endDate >= currentDate;
        })
    })
    
    
    watch(leaveRequests, (newLeaveRequestsList, oldLeaveRequestsList) => {
        if (combinedLeaveCalendar.value) {
            combinedLeaveCalendar.value.removeEvents(oldLeaveRequestsList)
            combinedLeaveCalendar.value.addEvents(newLeaveRequestsList)
        }
    })

</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    :deep(#leave-type-dropdown-selector) {
        border-radius: 13px;
        input {
            border-radius: 13px;
            background-color: white;
            color: black;
            font-weight: 500;
            font-size: 16px;
            padding: 12px 15px;
        }
        .form-notch-leading {
            border-bottom-left-radius: 13px;
            border-top-left-radius: 13px;
        }
        .form-notch-trailing {
            border-bottom-right-radius: 13px;
            border-top-right-radius: 13px;
        }
        span.select-arrow {
            top: 22px;
        }
    }
    
    #team-and-status-col {
        flex: 0 0 152px;
    }
    #future-leave-requests-count {
        flex: 0 0 100%;
    }
    
    // the calendar
    #calendar-cont {
        :deep(.calendar) {
            tbody {
                background-color: white;
            }
            .calendar-heading {
                font-weight: 600;
                @include font-size-19;
            }
            
            button {
                font-weight: 600 !important;
                &:hover {
                    background: $gradient-green-alt-1-darken;
                }
            }
        }
    }
</style>