<template>
    <benefit-block>
        <template #title>Group Life Cover</template>
        <template #subTitle>Your life is currently covered for the amount of {{ bentoCurrencyFormat(coverAmount) }}</template>
        
        <template #benefitBlockContent>
            
            <section-header>Cover Definitions</section-header>
            
            <div class="mt-3">
                <div class="d-flex cover-row">
                    <div>
                        Cover Amount
                    </div>
                    <div>
                        {{ bentoCurrencyFormat(coverAmount) }}
                    </div>
                    <div>
                        4 x Annual Salary <info-tooltip class="gradient-green-text-dark" :tip-content="tipContent"/>
                    </div>
                </div>
                
                <div class="d-flex cover-row">
                    <div>
                        Premium
                    </div>
                    <div>
                        {{ bentoCurrencyFormat(premium) }}
                    </div>
                    <div>
                        100% employer contributed
                    </div>
                </div>
                
                <div class="d-flex cover-row">
                    <div>
                        Person Covered
                    </div>
                    <div>
                        {{ personCovered }}
                    </div>
                    <div>
                        No spouse cover
                    </div>
                </div>
                
                <div class="d-flex cover-row">
                    <div>
                        Benefit Definition
                    </div>
                    <div>
                        Death Only
                    </div>
                    <div>
                        Accidental or Disability not included
                    </div>
                </div>
            </div>
            
            <div class="mt-5 font-size-16 downloads" v-if="!isBentoDemoCompany">
                <div class="my-3">
                    <a href="https://content.mybento.net/media/assets/ccfo/R009916D_FAM_Cover_Schedule.pdf" target="_blank" class="hover-text-shadow"><img src="@/assets/v2/icons/pdf-icon-black.svg" class="d-inline-block me-3"/> <span>Download Cover Schedule</span></a>
                </div>
                <div>
                    <a href="https://content.mybento.net/media/assets/ccfo/R009916D_FAM_Cover_Policy.pdf" target="_blank"><img src="@/assets/v2/icons/pdf-icon-black.svg" class="d-inline-block me-3"/> <span>Download Cover Policy</span></a>
                </div>
            </div>
            
        </template>
    </benefit-block>
</template>

<script setup>
    import BenefitBlock from "@/components/benefits-leave-reimbursements/BenefitBlock.vue"
    import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"
    import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
    import {computed} from "vue"
    import {useStore} from "vuex"
    import InfoTooltip from "@/components/UI/v2/misc/InfoTooltip.vue"
    import {isProd} from "@/hooks/api/app-environment-origin"
    const store = useStore()
    
    const riskSalary = computed(() => {
        const salaryBeforeDeductions = store.getters?.latestPayslip?.salary_before_deductions || false
        if (!salaryBeforeDeductions) { return null }
        return salaryBeforeDeductions > 75000 ? 75000 : salaryBeforeDeductions
    })
    
    const premium = computed(() => riskSalary.value * .0068)
    
    const coverAmount = computed(() => {
        if (!riskSalary.value) return null
        return riskSalary.value * 12 * 4
    })
    
    const personCovered = store.getters.userFullName
    
    const tipContent = `4 x annual salary up to a maximum cover amount of R3.6 million.`
    
    const employer = computed(() => store.getters.employer)
    const isBentoDemoCompany = computed(() => employer.value.name === 'Bento Demo' || (employer.value.id === 6 && isProd()) )
</script>


<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins";
    
    .cover-row {
        margin: 24px 0;
        flex-wrap: wrap;
        > div {
            &:first-child {
                font-weight: 600;
            }
            padding: 1px 0;
            flex: 0 0 100%;
        }
        
        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
            > div {
                flex: 0 0 33.33%;
            }
        }
    }
    
    .downloads {
        a:hover {
            span {
                @include bento_underline;
                &:before {
                    background-color: black;
                }
            }
        }
    }
</style>