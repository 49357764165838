<template>
    <div
        class="table-line-item"
        :class="{ 'title-line-item': isHeader, 'large-line-item': isLarge }"
    >
        <div class="label" v-html="item.label">
        </div>
        <div class="value">
            <span v-if="!item.dontShowCurrent">
                {{ itemValue(item) }}
            </span>
        </div>
        <div class="value-planned">
            <span>
                {{ itemValuePlanned(item.value_planned) }}
            </span>
        </div>
    </div>
</template>

<script>
    import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"
    export default {
        name: "WizardBlockMonthlyPaymentsLineItem",
        props: {
            item: {
                required: true,
                type: Object,
            },
            isHeader: {
                required: false,
                type: Boolean,
            },
            isLarge: {
                required: false,
                type: Boolean,
            }
        },
        
        computed: {
            currentContract() {
                return this.$store.getters.medicalAidContractObj;
            },
            
            plannedContract() {
                return this.$store.getters.medicalAidContractObjPlanned;
            },
            
            
        },
    
        methods: {
    
            itemValue(item) {
                if (item.value == 'Current Plan') {
                    return item.value
                } else if (item.id == 'plan-name') {
                    return item.value
                } else if (this.currentContract) {
                    if (typeof item.value === 'number') {
                        return bentoCurrencyFormat(item.value);
                    }
                }
                return '-'
            },
            
            
            // We always pass zero value to the item from WizardBlockMonthlyPayments
            // If we do have a planned contract then show R0, else show -
            itemValuePlanned(plannedValue) {
                if (plannedValue === 'Selected Plan') {
                        return plannedValue;
                }
                else if (this.plannedContract) {
                    if (typeof plannedValue === 'number') {
                        return bentoCurrencyFormat(plannedValue);
                    }
                    else {
                        return plannedValue;
                    }
                } else {
                    return '-';
                }
            }
            
            
        
        },
        
        
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .table-line-item {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 18px;
        font-weight: 500;
        align-items: center;
        
        &.title-line-item {
            font-weight: 700;
            margin-top: 26px;
            margin-bottom: 26px;
        }
        
        &.large-line-item {
            font-weight: 600;
            font-size: 15px;
            margin-top: 26px;
            
            @media (min-width: 420px) {
                font-size: 16px;
            }
            
            @media (min-width: 500px) {
                font-size: 17px;
            }
        }
    }
    
    .label {
        flex: 1 auto;
        padding-right: 18px;
        font-size: 14px;
    
        @media (min-width: 420px) {
            font-size: 15px;
        }
    
        @media (min-width: 500px) {
            font-size: 16px;
        }
    }
    
    
    .value, .value-planned {
        flex: 0 0 110px;
        margin-left: 5px;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 13px;
    
        @media (min-width: 420px) {
            font-size: 14px;
        }
    
        @media (min-width: 500px) {
            font-size: 15px;
        }
        
        
        @include media-breakpoint-up(md) {
            flex: 0 0 180px
        }
    }
    
    .value {
        display: none;
        @media (min-width: 460px) {
            display: block;
        }
    }
    
    .value-planned {
        color: $secondary;
    }
    
</style>