<template>
    <div class="card bento-card no-shadow">
        <slot/>
    </div>
</template>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .no-shadow {
        box-shadow: none !important;
    }
    
    .bento-card {
        padding: 14px 10px;
        @include media-breakpoint-up(sm) {
            padding: 14px;
        }
        @include media-breakpoint-up(md) {
            padding: 12px 17px 16px;
        }
    }
    
    .green {
        background-color: $primary-50;
    }
    
    .my-0 {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
</style>