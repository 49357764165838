<template>
    <benefit-block>
        <template #title>Group Funeral Cover</template>
        <template #subTitle>You currently have funeral cover policy to the total value of {{ bentoCurrencyFormat(70000) }}</template>
        
        <template #benefitBlockContent>
            
            <section-header>Cover Definitions</section-header>
            
            <div class="mt-3">
                <div class="d-flex cover-row">
                    <div>
                        Ceasing Age
                    </div>
                    <div>
                        70
                    </div>
                </div>
                
                <div class="d-flex cover-row" v-if="false">
                    <div>
                        Commencement date
                    </div>
                    <div>
                        1 August 2023
                    </div>
                </div>
                
                <div class="d-flex cover-row cover-row-less-spacing">
                    <div>
                        Family Benefit
                    </div>
                    <div>
                        A Primary Insured Person
                    </div>
                    <div>
                        {{ bentoCurrencyFormat(20000) }}
                    </div>
                </div>
                <div class="d-flex cover-row cover-row-less-spacing">
                    <div>
                    </div>
                    <div>
                        An Insured Spouse
                    </div>
                    <div>
                        {{ bentoCurrencyFormat(20000) }}
                    </div>
                </div>
                <div class="d-flex cover-row cover-row-less-spacing">
                    <div>
                    </div>
                    <div>
                        An Insured Child over 14
                    </div>
                    <div>
                        {{ bentoCurrencyFormat(20000) }}
                    </div>
                </div>
                <div class="d-flex cover-row cover-row-less-spacing">
                    <div>
                    </div>
                    <div>
                        <div>An Insured child under 14</div>
                        <div class="disclaimer-text">(including a stillborn insured child)</div>
                    </div>
                    <div>
                        {{ bentoCurrencyFormat(10000) }}
                    </div>
                </div>
            </div>
            
            <div class="mt-5 font-size-16 downloads" v-if="!isBentoDemoCompany">
                <div class="my-3">
                    <a href="https://content.mybento.net/media/assets/ccfo/R009916D_GLA_Cover_Schedule.pdf" target="_blank"><img src="@/assets/v2/icons/pdf-icon-black.svg" class="d-inline-block me-3"/> <span>Download Cover Schedule</span></a>
                </div>
                <div>
                    <a href="https://content.mybento.net/media/assets/ccfo/R009916D_GLA_Cover_Policy.pdf" target="_blank"><img src="@/assets/v2/icons/pdf-icon-black.svg" class="d-inline-block me-3"/> <span>Download Cover Policy</span></a>
                </div>
            </div>
         
            
        </template>
    </benefit-block>
</template>

<script setup>
    import BenefitBlock from "@/components/benefits-leave-reimbursements/BenefitBlock.vue"
    import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters"
    import SectionHeader from "@/components/UI/v2/containers-cards-headers/SectionHeader.vue"
    import {computed} from "vue"
    import {isProd} from "@/hooks/api/app-environment-origin"
    import {useStore} from "vuex"
    const store = useStore()
    
    const employer = computed(() => store.getters.employer)
    const isBentoDemoCompany = computed(() => employer.value.name === 'Bento Demo' || (employer.value.id === 6 && isProd()) )
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins";
    
    .cover-row {
        margin: 24px 0;
        &.cover-row-less-spacing {
            margin: 8px 0;
        }
        
        flex-wrap: wrap;
        > div {
            &:first-child {
                font-weight: 600;
            }
            padding: 1px 0;
            flex: 0 0 100%;
        }
        
        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
            > div {
                flex: 0 0 33.33%;
            }
        }
    }
    
    .downloads {
        a:hover {
            span {
                @include bento_underline;
                &:before {
                    background-color: black;
                }
            }
        }
    }
</style>