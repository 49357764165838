// employee reimbursements mutations
// remember a mutation can only update it's own index store

export default {

    // claims
    setReimbursementClaimList: (state, payload) => state.reimbursementClaimList = payload,
    setLastReimbursementClaimListFetch: (state, payload) => state.lastReimbursementClaimListFetch = payload.timestamp,

    // updates the state of a claim to 'approve / rejected / cancelled' - from employee UI or manager UI
    updateReimbursementClaimState: (state, payload) => state.reimbursementClaimList[payload.leaveObjectIndex].state = payload.updatedState,

    // allowances
    setReimbursementAllowanceList: (state, payload) => state.reimbursementAllowanceList = payload,
    setLastReimbursementAllowanceListFetch: (state, payload) => state.lastReimbursementAllowanceListFetch = payload.timestamp,
}