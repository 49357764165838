<template>
    <div>
        <Carousel
            :settings="settings"
            :breakpoints="breakpoints"
            ref="seriesCarousel"
        >
            <template #slides>
                <Slide
                    v-for="slide in medicalAidSeriesListWithIndex"
                    :key="slide.id"
                >
                    <div class="carousel__item">
                        <wizard-block-info-card-series
                            :series="slide"
                            @series-select-handler="onSeriesSelectHandler"
                        />
                    </div>
                </Slide>
            </template>
            
            <template #addons>
                <Navigation/>
                <Pagination/>
            </template>
        </Carousel>
    
    </div>
</template>

<script>
    // docs: https://ismail9k.github.io/vue3-carousel/examples.html
    
    import {defineComponent} from 'vue'
    import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel';
    
    import 'vue3-carousel/dist/carousel.css';
    import WizardBlockInfoCardSeries
        from "@/components/benefits-leave-reimbursements/medical-aid/wizard/card-carousel/series/WizardBlockInfoCardSeries";
    import {mapGetters} from "vuex";
    
    export default defineComponent({
        name: 'WizardBlockCardCarouselSeries',
        components: {
            WizardBlockInfoCardSeries,
            Carousel,
            Slide,
            Pagination,
            Navigation,
        },
        
        computed: {
            
            ...mapGetters([
                'tempSelectedMedicalAidContractObj',
            ]),
            
            medicalAidSeriesList() {
                const seriesList = this.$store.getters.medicalAidSeriesList;
                const seriesListSorted = seriesList.sort( (series1, series2) => (series1.cost_from < series2.cost_from) ? 1 : -1);
                return seriesListSorted;
            },
            
            medicalAidSeriesListWithIndex() {
                const seriesListSorted = this.medicalAidSeriesList;
                const seriesListWithIndex = [];
                let i = 0;
                for (const seriesObject of seriesListSorted) {
                    seriesListWithIndex.push(
                        {
                            ...seriesObject,
                            index: i,
                        }
                    )
                    i++;
                }
                return seriesListWithIndex;
            },
            
        },
        
        methods: {
            
            onSeriesSelectHandler(slideIndex) {
                const seriesCarousel = this.$refs.seriesCarousel;
                if (!seriesCarousel || (!slideIndex && slideIndex != 0)) {
                    return;
                } else {
                    seriesCarousel.slideTo(slideIndex);
                }
            },
            
            centerSelectedSeries() {
                const selectedPlanSeriesName = this.tempSelectedMedicalAidContractObj.series;
                const selectedPlanObjectWithIndex = this.medicalAidSeriesListWithIndex.find( obj => obj.name == selectedPlanSeriesName);
                const slideIndex = selectedPlanObjectWithIndex.index;
                const seriesCarousel = this.$refs.seriesCarousel;
                seriesCarousel.slideTo(slideIndex);
            },
            
        },
        
        data: () => ({
            
            // carousel settings
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
                wrapAround: true,
            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 3,
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                },
            },
            
        }),
        
        mounted() {
            this.centerSelectedSeries();
        },
    });
</script>

<style scoped>
    /*Carousel styles come from unscoped WizardBlockCardCarouselBenefits*/
</style>