// employee medical getters

export default {

    // The medical aid contract object is on the employee object
    // I have left the getter in employee/getters.js -> medicalAidContractObj

    /*Addon (Vitality) list*/
    medicalAidPlanAddonList(state) {
        return state.medicalAidPlanAddonList;
    },


    lastMedicalAidPlanAddonListFetch(state) {
      return state.lastMedicalAidPlanAddonListFetch;
    },


    /*working with the cache*/
    shouldUpdateMedicalAidPlanAddonList(_, getters) {
        const lastMedicalAidPlanAddonListFetch = getters.lastMedicalAidPlanAddonListFetch;
        if (!lastMedicalAidPlanAddonListFetch) {
            return true;
        }
        const currentTimeStamp = new Date().getTime();
        // currently set to 900 seconds / 15 mins. Could also rather just check if the store has data
        return (currentTimeStamp - lastMedicalAidPlanAddonListFetch) / 1000 > 900;
    },


    // Addon Vitality Wizard Block
    tempSelectedAddonPlan(state) {
        return state.tempSelectedAddonPlan;
    }


}