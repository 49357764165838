// employee medical contract mutations
export default {

    // temp medical aid contract object
    setTempSelectedMedicalAidContractObj: (state, tempContractObj) => state.tempSelectedMedicalAidContractObj = tempContractObj,


    // medical aid plan list
    setMedicalAidPlanList: (state, payload) => (state.medicalAidPlanList = payload),
    setLastMedicalAidPlanListFetch: (state, payload) => state.lastMedicalAidPlanListFetch = (payload) ? (payload.timestamp) : null,


    // contract change request list
    // same mutation for updating and deleting
    setMedicalAidContractChangeRequestList: (state, contractChangeRequestList) => state.medicalAidContractChangeRequestList = contractChangeRequestList,
    setLastMedicalAidContractChangeRequestListFetch: (state, payload) => state.lastMedicalAidContractChangeRequestListFetch = (payload) ? (payload.timestamp) : null,

}