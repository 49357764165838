// payroll changes getters
import {isEqual} from "lodash"

export default {
    arePayrollChangesSubmitted: (_, getters) => getters.areMedicalAidChangesSubmitted || getters.areRetirementChangesSubmitted || getters.areBankAccountChangesSubmitted,
    arePayrollDraftChanges: (_, getters) => getters.areMedicalAidDraftChanges || getters.areRetirementDraftChangesDifferentToCurrent, // dont do draft bank account changes

    areMedicalAidChangesSubmitted: (_, getters) => getters.hasMedicalAidContractPlanned && getters.medicalAidContractObjPlanned.status === 'submitted',
    areMedicalAidDraftChanges: (_, getters) => {
        if (!getters.hasMedicalAidContractPlanned) return false
        if (getters.medicalAidContractObjPlanned.status === 'submitted') return false
        if (!getters.hasMedicalAidContract && getters.hasMedicalAidContractPlanned) return true

        const {plan_id: currentPlanId, adult_dependents: currentAdultDependents, child_dependents: currentChildDependents} = getters.medicalAidContractObj
        const {plan_id: plannedPlanId, adult_dependents: plannedAdultDependents, child_dependents: plannedChildDependents} = getters.medicalAidContractObjPlanned

        return !((currentPlanId === plannedPlanId) && (currentAdultDependents === plannedAdultDependents) && (currentChildDependents === plannedChildDependents))
    },

    areRetirementChangesSubmitted: (_, getters) => (getters.hasRetirementFundContractPlanned && getters.retirementFundContractObjPlanned.status === 'submitted') || false,


    // retirement draft changes different to current contract
    areRetirementDraftChangesDifferentToCurrent: (_, getters) => getters.areRetirementDraftChangesFiguresDifferentToCurrent || getters.areRetirementDraftChangesExtraDataDifferentToCurrent,
    areRetirementDraftChangesFiguresDifferentToCurrent: (_, getters) => {
        if (!getters.hasRetirementFundContractPlanned) return false
        if (getters.retirementFundContractObjPlanned.status === 'submitted') return false
        if (!getters.hasRetirementFundContract && getters.hasRetirementFundContractPlanned) return true

        const currentPlanId = getters.retirementFundContractObj.retirement_fund.id
        const plannedPlanId = getters.retirementFundContractObjPlanned.id
        const currentEmployeeContribution = getters.retirementFundContractObj.employee_contribution
        const plannedEmployeeContribution = getters.retirementFundContractObjPlanned.monthly_contribution

        return !((currentPlanId === plannedPlanId) && (currentEmployeeContribution === plannedEmployeeContribution))
    },
    areRetirementDraftChangesExtraDataDifferentToCurrent: (_, getters) => {
        if (!getters.hasRetirementFundContractPlanned) return false
        if (getters.retirementFundContractObjPlanned.status === 'submitted') return false
        if (!getters.hasRetirementFundContract && getters.hasRetirementFundContractPlanned) return true

        const currentPlanExtraData = getters.retirementFundContractObj.extra_data
        const plannedPlanExtraData = getters.retirementFundContractObjPlanned.extra_data

        return !isEqual(currentPlanExtraData, plannedPlanExtraData)
    },

    // retirement temp changes different to saved draft
    areRetirementTempChangesDifferentToDraft: (_, getters) => getters.areRetirementTempChangesFiguresDifferentToDraft || getters.areRetirementTempChangesExtraDataDifferentToDraft,
    areRetirementTempChangesFiguresDifferentToDraft: (_, getters) => {
        if (!getters.retirementFundContractObjDraft) return true

        const draftPlanId = getters.retirementFundContractObjDraft.retirement_fund
        const tempPlanId = getters.tempSelectedRetirementFundContractObj.fundId
        const draftEmployeeContribution = getters.retirementFundContractObjDraft.monthly_contribution
        const tempEmployeeContribution = getters.tempSelectedRetirementFundContractObj.employeeContribution

        return !((draftPlanId == tempPlanId) && (draftEmployeeContribution == tempEmployeeContribution))
    },
    areRetirementTempChangesExtraDataDifferentToDraft: (_, getters) => {
        if (!getters.retirementFundContractObjDraft) return true

        const tempPlanExtraData = getters.tempSelectedRetirementFundContractObj.extraData
        const draftPlanExtraData = getters.retirementFundContractObjDraft.extra_data

        return !isEqual(tempPlanExtraData, draftPlanExtraData)
    },


}