<template>
    <div class="paye-calcs-container">
        
        <h1>
            PAYE Calcs
            <div class="admin-only-block">Bento Admin Only</div>
        </h1>
        
        <div class="preamble">
            <div><strong>2 methods:</strong></div>
            <ol>
                <li>Non-annualised, based on current month salary package alone. This is only accurate if salary package does not change within the year.</li>
                <li>Annualised. Accurate. Should be exactly the same as PAYE SimplePay API figure.</li>
            </ol>
        </div>
        
        <div class="extra-note">
            <strong>Payslip data from API</strong>
            <ul>
                <li>
                    The latest payslip is the most recent payslip taken from employee.payslips. It is the most recent payslip that has been finalised in SimplePay. Not before the payslip release day, set up in /admin/ on the employer interface.
                </li>
                <li>employee.payslips -> returns all payslips present in SimplePay including
                    <strong>unfinalised.</strong></li>
                <li>In annualised calc I sync payslip list to latest payslip. The list may even be more than 1 payslip ahead, who knows how many payslips in the future an employer has created in SimplePay.</li>
            </ul>
        </div>
        
        <div class="bento-admin-link">
            <button-bento button-color="dark" class="mt-2">
                <router-link :to="{ name: 'bento-admin' }">Back to Bento Admin</router-link>
            </button-bento>
        </div>
    
        <loading-full-screen v-model="isLoading" loadingText="Loading data ..."/>
        
        <div v-if="!isLoading">
            
            <div class="employee-api-data-block">
                <h3>Tax Trace SimplePay PAYE figures</h3>
                <div class="payrun-period">
                    Pay Run: {{ getPayrun }}
                </div>
                
                <div class="derived-from">
                    Derived from
                    <a href="https://www.simplepay.co.za/help/payroll-concepts/statutory-deductions-and-contributions/tax-paye/understanding-the-tax-trace" target="_blank" rel="noopener"> SimplePay Tax Trace Method
                        <img src="@/assets/v2/icons/other/external-link.svg"/> </a>
                </div>
                
                <div class="tax-trace-items-container">
                    
                    <div class="tax-trace-item key">
                        <div class="label">Label</div>
                        <div class="current">Current</div>
                        <div class="planned">Planned</div>
                        <div class="note">Note</div>
                    </div>
                    
                    <tax-trace-item :tax-trace-item="ytdRegularIncome" note="This is the total of all regular income from the beginning of the tax year (1 March) until the current payslip.<br> This is total earnings (gross remuneration), so includes allowances (actual taxable, not reimbursements) + pension fund contribution employer + medical aid contribution employer."/>
                    <div class="note-to-tax-trace-item">
                        <strong>cash income + cash allowances (taxable) + the value of benefits (non incl. pension)</strong>
                    </div>
                    
                    <tax-trace-item :tax-trace-item="ytdIrregularIncome"
                                    note="<span>This is the total of all irregular income from the beginning of the tax year (1 March) until the current payslip. <strong>Annual Bonus, Annual Payment, Leave Paid Out.</strong> * Leave paid out is only applicable to when an employee's service ends, so not strictly applicable to show in Bento App.<br>
                                      <strong>Planned irregular income is going to be the same as current regular income. We don't know if a new irregular income is going to be added in the next payslip.</strong></span>"
                    />
                    <div class="note-to-tax-trace-item">
                        Loop through all payslips and sum up all irregular items (Annual bonus, Annual Payment, Leave Paid Out, Broad Based Employee Share Plan) from all payslips.<br> Need to find out if there are any custom irregular income items created.
                        <!--Insert here-->
                    </div>
                    
                    <tax-trace-item :tax-trace-item="ytdTotalIncome" note="This is the sum of the YTD Regular Income and YTD Irregular Income"/>
                    
                    <tax-trace-item :tax-trace-item="periodOfEmployment" :financial="false" color="aqua" note="<span>Period of first payslip. March -> 1, April -> 2, May -> 3, ..., Feb -> 12</span>"/>
                    
                    <tax-trace-item :tax-trace-item="periodsInYear" :financial="false" color="aqua" note="<span>If an employee started employment mid year then there are less than 12 periods in a year. This effects the annualisation ratio. If first payslip is in January, then there are 2 periods in the year.</span>"/>
                    
                    <tax-trace-item :tax-trace-item="ytdPeriods" :financial="false" color="aqua" note="<span>YTD periods. This is applicable to the current payslip. E.g. March -> 1, April -> 2, May -> 3, ..., Feb -> 12. <strong>(If was in employment from 1 March)</strong> Period is analogous for months.</span>"/>
                    
                    <tax-trace-item :tax-trace-item="annualisationRatio" :financial="false" color="aqua" note="<span><strong>Periods in Year / YTD periods</strong> . E.g. June would be month 6, so the annualisation ratio would be 2. That's for an employee starting employed from March. <strong>Different if started mid way through the year.</strong><br>I have rounded the display value to 3 decimal places.</span>"/>
                    
                    
                    <tax-trace-item :tax-trace-item="annualisedTotalIncome" color="aqua" note="<span>Used in calc below. The annualised total income is calculated as follows: <strong>(YTD Regular Income x Annualisation Ratio) + YTD Irregular Income.</strong>
                    <br>The year-to-date figures are used to project a total income for the year, called the annualised total income.
                    <br>Not in tax trace, ross added for clarity. The figure is actually displayed in the tax trace to verify.<br>
                    <strong>*** Notice that logically irregular income doesn't get annualised, as it is irregular ***</strong>
                    </span>"/>
                    
                    <tax-trace-item :tax-trace-item="taxOnAnnualisedTotalIncome" note="<span>The year-to-date figures are used to project a total income for the year, called the annualised total income. This is the tax that would be payable if the employee earns the projected annualised total income for the year. It is calculated in two steps: First, the annualised total income is calculated as follows: <strong>(YTD Regular Income x Annualisation Ratio) + YTD Irregular Income.</strong> The annualisation ratio is not shown on this page, but it is the inverse of the de-annualisation ratio, which is shown in the YTD Tax calculation near the bottom of the page. Once the annualised total income has been calculated, the tax on this figure is calculated using the tax tables for SARS.</span>"/>
                    <div class="note-to-tax-trace-item">
                        Cross referenced my calculation with online calculators -> TaxTim, Metropolitan (exactly the same), Old Mutual & Sage (3 cents out). SimplePay was 40 cents out. Must be variation in rounding behaviour, code syntax, et al, and not an error with the calc.
                    </div>
                    
                    <tax-trace-item :tax-trace-item="annualisedTotalRegularIncome" color="aqua" note="<span>
                    <strong>YTD Regular income * annualisation ratio</strong>, i.e. annualised. OR another would be <strong>Annualised Total Income - YTD Irregular Income</strong>.<br> Notice that logically irregular income doesn't get annualised, as it is irregular.</span>
                    </span>"/>
                    <div class="note-to-tax-trace-item">
                        Same as annualised total income if no irregular income.<br>
                    </div>
                    
                    <tax-trace-item :tax-trace-item="taxOnAnnualisedRegularIncome" note="<span>Next, the annualised regular income is calculated as follows: <strong>Annualised total income – Irregular Income.</strong> Once the annualised regular income has been calculated, the tax on this figure is calculated using the tax tables for SARS.</span>"/>
                    <div class="note-to-tax-trace-item">
                        Same as tax on total income if no irregular income<br> Cross referenced my calculation with online calculators -> TaxTim, Metropolitan (exactly the same), Old Mutual & Sage (3 cents out). SimplePay was 40 cents out. Must be variation in rounding behaviour, code syntax, et al, and not an error with the calc.
                    </div>
                    
                    <tax-trace-item :tax-trace-item="taxOnIrregularIncome" note="<span>The tax on irregular income is calculated as the difference between the tax on the annualised total income and the tax on the annualised regular income. <strong>Tax on Annualised Income - Tax on Annualised Regular Income</strong></span>"/>
                    <div class="note-to-tax-trace-item">
                        Obviously 0 if there is no irregular income.
                    </div>
                    
                    <tax-trace-item :tax-trace-item="ytdTax" note="<span>This is calculated by de-annualising the tax on the regular income and adding the tax on the regular income. This represents the tax that the employee should have paid up until this payslip.<br>
                    <strong>Tax on Annualised Regular Income * YTD Periods / Total Periods + Tax on Irregular Income</strong></span>"/>
                    
                    <tax-trace-item :tax-trace-item="ytdMatc" color="aqua" note="<span>Used to calculate Tax for Current (and current planned) Payslip below.</span>"/>
                    
                    <tax-trace-item :tax-trace-item="ytdTaxPaid" color="aqua" note="<span>Used to calculate Tax for Current (and current planned) Payslip below.</span>"/>
                    <div class="note-to-tax-trace-item">
                        Tax paid up until but not including the current payslip we are calculating PAYE for.
                    </div>
                    
                    <tax-trace-item :tax-trace-item="taxPayeAnnualisedCalc" note="<span>The PAYE due for the current payslip is the difference between the year-to-date tax calculated and the tax paid on previous payslips, less any year-to-date medical aid tax credits.<br>
                    <strong>YTD Tax - Tax Already Paid - YTD Medical Aid Tax Credits</strong></span>"/>
                
                </div>
            
            </div>
            
            <div class="employee-api-data-block">
                <h3>Payslips List Trimmed (Last SimplePay Finalised) and Current Financial Year</h3>
                <div>{ payslipsListOrderedSyncedCurrent }</div>
                <div>
                    <pre>{{ payslipsListOrderedSyncedCurrent }}</pre>
                </div>
            </div>
            
            <div class="employee-api-data-block">
                <h3>API: Payslips List Trimmed (to be in sync with latest payslip)</h3>
                <div>{ payslipsListOrderedSynced }</div>
                <div>
                    <pre>{{ payslipsListOrderedSynced }}</pre>
                </div>
            </div>
            
            <div class="employee-api-data-block">
                <h3>API: Payslips</h3>
                <div>{ payslipsListOrdered }</div>
                <p>Employee object -> app.mybento.net/api/1.0/employees/{userId}/payslips</p>
                <div>
                    <pre>Derived from endpoint -> cos it's been sorted</pre>
                    <pre>{{ payslipsListOrdered }}</pre>
                </div>
            </div>
            
            <div class="employee-api-data-block">
                <h3>API: Current Payslip</h3>
                <div>
                    <pre>{{ latestPayslip }}</pre>
                </div>
            </div>
            
            <salary-figures/>
        
        </div>
        
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import SalaryFigures from "@/components/admin-restricted/salary-figures/SalaryFigures";
    import TaxTraceItem from "@/components/admin-restricted/paye-calcs/TaxTraceItem";
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento";
    import LoadingFullScreen from "@/components/UI/v2/loading/LoadingFullScreen";
    
    export default {
        name: "AdminRestrictedPayeCalcsPage",
        components: {LoadingFullScreen, ButtonBento, TaxTraceItem, SalaryFigures},
        data() {
            return {
                isLoadingPayslipsList: false,
            }
        },
        
        computed: {
            
            isLoading() {
                return this.isLoadingPayslipsList
            },
            
            ...mapGetters([
                
                'getPayrun',
                
                /*payslips list*/
                'payslipsList',
                'payslipsListOrdered',
                'payslipsListOrderedSynced',
                'payslipsListOrderedSyncedCurrent',
                'latestPayslip',
                
                /*SimplePay tax trace figures*/
                'ytdRegularIncome',
                'ytdIrregularIncome',
                'ytdTotalIncome',
                'periodOfEmployment',
                'periodsInYear',
                'ytdPeriods',
                'annualisationRatio',
                'annualisedTotalIncome',
                'annualisedTotalRegularIncome',
                'taxOnAnnualisedTotalIncome',
                'taxOnAnnualisedRegularIncome',
                'taxOnIrregularIncome',
                'ytdTax',
                'ytdMatc',
                'ytdTaxPaid',
                'taxPayeAnnualisedCalc',
                
                /*summary*/
                'totalReimbursiveAllowances',
                
                /*cash portion list*/
                'cashPortionsList',
                
                /*medical aid contract*/
                'hasMedicalAidContract',
                'medicalAidProvider',
                'medicalAidPlanId',
                'medicalAidPlanName',
                'adultDependants',
                'childDependants',
                'medicalAidTaxCredit',
                // 'totalMedicalAidPlanCost',
                
                /*salary before deductions*/
                'salaryBeforeDeductions',
                
                'totalAllowancesTaxable',
            ]),
            
        },
        
        methods: {
            
            async loadPayslipsList(refresh = false) {
                this.isLoadingPayslipsList = true;
                try {
                    await this.$store.dispatch('loadPayslipsList', {forceRefresh: refresh});
                } catch (error) {
                    this.error = error.message || 'Error: Could not load employee payslips list!';
                }
                this.isLoadingPayslipsList = false;
            },
            
            
        },
        
        created() {
            this.loadPayslipsList();
        },
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .paye-calcs-container {
        padding: 0 20px;
        max-width: 1200px;
        margin: -30px auto 0;
        font-size: 14px;
    }
    
    
    h1 {
        margin-top: 5px;
        position: relative;
    }
    
    .admin-only-block {
        display: inline-block;
        text-transform: uppercase;
        font-weight: 600;
        padding: 8px;
        background-color: #386CEC;
        color: white;
        font-size: 13px;
        position: absolute;
        top: 0;
        right: 0;
    }
    
    .preamble {
        background-color: rgba($gradient-green-2, .65);
        padding: 12px 10px 8px;
        margin-bottom: 10px;
        p {
            font-size: 14px;
            margin: 8px 0 12px;
        }
        ol {
            margin: 8px 0;
            line-height: 1.3;
        }
    }
    
    .derived-from {
        a {
            color: #001A5C;
            font-weight: 600;
            &:hover {
                text-decoration: underline;
            }
            img {
                width: 14px;
            }
        }
    }
    
    .extra-note {
        background-color: rgba($gradient-pink-text-light, .2);
        padding: 12px 10px 8px;
        p {
            font-size: 14px;
            margin: 8px 0 12px;
        }
        ul {
            margin: 8px 0;
            line-height: 1.3;
        }
    }
    
    .tax-trace-items-container {
        margin-top: 24px;
    }
    
    .note-to-tax-trace-item {
        font-size: 13px;
        font-weight: 400;
        background-color: rgba(57, 255, 20, .2);
        text-align: center;
        padding: 5px 0;
    }
    
    .employee-api-data-block {
        margin-top: 35px;
        margin-bottom: 45px;
        border: 1px solid black;
        padding: 15px 15px 50px;
    }
    
    pre {
        font-size: 14px;
    }
    
    .payrun-period {
        color: darken(#00B5C0, 5%);
        font-weight: 600;
        margin-top: -10px;
        margin-bottom: 10px;
    }
    
    /*this in here for the key*/
    .tax-trace-item.key {
        margin-top: 20px;
        display: flex;
        flex-wrap: nowrap;
        border-bottom: 1px solid #efefef;
        border-top: 1px solid #efefef;
        padding: 15px 10px;
        background-color: rgba(20, 20, 20, .05);
        border-bottom: 1px solid black;
    }
    
    .label {
        flex: 0 0 180px;
        font-weight: 600;
        display: flex;
        align-items: center;
        padding-right: 5px;
    }
    
    .current {
        padding-left: 5px;
        flex: 0 0 120px;
        font-weight: 600;
        display: flex;
        align-items: center;
    }
    
    .planned {
        padding-left: 5px;
        flex: 0 0 120px;
        font-weight: 600;
        display: flex;
        align-items: center;
    }
    
    .note {
        flex: 1 auto;
        line-height: 1.4;
        font-size: 14px;
        font-weight: 600;
        display: flex;
        align-items: center;
    }

</style>