<template>
    <bento-alert color="danger" static v-model="noApproverNotice" id="the-alert">
        <span>You do not have an allocated approver.<br></span>
        <span v-if="hrContact">Get in touch with your HR contact, <a :href="`mailto:${hrContact.email}`" class="fw-500 bento-underline bento-underline-secondary hover-text-shadow">{{ hrContact.name }}</a>, to allocate you one. Or <a href="mailto:hello@mybento.net" class="fw-600 bento-underline bento-underline-secondary hover-text-shadow">contact us</a> for assistance.</span>
        
        <span v-else>Contact your HR admin contact to allocate an approver, or <a href="mailto:hello@mybento.net" class="fw-500 bento-underline bento-underline-secondary hover-text-shadow">contact us</a> for assistance.</span>
    </bento-alert>
</template>

<script setup>
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
    import {ref} from "vue"
    import {useStore} from "vuex"
    const store = useStore()
    const hrContact = store.getters.hrContact
    
    const noApproverNotice = ref(false)
</script>

<style lang="scss" scoped>
    #the-alert {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }
</style>