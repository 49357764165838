// ### simple benefits (free perks) getters

import {isValidArray} from "@/hooks/general/type-and-value-checks";

export default {

    simpleBenefitsList: (state) => state.simpleBenefitsList,

    simpleBenefitsCategoryList(_, getters) {
        if (!isValidArray(getters.simpleBenefitsList)) { return null }

        let categoryList = []
        for (const benefit of getters.simpleBenefitsList) {
            categoryList.push({
                category: benefit.category,
                categoryName: benefit.category_name,
                benefits: []
            })
        }

        // step 2:
        // remove duplicates. Referenced from here:
        // https://stackoverflow.com/questions/2218999/how-to-remove-all-duplicates-from-an-array-of-objects
        categoryList = categoryList.filter((categoryObj, index) => {
            const _value = JSON.stringify(categoryObj)

            return index === categoryList.findIndex(categoryObj => {
                return JSON.stringify(categoryObj) === _value
            });
        });

        // step 3:
        for (const benefit of getters.simpleBenefitsList) {
            for (const categoryObj of categoryList) {
                if (benefit.category === categoryObj.category) {
                    categoryObj.benefits.push(benefit)
                }
            }
        }

        // step 4:
        // edge case of removing 'Essential Benefits' from Simple Benefits list.
        // created this for Legalese 'Bonuses & Commissions'
        categoryList = categoryList.filter(categoryObj => categoryObj.category !== 9)

        return categoryList
    },


    lastSimpleBenefitsListFetch(state) {
        return state.lastSimpleBenefitsListFetch;
    },

    shouldUpdateSimpleBenefitsList(_, getters) {
        const lastSimpleBenefitsListFetch = getters.lastSimpleBenefitsListFetch;
        if (!lastSimpleBenefitsListFetch) {
            return true;
        }
        const currentTimeStamp = new Date().getTime();
        // currently set to 300 seconds / 5 mins. Could also rather just check if the store has data
        return (currentTimeStamp - lastSimpleBenefitsListFetch) / 1000 > 300;
    },

}