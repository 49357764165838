<template>
    
    <div
        class="card-container-series"
        @click="updatetempSelectedMedicalAidContractObj(series)"
        :class="{'selected': series.name === tempSelectedSeries}"
    >
        
        <div class="series-title">{{ series.name }} Series</div>
        
        <div class="series-sub-title">
            Main member contribution
        </div>
        <div class="series-contribution-value">
            <div><span>FROM</span></div>
            <div>{{ bentoCurrencyFormat(series.cost_from) }}</div>
        </div>
        
        <div class="content-series">
            {{ series.description }}
        </div>
        
        <div class="see-more-plan-options">
            <span>See plan options</span>
            <span class="down-arrow-container">
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.802 21.0737L22.6496 13.328C22.9028 13.1292 23.1939 13.0299 23.523 13.0299C23.852 13.0299 24.1368 13.1348 24.3773 13.3445C24.6178 13.5543 24.7381 13.8026 24.7381 14.0897C24.7381 14.3767 24.6241 14.6306 24.3963 14.8514L13.4604 24.3894C13.2073 24.5881 12.9161 24.6875 12.587 24.6875C12.258 24.6875 11.9668 24.5881 11.7137 24.3894L0.777782 14.8514C0.54995 14.6306 0.436035 14.3767 0.436035 14.0897C0.436035 13.8026 0.556279 13.5543 0.796768 13.3445C1.03726 13.1348 1.32205 13.0299 1.65114 13.0299C1.98023 13.0299 2.27134 13.1292 2.52449 13.328L11.3718 21.0735L11.3718 1.3722C11.3718 1.0631 11.4857 0.809192 11.7136 0.610482C11.9414 0.411773 12.2325 0.312421 12.5869 0.312421C12.9413 0.312421 13.2324 0.411773 13.4603 0.610482C13.6881 0.809192 13.802 1.0631 13.802 1.3722L13.802 21.0737Z" fill="black"/>
                </svg>
            </span>
        </div>
    
    </div>
    
</template>

<script>
    import {bentoCurrencyFormat} from "@/hooks/general/currency-number-formatters";
    import {mapGetters} from "vuex";

    export default {
        name: "WizardBlockInfoCardSeries",
        props: [
            'series',
        ],
        emits: [
            'seriesSelectHandler'
        ],
        
        methods: {
            bentoCurrencyFormat,
          
            // update the temp series
            updatetempSelectedMedicalAidContractObj(series) {
                
                // I want to get the slide index
                const slideIndex = series.index;
                this.$emit('seriesSelectHandler', slideIndex);
                
                const tempContractObj = {
                    ...this.tempSelectedMedicalAidContractObj,
                    series: series.name,
                };
                this.$store.dispatch('setTempSelectedMedicalAidContractObj',tempContractObj);
            },
            
        },
        
        created() {
          
            // need this to update plans when nothing has been selected (current, planned, or temp)
            if (this.series.name === this.tempSelectedSeries) {
                this.updatetempSelectedMedicalAidContractObj(this.series);
            }
            
        },
    
        computed: {
            
            ...mapGetters([
               'tempSelectedMedicalAidContractObj',
            ]),
            
            /*Default temp selected series to "Comprehensive"*/
            tempSelectedSeries() {
                return (this.tempSelectedMedicalAidContractObj.series) ? (this.tempSelectedMedicalAidContractObj.series) : "Comprehensive";
            },
            
            imageDir() {
                return this.$store.getters.imageDir;
            },
            
        }
    }
</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    // needs to be unscoped to reach tags in v-html
    
    .card-container-series {
        background-color: white;
        border-radius: $border_radius;
        margin: 5px;
        padding: 15px;
        height: 320px;
        width: 256px;
        position: relative;
        text-align: left;
        cursor: pointer;
        
        @media (min-width: 700px) {
            height: 380px;
        }
        
        @media (min-width: 732px) {
            height: 340px;
        }
        
        @media (min-width: 860px) {
            height: 320px;
        }
        
        &.selected {
            background-color: $primary-light;
        }
        
        &:hover {
            background-color: $primary-light;
        }
    }
    
    .series-title {
        font-size: 17px;
        font-weight: 600;
        margin-top: 4px;
        margin-bottom: 16px;
    }
    
    .series-sub-title {
        color: $grey;
        font-size: 13px;
        font-weight: 500;
    }
    
    .series-contribution-value {
        font-size: 28px;
        font-weight: 600;
        margin-top: 0;
        line-height: 1.1;
        span {
            font-size: 18px;
        }
    }
    
    .content-series {
        margin-top: 20px;
        font-size: 13px;
        line-height: 1.3;
    }
    
    .more-information-series {
        position: absolute;
        left: 20px;
        width: calc(100% - 40px);
        bottom: 20px;
        display: flex;
        
        a {
            position: relative;
            font-weight: 300;
            font-size: 13px;
            display: inline-block;
            width: 110px;
            
            &:hover {
                font-weight: 500;
            }
            
            &:after {
                content: '';
                width: 104px;
                position: absolute;
                height: 1px;
                bottom: -1px;
                background-color: black;
                left: 0;
            }
        }
    }
    
    .see-more-plan-options {
        position: absolute;
        left: 15px;
        width: calc(100% - 30px);
        bottom: 20px;
        color: $grey_1;
        font-weight: 600;
        font-size: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
        .down-arrow-container {
            display: inline-block;
            max-width: 18px;
            svg {
                width: 100%;
            }
        }
    }

    

</style>