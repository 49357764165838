import BenefitPageReimbursements from "@/components/benefits-leave-reimbursements/reimbursements/BenefitPageReimbursements.vue"
import LearningResource from "@/components/learn-and-explore/LearningResource.vue"
import BenefitPageAdHocReimbursements from "@/components/benefits-leave-reimbursements/reimbursements/BenefitPageAdHocReimbursements.vue"

export default [
    {
        name: 'reimbursements',
        path: '/reimbursements',
        component: BenefitPageReimbursements,
        meta: { menuParent: 'reimbursements' },
        children: [
            {
                name: 'reimbursement-resource',
                path: ':resourceSlug',
                component: LearningResource,
                props: true,
                meta: {learningResource: true}, // used in scrollBehaviour()
            },
        ],
    },

    {
        name: 'ad-hoc-reimbursements',
        path: '/ad-hoc-reimbursements',
        component: BenefitPageAdHocReimbursements,
        meta: { menuParent: 'reimbursements' },
        children: [
            {
                name: 'reimbursement-resource-ad-hoc',
                path: ':resourceSlug',
                component: LearningResource,
                props: true,
                meta: {learningResource: true}, // used in scrollBehaviour()
            },
        ],
    },
]