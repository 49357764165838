<template>
    <menu-item :class="classList" :is-current="isCurrent">
        <template #title>
            {{ simpleBenefitTitle }}
        </template>
        <template #subMenuItems>
            <menu-item-second
                v-for="benefit in simpleBenefitCategoryObj.benefits" :key="benefit.id"
                :routePath="link(benefit)"
            >{{ benefit.name }}</menu-item-second>
        </template>
    </menu-item>
</template>

<script setup>
    import MenuItem from "@/components/layouts/side-nav/side-nav-components/MenuItem.vue"
    import MenuItemSecond from "@/components/layouts/side-nav/side-nav-components/MenuItemSecond.vue"
    import {convertToKebabCase} from "@/hooks/general/text-formatters"
    import {computed} from "vue"
    import {useRoute} from 'vue-router'
    
    const props = defineProps({
        simpleBenefitTitle: {
            required: true,
            type: String
        },
        simpleBenefitCategoryObj: {
            required: true,
            type: Object
        }
    })

    const link = (benefit) => {
        let categoryName = convertToKebabCase(benefit.category_name);
        categoryName = (categoryName === 'wellness-advice') ? 'wellness-and-advice' : categoryName
        const slug = convertToKebabCase(benefit.name)
        return `/perk/${benefit.category}/${categoryName}/${benefit.id}/${slug}`
    }
    
    const route = useRoute()
    const isCurrent = computed(() => route.params.simpleBenefitCategoryId == props.simpleBenefitCategoryObj.category)
    const classList = computed(() => {
        return {'current-parent': isCurrent.value}
    })
</script>