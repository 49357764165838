<template>
    <MDBRow tag="form" class="needs-validation the-form-row" novalidate @submit.prevent="loginHandler">
        
        <MDBRow>
            <MDBCol>
                <MDBInput
                    type="email"
                    label="Email address"
                    id="email"
                    v-model="email"
                    wrapperClass="mb-4"
                    size="lg"
                    class="font-size-15"
                    invalidFeedback="Please enter a valid work email address."
                    required
                    @keyup="clearErrors"
                />
                <MDBInput
                    type="password"
                    label="Password"
                    id="password"
                    v-model="password"
                    wrapperClass="mb-3"
                    size="lg"
                    class="font-size-15"
                    invalidFeedback="Please enter your password."
                    required
                />
            </MDBCol>
        </MDBRow>
        
        <MDBRow>
            <MDBCol>
                <p class="text-danger mb-0 mt-n1" v-if="apiResponseError">
                    <strong><span class="api-response-error-span" v-html="apiResponseError"></span></strong>
                </p>
            </MDBCol>
        </MDBRow>
        
        <MDBRow class="mb-4">
            <MDBCol class="forgot-password-col pt-1">
                <a :href="currentUrlOrigin() + '/accounts/password_reset/'"
                   target="_blank"
                   class="text-gray-500 fw-bolder hover-primary"
                >Forgot password? </a>
            </MDBCol>
        </MDBRow>
        
        <MDBRow>
            <MDBCol>
                <button-bento type="submit" size="lg" class="lets-go-log-in">
                    <span v-if="!isLoading">Let's Go</span> <span v-else class="spinner-loading-text"><MDBSpinner tag="div" size="sm"/> Logging in ...</span>
                </button-bento>
            </MDBCol>
        </MDBRow>
    
    </MDBRow>
</template>

<script setup>
    import {
        MDBRow,
        MDBCol,
        MDBInput,
        MDBSpinner,
    } from "mdb-vue-ui-kit";
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento"
    import {ref, computed} from "vue"
    import {useStore} from 'vuex'
    import {useRouter, useRoute} from 'vue-router'
    import {currentUrlOrigin} from "@/hooks/api/app-environment-origin"
    import {loadAllApiData} from "@/hooks/api/api-dispatch-calls"
    
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    
    const email = ref("")
    const password = ref("")
    const emailValid = ref(true)
    const passwordValid = ref(true)
    const isLoading = ref(false)
    const apiResponseError = ref(null)
    
    const formIsValid = computed(() => !!(emailValid.value && passwordValid.value))
    
    const clearErrors = () => {
        apiResponseError.value = false
    }
    
    const loginHandler = async (e) => {
        
        // validation
        e.target.classList.add("was-validated")
        emailValid.value = true
        passwordValid.value = true
        if (email.value === '' || !email.value.includes('@')) {
            emailValid.value = false
        }
        if (password.value.length == 0) {
            passwordValid.value = false
        }
        if (!formIsValid.value) return
        
        // passed form validation -> API Call
        const loginSignupPayload = {
            email: email.value,
            password: password.value,
        }
        
        isLoading.value = true
        try {
            await store.dispatch('getAuthToken', loginSignupPayload) // 1. get auth token and userId
            await store.dispatch('loadEmployee', {forceRefresh: true}) // 2. load employee first, in order to check tc_date_of_acceptance
            await loadAllApiData(false) // 3. load all api data
            let redirectUrl = '/' // 4. once data set -> redirect from login page to home or query redirect var
            if (route.query.redirect) {
                redirectUrl = route.query.redirect
            }
            await router.replace(redirectUrl)
        } catch (error) {
            if (error.message === 'Failed to fetch') {
                apiResponseError.value = `Looks like something went wrong with logging in. Please <a href="mailto:hello@mybento.net">contact us for assistance.<a/>`
            } else {
                apiResponseError.value = error.message
            }
        }
        isLoading.value = false
    }
</script>

<style lang="scss" scoped>
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .spinner-loading-text {
        color: $primary;
    }
    
    .the-form-row {
        max-width: 340px;
    }
    
    :deep(.form-outline .form-control.form-control-lg ~ .form-label) {
        font-size: 15px !important;
        padding-top: 13px;
    }
    
    :deep(.form-outline .form-control.form-control-lg:focus ~ .form-label) {
        padding-top: 8px;
    }
    
    :deep(.invalid-feedback) {
        margin-top: -14px;
    }
    
    :deep(.api-response-error-span a) {
        text-decoration: underline;
        &:hover {
            color: darken($danger, 20%) !important;
        }
    }
    
    .lets-go-log-in {
        min-width: 160px;
    }
</style>