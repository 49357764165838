// ui state module getters
export default {
    // modals
    showRemSummaryModal: state => state.showRemSummaryModal,

    // rem changes combined
    showRemDraftModalCombined: state => state.showRemDraftModalCombined,
    showRemSubmittedModalCombined: state => state.showRemSubmittedModalCombined,

    // medical
    showRemDraftModalMedical: state => state.showRemDraftModalMedical,
    showConfirmMedicalAidChangesModal: state => state.showConfirmMedicalAidChangesModal,

    // retirement modals
    showRemDraftModalRetirement: state => state.showRemDraftModalRetirement,
    showConfirmRetirementFundChangesModal: state => state.showConfirmRetirementFundChangesModal,
    // retirement interface
    selectedRetirementView: state => state.selectedRetirementView,
    currentRetirementWizardStep: state => state.currentRetirementWizardStep,

    // side nav
    isSideNav: state => state.isSideNav,
    isSideNavSlim: state => state.isSideNavSlim,

    // employee directory
    isListView: state => state.isListView,

    // api error management
    showApiErrorModal: state => state.showApiErrorModal,
    apiErrorModalTitle: state => state.apiErrorModalTitle,
    apiErrorMessage: state => state.apiErrorMessage,

    // leave
    selectedLeaveView: state => state.selectedLeaveView,
    selectedLeaveType: (state, getters) => state.selectedLeaveType ? state.selectedLeaveType : getters.leaveBalancesSorted ? getters.leaveBalancesSorted[0] : null,
}