<template>
    <div>
        <card-general-inner
            v-if="hasRetirementFundContract || hasRetirementFundContractPlanned"
            class="green no-shadow gradient-green-text-dark mt-5"
        >
            <div class="font-size-18 font-weight-600 mb-4">Application Summary</div>
            
            <div v-if="areRetirementDraftChangesDifferentToCurrent || areRetirementChangesSubmitted" class="mt-2 mb-3">
                <div class="fw-600 font-size-16 mb-1" v-if="areRetirementChangesSubmitted">Submitted Retirement Fund Changes</div>
                <div class="fw-600 font-size-16 mb-1" v-else>Draft Retirement Fund Changes</div>
                <ul class="mb-0 list-unstyled font-weight-500 gradient-green-text-light">
                    <li v-for="change in textSummaryOfChangesRetirement" :key="change" v-html="change"></li>
                </ul>
            </div>
            
            <div v-if="hasRetirementFundContract" class="mt-2 mb-3">
                <div class="fw-600 font-size-16">Current Plan</div>
                <ul class="mb-0 list-unstyled font-weight-500 changes-list gradient-green-text-light">
                    <li v-for="change in textSummaryOfCurrentPlanRetirement" :key="change" v-html="change"></li>
                </ul>
            </div>
        </card-general-inner>
        
        <!--notices before submitting-->
        <div class="mt-4" v-if="!areRetirementChangesSubmitted">
            <!--no temp selected & no draft-->
            <div v-if="!tempSelectedRetirementFundContractObj || (!tempSelectedRetirementFundContractObj.fundId && !retirementFundContractObjDraft)">
                <bento-alert color="warning" static v-model="noDraftnoTempNotice">
                    Please select a retirement fund above and save your changes before submitting.
                </bento-alert>
            </div>
            <!--must save before comparisons can happen-->
            <div v-else-if="!retirementFundContractObjDraft">
                <bento-alert color="warning" static v-model="saveChangesNotice">
                    Please save your changes before submitting.
                </bento-alert>
            </div>
            <!--then we compare-->
            <div v-else>
                <div v-if="areRetirementTempChangesDifferentToDraft">
                    <bento-alert color="secondary" static v-model="tempChangesNotSavedNotice">
                        You have unsaved changes. Please save your changes to view change comparison before submitting.
                    </bento-alert>
                </div>
                <div v-else>
                    <bento-alert color="warning" static v-model="noChangesMadeNotice" v-if="!areRetirementDraftChangesDifferentToCurrent">
                        You haven't made any changes. Update your contribution in <span @click="stepNavigationHandler(1)" class="fw-600 cursor-pointer hover-underline">step 1</span> or choose a different plan above.
                    </bento-alert>
                    <bento-alert color="warning" static v-model="addBeneficiaryNotice" v-if="!hasABeneficiary">
                        Please add at least one beneficiary in <span @click="stepNavigationHandler(2)" class="fw-600 cursor-pointer hover-underline">step 2</span> before submitting your application.
                    </bento-alert>
                    <bento-alert color="warning" static v-model="addBeneficiaryNotice" v-if="!areAllBeneficiariesComplete && hasABeneficiary">
                        All fields for your beneficiaries need to be completed in <span @click="stepNavigationHandler(2)" class="fw-600 cursor-pointer hover-underline">step 2.</span><br>
                        Beneficiary allocation must add up to 100%.
                    </bento-alert>
                    <bento-alert color="warning" static v-model="addBeneficiaryNotice" v-if="!areBeneficiaryAllocations100 && areAllBeneficiariesComplete && hasABeneficiary">
                        Beneficiary allocation must <span class="fw-600">add up to 100%</span>. Please update in <span @click="stepNavigationHandler(2)" class="fw-600 cursor-pointer hover-underline">step 2.</span><br>
                        Your allocations currently add up to <span class="fw-600">{{ beneficiaryAllocationsTotal }}%</span>.
                    </bento-alert>
                </div>
            </div>
        </div>
        
        <!--action buttons and notices-->
        <bento-alert :color="noticeColor" static v-model="showSaveDraftNotice" v-if="showSaveDraftNotice" class="mb-0 mt-4">
            {{ saveDraftNoticeText }}
        </bento-alert>
        <div id="loading-retirement-fund-changes" v-if="isSubmittingChanges">
            <loading-small v-model="isSubmittingChanges" parentSelector="#loading-retirement-fund-changes" loadingText="Saving as draft ..."/>
        </div>
        <div class="mt-4 pt-2 mb-3 d-flex flex-wrap flex-md-nowrap" v-if="!areRetirementChangesSubmitted">
            <button-bento
                color="dark" class="me-sm-2"
                @click="submitApplicationHandler"
                :disabled="( !areRetirementDraftChangesDifferentToCurrent || !hasABeneficiary || !areBeneficiaryAllocations100 || !areAllBeneficiariesComplete)"
            >
                <span>Submit Application</span> <img src="@/assets/v2/icons/arrows/arrow-right-white.svg" class="arrow"/> <img src="@/assets/v2/icons/arrows/arrow-right-black.svg" class="arrow-hover"/>
            </button-bento>
            
            <button-bento
                v-if="areRetirementTempChangesDifferentToDraft || !retirementFundContractObjDraft"
                color="white-bg-green" class="ms-md-2 mt-3 mt-sm-0" @click="saveDraftRetirementContractHandler">
                <span>Save as draft</span>
            </button-bento>
        </div>
    
    </div>
</template>

<script setup>
    import CardGeneralInner from '@/components/UI/v2/containers-cards-headers/CardGeneralInner.vue'
    import {computed, ref} from "vue"
    import {useStore} from "vuex"
    import BentoAlert from "@/components/UI/v2/alerts/BentoAlert.vue"
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento.vue"
    import {useSaveDraftRetirementContractHandler} from "@/hooks/retirement/save-draft-retirement-contract"
    import LoadingSmall from "@/components/UI/v2/loading/LoadingSmall.vue"
    
    const store = useStore()
    
    // notices
    const noChangesMadeNotice = ref(true)
    const saveChangesNotice = ref(true)
    const addBeneficiaryNotice = ref(true)
    const tempChangesNotSavedNotice = ref(true)
    const noDraftnoTempNotice = ref(true)
    
    // current contract
    const hasRetirementFundContract = computed(() => store.getters.hasRetirementFundContract)
    const textSummaryOfCurrentPlanRetirement = computed(() => store.getters.textSummaryOfCurrentPlanRetirement)
    
    // draft contract
    const retirementFundContractObjDraft = computed(() => store.getters.retirementFundContractObjDraft)
    
    // are draft or submitted changes
    const areRetirementDraftChangesDifferentToCurrent = computed(() => store.getters.areRetirementDraftChangesDifferentToCurrent)
    const areRetirementChangesSubmitted = computed(() => store.getters.areRetirementChangesSubmitted)
    
    // has submitted or draft contract
    const hasRetirementFundContractPlanned = computed(() => store.getters.hasRetirementFundContractPlanned)
    
    // are submitted contract
    
    
    // temp contract
    const tempSelectedRetirementFundContractObj = computed(() => store.getters.tempSelectedRetirementFundContractObj)
    const areRetirementTempChangesDifferentToDraft = computed(() => store.getters.areRetirementTempChangesDifferentToDraft)
    
    // summary of changes
    const textSummaryOfChangesRetirement = computed(() => store.getters.textSummaryOfChangesRetirement)
    
    // are there changes to submit / ready to submit
    const hasABeneficiary = computed(() => store.getters.hasABeneficiary)
    const areBeneficiaryAllocations100 = computed(() => store.getters.areBeneficiaryAllocations100)
    const areAllBeneficiariesComplete = computed(() => store.getters.areAllBeneficiariesComplete)
    const beneficiaryAllocationsTotal = computed(() => store.getters.beneficiaryAllocationsTotal)
    
    const stepNavigationHandler = (step) => store.commit('setCurrentRetirementWizardStep', step)
    
    
    // action button handlers
    const isSubmittingChanges = ref(false)
    const noticeColor = ref('primary')
    const showSaveDraftNotice = ref(false)
    const saveDraftNoticeText = ref(null)
    
    const submitApplicationHandler = () => {
        saveDraftNoticeText.value = null
        showSaveDraftNotice.value = false
        store.commit('toggleShowRemDraftModalRetirement', true)
    }
    
    const saveDraftRetirementContractHandler = () => {
        useSaveDraftRetirementContractHandler(tempSelectedRetirementFundContractObj, showSaveDraftNotice, saveDraftNoticeText, noticeColor, isSubmittingChanges)
    }
</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    ul {
        line-height: 1.9;
        :deep(li span) {
            font-weight: 600;
            color: $gradient-green-text-dark;
        }
    }
    
    .file-upload-interface-container {
        width: 260px;
        padding: 10px;
        border: 1px solid rgba(102, 102, 102, 0.25);
        border-radius: 13px;
    }
    
    .arrow,
    .arrow-hover {
        margin-left: 10px;
        margin-bottom: 2px;
        width: 16px;
    }
    button {
        .arrow-hover {
            display: none;
        }
        &:hover {
            .arrow {
                display: none;
            }
            .arrow-hover {
                display: inline;
            }
        }
        flex: 0 0 100%;
        @include media-breakpoint-up(sm) {
            flex: 0 1 auto;
        }
    }
    
    .contact-us-link:hover {
        text-decoration: underline;
    }
    
    #loading-retirement-fund-changes {
        min-height: 50px;
        margin-top: 25px;
        margin-bottom: 25px;
    }
</style>
