// ### content cards actions
import theApiCall from '@/hooks/api/the-api-call'
import {apiResponseHandler} from "@/hooks/api/api-helper-function";

export default {

    async loadContentCards(context, payload) {
        if (!payload.forceRefresh && !context.getters.shouldUpdateContentCards) { return }

        const response = await theApiCall('content_cards/')
        const responseData = await apiResponseHandler(response, 'Could not load content cards.')

        context.commit('setContentCards', responseData)
        context.commit('setFetchContentTimeStamp', { timestamp: new Date().getTime() })
    }

}