<template>
    
    <div class="p-5 mb-5 rounded-3 bg-primary-100">
        
        <h4>Testing purposes - only showed to Bento admins</h4>
        
        <div>
            <small><strong style="text-decoration: underline">Planned "draft" medical aid contract object to be submitted</strong></small><br>
            <small><strong style="text-decoration: underline">OR "submitted" medical aid contract object to be finalised in back-end by Bento</strong></small><br>
            <pre v-if="hasMedicalAidContractPlanned">
                {{ medicalAidContractObjPlanned }}
            </pre>
            <pre v-else>
                No saved draft / submitted medical aid contract.
            </pre>
        
        </div>
        
        <div>
            <small><strong style="text-decoration: underline">Current Medical Aid Contract</strong></small>
            <pre v-if="hasMedicalAidContract">
                {{ medicalAidContractObj }}
            </pre>
            <pre v-else>
                No defined current medical aid contract. Upload or create in CMS if there should be one.
            </pre>
        </div>
    
        <div>
            <small><strong style="text-decoration: underline">Temp Selected Contract Object</strong></small><br>
            <small><strong style="text-decoration: underline">Used to temporarily hold data changes before API POST / PUT</strong></small><br>
            <small><strong style="text-decoration: underline">This is pre-populated on load with the saved draft plan, or current (if no planned)</strong></small>
            <pre>
                {{ tempSelectedMedicalAidContractObj }}
            </pre>
        </div>
        
        <div class="base-button-cont">
            <button-bento @click="fetchContractChange">
                Fetch Medical Aid Contract Object Change Request List
            </button-bento>
        </div>
        
        <div class="d-none">
            <h4>Medical Aid Plan Addon List (Vitality)</h4>
            <div>
                <pre>{{ medicalAidPlanAddonList }}</pre>
            </div>
        </div>
    
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import ButtonBento from "@/components/UI/v2/buttons/ButtonBento";

    export default {
        name: "WizardBlockTestingInfo",
        components: {ButtonBento},
        computed: {
            ...mapGetters([
                'employee',
                'age',
                'adultDependants',
                'childDependants',
                'hasMedicalAidContract',
                'medicalAidContractObj',
                'hasMedicalAidContractPlanned',
                'medicalAidContractObjPlanned',
                'medicalAidPlanAddonList',
                'tempSelectedMedicalAidContractObj',
            ]),
            
        },
        
        methods: {
            
            async fetchContractChange(refresh = false) {
                try {
                    await this.$store.dispatch('loadMedicalAidContractChangeRequestList', {forceRefresh: refresh});
                } catch (error) {
                    // this.error = error.message || 'Error: Could not load employee data!';
                    return error
                }
            }
            
        },
        
    }
</script>