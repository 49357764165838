<template>
    <div class="carousel-container">
        
        <!--Vitality Plan Options-->
        <Carousel
            :settings="settings"
            :breakpoints="breakpoints"
        >
            <Slide v-for="slide in medicalAidPlanAddonList" :key="slide">
                <div class="carousel__item">
                    <wizard-block-info-card-addon-plan
                        :vitality-plan="slide"
                    />
                </div>
            </Slide>
            
            <template #addons>
                <Navigation/>
                <Pagination/>
            </template>
        </Carousel>
        
    
    </div>
</template>

<script>
    // docs: https://ismail9k.github.io/vue3-carousel/examples.html
    
    import {defineComponent} from 'vue'
    import {Carousel, Navigation, Pagination, Slide} from 'vue3-carousel';
    
    import 'vue3-carousel/dist/carousel.css';
    import {mapGetters} from "vuex";
    import WizardBlockInfoCardAddonPlan
        from "@/components/benefits-leave-reimbursements/medical-aid/wizard/card-carousel/addons-plan/WizardBlockInfoCardAddonPlan";
    
    export default defineComponent({
        name: 'WizardBlockCardCarouselAddonPlan',
        components: {
            WizardBlockInfoCardAddonPlan,
            Carousel,
            Slide,
            Pagination,
            Navigation,
        },
        
        computed: {
            
            ...mapGetters([
                'medicalAidPlanAddonList',
            ]),
            
            
            // *** CURRENTLY DON'T KNOW WHAT VITALITY OPTION THE EMPLOYEE HAS ***
            // *** THEN WILL NEED TO ADD AN EDIT FUNCTION ***
            
            
            
        },
        
        data: () => ({
            
            // carousel settings
            settings: {
                itemsToShow: 1,
                snapAlign: 'start',
                wrapAround: true,
            },
            // breakpoints are mobile first
            // any settings not specified will fallback to the carousel settings
            breakpoints: {
                // 700px and up
                700: {
                    itemsToShow: 3,
                    snapAlign: 'center',
                    wrapAround: false,
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'center',
                    wrapAround: false,
                },
            },
            
        }),
    });
</script>

<style scoped>
    /*Carousel styles come from unscoped WizardBlockCardCarouselSeries*/
</style>