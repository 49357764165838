<template>
    <button class="hamburger hamburger--arrow" :class="{'is-active':isSideNav}" type="button" @click="toggleSideNavHandler(!isSideNav)">
        <span class="hamburger-box"><span class="hamburger-inner"></span></span>
    </button>
</template>

<script setup>
    import {computed} from "vue"
    import {useStore} from "vuex"
    
    const store = useStore()
    
    const isSideNav = computed({
        get: () => store.getters.isSideNav,
        set: (isSideNav) => store.commit('toggleIsSideNav', isSideNav)
    })
    
    const toggleSideNavHandler = (isSideNav) => {
        store.commit('toggleIsSideNav', isSideNav)
        store.commit('toggleIsSideNavSlim', false)
    }
</script>

<style lang="scss" scoped>
    @use "sass:math";
    
    /*!
     * Hamburgers
     * @description Tasty CSS-animated hamburgers
     * @author Jonathan Suh @jonsuh
     * @site https://jonsuh.com/hamburgers
     * @link https://github.com/jonsuh/hamburgers
     */
    
    // Settings
    // ==================================================
    $hamburger-padding-x: 5px !default;
    $hamburger-padding-y: 4px !default;
    $hamburger-layer-width: 32px !default;
    $hamburger-layer-height: 3px !default;
    $hamburger-layer-spacing: 6px !default;
    $hamburger-layer-color: white !default;
    $hamburger-layer-border-radius: 2px !default;
    $hamburger-hover-opacity: 0.8 !default;
    $hamburger-active-layer-color: $hamburger-layer-color !default;
    $hamburger-active-hover-opacity: $hamburger-hover-opacity !default;
    
    // To use CSS filters as the hover effect instead of opacity,
    // set $hamburger-hover-use-filter as true and
    // change the value of $hamburger-hover-filter accordingly.
    $hamburger-hover-use-filter: false !default;
    $hamburger-hover-filter: opacity(50%) !default;
    $hamburger-active-hover-filter: $hamburger-hover-filter !default;
    
    // Base Hamburger (We need this)
    // ==================================================
    
    .hamburger {
        // ross
        margin-right: 14px !important;
        position: relative;
        top: 4px;
        // ----
        
        padding: $hamburger-padding-y $hamburger-padding-x;
        display: inline-block;
        cursor: pointer;
        
        transition-property: opacity, filter;
        transition-duration: 0.15s;
        transition-timing-function: linear;
        
        // Normalize (<button>)
        font: inherit;
        color: inherit;
        text-transform: none;
        background-color: transparent;
        border: 0;
        margin: 0;
        overflow: visible;
        
        &:hover {
            @if $hamburger-hover-use-filter == true {
                filter: $hamburger-hover-filter;
            } @else {
                opacity: $hamburger-hover-opacity;
            }
        }
        
        &.is-active {
            &:hover {
                @if $hamburger-hover-use-filter == true {
                    filter: $hamburger-active-hover-filter;
                } @else {
                    opacity: $hamburger-active-hover-opacity;
                }
            }
            
            .hamburger-inner,
            .hamburger-inner::before,
            .hamburger-inner::after {
                background-color: $hamburger-active-layer-color;
            }
        }
    }
    
    .hamburger-box {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
        display: inline-block;
        position: relative;
    }
    
    .hamburger-inner {
        display: block;
        top: 50%;
        margin-top: math.div($hamburger-layer-height, -2);
        
        &,
        &::before,
        &::after {
            width: $hamburger-layer-width;
            height: $hamburger-layer-height;
            background-color: $hamburger-layer-color;
            border-radius: $hamburger-layer-border-radius;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
        }
        
        &::before,
        &::after {
            content: "";
            display: block;
        }
        
        &::before {
            top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
        }
        
        &::after {
            bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
        }
    }
    
    
    // Hamburger types - only include slider & arrow
    // ==================================================
    
    // slider
    .hamburger--slider {
        .hamburger-inner {
            top: math.div($hamburger-layer-height, 2);
            
            &::before {
                top: $hamburger-layer-height + $hamburger-layer-spacing;
                transition-property: transform, opacity;
                transition-timing-function: ease;
                transition-duration: 0.15s;
            }
            
            &::after {
                top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
            }
        }
        
        &.is-active {
            .hamburger-inner {
                $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;
                
                transform: translate3d(0, $y-offset, 0) rotate(45deg);
                
                &::before {
                    transform: rotate(-45deg) translate3d(math.div($hamburger-layer-width, -7), $hamburger-layer-spacing * -1, 0);
                    opacity: 0;
                }
                
                &::after {
                    transform: translate3d(0, $y-offset * -2, 0) rotate(-90deg);
                }
            }
        }
    }
    
    // Arrow
    .hamburger--arrow.is-active {
        transform: scale(.8);
        
        .hamburger-inner {
            &::before {
                transform: translate3d($hamburger-layer-width * -0.2, 0, 0) rotate(-45deg) scale(0.7, 1);
            }
            
            &::after {
                transform: translate3d($hamburger-layer-width * -0.2, 0, 0) rotate(45deg) scale(0.7, 1);
            }
        }
    }
</style>