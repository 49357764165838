// employee reimbursements getters


import {isValidArray} from "@/hooks/general/type-and-value-checks";

export default {

    lastReimbursementAllowanceListFetch: state => state.lastReimbursementClaimListFetch,
    shouldUpdateReimbursementAllowanceList: (_, getters) => {
        if (!getters.lastReimbursementAllowanceListFetch) return true
        const currentTimeStamp = new Date().getTime()
        return (currentTimeStamp - getters.lastReimbursementAllowanceListFetch) / 1000 > 900
    },


    payslipReimbursements: (_, getters) => getters.latestPayslip?.reimbursements || null,

    reimbursementAllowanceList: state => state.reimbursementAllowanceList, // from allowance types set up in Bento (these don't appear in payroll)
    reimbursementAllowanceListExclAdHoc: (_, getters) => getters.reimbursementAllowanceList?.filter(allowance => allowance.method != 'adhoc') || null,
    reimbursementAllowanceListAdHocOnly: (_, getters) => getters.reimbursementAllowanceList?.filter(allowance => allowance.method == 'adhoc') || null,

    adHocReimbursementAllowance: (_, getters) => getters.reimbursementAllowanceList?.find(allowance => allowance.method === 'adhoc') || null,

    employeeReimbursementApprovers: (_, getters) => getters.employee?.reimbursement_approvers || null,
    reimbursementApproversCommaSeparated: (_, getters) => {
        if (!isValidArray(getters.employeeReimbursementApprovers)) return false
        let approvers = []
        for (const approver of getters.employeeReimbursementApprovers) {
            approvers.push(approver.name)
        }
        return approvers.join(', ')
    },

    // incl. ad hoc
    totalReimbursiveAllowances: (_, getters) => {
        let total = 0
        if (isValidArray(getters.reimbursementAllowanceList)) {
            getters.reimbursementAllowanceList.forEach(allowance => total += Number(allowance.amount))
        }
        return {
            id: 'reimbursive-allowances-budget',
            label: 'Total Reimbursive Allowances',
            api: total,
            current: false,
            planned: false,
        }
    },

    // exclude ad hoc
    totalReimbursementAllowancesExAdHoc: (_, getters) => {
        let total = 0
        if (isValidArray(getters.reimbursementAllowanceListExclAdHoc)) {
            getters.reimbursementAllowanceListExclAdHoc.forEach(allowance => total += Number(allowance.amount))
        }
        return {
            id: 'reimbursive-allowances-budget-ex-ad-hoc',
            label: 'Total Reimbursive Allowances',
            api: total,
            current: false,
            planned: false,
        }
    },


    // ### reimbursement claim list (These are just the budgets)
    // -----------------------------------------------------------
    lastReimbursementClaimListFetch: (state) => state.lastReimbursementClaimListFetch,
    shouldUpdateReimbursementClaimList: (_, getters) => !getters.lastReimbursementClaimListFetch || ((new Date().getTime() - getters.lastReimbursementClaimListFetch) / 1000) > 300,

    reimbursementClaimList: (state) => state.reimbursementClaimList,
    reimbursementClaimApprovers: (_, getters) => getters.employee.reimbursement_approvers,
    hasReimbursementClaimApprover: (_, getters) => Array.isArray(getters.reimbursementClaimApprovers) && getters.reimbursementClaimApprovers.length > 0,


    // ### Reimbursements (The actual reimbursement expense claims)
    // ------------------------------------------------------------


    // *** OUR ACTUAL SUBMITTED EXPENSE CLAIMS WILL BE SENT BACK TO US AS A SINGLE ITEM
    // *** These are the expense claims submitted against the created reimbursement claims list (budgets). Fetched from above.
    // *** this will be submitted expenses -> expense claim (non-taxable)
    // *** for some strange reason this appears under 'Allowances' on the payslip per SimplePay
    // *** ### So that is where I create the getter -> in the allowances payslip-financials/allowances module

    // expenseClaimsNonTaxable() {
    //      created in allowances module
    // },


    // *** Custom Reimbursements created in SimplePay for some strange reason appear in their own section on the payslip
    // *** You can find these in the 'reimbursements" property on the latest payslip
    reimbursementsPaidOutList: (_, getters) => getters.latestPayslip?.reimbursements,

    totalReimbursementsPaidOut(_, getters) {
        let current = 0;
        if (getters.reimbursementsPaidOutList) {
            for (const reimbursement of getters.reimbursementsPaidOutList) {
                current += Number(reimbursement.amount);
            }
        }
        return {
            id: 'total-reimbursements-finalised',
            label: 'Total Reimbursements Finalised',
            api: current,
            current,
            planned: current,
        }
    },

}