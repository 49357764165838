import {useLogOut} from "@/hooks/api/logout"

export async function apiResponseHandler(apiResponse, alternativeErrorMessage = 'Could not fetch your data.', resourceNotFoundMessage = 'Resource not found', debugFlag = null) {
    let responseData, errorMessage
    const contentType = apiResponse.headers.get("content-type")
    if (apiResponse.ok) {
        if (contentType && contentType.includes("application/json")) {
            responseData = await apiResponse.json()
            if (debugFlag) {
                console.warn(debugFlag)
                console.warn(responseData)
            }
        } else if (contentType && contentType.includes("text/html; charset=utf-8")) {
            console.warn('text/html; charset=utf-8 => response')
            // const textResponse = await apiResponse.text()
            // console.log(textResponse)
        }
        else {
            console.warn('nonJSON & Non text/html; charset=utf-8')
        }
    }

    // RESPONSE NOT OK -> ok: false,
    else {
        if (apiResponse.status == 404) {
            errorMessage = resourceNotFoundMessage
        }
        else if (apiResponse.status === 500) {
            errorMessage = apiResponse.statusText + '. Please contact us on hello@mybento.net'
        }
        else if (apiResponse.status === 501) {
            errorMessage = apiResponse?.statusText + '. Server error. [501]. Please contact us on hello@mybento.net'
        }
        else if (apiResponse.status === 502) {
            console.error('502 server error. Please contact us on hello@mybento.net')
            errorMessage = 'There has been a error. Please contact us on hello@mybento.net'
        }
        else if (apiResponse.status === 504) {
            errorMessage = 'Your request has timed out. [504].'
        }

        // 401 - UNAUTHORISED
        else if (apiResponse.status === 401) {
            responseData = await apiResponse.json()
            errorMessage = responseData.state ||
                responseData.detail ||
                responseData.message ||
                responseData[0] ||
                responseData.amount ||
                responseData.file[0]
            await useLogOut()
            // TO DO
            // may want to logout just for invalid token
            // as a 401 may be a valid response in certain cases
            // need to rethink this
            // if (typeof errorMessage === "string" && (errorMessage.toLowerCase() === 'invalid token' || errorMessage.toLowerCase() === 'the token is expired')) {
            //     console.log('logging out cos of token')
            //     debugger;
            //     await router.replace({name: 'login'})
            //     debugger;
            //     await store.dispatch('logout')
            //     debugger;
            //     return
            // }
        }

            // 400 - BAD REQUEST, OR
            // 403 - FORBIDDEN -  Unlike a 401 "Unauthorized" response, authenticating will make no difference.
        // The client does not have permission to access the requested resource.
        else if (apiResponse.status === 400 || apiResponse.status === 403) {
            const errorMessageResponse = await apiResponse.json()
            if (Array.isArray(errorMessageResponse)) {
                errorMessage = errorMessageResponse[0]
            }
            if (typeof errorMessageResponse === 'object' && errorMessageResponse !== null && !Array.isArray(errorMessageResponse) && !(errorMessageResponse instanceof Function)) {
                let keys = Object.keys(errorMessageResponse)
                if (keys.length > 0) {
                    errorMessage = errorMessageResponse[keys[0]]
                }
                // sometimes there are nested objects with the error response
                if (typeof errorMessage === 'object') {
                    errorMessage = Object.values(errorMessage)[0]
                }
            }
        } else {
            responseData = await apiResponse.json()
            if (typeof responseData === 'object') {
                const firstKey = Object.keys(responseData)[0]
                const firstValue = Object.values(responseData)[0]
                errorMessage = `${firstKey}: ${firstValue[0]}`
            } else {
                errorMessage = responseData.state ||
                    responseData.detail ||
                    responseData.message ||
                    responseData[0] ||
                    responseData.amount ||
                    responseData.file[0]
            }
        }
        throw new Error(errorMessage || alternativeErrorMessage)
    }
    return responseData
}