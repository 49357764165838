<template>
    <div>
        <width-container>
        
            <raw-output-block
                title="Deductions List"
                :computed-property-to-display="deductionsList"
            ></raw-output-block>
    
            <raw-output-block
                title="Employee Medical Aid"
                :computed-property-to-display="employeeMedicalAid"
            ></raw-output-block>
    
            <raw-output-block
                title="Employee Pension Fund"
                :computed-property-to-display="employeePensionFund"
            ></raw-output-block>
    
            <raw-output-block
                title="Tax (PAYE)"
                :computed-property-to-display="taxPayeApi"
            ></raw-output-block>
    
            <raw-output-block
                title="Employee Repayment of Advance"
                :computed-property-to-display="employeeRepaymentOfAdvance"
            ></raw-output-block>
    
            <raw-output-block
                title="Employee UIF"
                :computed-property-to-display="employeeUif"
            ></raw-output-block>
    
            <raw-output-block
                title="Total Employee Deductions"
                :computed-property-to-display="totalEmployeeDeductionsCalc"
            ></raw-output-block>
            
        </width-container>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import WidthContainer from "@/components/UI/v2/containers-cards-headers/WidthContainer";
    import RawOutputBlock from "@/components/admin-restricted/salary-figures/RawOutputBlock";

    export default {
        name: "SalaryFiguresDeductions",
        components: {RawOutputBlock, WidthContainer},
        computed: {
            ...mapGetters([
                'deductionsList',
                'employeeMedicalAid',
                'employeePensionFund',
                'employeeRepaymentOfAdvance',
                'employeeUif',
                'totalEmployeeDeductionsCalc'
            ])
        }
    }
</script>

<style scoped>

    
</style>