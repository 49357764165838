<template>
    <div
        class="menu-item-2nd cursor-pointer text-gray-main font-weight-500 rounded-1 font-size-13"
        :class="classList"
        @click="menuItemClickHandler"
    >
        <slot></slot>
    </div>
</template>

<script setup>
    import {useRoute, useRouter} from "vue-router";
    import {computed} from "vue";
    
    const router = useRouter()
    const route = useRoute()
    
    const props = defineProps({
        routePath: {
            type: String,
            required: false,
        },
        routeName: {
            type: String,
            required: false,
        },
        sectionId: {
            type: String,
            required: false,
        }
    })
    
    const classList = computed(() => {
        if (route.path === '/my-team/leave-management') {
            return {'current': route.hash === `#${props.sectionId}`}
        }
        if (route.path === '/my-team/reimbursements') {
            return {'current': route.hash === `#${props.sectionId}`}
        }
        if (route.name === 'employee-directory-employee-profile' && props.routeName === 'employee-directory') {
            return {'current': true}
        }
        return {'current': route.path === props.routePath || route.name === props.routeName}
    })
    
    const menuItemClickHandler = () => {
        let navigationObject = {}
        
        if (props.routeName) {
            navigationObject.name = props.routeName
        } else if (props.routePath) {
            navigationObject.path = props.routePath
        }
        
        if (props.sectionId) {
            navigationObject.hash = `#${props.sectionId}`
        }
        router.push(navigationObject)
    }


</script>

<style scoped lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    .menu-item-2nd {
        line-height: 1.35;
        padding: 6px 4px 6px 20px;
        margin: 2px 4px 2px 0;
        &:hover {
            background: $gray-50;
        }
        
        &:hover, &.current {
            background: $gray-50;
            color: $primary !important;
        }
        
        &.drafted-changes-item {
            background: lighten($gradient-pink-1, 2%);
            color: $gradient-pink-text-dark !important;
            &:hover {
                background: $gradient-pink-1;
            }
        }
        
        &.changes-submitted-item {
            background: lighten($gradient-green-1, 2%);
            color: $gradient-green-text-dark !important;
            &:hover {
                background: darken($gradient-green-1, 1%);
            }
        }
    }
</style>