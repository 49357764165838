// employee leave store module [not namespaced]
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

export default {
    state() {
        return {
            // this is an array of request objects
            leaveRequestList: null,

            // timestamp for the last fetch, api call caching purposes
            lastLeaveRequestListFetch: null,
        }
    },
    mutations,
    actions,
    getters,
}