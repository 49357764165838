// static content module mutations
// not namespaced

export default {

    setStaticContent(state, payload) {
        state.staticContent = payload;
    },

    setLastStaticContentFetch(state, payload) {
        state.lastStaticContentFetch = payload.timestamp;
    }

}