<template>
    <div v-if="false">
        <div><pre>summaryStats: {{ summaryStats }}</pre></div>
        <div><pre>totalPackage: {{ totalPackage }}</pre></div>
        <div><pre>grossRemuneration: {{ grossRemuneration }}</pre></div>
        <div><pre>totalReimbursementAllowancesExAdHoc: {{ totalReimbursementAllowancesExAdHoc }}</pre></div>
        <div><pre>salaryBeforeDeductions: {{ salaryBeforeDeductions }}</pre></div>
        <div><pre>totalAllowancesExclExpenseClaim: {{ totalAllowancesExclExpenseClaim }}</pre></div>
        <div><pre>employerRetirementFund: {{ employerRetirementFund }}</pre></div>
        <div><pre>medicalAidBenefit: {{ medicalAidBenefit }}</pre></div>
    </div>
    
    <rem-summary-modal-line-item
        v-for="stat in summaryStats"
        :key="stat.id"
        :stat="stat"
        stat-type="current"
    />
</template>

<script setup>
    import {computed} from "vue";
    import {useStore} from "vuex";
    import RemSummaryModalLineItem from "@/components/summary-and-payslip/modal/remSummaryModalLineItem";
    const store = useStore()
    
    const summaryStats = computed(() => store.getters.summaryStats)
    
    // testing
    const totalPackage = computed(() => store.getters.totalPackage) // Gross Remuneration + Reimbursive Budget Allowances
    const grossRemuneration = computed(() => store.getters.grossRemuneration) // getters.salaryBeforeDeductions.api + getters.totalAllowancesExclExpenseClaim.current + getters.employerRetirementFund.api + getters.medicalAidBenefit.api
    const totalReimbursementAllowancesExAdHoc = computed(() => store.getters.totalReimbursementAllowancesExAdHoc)
    
    // these make up grossRemuneration
    const salaryBeforeDeductions = computed(() => store.getters.salaryBeforeDeductions)
    const totalAllowancesExclExpenseClaim = computed(() => store.getters.totalAllowancesExclExpenseClaim)
    const employerRetirementFund = computed(() => store.getters.employerRetirementFund)
    const medicalAidBenefit = computed(() => store.getters.medicalAidBenefit)
    
</script>