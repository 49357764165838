// employee/payslip-financials/employer-contributions getters
// All contributions

// import {createPayslipGetter} from '@/services/general/getters-helpers';
import {isValidArray} from "@/hooks/general/type-and-value-checks";

export default {
    // In the SP API and payslip employer contributions are split up into
    // 1. Employer Contributions (Includes 'Pension Fund - Employer', Doesn't include medical Aid).
    // 2. Benefit (Als includes 'Pension Fund Benefit' & 'Medical Aid Benefit').

    employerContributionsList: (_, getters) => getters.latestPayslip?.employer_contributions || null,


    benefits: (_, getters) => getters.latestPayslip?.benefits || null,

    totalBenefits: (_, getters) => {
        const apiAmount = Number(getters.latestPayslip?.total_benefits) || 0
        const plannedTotal = getters.pensionFundBenefit?.planned + getters.medicalAidBenefit?.planned

        return {
            id: 'total-benefits',
            label: 'Benefits',
            api: apiAmount,
            current: false,
            planned: plannedTotal,
        }
    },


    // Employer contribution items
    // Medical Aid Benefit located in medical-aid-employee/getters.js
    // Pension Fund Benefit located in retirement-employee/getters.js


    // employer SDL
    // 1% of taxable income
    employerSdl: (_, getters) => {
        let apiAmount = 0
        if (isValidArray(getters.employerContributionsList)) {
            const sdlObj = getters.employerContributionsList.find(obj => obj.internal_name === 'employer_sdl');
            if (sdlObj) {
                apiAmount = Number(sdlObj.amount);
            } else {
                apiAmount = 0
            }

        }
        let current = getters.taxableIncomeCalc.current * .01
        let planned = getters.taxableIncomeCalc.planned * .01

        return {
            id: 'sdl-employer',
            label: 'SDL (Employer)',
            api: apiAmount,
            current: current,
            planned: planned,
        }
    },


    employerUif: (_, getters) => {
        let apiAmount = 0;
        let current = 0;
        let planned = 0;

        if (isValidArray(getters.employerContributionsList)) {
            const employerUifObj = getters.employerContributionsList.find(obj => obj.internal_name === 'employer_uif');
            const uifCalc = (gross) => (gross * .01 > 177.12) ? 177.12 : gross * .01;

            if (employerUifObj) {
                apiAmount = Number(employerUifObj.amount);
                current = uifCalc(getters.grossRemuneration.current);
                planned = uifCalc(getters.grossRemuneration.planned);
            }
        }

        return {
            id: 'uif-employer',
            label: 'UIF (Employer)',
            api: apiAmount,
            current: current,
            planned: planned,
        }
    },


    // ### EMPLOYER CONTRIBUTION TOTALS

    // AJ: Total Employer Contributions (Excluding SDL & UIF)
    // M + P
    // Pension Fund Employer + Medical Aid Employer Benefit
    totalEmployerContributionsExSdlUif(_, getters) {
        return {
            id: 'total-employer-contributions-ex-sdl-uif',
            label: 'Total Employer Contributions (excl. SDL & UIF)',
            api: false,
            current: getters.employerRetirementFund.api + getters.medicalAidBenefit.api, // should always use api as source of truth, not contract object saved in Bento
            planned: getters.employerRetirementFund.planned + getters.medicalAidBenefit.planned,
        }
    },

    // AK: Employer SDL & UIF
    // N + O
    // Employer SDL + Employer UIF
    employerSdlUif(_, getters) {
        return {
            id: 'employer-sdl-uif',
            label: 'Employer SDL & UIF Contributions',
            api: false,
            current: getters.employerSdl.api + getters.employerUif.current,
            planned: getters.employerSdl.planned + getters.employerUif.planned,
        }
    },


    totalEmployerContributions: (_, getters) => {
        const apiAmount = Number(getters.latestPayslip?.total_employer_contributions) || 0
        const employerSdlPlanned = getters.employerSdl?.planned ?? getters.employerSdl?.api
        const plannedTotal = getters.employerRetirementFund?.planned + employerSdlPlanned + getters.employerUif?.planned

        return {
            id: 'total-employer-contributions',
            label: 'Total Employer Contributions',
            api: apiAmount,
            current: false,
            planned: plannedTotal,
        }
    }


}